import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { notification, message as MESSAGE } from "antd";
import { Message } from "../../../framework/src/Message";
import firebase from "firebase";
import { ChangeEvent } from "react";
let config = require('../../../framework/src/config.js').baseURL;

export interface ValidResponseType {
    message: object;
    data: Array<object>;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface DoctorConsultation {
    created_by: string;
    id: number;
    description: string;
    status: boolean;
    consultation_category: string;
    consultation_type: string;
    price: string;
    doctor_id: number;
}
  
interface DoctorAvailability {
    start_time: string | null;
    id: number;
    service_provider_id: number | null;
    unavailable_start_time: string | null;
    unavailable_end_time: string | null;
    doctor_id: number;
    day_of_week: string[];
    mode_type: string;
    status: boolean;
    availability_date: string | null;
    timeslots: string | null;
    available_slots_count: number | null;
    end_time: string | null;
}
  
interface DataDoctor {
      type: string;
      id: string;
      attributes: {
        image: string;
        gender: string | null;
        online_consultation: DoctorConsultation[];
        inclinic_consultation: DoctorConsultation[];
        identity_details: string[];
        registration_details: string[];
        profile_image: string;
        first_name: string | null;
        last_name: string | null;
        full_name: string;
        full_phone_number: string;
        medical_representative_name: string;
        qualification: string;
        date_of_birth: string | null;
        is_kyc: string;
        hospital_admin_id: string | null;
        hospital: string | null;
        doctor_certificates: string[];
        representative_contact_no: string;
        experience: string;
        doctor_category: string;
        pin: number;
        degree_details: string[];
        language: string[];
        unique_auth_id: string | null;
        type: string | null;
        user_type: string;
        email: string;
        activated: boolean;
        device_detail: [];
        fees: number;
        reviews: [];
        reviews_count: number | null;
        doctor_weekly_availabilities: DoctorAvailability[];
        registration_no: string;
        registration_council: string;
        year: string;
        specialization: string;
        city: string;
        provider: string | null;
        about: string;
        doctor_availiablity_today: boolean;
        clinic_addresses: string[];
        device_language: string | null;
        service_offered: [];
        clinic_details: string[];
        doctor_id: number;
        text_size: string;
        mode: string;
        rating_average: number;
      }
}

interface DoctorLists {
    data: Array<DataDoctor>
}

interface PatientData {
    data: {
        id: string;
        type: string;
        attributes: {
            first_name: string | null;
            last_name: string | null;
            full_name: string;
            full_phone_number: string;
            email_id: string;
            date_of_birth: string;
            gender: string;
            weight: string;
            blood_group: string;
            city: string;
            aadhar_no: string;
            health_id: string;
            ayushman_bharat_id: string | null;
            disease: string[];
            unique_auth_id: string | null;
            type: string | null;
            user_type: string;
            provider: string | null;
            parent_patient_id: string | null;
            device_language: string | null;
            activated: boolean;
            unread_messages: [];
            last_message: string | null;
            image: string;
            device_detail: [];
            profile_photo: string;
            age: number;
            patient_id: number;
            language: string;
            mode: string;
            last_visit: string;
            appointments_details: {
                id: number;
                appointment_date: string;
                time_slot: string;
            }[];
        };
    };
}

export interface PatientListType {
    first_name: string | null;
    last_name: string | null;
    full_name: string;
    full_phone_number: string;
    email_id: string;
    gender: string;
    weight: string;
    city: string;
    health_id: string;
    profile_photo: string;
    patient_id: number;
    username: string;
    avatar: string;
}

export interface UserDoctor {
    username: string;
    avatar: string;
    registration_council: string;
    mode: string;
    rating_average: number;
    reviews: [];
    identity_details: string[];
    profile_image: string | null;
    reviews_count: number | null;
    device_detail: [];
    about: string;
    activated: boolean;
    device_language: string | null;
    service_offered: [];
    image: string;
    gender: string | null;
    first_name: string | null;
    last_name: string | null;
    full_name: string;
    full_phone_number: string;
    degree_details: string[];
    type: string | null;
    language: string[];
    doctor_availiablity_today: boolean;
    clinic_addresses: string[];
    doctor_weekly_availabilities: DoctorAvailability[];
    unique_auth_id: string | null;
    email: string;
    registration_no: string;
    qualification: string;
    online_consultation: DoctorConsultation[];
    date_of_birth: string | null;
    is_kyc: string;
    hospital_admin_id: string | null;
    hospital: string | null;
    user_type: string;
    year: string;
    specialization: string;
    city: string;
    medical_representative_name: string;
    representative_contact_no: string;
    experience: string;
    doctor_category: string;
    pin: number;
    doctor_certificates: string[];
    registration_details: string[];
    clinic_details: string[];
    doctor_id: number;
    text_size: string;
    provider: string | null;
    fees: number;
    inclinic_consultation: DoctorConsultation[];
}

export interface ChatMessage {
    createdAt: number;
    file?: string;
    type?: string;
    read: boolean;
    _id: number;
    sender: string;
    text?: string;
}

export interface DataForFriends {
    chatroomId: string;
    username: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showPopup: boolean;
    doctorLists: DoctorLists;
        patientDetails: PatientData;
    patientChatDetails: PatientListType;
    isChatMessage: boolean;
    userDoctorDetails: UserDoctor;
    patientChatBox: boolean;
    messages: Array<ChatMessage>;
    chatRoomId: string;
    typeofFile: string;
    textMessage: string;
    isLoader: boolean;
    doctorProfilePhoto: string;
    contentHeight: number;
    mediaPath: string;
    isData: boolean;
    filterdDoctorList: Array<DataDoctor>;
    searchDoctorQry: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class PatientChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDoctorsLsitApiCallId : string = "";
    getPatientDetailsApiCallId : string = "";
    subMessageListener: firebase.database.Reference | null = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            showPopup: false,
            doctorLists:{
            data : [
                {
                    id: "",
                    type: "",
                    attributes: {
                        first_name: "",
                        last_name: "",
                        full_name: "",
                        full_phone_number: "",
                        email: "",
                        registration_no: "",
                        registration_council: "",
                        year: "",
                        specialization: "",
                        city: "",
                        medical_representative_name: "",
                        representative_contact_no: "",
                        experience: "",
                        doctor_category: "",
                        pin: 0,
                        language: [],
                        unique_auth_id: null,
                        type: null,
                        user_type: "",
                        provider: null,
                        about: "",
                        activated: false,
                        device_language: null,
                        service_offered: [],
                        image: "",
                        gender: "",
                        qualification: "",
                        date_of_birth: null,
                        is_kyc: "",
                        hospital_admin_id: null,
                        hospital: null,
                        doctor_certificates: [],
                        profile_image: null || "",
                        identity_details: [],
                        degree_details: [],
                        registration_details: [],
                        clinic_details: [],
                        doctor_id: 0,
                        text_size: "",
                        mode: "",
                        rating_average: 0,
                        reviews: [],
                        reviews_count: null,
                        device_detail: [],
                        fees: 0,
                        online_consultation: [],
                        inclinic_consultation: [],
                        doctor_availiablity_today: false,
                        clinic_addresses: [],
                        doctor_weekly_availabilities: []
                    }
                }
            ]
            },
            patientDetails: {
            data: {
                id: "",
                type: "",
                attributes: {
                    first_name: "",
                    last_name: "",
                    full_name: "",
                    full_phone_number: "",
                    email_id: "",
                    date_of_birth: "",
                    gender: "",
                    weight: "",
                    blood_group: "",
                    city: "",
                    aadhar_no: "",
                    health_id: "",
                    ayushman_bharat_id: "",
                    disease: [],
                    unique_auth_id: "",
                    type: "",
                    user_type: "",
                    provider: "",
                    parent_patient_id: "",
                    device_language: "",
                    activated: false,
                    unread_messages: [],
                    last_message: "",
                    image: "",
                    device_detail: [],
                    profile_photo: "",
                    age: 0,
                    patient_id: 0,
                    language: "",
                    mode: "",
                    last_visit: "",
                    appointments_details: []
                }
            }
            },
            patientChatDetails: {    
                full_name: "",
                profile_photo: "",
                patient_id: 0,
                gender: "",
                weight: "",
                first_name: "",
                last_name: "",
                city: "",
                health_id: "",
                username: "",
                full_phone_number: "",
                email_id: "",
                avatar: ""
            },
            isChatMessage: false,
            userDoctorDetails: {
                clinic_addresses: [],
                doctor_weekly_availabilities: [],
                username: "",
                avatar: "",
                first_name: "",
                medical_representative_name: "",
                representative_contact_no: "",
                experience: "",
                doctor_category: "",
                pin: 0,
                language: [],
                unique_auth_id: null,
                type: "",
                user_type: "",
                registration_details: [],
                clinic_details: [],
                doctor_id: 0,
                text_size: "",
                mode: "",
                rating_average: 0,
                reviews: [],
                reviews_count: null,
                device_detail: [],
                fees: 0,
                full_phone_number: "",
                email: "",
                registration_no: "",
                registration_council: "",
                year: "",
                specialization: "",
                city: "",
                about: "",
                activated: false,
                device_language: null,
                service_offered: [],
                image: "",
                gender: "",
                qualification: "",
                date_of_birth: "",
                is_kyc: "",
                hospital_admin_id: null,
                hospital: null,
                doctor_certificates: [],
                profile_image: "",
                identity_details: [],
                degree_details: [],
                provider: null,
                last_name: "",
                full_name: "",
                online_consultation: [],
                inclinic_consultation: [],
                doctor_availiablity_today: false,
            },
            patientChatBox: false,
            messages: [],
            chatRoomId: "",
            typeofFile: "",
            textMessage: "",
            isLoader: false,
            doctorProfilePhoto: "",
            contentHeight: 0,
            mediaPath: "",
            isData: false,
            filterdDoctorList: [],
            searchDoctorQry: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
    const rootElement = document.getElementById('root');
      if (rootElement) {
        this.setState({ contentHeight: rootElement.offsetHeight});
    }
    this.getDoctorsListfunction();
    this.getpatientDetailsFunction();
    };

    showDetailsHandler = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };

    handleLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout succesfully",
        });
    };

    apiCall = (apiData: APIPayloadType) => {
        let { contentType, method, endPoint, body, type } = apiData;
        let token = localStorage.getItem("token");
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        const header = {
            "Content-Type": contentType,
            token
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            type === "" ? JSON.stringify(body) : body
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (responseJson.status === 500) {
            MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
            return;
        }
        if (this.validResponse(responseJson)) {
            this.apiSuccessCallBacks(apiRequestCallId, responseJson);
        }
        }
    };
    
    validResponse = (responseJson: ValidResponseType) => {
        return (responseJson);
    };
    
    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: DoctorLists & PatientData) => {
        if (apiRequestCallId === this.getDoctorsLsitApiCallId) {
          this.getDoctorsListSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getPatientDetailsApiCallId) {
            this.getpatientDetailsSuccessCallBack(responseJson);
        }
    };

    getDoctorsListfunction = async () => {
        this.getDoctorsLsitApiCallId = this.apiCall({
          contentType: configJSON.apiContentType,
          method: configJSON.getApiMethod,
          endPoint: configJSON.getDoctorsListApiEndPoint 
        });
        this.setState({isLoader : true});
    };

    searchDoctor = (event:{target:{value:string}}) => {
      let searchQry = event.target.value.toLowerCase();
      let filterdData = this.state.doctorLists.data.filter(doctorData => doctorData.attributes.full_name.toLowerCase().includes(searchQry)); 
      this.setState({
        filterdDoctorList: filterdData,
        searchDoctorQry: event.target.value
      });
    };

    getDoctorsListSuccessCallBack = (response : DoctorLists) => {
      this.setState({
        isLoader: false,
        isData: response.data ? true : false,
        doctorLists: response
      });
    };

    getpatientDetailsFunction = async () => {
        this.getPatientDetailsApiCallId = this.apiCall({
          contentType: configJSON.apiContentType,
          method: configJSON.getApiMethod,
          endPoint: configJSON.getPatientDetailsApiEndPoint 
        });
        this.setState({isLoader : true});
    };

    getpatientDetailsSuccessCallBack = (response : PatientData) => {
      this.setState({
        isLoader: false,
        patientDetails: response
      }, () => {
        this.patientChathandleMessage();
      });
    };

  patientChathandleMessage = () => {
    let patientProfileData = this.state.patientDetails.data;
    const patientUserName = "patient_" + patientProfileData.attributes.patient_id;
    const patientRef = firebase.database().ref(`users/${patientUserName}`);
    patientRef.once('value', (snapshot) => {
      const patientUser = snapshot.val() || null;
      if (patientUser) {
        this.setState({
          patientChatDetails: patientUser
        });
      } else {
        const newPatientObj = {
          username: patientUserName,
          avatar: patientProfileData.attributes.profile_photo.length > 0 ? config + patientProfileData.attributes.profile_photo : "https://i.imgur.com/IUw5yWb.png",
          ...patientProfileData.attributes
        };
        patientRef.set(newPatientObj);
      }
    });
    patientRef.on('value', (snapshot) => {
      const patientSnapData = snapshot.val();
      this.setState({
        patientChatDetails: patientSnapData
      });
    });
  };

  doctorVerificationAndToChat = (doctorData: DataDoctor) => {
    this.setState({
      isLoader: true,
      isChatMessage: true
    }, () => {
      this.doctorRegister(doctorData).then(() => {
        this.addDoctorToPatientChat().then(() => {
          this.fetchChatRoomId();
        })
      }
      )
    });
  };

  doctorRegister = (doctorDetails: DataDoctor) => new Promise<void>(async (resolve) => {
    const userNameFromMail = "doctor_" + doctorDetails.attributes.doctor_id;
    const doctorRef = firebase.database().ref(`users/${userNameFromMail}`);
    doctorRef.once('value', (snapshot) => {
      const doctorUser = snapshot.val() || null;
        if (doctorUser) {
        resolve(
          this.setState({
            userDoctorDetails: doctorUser,
            patientChatBox: true,
            doctorProfilePhoto: doctorUser.profile_image,
            messages: []
            })
        )
      } else {
        const doctorNewUsewrObj = {
          username: userNameFromMail,
          avatar: doctorDetails.attributes.profile_image.length > 0 ? config + doctorDetails.attributes.profile_image : "https://i.imgur.com/IUw5yWb.png",
          ...doctorDetails.attributes
        };
        doctorRef.set(doctorNewUsewrObj);
      }
    });
    doctorRef.on('value', (snapshot) => {
      const snapData = snapshot.val();
        resolve(
        this.setState({
          userDoctorDetails: snapData,
          doctorProfilePhoto: snapData.profile_image,
          patientChatBox: true,
          messages: []
        })
      )
    });
  });

  addDoctorToPatientChat = () => {
    return new Promise(async (resolve) => {
      try {
        const databaseRef = firebase.database();
        const { userDoctorDetails, patientChatDetails } = this.state;
        const doctorDetailsSnap = await databaseRef.ref(`users/${userDoctorDetails.username}`).once('value', () => { });
        const patientDetailsSnap = await databaseRef.ref(`users/${patientChatDetails.username}`).once('value', () => { });
        const drDetails = doctorDetailsSnap.val();
        const ptDetails = patientDetailsSnap.val();
        const docFdData = drDetails?.friends || [];
        const patFdData = ptDetails?.friends || [];
        let drInPatientList = docFdData.find((element: UserDoctor) => element.username === patientChatDetails.username);
        let ptInDoctorList = patFdData.find((element: PatientListType) => element.username === userDoctorDetails.username);
        if (drInPatientList && ptInDoctorList) {
          return resolve({
            ...drDetails,
            ...ptDetails
          });
        } else if (drInPatientList && !ptInDoctorList) {
          this.addFriendUserList(
            ptDetails, patFdData, drInPatientList.chatroomId, drDetails.username
          );
          return resolve({
            ...drDetails,
            ...ptDetails
          });
        } else if (!drInPatientList && ptInDoctorList) {
          this.addFriendUserList(
            drDetails, docFdData, ptInDoctorList.chatroomId, ptDetails.username
          );
          return resolve({
            ...drDetails,
            ...ptDetails
          });
        } else if (!drInPatientList && !ptInDoctorList) {
          const newChatroomRef = databaseRef.ref('chatrooms').push({
            firstUser: drDetails.username,
            secondUser: ptDetails.username,
            messages: [],
          });
          const newChatroomId = newChatroomRef.key;
          this.addFriendUserList(
            ptDetails, patFdData, newChatroomId, drDetails.username
          );
          this.addFriendUserList(
            drDetails, docFdData, newChatroomId, ptDetails.username
          );
          return resolve({
            ...drDetails,
            ...ptDetails
          });
        }
      } catch (error) {
        notification["error"]({
          message: "Something went wrong",
        });
      }
    });
  };

  addFriendUserList(ltsDetails: PatientListType, userFriends: Array<DataForFriends>, newChatroomId: string | null, userToAdd: string) {
    this.setState({isLoader: false});
    const friendUserRef = firebase.database().ref(`users/${ltsDetails.username}`);
    const updatedFriendLists = [
      ...userFriends,
      {
        username: userToAdd,
        chatroomId: newChatroomId,
      },
    ];
    friendUserRef.update({
      friends: updatedFriendLists,
    });
  };

  fetchChatRoomId = async () => {
    const refDataBase = firebase.database();
    let referenceUser = refDataBase.ref(`users/${this.state.patientChatDetails.username}`);
    try {
      const snapshot = await referenceUser.once('value', () => { });
      let listFriend = snapshot.val().friends;
      let roomId = "";
      for (let element of listFriend) {
        if (element.username === this.state.userDoctorDetails.username) {
          roomId = element.chatroomId;
        }
      }
      this.setState({
        chatRoomId: roomId,
        isLoader: false
      }, () => {
        this.getData();
        this.subMessageListener = refDataBase.ref(`chatrooms/${this.state.chatRoomId}`);
        this.subMessageListener.on('value', (snapshot: firebase.database.DataSnapshot) => {
          snapshot.val();
          this.getData();
        });
      });
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
    }
  };

  getData = async () => {
    const chatRoom = await this.getMessages();
    this.setState({ messages: this.messageRender(chatRoom.messages) });
  };

  getMessages = async () => {
    try {
      let databseStore = firebase.database();
      let snapshot = await databseStore.ref(`chatrooms/${this.state.chatRoomId}`).once('value', () => { });
      return snapshot.val();
    } catch (error) {
      return null;
    }
  };

  messageRender = (messages: Array<ChatMessage>) => {
    let messageReverse: Array<ChatMessage> = [];
    messages.forEach(element => {
      messageReverse.unshift(element);
    });
    return messages
      ? messageReverse.map((messageObject, messgaeIndex: number) => ({
        ...messageObject,
        _id: messgaeIndex,
        user: {
          _id:
            messageObject.sender === this.state.patientChatDetails.username
              ? this.state.patientChatDetails.username
              : this.state.userDoctorDetails.username,
          avatar:
            messageObject.sender === this.state.patientChatDetails.username
              ? this.state.patientChatDetails.avatar
              : this.state.userDoctorDetails.avatar,
          name:
            messageObject.sender === this.state.patientChatDetails.username
              ? this.state.patientChatDetails.username
              : this.state.userDoctorDetails.username,
        },
      }))
      : [];
  };

  handleSend = async (fullPath: string = "") => {
    try {
      const storeDatabase = firebase.database();
      let object = null;
      if (fullPath.length > 0) {
        object = {
          sender: this.state.patientChatDetails.username,
          file: fullPath,
          type: this.state.typeofFile,
          read: false,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
        };
        let presentChatRoom = await this.getMessages();
        const endMessages = presentChatRoom.messages || [];
        if (this.state.chatRoomId) {
          let refChatRoom = storeDatabase.ref(`chatrooms/${this.state.chatRoomId}`);
          await refChatRoom.update({
            messages: [
              ...endMessages,
              object,
            ],
          });
        }
        this.setState({
          textMessage: ""
        }, () => {
          this.getData();
        });
      }
      if (this.state.textMessage.length > 0) {
        object = {
          read: false,
          text: this.state.textMessage,
          sender: this.state.patientChatDetails.username,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
        };
        let chatRoom = await this.getMessages();
        let lastmessages = chatRoom.messages || [];
        if (this.state.chatRoomId) {
          let chatRoomReference = storeDatabase.ref(`chatrooms/${this.state.chatRoomId}`);
          await chatRoomReference.update({
            messages: [
              ...lastmessages,
              object,
            ],
          });
        }
        this.setState({
          textMessage: ""
        }, () => {
          this.getData();
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
    }
  };

  textMessageChange = (event: {target : {value : string}}) => {
    let textMessage = event?.target.value;
    this.setState({
      textMessage
    });
  };

  timeMessageFormate = (timestamp: number) =>{
    const currentDate = new Date(timestamp);
    const offset = 5.5 * 60;
    const istTime = new Date(currentDate.getTime() + offset * 60000);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[istTime.getDay()];
    let getHours = istTime.getHours();
    const minutes = istTime.getMinutes();
    let formattedDate = `${dayOfWeek.slice(0, 3)}, ${getHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedDate;
  };

  onkeyDown = (event: {target : {value : string}} & React.KeyboardEvent<HTMLInputElement>) => {
    if (event?.key === "Enter") {
      this.handleSend();
    } else {
      this.textMessageChange(event);
    }
  };

  handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      Array.from(event.target.files).forEach((file: File) => {
        if (file) {
          this.handleMediaUploadToFirebase(file);
        }
      });
    }
  };
  
  handleMediaUploadToFirebase = async (file: File) => {
    const mediaName = file.name;
    let dataStorage = firebase.storage();
    let referenceType;
    if(file.type.startsWith("image/")){
      referenceType = dataStorage.ref(`/images/${mediaName}`);
      this.setState({
        typeofFile:"images"
      });
    } else {
      referenceType = dataStorage.ref(`/files/${mediaName}`);
      this.setState({
        typeofFile:"files"
      });
    }
    try {
      const objectData = {
        contentDisposition: 'attachment'
      };
      const typeReference = await referenceType.put(file,objectData);
      const downloadUrl = await typeReference.ref.getDownloadURL();
      this.setState({ mediaPath: downloadUrl });
      this.handleSend(downloadUrl);
    } catch (error) {
      notification["error"]({
        message: "Error in uploading file",
      });
    }
  };

  mediaDownload = (fileUrl: string) => {
    const mediaUrl = document.createElement('a');
    mediaUrl.href = JSON.parse(fileUrl);
    mediaUrl.download = "download";
    document.body.appendChild(mediaUrl);
    mediaUrl.click();
    document.body.removeChild(mediaUrl);
    window.URL.revokeObjectURL(fileUrl)
  };
    // Customizable Area End
}
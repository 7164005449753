import React from "react";
// Customizable Area Start
import { Button, Input, Modal } from "antd";
import UpdateManageAddressPageController from "./UpdateManageAddressPagecontroller.web";
import PatientOutLet from "./ProfileOutlet.web";
// Customizable Area En
class UpdateManageAddressPage extends UpdateManageAddressPageController {
  // Customizable Area Start
  render() {
    return (
      <div>
        <PatientOutLet {...this.props} selectedKey="3">
          <form
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <p className="Settingheading">Your Address</p>
            <div>
              <label className="text-label-css-input">Full Name</label>
              <Input
                className="text-input-css"
                name="fullName"
                value={this.state.Name}
                data-test-id={"NameId"}
                onChange={this.handleChangeName}
              />
              {this.state.Name_error !== "" && this.state.Name === "" && (
                <p style={{ color: "red" }}>{this.state.Name_error}</p>
              )}
            </div>
            <div>
              <label className="text-label-css-input">
                Flat, House no, Buildng name
              </label>
              <Input
                className="text-input-css"
                name="flathouse"
                value={this.state.house}
                data-test-id={"buildingsId"}
                onChange={this.handleChangeHouse}
              />
              {this.state.house_error !== "" && this.state.house === "" && (
                <p style={{ color: "red" }}>{this.state.house_error}</p>
              )}
            </div>
            <div>
              <label className="text-label-css-input">Area / Street Name</label>
              <Input
                className="text-input-css"
                name="areaStreet"
                value={this.state.street}
                onChange={this.handleChangeAreaName}
                data-test-id={"areasId"}
              />
              {this.state.street_error !== "" && this.state.street === "" && (
                <p style={{ color: "red" }}>{this.state.street_error}</p>
              )}
            </div>
            <div>
              <label className="text-label-css-input">Landmark</label>
              <Input
                className="text-input-css"
                name="landmark"
                value={this.state.landmarked}
                data-test-id={"landmarkedId"}
                onChange={this.handleChangelandmarked}
              />
              {this.state.landMarkedError !== "" &&
                this.state.landmarked === "" && (
                  <p style={{ color: "red" }}>{this.state.landMarkedError}</p>
                )}
            </div>
            <div>
              <label className="text-label-css-input">Pincode</label>
              <Input
                className="text-input-css"
                name="pincode"
                value={this.state.pincodeNo}
                data-test-id={"pincodenoId"}
                maxLength={6}
                onChange={this.handleChangepincodeNo}
                inputMode="numeric"
              />

              {this.state.pincodeNo_error !== "" &&
                this.state.pincodeNo === "" && (
                  <p style={{ color: "red" }}>{this.state.pincodeNo_error}</p>
                )}
            </div>
            <div>
              <div style={{ display: "flex", gap: "5px" }}>
                <div style={{ width: "50%" }}>
                  <label className="text-label-css-input">Town / City</label>
                  <Input
                    className="text-input-css"
                    name="townCity"
                    value={this.state.townCityName}
                    data-test-id={"townCityNameId"}
                    onChange={this.handleChangeCityName}
                  />
                  {this.state.townCityName_error !== "" &&
                    this.state.townCityName === "" && (
                      <p style={{ color: "red" }}>
                        {this.state.townCityName_error}
                      </p>
                    )}
                </div>
                <div style={{ width: "50%" }}>
                  <label className="text-label-css-input">State</label>
                  <Input
                    className="text-input-css"
                    name="state"
                    value={this.state.state}
                    data-test-id={"stateNameId"}
                    onChange={this.handleChangeStateName}
                  />
                  {this.state.state_error !== "" && this.state.state === "" && (
                    <p style={{ color: "red" }}>{this.state.state_error}</p>
                  )}
                </div>
              </div>
            </div>
            <button
              className="submit-btn-form"
              data-test-id={"continueId"}
              onClick={this.handleOnSubmit}
            >
              Update
            </button>
          </form>
        </PatientOutLet>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default UpdateManageAddressPage;
// Customizable Area End

import React from "react";
// Customizable Area Start
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import { Link } from 'react-router-dom';

// Customizable Area End

import SummaryController, {
    Props,
    configJSON,
} from "./SummaryController.web";


// Customizable Area Start
const madication = [
    {
        id: 1,
        list: 10,
        name: 'Medication 1',
        duration: '1 - 0 - 1'
    },
    {
        id: 2,
        list: 20,
        name: 'Medication 2',
        duration: '1 - 1 - 1'
    },
    {
        id: 3,
        list: 10,
        name: 'Medication 1',
        duration: '1 - 0 - 1'
    },
    {
        id: 4,
        list: 20,
        name: 'Medication 2',
        duration: '1 - 1 - 1'
    },
    {
        id: 5,
        list: 10,
        name: 'Medication 1',
        duration: '1 - 0 - 1'
    },
]
// Customizable Area End

export default class Summary extends SummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    DoctorPageNavbar = () => {
        return (
            <Box className="pageContainer">
                <ul
                    className="DoctorDashboardHomePageNavbar-list">
                    <li
                        className="DoctorDashboardHomePageNavbar-list-item">
                        <Link
                            to="/doctorhome">
                            <p>Home</p>
                        </Link>
                    </li>
                    <li
                        className="DoctorDashboardHomePageNavbar-list-item">
                        <Link
                            to="/doctorschedule">
                            <p className="activeColor">Schedule</p>
                        </Link>
                    </li>
                    <li
                        className="DoctorDashboardHomePageNavbar-list-item">
                        <Link
                            to="/addpatient"><p>Add Patient</p></Link>
                    </li>
                    <li
                        className="DoctorDashboardHomePageNavbar-list-item">
                        <p>Chats</p>
                    </li>
                </ul>
            </Box>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <Box>
            <NavbarCommonComponent data-test-id="showProfileHeaderId" handleProfile={undefined} />
            <DoctorProfilePopup profileData={undefined} logoutFunctiuon={undefined} showProfile={undefined} doctorDetailsData={undefined} handleEditProfile={undefined} handleClosePopup={undefined} />
            {this.DoctorPageNavbar()}
            <Container>
                <Typography style={Styles.summary}>{configJSON.Summary}</Typography>
                <Typography style={Styles.Subhedding}>{configJSON.DrName}</Typography>
                <Box style={{ marginBottom: '25px' }}>
                    <Grid container spacing={2}>
                        <Grid item lg={8} md={9} sm={12} xs={12}>
                            <Box style={Styles.patientDetail}>
                                <Typography style={Styles.mainHedding}>PATIENT DETAIL</Typography>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography style={Styles.uniqueColor}>PATIENT NAME</Typography>
                                            <Typography style={Styles.SubUniqueColor}>Disha Rathod</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6} style={Styles.secondGrid}>
                                            <Typography style={Styles.uniqueColor}>S/O | D/O | W/O :</Typography>
                                            <Typography style={Styles.SubUniqueColor}>Kalpan yadan</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography style={Styles.uniqueColor}>AGE :</Typography>
                                            <Typography style={Styles.SubUniqueColor}>41</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6} style={Styles.secondGrid}>
                                            <Typography style={Styles.uniqueColor}>SEX :</Typography>
                                            <Typography style={Styles.SubUniqueColor}>Female</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.bottomBox}>
                                    <Typography style={Styles.uniqueColor}>PATIENT ADDRESS</Typography>
                                    <Typography style={Styles.SubUniqueColor}>23/27 South Carolina, US-29401</Typography>
                                </Box>
                            </Box>

                            <Box style={Styles.patientDetail}>
                                <Typography style={Styles.mainHedding}>MEDICAL DETAIL</Typography>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography style={Styles.uniqueColor}>HEALTH INSURANCE</Typography>
                                            <Typography style={Styles.SubUniqueColor}>983787575</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6} style={Styles.secondGrid}>
                                            <Typography style={Styles.uniqueColor}>PATIENT ID:</Typography>
                                            <Typography style={Styles.SubUniqueColor}>123456</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography style={Styles.uniqueColor}>HEALTH CARE PROVIDER</Typography>
                                            <Typography style={Styles.SubUniqueColor}> - </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.bottomBox}>
                                    <Typography style={Styles.uniqueColor}>HEALTH CARD NO:</Typography>
                                    <Typography style={Styles.SubUniqueColor}>234556788</Typography>
                                </Box>
                            </Box>

                            <Box style={Styles.patientDetail}>
                                <Typography style={Styles.mainHedding}>EXTRA INFORMATION</Typography>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography style={Styles.uniqueColor}>DIET TO FOLLOW</Typography>
                                            <Typography style={Styles.SubUniqueColor}>Take meal full of calories avoid eating too only and Spicy</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.bottomBox}>
                                    <Typography style={Styles.uniqueColor}>BRIEF HISTORY OF PATIENT</Typography>
                                    <Typography style={Styles.SubUniqueColor}> - </Typography>
                                </Box>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography style={Styles.uniqueColor}>FOLLOW UP PHYSICIAN</Typography>
                                            <Typography style={Styles.SubUniqueColor}>Dr. Manish Mehta</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6} style={Styles.secondGrid}>
                                            <Typography style={Styles.uniqueColor}>Follow UP</Typography>
                                            <Typography style={Styles.SubUniqueColor}>10 Days</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box style={Styles.patientDetail}>
                                <Typography style={Styles.mainHedding}>FOLLOW UP CONSULTATION</Typography>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography style={Styles.uniqueColor}>PATIENT'S HEALTH ID</Typography>
                                            <Typography style={Styles.SubUniqueColor}>234567888</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6} style={Styles.secondGrid}>
                                            <Typography style={Styles.uniqueColor}>MODE :</Typography>
                                            <Typography style={Styles.SubUniqueColor}>In - Clinic</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography style={Styles.uniqueColor}>FOLLOW UP DAYS:</Typography>
                                            <Typography style={Styles.SubUniqueColor}>Monday, Wednesday, Friday</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.bottomBox}>
                                    <Typography style={Styles.uniqueColor}>FOLLOW UP TIME:</Typography>
                                    <Typography style={Styles.SubUniqueColor}>10:00 AM - 11:00 AM</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box style={Styles.patientDetail}>
                                <Typography style={Styles.mainHedding}>SUMMARY</Typography>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography style={Styles.uniqueColor}>DIAGNOSED WITH :</Typography>
                                            <Typography style={Styles.SubUniqueColor}>Chest Pain, dizziness, swelling, and stiffness like symptoms</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.BoxPadding}>
                                    <Grid container>
                                        <Grid item lg={5} md={5} sm={6} xs={6}>
                                            <Typography style={Styles.uniqueColor1}>BLOOD PRESSURE:</Typography>
                                            <Typography style={Styles.SubUniqueColor}>120/80</Typography>

                                        </Grid>
                                        <Grid item lg={4} md={4} sm={3} xs={6} style={Styles.SubtableGrid}>
                                            <Typography style={Styles.uniqueColor1}>PULSE RATE</Typography>
                                            <Typography style={Styles.SubUniqueColor}>85</Typography>
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={6} style={Styles.SubtableGrid}>
                                            <Typography style={Styles.uniqueColor1}>WEIGHT</Typography>
                                            <Typography style={Styles.SubUniqueColor}>70</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={Styles.bottomBox}>
                                    <Typography style={Styles.uniqueColor}>ALLERGIES:</Typography>
                                    <Typography style={Styles.SubUniqueColor}>70 Eggs and cow's milk</Typography>
                                </Box>
                                <Box style={Styles.bottomBox}>
                                    <Typography style={Styles.uniqueColor}>DISABILITIES IF ANY:</Typography>
                                    <Typography style={Styles.SubUniqueColor}> - </Typography>
                                </Box>
                                <Grid container spacing={2} style={Styles.borderTopBottom}>
                                    <Grid item lg={2} md={2} sm={2} xs={2} style={Styles.tableGrid}><Typography style={Styles.uniqueColor2}>SR.NO</Typography></Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} style={Styles.tableGrid}><Typography style={Styles.uniqueColor2}>DRUGS LIST</Typography></Grid>
                                    <Grid item lg={4} md={4} sm={4} xs={4} style={Styles.tableGrid}><Typography style={Styles.uniqueColor2}>TABLET/SYRUP</Typography></Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3}><Typography style={Styles.uniqueColor2}>DOSAGE (PER DAY)</Typography></Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ margin: 'auto' }}>
                                    {madication.map((item: { id: number; list: number; name: string; duration: string; }) => {
                                        return (
                                            <>
                                                <Grid item lg={2} md={2} sm={2} xs={2} style={Styles.tableGrid}><Typography style={Styles.SubUniqueColor1}>{item.id}</Typography></Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3} style={Styles.tableGrid}><Typography style={Styles.SubUniqueColor1}>{item.list}</Typography></Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={4} style={Styles.tableGrid}><Typography style={Styles.SubUniqueColor1}>{item.name}</Typography></Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3} ><Typography style={Styles.SubUniqueColor1}>{item.duration}</Typography></Grid>
                                            </>
                                        )
                                    })}

                                </Grid>
                            </Box>
                            <Grid container spacing={2} style={Styles.ButtonGrid}>
                                <Grid item lg={6} md={6} sm={6} xl={6}>
                                    <Button style={Styles.EditButton} variant="outlined" color="primary">
                                        Edit
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xl={6}>
                                    <Button style={Styles.SaveButton} variant="outlined" color="primary">
                                        Confirm & Share
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <FooterDoctor />
        </Box>
        );
        // Customizable Area Start
    }
}

// Customizable Area Start
const Styles = {
    summary: {
        fontSize: '28px',
        color: 'black' as 'black',
        fontWeight: 'bold' as 'bold'
    },
    Subhedding: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop: '30px'
    },
    patientDetail: {
        width: "100%",
        minHeight: "100px",
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px rgba(0, 0, 0, 0.1), -5px 5px 10px rgba(0, 0, 0, 0.1), 5px -5px 10px rgba(0, 0, 0, 0.1)',
        marginTop: '25px',
        borderRadius: '10px'
    },
    secondGrid: {
        display: 'grid',
        justifyContent: 'end'
    },
    BoxPadding: {
        padding: '20px'
    },
    mainHedding: {
        padding: '15px 0 0 20px',
        fontSize: '18px',
        color: '#53b1e0',
        fontWeight: 600,
    },
    bottomBox: {
        padding: "0 0 20px 20px"
    },
    uniqueColor: {
        color: '#929292'
    },
    uniqueColor1: {
        color: '#929292',
        fontSize: '15px'
    },
    uniqueColor2: {
        color: '#929292',
        fontSize: '13px'
    },
    tableGrid: {
        textAlign: 'center' as 'center',
        alignItems: 'center'
    },
    SubUniqueColor: {
        fontSize: '18px',
        fontWeight: 500
    },
    SubUniqueColor1: {
        fontSize: '13px',
        fontWeight: 500
    },
    SubtableGrid: {
        display: 'grid',
        justifyContent: 'end'
    },
    borderTopBottom: {
        borderTop: '1px solid #929292',
        borderBottom: '1px solid #929292',
        width: '98%',
        margin: 'auto'
    },
    EditButton: {
        width: '100%',
        height: '55px',
        border: '1px solid #929292',
        borderRadius: '25px',
        color: '#929292',
    },
    SaveButton: {
        width: '100%',
        height: '55px',
        border: '1px solid #929292',
        borderRadius: '25px',
        color: '#fff',
        backgroundColor: '#53b1e0'
    },
    ButtonGrid: {
        display: 'flex',
        alignContent: 'end',
        alignItems: 'end',
        width: '100%',
        height: '100px',
    }
}
// Customizable Area End
import React from "react";
// Customizable Area Start
import ProfileSettingsPageController from "./ProfileSettingsPageController";
import PatientOutLet from "./ProfileOutlet.web";
import { Avatar, Col, Menu, Row, Space } from "antd";
import {
  Language,
  RightOutlined,
  deleteAccount,
  downback,
  notification,
  privacy,
  question,
  voice,
} from "./assets";
import { searchIcon } from "../../splashscreen/src/assets";

// Customizable Area End

class ProfileSettingsPage extends ProfileSettingsPageController {
  // Customizable Area Start
  render() {
    const languages = [
      {
        id: 1,
        lngSelect: "English",
      },
      {
        id: 2,
        lngSelect: "Hindi",
      },
      {
        id: 3,
        lngSelect: "Gujrati",
      },
      {
        id: 4,
        lngSelect: "Marathi",
      },
      {
        id: 5,
        lngSelect: "Punjabi",
      },
      {
        id: 6,
        lngSelect: "Bengali",
      },
      {
        id: 7,
        lngSelect: "Telugu",
      },
      {
        id: 8,
        lngSelect: "Tamil",
      },
      {
        id: 9,
        lngSelect: "Malayalam",
      },
    ];
    return (
      <div>
        <PatientOutLet {...this.props} selectedKey={"5"}>
          <>
            <h6 className="Settingheading">Settings</h6>
          </>
          <div className="searchBoxDoc_profile">
            <div className="searchInput_profile">
              <img
                src={searchIcon}
                alt="Right Arrow"
                style={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              />

              <input
                style={{ backgroundColor: "white" }}
                type="text"
                className="searchInput_profile"
                placeholder="Search Orders"
              />

              <img
                src={voice}
                alt="Right Arrow"
                style={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              />
            </div>
          </div>

          <Menu
            style={{ width: "100%", background: "#F4F4F4", marginTop: "20px" }}
          >
            <Menu.Item key="1" style={{ borderBottom: "1px solid #EAEAEA" }}>
              <div>
                <Row justify="space-between" className="space-align-block">
                  <Space>
                    <Avatar size="small" src={notification} />
                    <span> Notification</span>
                  </Space>

                  <img src={RightOutlined} alt="" style={{ width: "10px" }} />
                </Row>
                <hr />
              </div>
            </Menu.Item>

            <Menu.Item key="2" style={{ borderBottom: "1px solid #EAEAEA" }}>
              <div
                onClick={this.toggleLanguagesList}
                data-test-id="language-toggle-id"
              >
                <Row justify="space-between" className="space-align-block">
                  <Space>
                    <Avatar size="small" src={Language} />
                    <span> Language</span>
                  </Space>
                  {this.state.languagesVisible ? (
                    <img src={downback} alt="" style={{ width: "18px" }} />
                  ) : (
                    <img src={RightOutlined} alt="" style={{ width: "10px" }} />
                  )}
                </Row>
              </div>
            </Menu.Item>

            {this.state.languagesVisible && (
              <>
                {languages.map((item) => (
                  <Col
                    flex={"100%"}
                    key={item.id}
                    style={{
                      borderBottom: "1px solid #EAEAEA",
                      marginTop: "30px",
                      paddingLeft: "60px",
                    }}
                  >
                    <p>{item.lngSelect}</p>
                  </Col>
                ))}
              </>
            )}
            <Menu.Item key="3" style={{ borderBottom: "1px solid #EAEAEA" }}>
              <div>
                <Row justify="space-between" className="space-align-block">
                  <Space>
                    <Avatar size="small" src={privacy} />
                    <span> Privacy</span>
                  </Space>

                  <img src={RightOutlined} alt="" style={{ width: "10px" }} />
                </Row>
              </div>
            </Menu.Item>

            <Menu.Item key="4" style={{ borderBottom: "1px solid #EAEAEA" }}>
              <div>
                <Row justify="space-between" className="space-align-block">
                  <Space>
                    <Avatar size="small" src={question} />
                    <span> Help</span>
                  </Space>

                  <img src={RightOutlined} alt="" style={{ width: "10px" }} />
                </Row>
              </div>
            </Menu.Item>

            <Menu.Item key="5" style={{ borderBottom: "1px solid #EAEAEA" }}>
              <div>
                <Row justify="space-between" className="space-align-block">
                  <Space>
                    <Avatar size="small" src={deleteAccount} />
                    <span style={{ color: "#FF2D2D" }}> Delete Account</span>
                  </Space>

                  <img src={RightOutlined} alt="" style={{ width: "10px" }} />
                </Row>
              </div>
            </Menu.Item>
          </Menu>
        </PatientOutLet>
      </div>
    );
  }

  // Customizable Area End
}

// Customizable Area Start
export default ProfileSettingsPage;
// Customizable Area End

import React from "react";
// Customizable Area Start
import { Button, Input, Modal } from "antd";
import PatientProfileAddressPageController from "./PatientProfileAddressPageController";
import PatientOutLet from "./ProfileOutlet.web";
// Customizable Area En
class PatientProfileAddressPage extends PatientProfileAddressPageController {
  // Customizable Area Start
  render() {
    return (
      <div>
        <PatientOutLet {...this.props} selectedKey="3">
          <form
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <p className="Settingheading">Your Address</p>
            <div>
              <label className="text-label-css-input">Full Name</label>
              <Input
                className="text-input-css"
                name="fullName"
                value={this.state.fullName}
                data-test-id={"fullNameId"}
                onChange={this.handleChangeFullName}
              />
              {this.state.fullName_error !== "" &&
                this.state.fullName === "" && (
                  <p style={{ color: "red" }}>{this.state.fullName_error}</p>
                )}
            </div>
            <div>
              <label className="text-label-css-input">
                Flat, House no, Buildng name
              </label>
              <Input
                className="text-input-css"
                name="flathouse"
                value={this.state.flathouse}
                data-test-id={"buildingId"}
                onChange={this.handleChangeFlatHouse}
              />
              {this.state.flathouse_error !== "" &&
                this.state.flathouse === "" && (
                  <p style={{ color: "red" }}>{this.state.flathouse_error}</p>
                )}
            </div>
            <div>
              <label className="text-label-css-input">Area / Street Name</label>
              <Input
                className="text-input-css"
                name="areaStreet"
                value={this.state.areaStreet}
                onChange={this.handleChangeArea}
                data-test-id={"areaId"}
              />
              {this.state.areaStreet_error !== "" &&
                this.state.areaStreet === "" && (
                  <p style={{ color: "red" }}>{this.state.areaStreet_error}</p>
                )}
            </div>
            <div>
              <label className="text-label-css-input">Landmark</label>
              <Input
                className="text-input-css"
                name="landmark"
                value={this.state.landmark}
                data-test-id={"landmarkId"}
                onChange={this.handleChangeLandmark}
              />
              {this.state.landmark_error !== "" &&
                this.state.landmark === "" && (
                  <p style={{ color: "red" }}>{this.state.landmark_error}</p>
                )}
            </div>
            <div>
              <label className="text-label-css-input">Pincode</label>
              <Input
                className="text-input-css"
                name="pincode"
                value={this.state.pincode}
                data-test-id={"pincodeId"}
                maxLength={6}
                onChange={this.handleChangePincode}
                inputMode="numeric"
              />

              {this.state.pincode_error !== "" && this.state.pincode === "" && (
                <p style={{ color: "red" }}>{this.state.pincode_error}</p>
              )}
            </div>
            <div>
              <div style={{ display: "flex", gap: "5px" }}>
                <div style={{ width: "50%" }}>
                  <label className="text-label-css-input">Town / City</label>
                  <Input
                    className="text-input-css"
                    name="townCity"
                    value={this.state.townCity}
                    data-test-id={"townCity"}
                    onChange={this.handleChangeCity}
                  />
                  {this.state.townCity_error !== "" &&
                    this.state.townCity === "" && (
                      <p style={{ color: "red" }}>
                        {this.state.townCity_error}
                      </p>
                    )}
                </div>
                <div style={{ width: "50%" }}>
                  <label className="text-label-css-input">State</label>
                  <Input
                    className="text-input-css"
                    name="state"
                    value={this.state.state}
                    data-test-id={"stateId"}
                    onChange={this.handleChangeState}
                  />
                  {this.state.state_error !== "" && this.state.state === "" && (
                    <p style={{ color: "red" }}>{this.state.state_error}</p>
                  )}
                </div>
              </div>
            </div>
            <button
              className="submit-btn-form"
              data-test-id={"continueId"}
              onClick={this.handleSubmit}
            >
              Continue
            </button>
          </form>
        </PatientOutLet>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default PatientProfileAddressPage;
// Customizable Area End

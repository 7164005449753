import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as H from "history";
import { message as MESSAGE, notification } from "antd";
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: any;
    // Customizable Area End
}

// Customizable Area Start
export interface MenuTabData {
    id: number;
    tabName: string;
    isSelect: boolean;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
}

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

export interface InvalidResponseType {
    errors: Array<string>;
}

export interface DoctorDetailsType {
    data: DoctorDetailsData;
}
export interface DoctorDetailsData {
    id: string;
    type: string;
    attributes: {
        full_name: string;
        medical_representative_name: string;
        representative_contact_no: string;
        profile_image: string;
    }
}

export interface PrescriptionType {
    data: {
        id: string;
        type: string;
        attributes: {
            medicine_name: string;
            duration: string;
            time: string;
            comments: string;
            dose_time: string;
            patient_id: string;
            doctor_id: number;
            appointment_id: number;
            add_extra_information: object;
            follow_up_consultation: object;
            image: string;
            prescription_medicines: PrescriptionDetailsType
        }
    }
}

export interface PrescriptionDetailsType {
    data: [
        {
            id: string;
            type: string;
            attributes: {
                id: string;
                medicine_name: string;
                duration: string;
                time: string;
                comments: string;
                dose_time: string;
            }
        }
    ]
}

export interface DetailsType {
    id: string;
    type: string;
    attributes: {
        id: string;
        medicine_name: string;
        duration: string;
        time: string;
        comments: string;
        dose_time: string;
    }
}

export interface Errors {
    errors: Array<string>;
}

export interface MedicalHistory {
    data: Array<MedicalHistoryType>;
}

export interface MedicalInvalidResponseType {
    message : "";
}

export interface MedicalHistoryType {
    id: string;
    type: string;
    attributes: {
        doctor_full_name: string;
        prescription_medicines: {
            data: [
                {
                    id: string;
                    type: string;
                    attributes: {
                        id: number;
                        medicine_name: string;
                        duration: string;
                        time: string;
                        comments: string;
                        dose_time: string;
                    }
                }
            ]
        }
    }
}

export interface Meds {
        medicineName: string;
        doseValue: unknown;
        commentText: string;
        selectedDuration: unknown;
        selectedTime: unknown;
}
// Customizable Area End

interface S {
    // Customizable Area Start
    menuTabData: Array<MenuTabData>;
    appointmentId: number;
    selectDuration: Array<string>;
    medicineName: string;
    commentText: string;
    selectedDuration: string | unknown;
    selectedTime: string | unknown;
    showPrescrition: PrescriptionType;
    showPopup: boolean;
    doctorProfileData: object;
    showUploadPictureBox: boolean;
    doseValOne: string;
    doseValTwo: string;
    doseValThree: string;
    patientId: number;
    medicalHistoryTab: string;
    medicalHistory: MedicalHistory;
    prescriptionEditIndex: number;
    editValOne: string;
    editMedicineName: string;
    editValTwo: string;
    editValThree: string;
    editSelectedDuration: string | unknown;
    editCommentText: string;
    editSelectTime: string | unknown;
    medicineId: string;
    medicineNameErr: string;
    editMedicineNameErr: string;
    doseValueError: string;
    editDoseValueError: string;
    dietPlan: string;
    dietPlanErr: string;
    historyPatient: string;
    historyPatientErr: string;
    patientFollow: string;
    patientFollowErr: string;
    followUp: string;
    followUpErr: string;
    addMedicine: boolean;
    prescriptionData: any;
    closeAccordian: boolean;
    isError: boolean;
    isShow: boolean;
    modeValue: string | unknown;
    modeValueErr: string;
    weekDays: Array<string>;
    followDays: any;
    followErr: string;
    start: string;
    end: string;
    errMsg: string;
    healthId: string | null;
    isErrorFollow: boolean;
    isFollowAdd: boolean;
    followUpDetails: any;
    closeFollowAccordian: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ViewPrescriptionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postCreateMedicineApiCallId: string = "";
    getShowPrescriptionApiCallId: string = "";
    getProfileDetailsCallId: string = "";
    getAllRecordsApiCallId: string = "";
    deletePrescriptionMedicineApiCallId: string = "";
    getMedicalHistoryApiCallId: string = "";
    putCreateMedicineApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectedDuration: "",
            medicineName: "",
            commentText: "",
            appointmentId: 0,
            prescriptionEditIndex: -1,
            showUploadPictureBox: false,
            menuTabData: [
                {
                    id: 1,
                    tabName: "Prescription",
                    isSelect: true,
                },
                {
                    id: 2,
                    tabName: "All Records",
                    isSelect: false,
                },
                {
                    id: 3,
                    tabName: "Case",
                    isSelect: false,
                },
            ],
            selectDuration: [
                "1 Day",
                "3 Days",
                "5 Days",
                "7 Days",
                "10 Days",
                "15 Days",
                "20 Days",
                "25 Days",
                "30 Days",
            ],
            selectedTime: "",
            showPrescrition: {
                data: {
                    id: "",
                    type: "",
                    attributes: {
                        medicine_name: "",
                        duration: "",
                        time: "",
                        comments: "",
                        dose_time: "",
                        patient_id: "",
                        doctor_id: 0,
                        appointment_id: 0,
                        add_extra_information: {},
                        follow_up_consultation: {},
                        image: "",
                        prescription_medicines: {
                            data: [
                                {
                                    id: "",
                                    type: "",
                                    attributes: {
                                        id: "",
                                        medicine_name: "",
                                        duration: "",
                                        time: "",
                                        comments: "",
                                        dose_time: ""
                                    }
                                }
                            ]
                        }
                    }
                }
            },
            showPopup: false,
            doctorProfileData: {},
            doseValOne: "",
            doseValTwo: "",
            doseValThree: "",
            patientId: 0,
            medicalHistoryTab: "",
            medicalHistory: {
                data: []
            },
            editValOne: "",
            editMedicineName: "",
            editValTwo: "",
            editValThree: "",
            editSelectedDuration: "",
            editCommentText: "",
            editSelectTime: "",
            medicineId: "",
            medicineNameErr: "",
            editMedicineNameErr: "",
            doseValueError: "",
            editDoseValueError: "",
            dietPlan: "",
            dietPlanErr: "",
            historyPatient: "",
            historyPatientErr: "",
            patientFollow: "",
            patientFollowErr: "",
            followUp: "",
            followUpErr: "",
            addMedicine: false,
            prescriptionData : [],
            closeAccordian: false,
            isError: false,
            isShow: false,
            modeValueErr: "",
            modeValue: "",
            weekDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            followDays: [],
            followErr: "",
            start: "",
            end: "",
            errMsg: "",
            healthId: "",
            isErrorFollow: false,
            isFollowAdd: false,
            followUpDetails: {},
            closeFollowAccordian: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    selectDurationFunction = (event: { target: { value: string | unknown } }) => {
        this.setState({ selectedDuration: event.target.value });
    };

    selectTimeFunction = (event: { target: { value: string | unknown } }) => {
        this.setState({ selectedTime: event.target.value });
    };

    activeTab = (tabIndex: number) => {
        let menuList = [...this.state.menuTabData];
        menuList.forEach((tabItem, index) => {
            if (index === tabIndex) {
                menuList[index].isSelect = true;
            } else {
                menuList[index].isSelect = false;
            }
        });
        this.setState({ menuTabData: menuList });
    };

    showUploadPictureBoxFunction = () => {
        this.setState({
            showUploadPictureBox: !this.state.showUploadPictureBox,
            medicineName: "",
            selectedTime: "",
            commentText: "",
            selectedDuration: "",
            doseValOne: "",
            doseValTwo: "",
            doseValThree: "",
            isShow: true
        });
    };

    prescriptionEditOnClick = (editIndex: number, medicineId: string) => {
        let data = this.state.showPrescrition?.data?.attributes.prescription_medicines.data[editIndex];
        let editDoseTime = this.state.showPrescrition?.data?.attributes.prescription_medicines.data[editIndex].attributes.dose_time.split("-");
        this.setState({
            prescriptionEditIndex: editIndex,
            editMedicineName: data.attributes.medicine_name,
            editValOne: editDoseTime[0],
            editValTwo: editDoseTime[1],
            editValThree: editDoseTime[2],
            editSelectedDuration: data.attributes.duration,
            editCommentText: data.attributes.comments,
            editSelectTime: data.attributes.time,
            medicineId: medicineId
        });
    };

    closeUploadPictureBoxFunction = () => {
        this.setState({ showUploadPictureBox: false });
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            if (this.isValidResponse(responseJson)) {
                this.apiViewSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.inValidResponse(responseJson)) {
                this.apiViewFailureCallBacks(apiRequestCallId, responseJson);
            }
            else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
        }
    }

    async componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        this.setState({
            appointmentId: this.props.location.state.appointments_id,
            patientId: this.props.location.state.patient_Id,
            medicalHistoryTab: this.props.location.state.medicalHistoryTab,
            healthId: localStorage.getItem("patient_health")
        }, () => {
            this.tabIndexCheck();
            this.getMedicalHistoryFunction();
            this.getShowPrescription();
        });
        this.getDoctorProfileDetailsfunction();
        this.getAllRecordsfunction();
    }

    tabIndexCheck = () => {
        let menuList = this.state.menuTabData
        let menuListMedicalIndex = menuList.findIndex((medicalitem) => medicalitem.tabName === this.state.medicalHistoryTab)
        if (menuListMedicalIndex !== -1) {
            this.activeTab(menuListMedicalIndex)
        }
    };

    apiCall = (data: APIPayloadType) => {
        const token = localStorage.getItem("token");
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    isValidResponse = (responseJson: ValidResponseType & MedicalInvalidResponseType) => {
        return (responseJson && responseJson.data) || (responseJson && responseJson.scheduled) || (responseJson && responseJson.message);
    };

    inValidResponse = (responseJson: InvalidResponseType & MedicalInvalidResponseType) => {
        return (responseJson && responseJson.errors) || (responseJson && responseJson.message);
    };

    apiViewSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType & DoctorDetailsType & PrescriptionType & object & MedicalHistory & MedicalInvalidResponseType) => {
        if (apiRequestCallId === this.postCreateMedicineApiCallId) {
            this.postCreatePrescriptionSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getShowPrescriptionApiCallId) {
            this.getShowPrescriptionSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getProfileDetailsCallId) {
            this.getDoctorProfileDetailsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.deletePrescriptionMedicineApiCallId) {
            this.deletePrescriptionMedicineSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getMedicalHistoryApiCallId) {
            this.getMedicalHistorySuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.putCreateMedicineApiCallId) {
            this.putMedicineSuccessCallBack(responseJson);
        }
    };

    apiViewFailureCallBacks = (apiRequestCallId: string, responseJson: Errors & MedicalInvalidResponseType) => {
        if (apiRequestCallId === this.getMedicalHistoryApiCallId) {
            this.getMedicalHistoryFailureCallBack(responseJson);
        }
    };

    postCreatePrescription = async () => {
        if(this.state.isError){
          this.handleExtraInfo()
          return
        }
        let apiBody = {
            appointment_id: this.state.appointmentId,
            add_extra_information: {
                diet_to_follow: this.state.dietPlan,
                patient_history: this.state.historyPatient,
                follow_up_physician: this.state.patientFollow,
                follow_up: this.state.followUp
              },
              follow_up_consultation: this.state.followUpDetails,
            prescription_medicines_attributes: this.state.prescriptionData
        }
        this.postCreateMedicineApiCallId = this.apiCall({
            contentType: configJSON.postCreatePrescriptionContentType,
            method: configJSON.postCreatePrescriptionApiMethod,
            endPoint: configJSON.postCreatePrescriptionApiEndPoint,
            body: apiBody
        });
    };

    postCreatePrescriptionSuccessCallBack = (response: object) => {
        notification["success"]({
            message: "Prescription created successfully",
        });
        this.setState({ showUploadPictureBox: false , addMedicine: false, prescriptionData: []},()=>{this.getMedicalHistoryFunction()});
        this.getShowPrescription();
        this.setState({
            medicineName: "",
            selectedTime: "",
            commentText: "",
            selectedDuration: "",
            doseValOne: "",
            doseValTwo: "",
            doseValThree: "",
            dietPlan: "",
            historyPatient: "",
            patientFollow: "",
            followUp: "",
            closeAccordian: false,
            modeValue: "",
            followDays: [],
            start: "",
            end: "",
            isErrorFollow: false,
            errMsg: "",
            followUpDetails: {},
            closeFollowAccordian: false
        });
        this.props.history.push("/Summary")
    };

    handleMedicineName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const medInputValue = event.target.value;
        const medRegex = /^[a-zA-Z0-9\s\-/]*[a-zA-Z][a-zA-Z0-9\s\-/]*$/;
        this.setState({
            medicineName: medInputValue,
        }, () => {
            if (medRegex.test(medInputValue)) {
                this.setState({
                    medicineNameErr: '',
                });
            } else {
                this.setState({
                    medicineNameErr: medInputValue ? 'Please enter a valid medicine name' : 'This field is required.',
                });
            }
        });
    };

    handleAddComments = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ commentText: event.target.value });
    };

    getShowPrescription = () => {
        this.getShowPrescriptionApiCallId = this.apiCall({
            contentType: configJSON.postCreatePrescriptionContentType,
            method: configJSON.getProfileDetailsMethod,
            endPoint: `${configJSON.showPrescriptionApiEndPoint}?appointment_id=${this.state.appointmentId}`
        });
    };

    getShowPrescriptionSuccessCallBack = (response: PrescriptionType) => {
        this.setState({
            showPrescrition: response
        });
    };

    handelDoctorProfilePopupShowFunction = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };

    handleUserLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
        });
        this.props.history.push("/");
    };

    handleDocDetailsPage = () => {
        this.props.history.push("/doctorprofiledetails");
    };

    handleEditDocProfile = () => {
        this.props.history.push("/editdoctordetails")
    };

    getDoctorProfileDetailsfunction = async () => {
        this.getProfileDetailsCallId = this.apiCall({
            contentType: configJSON.getProfileDetailsContentType,
            method: configJSON.getProfileDetailsMethod,
            endPoint: configJSON.getProfileDetailsEndPoint
        });
    };

    getDoctorProfileDetailsSuccessCallBack = (response: DoctorDetailsType) => {
        this.setState({
            doctorProfileData: response
        });
    };

    getAllRecordsfunction = async () => {
        this.getAllRecordsApiCallId = this.apiCall({
            contentType: configJSON.getProfileDetailsContentType,
            method: configJSON.getProfileDetailsMethod,
            endPoint: `${configJSON.allRecordsApiEndPoint}?id=${this.state.appointmentId}`
        });
    };

    deletePrescriptionMedicine = async (id: string) => {
        this.deletePrescriptionMedicineApiCallId = this.apiCall({
            contentType: configJSON.getProfileDetailsContentType,
            method: configJSON.deletePrescriptionMedicineMethod,
            endPoint: `${configJSON.deletePrescriptionMedicineApiEndPoint}?id=${id}`
        });
    };

    deletePrescriptionMedicineSuccessCallBack = (response: MedicalInvalidResponseType) => {
        notification["success"]({
            message: "Prescription medicine removed.",
        });
        this.getShowPrescription();
    };

    handlePopupClose = () => {
        this.setState({
            showPopup: false
        });
    };

    handleDoseTimeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
        let doseValue: string = event.target.value;
        if (/^[01]*$/.test(doseValue)) {
            this.setState({
                doseValOne: doseValue,
                doseValueError: ""
            });
        } else {
            this.setState({ doseValueError: "Dose value only accept 0 & 1" });
        }
    };

    handleDoseTimeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
        let doseValue: string = event.target.value;
        if (/^[01]*$/.test(doseValue)) {
            this.setState({
                doseValTwo: doseValue,
                doseValueError: ""
            });
        } else {
            this.setState({ doseValueError: "Dose value only accept 0 & 1" });
        }
    };

    handleDoseTimeThree = (event: React.ChangeEvent<HTMLInputElement>) => {
        let doseValue: string = event.target.value;
        if (/^[01]*$/.test(doseValue)) {
            this.setState({
                doseValThree: doseValue,
                doseValueError: ""
            });
        } else {
            this.setState({ doseValueError: "Dose value only accept 0 & 1" });
        }
    };

    getMedicalHistoryFunction = async () => {
        this.getMedicalHistoryApiCallId = this.apiCall({
            contentType: configJSON.getProfileDetailsContentType,
            method: configJSON.getProfileDetailsMethod,
            endPoint: `${configJSON.getMedicalHistoryEndPoint}?patient_id=${this.state.patientId}`
        });
    };

    getMedicalHistorySuccessCallBack = (response: MedicalHistory) => {
        this.setState({
            medicalHistory: response
        })
    };

    getMedicalHistoryFailureCallBack = (errors: MedicalInvalidResponseType) => {
        notification["error"]({
            message: "No medical History found.",
        });
    };

    handleInvoiceView = (id: number) => {
        this.props.history.push({
            pathname: "/invoicedetails",
            state: { appointments_id: id }
        });
    };

    handleMedicalHistory = (tabIndex: number) => {
        let menuList = [...this.state.menuTabData];
        menuList.forEach((tabItem, index) => {
            if (index === tabIndex) {
                menuList[index].isSelect = true;
            } else {
                menuList[index].isSelect = false;
            }
        });
        this.setState({ menuTabData: menuList });
    };

    handlePrescriptionsRecord = (tabIndex: number) => {
        let menuList = [...this.state.menuTabData];
        menuList.forEach((tabItem, index) => {
            if (index === tabIndex) {
                menuList[index].isSelect = true;
            } else {
                menuList[index].isSelect = false;
            }
        });
        this.setState({ menuTabData: menuList });
    };

    handleEditDoseTimeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
        let editDoseValue: string = event.target.value;
        if (/^[01]*$/.test(editDoseValue)) {
            this.setState({
                editValOne: editDoseValue,
                editDoseValueError: ""
            });
        } else {
            this.setState({ editDoseValueError: "Dose value only accept 0 & 1" });
        }
    };

    handleEditDoseTimeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
        let editDoseValue: string = event.target.value;
        if (/^[01]*$/.test(editDoseValue)) {
            this.setState({
                editValTwo: editDoseValue,
                editDoseValueError: ""
            });
        } else {
            this.setState({ editDoseValueError: "Dose value only accept 0 & 1" });
        }
    };

    handleEditDoseTimeThree = (event: React.ChangeEvent<HTMLInputElement>) => {
        let editDoseValue: string = event.target.value;
        if (/^[01]*$/.test(editDoseValue)) {
            this.setState({
                editValThree: editDoseValue,
                editDoseValueError: ""
            });
        } else {
            this.setState({ editDoseValueError: "Dose value only accept 0 & 1" });
        }
    };

    handleEditMedicineName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const medEditInputValue = event.target.value;
        const medRegex = /^[a-zA-Z0-9\s\-/]*[a-zA-Z][a-zA-Z0-9\s\-/]*$/;
        this.setState({
            editMedicineName: medEditInputValue,
        }, () => {
            if (medRegex.test(medEditInputValue)) {
                this.setState({
                    editMedicineNameErr: '',
                });
            } else {
                this.setState({
                    editMedicineNameErr: medEditInputValue ? 'Please enter a valid medicine name' : 'This field is required.',
                });
            }
        });
    };

    editSelectDurationFunction = (event: { target: { value: string | unknown } }) => {
        this.setState({ editSelectedDuration: event.target.value });
    };

    editSelectTimeFunction = (event: { target: { value: string | unknown } }) => {
        this.setState({ editSelectTime: event.target.value });
    };

    editHandleAddComments = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ editCommentText: event.target.value });
    };

    putCreatePrescription = async () => {
        if (this.state.editMedicineName.trim() == "") {
            return notification["error"]({
                message: "Please add medicine name",
            });
        }
        if (this.state.editValOne == "" || this.state.editValTwo == "" || this.state.editValThree == "") {
            return notification["error"]({
                message: "Please fill dose values",
            });
        }
        if (this.state.editValOne == "0" && this.state.editValTwo == "0" && this.state.editValThree == "0") {
            return notification["error"]({
                message: "There must be 1 in any one dose box",
            });
        }
        let apiBody = {
            medicine_name: this.state.editMedicineName,
            time: this.state.editSelectTime,
            comments: this.state.editCommentText,
            dose_time: `${this.state.editValOne}-${this.state.editValTwo}-${this.state.editValThree}`,
            duration: this.state.editSelectedDuration
        }
        this.putCreateMedicineApiCallId = this.apiCall({
            contentType: configJSON.postCreatePrescriptionContentType,
            method: configJSON.putPrescriptionMedicineMethod,
            endPoint: `${configJSON.putPrescriptionMedicineEndPoint}?id=${this.state.medicineId}`,
            body: apiBody
        });
    };

    putMedicineSuccessCallBack = (response: PrescriptionType) => {
        notification["success"]({
            message: "Prescription medicine update successfully.",
        });
        this.setState({
            prescriptionEditIndex: -1,
            editMedicineName: "",
            editValOne: "",
            editValTwo: "",
            editValThree: "",
            editSelectedDuration: "",
            editCommentText: "",
            editSelectTime: ""
        });
        this.getShowPrescription();
    };

    closeEditMedicineFunction = () => {
        this.setState({
            prescriptionEditIndex: -1
        });
    };

    InvoiceViewFunction = (id: number) => {
        this.props.history.push({
            pathname: "/invoicedetailsaa",
            state: { appointments_id: id }
        });
    };


    navigateToPatientHome=()=>{
        this.props.history.push({ pathname: "/doctorhome" });
        localStorage.setItem("is_kyc", JSON.stringify(true));   
    }


    loginFailure = (err: any) => {
        if (err === "Patient not found, or not activated") {
            this.props.history.push({ pathname: "/notfound" });
        }
    }
  
    handleDietFollow = (event: { target: { value: string } }) => {
        const eventValue = event.target.value;
        const dietRegex = /^[A-Za-z '",/0-9]{2,100}$/;
        this.setState({
          dietPlan: event.target.value,
          isError: false
        }, () => {
          if (dietRegex.test(eventValue)) {
            this.setState({
               dietPlanErr: "",
            });
          }
          else if (eventValue.length > 100) {
            this.setState({
                dietPlanErr: "Please enter a patient's diet plan within character limit",
                isError: true
            });
          }
          else {
            this.setState({
                dietPlanErr: eventValue ? "Please enter a valid patient's diet plan." : "This field is required.",
                isError: true
            });
          }
        });
    };

    handleHistoryPatient = (event: { target: { value: string } }) => {
        const changeEvent = event.target.value;
        const historyRegex = /^[A-Za-z '",/0-9]{2,100}$/;
        this.setState({
            historyPatient: event.target.value,
            isError: false
        }, () => {
          if (historyRegex.test(changeEvent)) {
            this.setState({
                historyPatientErr: "",
            });
          }
          else if (changeEvent.length > 100) {
            this.setState({
                historyPatientErr: "Please provide a brief patient history within character limit",
                isError: true
            });
          }
          else {
            this.setState({
                historyPatientErr: changeEvent ? "Please enter a valid brief patient history." : "This field is required.",
                isError: true
            });
          }
        });
    };

    handlePatientFollow = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;
        const clinicNameRegex = /^[A-Za-z ]{2,40}$/;
        this.setState({
            patientFollow: event.target.value,
            isError: false
        }, () => {
          if (clinicNameRegex.test(inputValue)) {
            this.setState({
                patientFollowErr: "",
            });
          }
          else if (inputValue.length > 40) {
            this.setState({
                patientFollowErr: "Please enter a follow-up physician's name within character limit",
                isError: true
            });
          }
          else {
            this.setState({
                patientFollowErr: inputValue ? "Please enter a valid follow-up physician's name." : "This field is required.",
                isError: true
            });
          }
        });
    };

    handleFollowUp = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;
        const clinicNameRegex = /^[A-Za-z '",/0-9]{2,20}$/;
        this.setState({
            followUp: event.target.value,
            isError: false
        }, () => {
          if (clinicNameRegex.test(inputValue)) {
            this.setState({
                followUpErr: "",
            });
          }
          else if (inputValue.length > 20) {
            this.setState({
                followUpErr: "Please schedule a follow-up appointment within character limit",
                isError: true
            });
          }
          else {
            this.setState({
                followUpErr: inputValue ? "Please enter a valid follow-up appointment" : "This field is required.",
                isError: true
            });
          }
        });
    };

    handleExtraInfo = () => {
        const dietRegex = /^[A-Za-z '",/0-9]{2,100}$/;
        const clinicNameRegex = /^[A-Za-z ]{2,40}$/;
        const followUpRegex = /^[A-Za-z '",/0-9]{2,20}$/;
        if (this.state.dietPlan.trim() == "") {
            notification["error"]({
                message: "Please enter the patient's diet plan",
            });
            return true
        }
        if (!dietRegex.test(this.state.dietPlan)){
            notification["error"]({
                message: "Please enter valid patient's diet plan",
            });
            return true
        }
        if (this.state.historyPatient == "") {
            notification["error"]({
                message: "Please provide a brief patient history",
            });
            return true
        }
        if (!dietRegex.test(this.state.historyPatient)) {
            notification["error"]({
                message: "Please enter valid patient history",
            });
            return true
        }
        if (this.state.patientFollow.trim() == "") {
            notification["error"]({
                message: "Please enter the follow-up physician's name",
            });
            return true
        }
        if (!clinicNameRegex.test(this.state.patientFollow)) {
            notification["error"]({
                message: "Please enter a valid physician's name",
            });
            return true
        }
        if (this.state.followUp.trim() == "") {
            notification["error"]({
                message: "Please schedule a follow-up appointment",
            });
            return true
        }
        if (!followUpRegex.test(this.state.followUp)) {
            notification["error"]({
                message: "Please enter a valid follow-up appointment",
            });
            return true
        }
    };

    handleAddMedicine = () => {
        if (this.state.medicineName.trim() == "") {
            return notification["error"]({
                message: "Please add medicine name",
            });
        }
        if (this.state.selectedDuration == "") {
            return notification["error"]({
                message: "Please select duration",
            });
        }
        if (this.state.selectedTime == "") {
            return notification["error"]({
                message: "Please select time",
            });
        }
        if (this.state.doseValTwo == "" || this.state.doseValOne == "" || this.state.doseValThree == "") {
            return notification["error"]({
                message: "Please fill dose values",
            });
        }
        if (this.state.doseValOne == "0" && this.state.doseValTwo == "0" && this.state.doseValThree == "0") {
            return notification["error"]({
                message: "There must be 1 in any one dose box",
            });
        }
        const prescription = {
            medicine_name: this.state.medicineName,
            time: this.state.selectedTime,
            comments: this.state.commentText,
            duration: this.state.selectedDuration,
            dose_time: `${this.state.doseValOne}-${this.state.doseValTwo}-${this.state.doseValThree}`
        };
        this.setState(prevState => ({
          prescriptionData: [...prevState.prescriptionData, prescription],
          medicineName: "",
          selectedTime: "",
          commentText: "",
          selectedDuration: ""
        }));
        this.setState({
            addMedicine : true,
            showUploadPictureBox: false,
            doseValOne: "",
            doseValThree: "",
            doseValTwo: ""
        });
    };

    handleNewMedicine = () => {  
      this.setState({
        showUploadPictureBox: true
      });
    };

    handleRemoveMedicine = (index: number) => {
      let tempData = this.state.prescriptionData;
      tempData.splice(index,1);
      this.setState({
        prescriptionData: tempData
    });
    };

    handleOpenAccordian = () => {
      this.setState({
        closeAccordian: !this.state.closeAccordian
      });
    };

    handleCancelPrescription = () => {
        this.setState({
            showUploadPictureBox: false,
            addMedicine: false,
            medicineName: "",
            selectedTime: "",
            commentText: "",
            selectedDuration: "",
            doseValOne: "",
            doseValTwo: "",
            doseValThree: "",
            isShow: true,
            prescriptionData: [],
            modeValue: "",
            followDays: [],
            start: "",
            end: "",
            isErrorFollow: false,
            errMsg: "",
            followUpDetails: {}
        });
    };

    handleModeValue = (event: ChangeEvent<{ value: unknown | string}>) => {
        const value = event.target.value;
        this.setState({
        modeValue: value,
        isErrorFollow: false,
        isFollowAdd: false
        }, () => {
          if(this.state.modeValue === ""){
            return this.setState({
                modeValueErr: "Please select mode type",
                isErrorFollow: true
            });
          } else {
            return this.setState({modeValueErr: ""});
          }
        });
      };

      handleDaysChange = (value: string) => {
        this.setState({
          followDays: value,
          isErrorFollow: false,
          isFollowAdd: false
        }, () => {
             if(this.state.followDays?.length > 3) {
                this.setState({
                    followErr: "Please select upto 3 days",
                    isErrorFollow: true
                });
                return true
            }
            else {
                return this.setState({
                    followErr: ""
                });
            }
        }
        )
      };

      handleStartChange(event : any) {
        const timeRegex = /^(1[0-2]|0?[1-9]):[0-5]\d\s?(AM|PM)$/;
        this.setState({
            start: event,
            isErrorFollow: false,
            isFollowAdd: false
        }, () => {
            if (timeRegex.test(this.state.start)) {
                this.setState({
                    errMsg: ""
                });
              }
              else {
                this.setState({
                    errMsg:  this.state.start ? "Please enter the time in HH:MM AM/PM format." : "This field is required.",
                    isErrorFollow: true
                });
              }
          });
    };

    handleEndChange(event: any) {
        const timeRegex = /^(1[0-2]|0?[1-9]):[0-5]\d\s?(AM|PM)$/;
        this.setState({
            end: event,
            isFollowAdd: false
        }, () => {
            if (timeRegex.test(this.state.end)) {
                this.setState({
                    errMsg: "",
                });
              }
              else {
                this.setState({
                    errMsg:  this.state.end ? "Please enter the time in HH:MM AM/PM format" : "This field is required.",
                    isErrorFollow: true
                });
              }
          });
    };

    handleFollowUpErr = () => {
        const timeRegex = /^(1[0-2]|0?[1-9]):[0-5]\d\s?(AM|PM)$/;
        if (this.state.modeValue === "") {
             notification["error"]({
                message: "Please select mode",
            });
            return true
        }
        if (this.state.start.trim() == "" || this.state.end.trim() == "") {
            notification["error"]({
                message: "Please input follow up time",
            });
            return true
        }
        if (!timeRegex.test(this.state.start)) {
            notification["error"]({
                message: "Please enter the time in HH:MM AM/PM format",
            });
            return true
        }
        if (!timeRegex.test(this.state.end)) {
            notification["error"]({
                message: "Please enter the time in HH:MM AM/PM format",
            });
            return true
        }
        if (this.state.followDays.length == 0) {
            notification["error"]({
                message: "Please select follow up days",
            });
            return true
        }
        if (this.state.followDays?.length > 3) {
            notification["error"]({
                message: "Please select upto 3 days",
            });
            return true
        }
        const followUp = {
            patient_health_id: this.state.healthId,
            mode: this.state.modeValue,
            follow_up_days: this.state.followDays,
            follow_up_time: {From: this.state.start, To: this.state.end}
          };
        this.setState({
            isFollowAdd: true,
            followUpDetails: followUp
        });
    };

    handleCloseFollowUp = () => {
        this.setState({
            modeValue: "",
            followDays: [],
            start: "",
            end: "",
            isErrorFollow: false,
            errMsg: "",
            isFollowAdd: false,
            followUpDetails: {}
        });
    };

    handleOpenFollowAccordian = () => {
       this.setState({
        closeFollowAccordian: !this.state.closeFollowAccordian
       })
    };
    // Customizable Area End
}
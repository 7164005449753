import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Row, Button, Carousel } from "antd";
import { MedicineImg } from "./assets";
import MedicineDetailsPageController from "./MedicineDetailsPageController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import FooterCardComponent from "../../shoppingcart/src/component/FooterCardComponent";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import Loader from "../../../components/src/Loader";

let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class MedicineDetailsPage extends MedicineDetailsPageController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patientappointment">Appointments</Link>

          <Link to="/services">Services</Link>
          <Link to="/patienthome">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { medicineDetails } = this.state;
    console.log(medicineDetails, "medicineDetails");

    const offerString = this.state.offerSlice
      ? medicineDetails.attributes.add_offers.slice(0, 260)
      : medicineDetails.attributes.add_offers;
    const detailsString = this.state.addionalSlice
      ? medicineDetails.attributes.product_details.slice(0, 260)
      : medicineDetails.attributes.product_details;

    return (
      <div>
        <Loader loading={this.state.loading} />

        <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup
          data-test-id="handleLogoutId"
          profileData={this.state.showPopup}
          logoutFunctiuon={this.handleLogout}
        />
        {this.renderNavigationTitle()}
        <div className="itemContainerWiidth" style={{ width: "85%" }}>
          <div className="ourServicesContainer">
            <Row gutter={[8, 16]}>
              <div style={{ width: "50%" }}>
                <Carousel autoplay>
                  <div style={stylesSheetCss.cardImgWrapper}>
                    <img
                      src={MedicineImg}
                      style={stylesSheetCss.carrasolIma}
                      alt="Carousel Image 1"
                    />
                  </div>
                  <div style={stylesSheetCss.cardImgWrapper}>
                    <img style={stylesSheetCss.carrasolIma} src={MedicineImg} />
                  </div>
                </Carousel>
              </div>
              <div style={{ width: "50%" }}>
                <h3 className="cardImageText">
                  {medicineDetails.attributes.medicine_name} 40 Tablet 10's
                </h3>
                <h3 className="cardImageTextSmall">
                  Strip of {medicineDetails.attributes.medicine_quantity}{" "}
                  tablets
                </h3>
                <p className="paragraphStyle">Manufacturer</p>
                <p className="paragraphStyle">TROIKAA PHARMACEUTICALS LTD</p>
                <p className="paragraphStyle">
                  MRP Rs {medicineDetails.attributes.total_amount}{" "}
                  {medicineDetails.attributes.discount} % OFF
                </p>
                <p>Rs {medicineDetails.attributes.discounted_price}</p>
                <Button
                  style={{
                    background: "#498ECC",
                    color: "white",
                    borderRadius: "30px",
                    padding: "0.5rem",
                    width: "20%",
                  }}
                >
                  ADD
                </Button>
              </div>
            </Row>
            <div>
              <div className="ourServicesHeaderPart">
                <h2 style={stylesSheetCss.addtionnalTitle}>
                  Additional offers
                </h2>
              </div>
              <div>
                <p style={stylesSheetCss.addtionalParagraph}>
                  {offerString} {medicineDetails.attributes.add_offers}{" "}
                  <span
                    data-test-id="additionOfferId"
                    style={stylesSheetCss.readMoreButton}
                    onClick={() =>
                      this.setState({ offerSlice: !this.state.offerSlice })
                    }
                  >
                    {this.state.offerSlice ? "Read More" : "Less"}
                  </span>
                </p>
              </div>
            </div>
            <div>
              <div className="ourServicesHeaderPart">
                <h2 style={stylesSheetCss.addtionnalTitle}>Product Details</h2>
              </div>
              <div>
                <p style={stylesSheetCss.addtionalParagraph}>
                  {detailsString}{" "}
                  <span
                    data-test-id="additionOfferId"
                    style={stylesSheetCss.readMoreButton}
                    onClick={() =>
                      this.setState({
                        addionalSlice: !this.state.addionalSlice,
                      })
                    }
                  >
                    {this.state.addionalSlice ? "Read More" : "Less"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div></div>
          <FooterCardComponent />
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const stylesSheetCss: any = {
  Carrasolwrapper: {
    height: "300px",
  },
  cardImgWrapper: {
    height: "300px",
  },
  carrasolIma: {
    maxWidth: "50%",
    height: "100%",
  },
  addtionnalTitle: {
    color: "#353535",
    fontFamily: "Encode Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  addtionalParagraph: {
    color: "#353535",
    fontFamily: "Encode Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  readMoreButton: {
    color: "#498ECC",
    fontFamily: "Encode Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    cursor: "pointer",
  },
};
// Customizable Area End

// Customizable Area Start
export default MedicineDetailsPage;
export { MedicineDetailsPage };
// Customizable Area End

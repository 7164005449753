import React from "react";
// Customizable Area Start
import { Typography, List, Avatar, Col, Row, Checkbox, Rate } from "antd";
import { Link } from "react-router-dom";
import NavBarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCardComponent from "./component/FooterCardComponent";
import { AiOutlineRight } from "react-icons/ai";
import { doctorImg } from "./assets";
import Text from "antd/lib/typography/Text";

// Customizable Area End

import DoctorListPageController, {
  Props,
} from "./DoctorListPageController.web";

export default class DoctorListPage extends DoctorListPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patientappointment">Appointment</Link>
          <Link to="/services">Services</Link>
          <Link to="/message">Message</Link>
        </div>
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    const doctordata = [
      {
        full_name: "Dr. rushabh",
        specialization: "General Physician",
        experience: "25",
        language: ["Hindi", "English", "Gujarati"],
      },
      {
        full_name: "Dr.Rohit Kumar",
        specialization: "General Physician",
        experience: "25",
        language: ["Hindi", "English"],
      },
      {
        full_name: "Dr.sumit yadav",
        specialization: "General Physician",
        experience: "25",
        language: ["Hindi", "English", "Gujarati"],
      },
      {
        full_name: "Dr. Anita Patel",
        specialization: "General Physician",
        experience: "25",
        language: ["Hindi", "English", "Gujarati"],
      },
    ];

    return (
      <>
        <NavBarCommonComponent handleProfile={undefined} />
        {this.renderNavigationTitle()}
        <div
          className="navigation-list-css-container"
          style={{ paddingLeft: "5%" }}
        >
          {" "}
          <Text className="navigation-list-css" style={{ color: "#498ECC" }}>
            Home
          </Text>{" "}
          <AiOutlineRight style={{ color: "#498ECC" }} />{" "}
          <Text style={{ color: "#498ECC" }} className="navigation-list-css">
            Service
          </Text>{" "}
          <AiOutlineRight style={{ color: "#498ECC" }} />{" "}
          <Text style={{ color: "#498ECC" }} className="navigation-list-css">
            Heart
          </Text>{" "}
          <AiOutlineRight style={{ color: "#498ECC" }} />{" "}
          <Text type="secondary" className="navigation-list-css">
            Angioplasty
          </Text>{" "}
          <AiOutlineRight style={{ color: "#498ECC" }} />{" "}
          <Text type="secondary" className="navigation-list-css">
            Package Details <AiOutlineRight style={{ color: "#498ECC" }} />{" "}
            <Text type="secondary" className="navigation-list-css">
              Choose Hospital
            </Text>
            <AiOutlineRight style={{ color: "#498ECC" }} />{" "}
            <Text type="secondary" className="navigation-list-css">
              Choose Doctor
            </Text>
          </Text>{" "}
        </div>
        <div className="doctorList">
          <Row>
            <Col style={{ width: "80%", margin: "0 auto" }}>
              {" "}
              <h5 style={{ textAlign: "start", padding: "30px" }}>
                Choose Doctor
              </h5>
            </Col>

            <List
              grid={{ gutter: 16, column: 2 }}
              style={{ width: "80%", margin: "0 auto" }}
              dataSource={doctordata}
              renderItem={(item, index) => (
                <List.Item
                  style={{
                    display: "flex ",
                    paddingRight: "40px",
                    paddingLeft: "40px",
                    marginBottom: "20px",
                    alignItems: "start",
                  }}
                >
                  <Avatar
                    style={{
                      width: "60px",
                      height: "60px",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                    size="small"
                    src={doctorImg}
                  />
                  <List.Item.Meta
                    title={
                      <Row justify={"space-between"}>
                        {" "}
                        <Typography className="doctorName">
                          {item.full_name}
                        </Typography>{" "}
                        <Rate style={{ margin: "0" }} allowHalf value={5} />{" "}
                      </Row>
                    }
                    description={
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography.Text
                          className="doctorDescription"
                          style={{ padding: "3px 0" }}
                        >
                          {item.specialization}{" "}
                        </Typography.Text>
                        <Typography.Text
                          className="doctorLanguageTitle"
                          style={{ padding: "3px 0", fontWeight: "bold" }}
                        >
                          MBBS{" "}
                        </Typography.Text>
                        <Typography.Text
                          className="doctorDescription"
                          style={{ padding: "3px 0" }}
                        >
                          {item.experience} Year Experience
                        </Typography.Text>
                        <Row
                          className="doctorDescription"
                          justify={"space-between"}
                        >
                          {" "}
                          <span>Patients treated</span>{" "}
                          <span className="reviewText">
                            Selected&nbsp; &nbsp; &nbsp; <Checkbox></Checkbox>
                          </span>{" "}
                        </Row>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Row>
          <FooterCardComponent />
        </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End

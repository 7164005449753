import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";

// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
    withLoaderProps & {
        // Customizable Area Start
        id: string;
        history: any;
        location: any;
        // Customizable Area End
    }

 
// Customizable Area Start
export interface HospitalsImagesType {
    id: number,
    url: string
}


export interface GeneralHealthType {
    id: string,
    type: string,
    attributes: {
        name: string
        identifier: string,
        description: string,
        category_type: string
        image:string
    }
}


export interface AdvancedHealthType {
    id: string,
    type: string,
    attributes: {
        name: string
        identifier: string,
        description: string,
        category_type: string
        image:string
    }
}
// Customizable Area End



// Customizable Area Start
export interface LabTestsType {
    id: string,
    type: string,
    attributes: {
        name: string,
        description: string,
        total_no_of_test: number,
        sample_type: string,
        fasting_required: string,
        price: number,
        lab_image: string,
    }
}
// Customizable Area End 


interface S {
    // Customizable Area Start
    generalHealthDataSliced: boolean;
    advancedHealthDataSliced: boolean;

    generalHealthDocctors:Array<GeneralHealthType>;
    advancedHealthDocctors:Array<AdvancedHealthType>;
    showPopup:boolean,
    isLoginUser:boolean,
    searchTerm:string,
    doctorList:any

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class PatientAppoitnmentLandingpageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getGeneralHealthDoctorsApiCallId: string | null = null;
    getAdvancedHealthDoctorsApiCallId: string | null = null;
    searchedDoctorsApiCallId: string | null = null;
    
    // Customizable Area Enddata:

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            // Customizable Area Start
            generalHealthDataSliced:false,
            advancedHealthDataSliced:false,
        
            generalHealthDocctors:[],
            advancedHealthDocctors:[],
            showPopup:false,
            isLoginUser:true,
            searchTerm:"",
            doctorList:[]

            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getGeneralHealthDocotrs();
        this.getAdvancedHealthDoctors();
        localStorage.removeItem("doctor_id");
        localStorage.removeItem("doctor_category")
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
                this.props.hideLoader();
            } else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
                this.props.hideLoader();
            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
                this.props.hideLoader();
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isValidResponse = (responseJson: any) => {
        return responseJson && responseJson.data;
    };
    // Customizable Area End

    // Customizable Area Start
    isInValidResponse = (responseJson: any) => {
        return responseJson && responseJson.errors;
    };
    // Customizable Area End

    // Customizable Area Start
    apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if(apiRequestCallId === this.getGeneralHealthDoctorsApiCallId){
            console.log("response...",responseJson)
            this.getGeneralHealthDocSuccessCallBack(responseJson)
        }
        if(apiRequestCallId === this.getAdvancedHealthDoctorsApiCallId){
            this.getAdvancedHealthDocSuccessCallBack(responseJson)
        }
    };
    // Customizable Area End

    // Customizable Area Start
    apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if(apiRequestCallId === this.getGeneralHealthDoctorsApiCallId){
            this.getgeneralHealthDoctorFailureCallBack(responseJson)
        }
        if(apiRequestCallId === this.getAdvancedHealthDoctorsApiCallId){
            this.getadvancedHealthDoctorFailureCallBack(responseJson)
        }
    };
    // Customizable Area End

   // Customizable Area Start
    handleGeneralHealthData=()=>{
        this.setState({
            generalHealthDataSliced:!this.state.generalHealthDataSliced
        })
    }


    handleAdvancedHealthData=()=>{
        this.setState({
            advancedHealthDataSliced:!this.state.advancedHealthDataSliced
        })
    }

   
    getGeneralHealthDocotrs = async () => {
        this.props.showLoader();
        this.getGeneralHealthDoctorsApiCallId = ApiCall({
            contentType: configJSON.getApiMethodContentType,
            method: configJSON.getApiMethod,
            endPoint: configJSON.getGeneralHealthDoctorsApiEndPoint,
        });
    };

    getAdvancedHealthDoctors = async () => {
        this.props.showLoader();
        this.getAdvancedHealthDoctorsApiCallId = ApiCall({
            contentType: configJSON.getApiMethodContentType,
            method: configJSON.getApiMethod,
            endPoint: configJSON.getAdvancedHealthDoctorsApiEndPoint,
        });
    };

    getGeneralHealthDocSuccessCallBack = (res: any) => {
        this.setState({
            generalHealthDocctors: res.data
        })
    }

    getAdvancedHealthDocSuccessCallBack = (res: any) => {
        this.setState({
            advancedHealthDocctors: res.data
        })
        console.log("advanced health...",res.data);
    }


    getgeneralHealthDoctorFailureCallBack = (err: any) => {
        console.log("general health >>>", err)
    }

    getadvancedHealthDoctorFailureCallBack = (err: any) => {
        console.log("advanced health >>>", err)
    }

    handleGeneralHealthonClickCard=( id:any,name:any, item:any)=>{
        localStorage.setItem("doctor_category",(item.attributes.name));
        this.props.history.push({ pathname: "/patient-appointment"},
        {state: { ids: { therapyListID: item.id }, navigateList: name,doctor_category: item.attributes.name}}
        
        )     
    }


    showDetailsHandler = () => {
        this.setState({ showPopup: !this.state.showPopup });
    } 


    handleLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout succesfully",
          });
    }

 // Customizable Area End
}
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web"
// Customizable Area End

export const configJSON = require("./config.js");


// Customizable Area Start
export interface OurBrandType {
    id: string,
    type: string,
    attributes: {
        name: string,
        description: string,
        brand_image: string
    }
}
// Customizable Area End

// Customizable Area Start
export interface HospitalsImagesType {
    id: number,
    url: string
}
// Customizable Area End

// Customizable Area Start
export interface TrendingType {
    id: string,
    type: string,
    attributes: {
        id: number,
        featured_therapy_id: number,
        therapy_name: string,
        therapy_images: Array<HospitalsImagesType>
    }
}
// Customizable Area End 


export interface Props {
    // Customizable Area Start
    id: string;
    history: any;
    location: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    BrandsData: Array<OurBrandType>;
    symptoms: Array<any>;
    categoryData: Array<TrendingType>;
    trendingTherapiesDataSliced: boolean;
    SymptomListSliced: boolean;
    BrandsDataSliced: boolean;
    showPopup: boolean;
    isLoginUser:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class LandingpageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getBrandsApiCallId: string | null = null;
    getSymtomsListApiCallId: string | null = null;
    getCategoryApiCallId: string | null = null;
    // Customizable Area Enddata:

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            // Customizable Area Start
            BrandsData: [],
            symptoms: [],
            categoryData: [],
            trendingTherapiesDataSliced: true,
            SymptomListSliced: true,
            BrandsDataSliced: false,
            showPopup: false,
            isLoginUser:true
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getBrandShow();
        this.getSymptomsShow();
        this.getCategoryShow();
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (this.isValidOKResponses(responseJson)) {
                this.apiSuccessOKCallBacks(apiRequestCallId, responseJson);
            } else if (this.isInValidOkResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isValidOKResponses = (responseJson: any) => {
        return responseJson && responseJson.data;
    };
    // Customizable Area End

    // Customizable Area Start
    isInValidOkResponse = (responseJson: any) => {
        return responseJson && responseJson.errors;
    };
    // Customizable Area End

    // Customizable Area Start
    apiSuccessOKCallBacks = (apiRequestCallId: any, responseJson: any) => {
        console.log("@@@ iresponseJson", responseJson)
        if (apiRequestCallId === this.getBrandsApiCallId) {
            this.getBrandsDataSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getSymtomsListApiCallId) {
            this.getSymptomsListSuccessDataCallBack(responseJson);
        }
        if (apiRequestCallId === this.getCategoryApiCallId) {
            this.getCategoryDataSuccessCallBack(responseJson);
        }

    };
    // Customizable Area End

    // Customizable Area Start
    apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getBrandsApiCallId) {
            this.getBrandsFailureCallBack(responseJson);
        }


    };
    // Customizable Area End

    handleServicesData = () => {
        this.setState({
            BrandsDataSliced: !this.state.BrandsDataSliced
        })
    }

    handleTherapiesData = () => {
        this.setState({
            trendingTherapiesDataSliced: !this.state.trendingTherapiesDataSliced
        })
    }

    navigateToDetailPage = (id: any) => {
    
        this.props.history.push({
          pathname: "/MedicineServicesPage",
          state: {
            therapieId: id,
          },
        });
      };

    handleSymptonDataSlide = () => {
        this.setState({
            SymptomListSliced: !this.state.SymptomListSliced
        })
    }


    getBrandShow = async () => {
        this.getBrandsApiCallId = ApiCall({
            contentType: configJSON.getTherapyDetailsApiContentType,
            method: configJSON.getTherapyDetailsAPiMethod,
            endPoint: `${configJSON.getBrandsAPiEndPoint}?is_request=web`,
        });
    };

    getBrandsDataSuccessCallBack = (res: any) => {
        this.setState({
            BrandsData: res.data
        })
    }
    getBrandsFailureCallBack = (err: any) => {
        console.log("@@@----error service", err)
    }
    getSymptomsShow = async () => {
        this.getSymtomsListApiCallId = ApiCall({
            contentType: configJSON.getTopDoctorsApiContentType,
            method: configJSON.getTopDoctorsAPiMethod,
            endPoint: `${configJSON.getSymptomps}?is_request=web`,
        });
    };
    getSymptomsListSuccessDataCallBack = (res: any) => {
        this.setState({
            symptoms: res.data
        })
    }
    getCategoryShow = async () => {
        this.getCategoryApiCallId = ApiCall({
            contentType: configJSON.getTherapyDetailsApiContentType,
            method: configJSON.getTherapyDetailsAPiMethod,
            endPoint: `${configJSON.getShopByCategoryAPiEndPoint}?is_request=web`,
        });
    };
    getCategoryDataSuccessCallBack = (res: any) => {
        this.setState({
            categoryData: res.data
        })
    }
    navigateToTherapyPage = (id: any, name: string) => {
        this.props.history.push({ pathname: "/Therapies", state: { therapyListID: id, bodyPartName: name } })
    }
    showDetailsHandler = () => {
        this.setState({ showPopup: !this.state.showPopup });
    } 
    
    handleLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
          });
}
// Customizable Area End
}
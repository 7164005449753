import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
  message: object;
  data: object;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface InvalidResponseType {
  errors: [
    {
      consultation_category: string;
    }
  ]
}

export interface DoctorProfileDetailsType {
  data: ProfileData;
}

export interface ProfileData {
  id: string;
  type: string;
  attributes: {
    representative_contact_no: string;
    full_name: string;
    medical_representative_name: string;
    profile_image: string;
  }
}

export interface PackageData {
  data: Array<PackageType>
}

export interface PackageType {
  id: string;
  attributes: {
    name: string;
    consultation_fees: number;
    sample_requirement: string;
    no_of_tests: number;
    description: string;
    duration: string;
    doctor_id: number;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  packageId:string;
  showPopup: boolean;
  doctorProfileData: DoctorProfileDetailsType;
  selectValue: Array<string>;
  editPackageName: string;
  editTestCount: string;
  editTestConsultationFees: string;
  editDescription: string;
  editDuration: string;
  editSample: string;
  editPackageNameErr: string;
  editTestCountErr: string;
  editTestConsultationFeesErr: string;
  editDescriptionErr: string;
  editDurationErr: string;
  editSampleErr: string;
  editDurationValue: Array<string>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PackageDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDetailsCallId: string = "";
  editPackageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      packageId: "",
      showPopup: false,
      doctorProfileData: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_name: "",
            profile_image: "",
            medical_representative_name: "",
            representative_contact_no: "",
          }
        }
      },
      selectValue: ["Blood", "Serum", "Plasma", "Stool"],
      editPackageNameErr: "",
      editTestCountErr: "",
      editTestConsultationFeesErr: "",
      editDescriptionErr: "",
      editDurationErr: "",
      editPackageName: "",
      editTestCount: "",
      editDescription: "",
      editTestConsultationFees: "",
      editDuration: "",
      editSample: "",
      editSampleErr: "",
      editDurationValue: ["15 minutes", "30 minutes", "45 minutes", "1 hour"]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scroll({
      top:0,
      left:0
    });
    this.getProfilefunction();
    this.setState({
      editPackageName: this.props.location.state.packageDetails.attributes.name,
      editTestConsultationFees: this.props.location.state.packageDetails.attributes.consultation_fees,
      editDescription: this.props.location.state.packageDetails.attributes.description,
      editDuration: this.props.location.state.packageDetails.attributes.duration,
      editSample: this.props.location.state.packageDetails.attributes.sample_requirement,
      packageId: this.props.location.state.packageDetails.id,
      editTestCount: this.props.location.state.packageDetails.attributes.no_of_tests
    });
  }

  apiCall = (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, type = "" } = data;
    let token = localStorage.getItem("token");
    let header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isEditValidRes(responseJson)) {
        this.editApiSuccessCall(apiRequestCallId, responseJson);
      }
    }
  }

  isEditValidRes = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  editApiSuccessCall = (apiRequestCallId: string, responseJson: DoctorProfileDetailsType & PackageData & object) => {
    if (apiRequestCallId === this.editPackageApiCallId) {
      this.editPackageListFunctionSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      this.getProfileSuccessCallBack(responseJson);
    }
  };

  handleDocDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleEditDocProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  handleShowPopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  getProfilefunction = async () => {
    this.getProfileDetailsCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint
    });
  };

  getProfileSuccessCallBack = (response: DoctorProfileDetailsType) => {
    this.setState({
      doctorProfileData: response
    });
  };

  handelShowDoctoPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  editPackageFunction = async () => {
    const nameRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
    const regex = /^[1-9]\d*$/;
    const feesRegex = /^[1-9]\d*$/;
    if (!nameRegex.test(this.state.editPackageName)) {
      return this.setState({
        editPackageNameErr: "Please enter a valid package name"
      });
    }
    if (!regex.test(this.state.editTestCount)) {
      return this.setState({
        editTestCountErr: "Please enter a valid test count"
      });
    }
    if (!feesRegex.test(this.state.editTestConsultationFees)) {
      return this.setState({
        editTestConsultationFeesErr: "Please enter a valid consultation fees"
      });
    }
    if (!nameRegex.test(this.state.editDescription)) {
      return this.setState({
        editDescriptionErr: "Please enter a valid description"
      });
    }
    if (this.state.editDuration == "") {
      return this.setState({
        editDurationErr: "Please select duration"
      });
    }
    if (this.state.editSample == "") {
      return this.setState({
        editSampleErr: "Please select sample requirement"
      });
    }
    let apiBody = {
      description: this.state.editDescription,
      consultation_fees: this.state.editTestConsultationFees,
      name: this.state.editPackageName,
      no_of_tests: this.state.editTestCount,
      duration: this.state.editDuration,
      sample_requirement: this.state.editSample
    }
    this.editPackageApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: `${configJSON.editPackagesApiEndPoint}?id=${this.state.packageId}`,
      body: apiBody
    });
  };

  editPackageListFunctionSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Package updated successfully",
    });
    this.props.history.push("/feespackagemanagement")
    this.setState({ 
      editPackageNameErr: "",
      editTestCountErr: "",
      editTestConsultationFeesErr: "",
      editDescriptionErr: "",
      editDurationErr: "",
      editPackageName: "",
      editTestCount: "",
      editDescription: "",
      editTestConsultationFees: "",
      editDuration: "",
      editSample: "",
      editSampleErr: ""
    });
  };

  handleEditPackageName = (event: { target: { value: string } }) => {
    let packageNameRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
    let value = event.target.value;
    this.setState({
      editPackageName: event.target.value,
    }, () => {
      if (packageNameRegex.test(value)) {
        this.setState({
          editPackageNameErr: "",
        });
      } else {
        this.setState({
          editPackageNameErr: value ? "Please enter a valid package name" : "This field is required.",
        });
      }
    });
  };

  handleEditTests = (event: { target: { value: string } }) => {
    let eventValue = event.target.value;
    let regex = /^[1-9]\d*$/
    this.setState({
      editTestCount: event.target.value,
    }, () => {
      if (regex.test(eventValue)) {
        this.setState({
          editTestCountErr: "",
        });
      } else {
        this.setState({
          editTestCountErr: eventValue ? "Please enter a valid test count" : "This field is required.",
        });
      }
    });
  };

  handleEditFeesChange = (event: { target: { value: string } }) => {
    let feesRegex = /^[1-9]\d*$/
    let consultationFees = event.target.value;
    this.setState({
      editTestConsultationFees: event.target.value,
    }, () => {
      if (feesRegex.test(consultationFees)) {
        this.setState({
          editTestConsultationFeesErr: "",
        });
      } else {
        this.setState({
          editTestConsultationFeesErr: consultationFees ? "Please enter a valid consultation fees" : "This field is required.",
        });
      }
    });
  };

  editDescriptionHandelChange = (event: { target: { value: string } }) => {
    let descriptionRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/
    const descriptionEvent = event.target.value;
    this.setState({
      editDescription: event.target.value,
    }, () => {
      if (descriptionRegex.test(descriptionEvent)) {
        this.setState({
          editDescriptionErr: "",
        });
      } else {
        this.setState({
          editDescriptionErr: descriptionEvent ? "Please enter a valid description" : "This field is required.",
        });
      }
    });
  };

  editDurationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const inputValue = (event.target as any).value;
      this.setState({ editDuration: inputValue }, () => {
        if (this.state.editDuration !== "") {
          return this.setState({
            editDurationErr: ""
          });
        }
      });
  };

  handleSampleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const inputValue = (event.target as any).value;
      this.setState({ editSample: inputValue }, () => {
        if (this.state.editSample !== "") {
          return this.setState({
            editSampleErr: ""
          });
        }
      });
  };

  handleBackButton = () => {
    this.props.history.goBack();
  };
  // Customizable Area End
}
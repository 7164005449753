import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";

// Customizable Area End

export const configJSON = require("./config.js");

// Customizable Area Start
interface ProductDetail {
  medicine_id: number;
  medicine_name: string;
  medicine_quantity: string;
  total_amount: string;
  discount: string;
  manufacturer_name: string;
  discount_price: string;
}

// Customizable Area End

// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start
export interface SymptomNameType {
  id: number;
  name: string;
}
// Customizable Area End

// Customizable Area Start
export interface CartDetailsType {
  id: string;
  type: string;
  attributes: {
    product_count: number;
    product_detail: ProductDetail;
    total_cost: string;
  };
}

interface CartResponse {
  cart_products: {
    data: CartDetailsType[];
  };
  total_product_cost: {
    total_cost: string;
  };
  price_discount: null | any;
  shipping_charge: {
    shipping_charge: number;
  };
  shipping_discount: {
    shipping_discount: number;
  };
  total_cart_cost: {
    total_cart: number;
  };
  total_savings: null | any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  CartDetails: CartResponse | null;
  offerSlice: boolean;
  addionalSlice: boolean;
  showPopup: boolean;
  isLoginUser: boolean;
  isModalOpen: boolean;
  CuponDetails: any;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class MedicineCartPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCartDetailsApiCallId: string | null = null;
  getApplyCuponApiCallId: string | null = null;
  getCreateCartSApiCallId: string | null = null;

  // Customizable Area Enddata:

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      offerSlice: true,
      addionalSlice: true,
      CartDetails: null,
      showPopup: false,
      isLoginUser: true,
      isModalOpen: false,
      CuponDetails: [],
      loading: false,
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getCartDetailsShow();
    this.getApplyCuponShow();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isValidOkResponse(responseJson)) {
        this.apiSuccessOKCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidOkResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidOkResponse = (responseJson: any) => {
    return responseJson !== null;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidOkResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End
  // Customizable Area Start

  // Customizable Area Start
  apiSuccessOKCallBacks = (apiRequestCallId: any, responseJson: any) => {
    console.log("@@@ iresponseJson", responseJson);
    if (apiRequestCallId === this.getCartDetailsApiCallId) {
      this.getCartDetailsDataSuccessCallBack(responseJson);
      this.setState({ isModalOpen: false });
    }
    if (apiRequestCallId === this.getApplyCuponApiCallId) {
      this.getApplyCuponSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCreateCartSApiCallId) {
      this.setState({ loading: false });
      this.getCartDetailsShow();
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getCartDetailsApiCallId) {
      this.getCarDetailsFailureCallBack(responseJson);
    }
  };
  // Customizable Area End
  getCartDetailsShow = async () => {
    this.getCartDetailsApiCallId = ApiCall({
      contentType: configJSON.getOurservicesApiContentType,
      method: configJSON.getOurservicesAPiMethod,
      endPoint: `${configJSON.getCartDetailsAPiEndPoint}?`,
    });
  };
  getCartDetailApply = async (promo_code: string) => {
    this.getCartDetailsApiCallId = ApiCall({
      contentType: configJSON.getOurservicesApiContentType,
      method: configJSON.getOurservicesAPiMethod,
      endPoint: `${configJSON.getCartDetailsAPiEndPoint}?promo_code=${
        promo_code ? promo_code : 0
      }`,
    });
  };

  getApplyCuponShow = async () => {
    this.getApplyCuponApiCallId = ApiCall({
      contentType: configJSON.getApplyCuponApiContentType,
      method: configJSON.getApplyCuponAPiMethod,
      endPoint: `${configJSON.getApplyCuponAPiEndPoint}`,
    });
  };

  getCartDetailsDataSuccessCallBack = (res: any) => {
    this.setState({
      CartDetails: res.data.attributes,
    });
  };

  getApplyCuponSuccessCallBack = (res: any) => {
    console.log(res.data, "resData");
    this.setState({
      CuponDetails: res.data,
    });
  };

  getCarDetailsFailureCallBack = (err: any) => {
    console.log("@@@----error service", err);
  };
  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout succesfully",
    });
  };
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  updateCartData = (data: CartDetailsType, type: number) => {
    const body = {
      cart: {
        patient_id: 986,
        cart_products_attributes: [
          {
            medicine_id: data.attributes.product_detail.medicine_id,
            product_count: type,
          },
        ],
      },
    };
    this.setState({ loading: true });
    this.getCreateCartSApiCallId = ApiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_shopping_cart/create_cart`,
      body,
    });
  };
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { Card } from "antd";
import {  fileIcon, batchIcon, compliantIcon, verifiedIcon} from "../assets";
// Customizable Area End
const FooterCardComponent = () => {
    // Customizable Area Start
    return (
        <>
      <Card
        className='footer-card-batge'
        style={styles.footerCardBatge}
      >
        <div
          style={{ display: 'flex', justifyContent: "space-between", }} className="footer-Navigation">
          <div>
            <img
              width={100} src={fileIcon} />
            {/* <Avatar src= /> */}
            <p>256 Bits Encryptions</p>
          </div>
          <div>
            <img style={{ height: "100px" }} src={batchIcon} />
            <p> ISO 27001 Certified</p>
          </div>
          <div>
            <img style={{ height: "100px" }} src={compliantIcon} />
            <p>HIPAA compliant<br /> data centers</p>
          </div><div>
            <img style={{ height: "100px" }} src={verifiedIcon} />
            <p>DSCI Member</p>
          </div>
        </div>
      </Card>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
const styles :any = {
    footerCardBatge: {
        boxShadow: "0px 2px 14px 0px rgba(0, 0, 0, 0.14)",
        margin: 20,
        padding: 10,
        borderRadius: 8,
    background: "#FCFCFC",
    }
  }
  // Customizable Area End
// Customizable Area Start
export default FooterCardComponent
// Customizable Area End


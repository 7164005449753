import React from 'react'
// Customizable Area Start
import { Row, Col, Select, Input, Form, Button } from "antd";
import LoginController from "./LoginController.web";
import CarouselCommonComponet from '../../../components/src/CarouselCommonComponet.web';
import { Link } from 'react-router-dom';
import { apple } from "../../social-media-account-registration/src/assets";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
// Customizable Area End

export default class Login extends LoginController {
    // Customizable Area Start
    renderForSign = () => {
        return (
            <div className="logIn-bottom-text">
                <p>Don't have an account? </p>
                <Link to="/signup">
                    <p className="sign-up-navigation">Sign Up</p>
                </Link>
            </div>
        )
    }
    // Customizable Area End

    // Customizable Area Start

    renderForSignUpWithNavigation = () => {
        return (
          <div className="horizontal-ruler-container">
            <div className="horizonatal-ruler" />
            <p className="signInWith">Or Login with</p>
            <div className="horizonatal-ruler" />
          </div>
        );
    };

    renderSignInForm = () => {
        return (
            <div className="signIn-container">
                <Form
                    data-test-id="formSubmitId"
                    name="basic"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={this.state.selectLoginRole === "Doctor" ? this.getDoctorLoginOtp : this.getLoginOtp}
                    autoComplete="off"
                    requiredMark={false}
                    className="signIn-form-style">
                    <p className="signIn-heading">Login</p>
                    <p className="signIn-text">
                        Welcome to Meril, please provide your login credentials below.
                    </p>
                    <Row className="">
                        <p className="form-email-text">Login as</p>
                    </Row>
                    <div className="selectItem">
                        <Select
                            data-test-id='btnLoginSelectRoleId'
                            defaultValue={this.state.selectLoginRole}
                            placeholder="Please select login role"
                            style={{ width: "100%" }}
                            onChange={this.handleLoginSelectRole}
                        >
                            {this.state.loginRoleData.map((item: any) => (
                                <Select.Option value={item.role} key={item.id} name="btnSelectRole">
                                    {item.role}
                                </Select.Option>
                            ))}
                        </Select>
                
                    </div>
                    <Row className="">
                        {
                            this.state.selectLoginRole === "Doctor" ? <p className="form-email-text">Enter your mobile no. or email id </p> : <p className="form-email-text">Enter your mobile no.</p>
                        }
                    </Row>
                    <Form.Item
                        label=""
                        name="phonoNoName"
                        rules={[
                            {
                                required: true,
                                message: this.state.selectLoginRole === "Patient" ? "Please input  mobile number" : "Please input  email id / mobile no.",
                            },
                            {
                                pattern: /^[6-9]\d{9}$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: this.state.selectLoginRole === "Patient" ? "Please input valid number" : "Please input valid email id / mobile no."
                            },
                        ]}
                    >
                        <Input data-test-id="phonoNoName" onChange={ this.state.selectLoginRole === "Patient" ? this.handleLoginMobile : this.validateDoctorInput} />
                    </Form.Item>
                    {this.state.invalidAccount ? (
                        <p className="error-password">Patient not found, or not activated</p>
                    ) : (
                        ""
                    )}
                      {this.state.inValidDoctorAccount ? (
                        <p className="error-password">Email or phone number is invalid or KYC is not done yet.</p>
                    ) : (
                        ""
                    )}

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            data-test-id="btnsubmit"
                            className="genrate-otp-button"
                        >
                            Generate OTP
                        </Button>
                    </Form.Item>
                </Form>

                {this.renderForSignUpWithNavigation()}
                <div className="social-login-containerr">
                    <div className="social-login-item">
                        <GoogleOAuthProvider clientId="247512604639-h7gesic8lut2m9pimah5tca0h8io8pia.apps.googleusercontent.com">
                            <GoogleLogin
                               type='icon'
                                data-test-id="googleLogin_Id"
                                onSuccess={googleResponse => {
                                    this.handleGoogleLoginResponse(googleResponse);
                                }}
                                onError={() => {
                                    this.handleGoogleLoginFailure();
                                }}
                            />
                        </GoogleOAuthProvider>
                    </div>
                    <div className="social-login-item socail_img">
                        <img src={apple} className="socail_img" />
                    </div>
                </div>
                {this.renderForSign()}
            </div>
        );
    };
    // Customizable Area End
    // Customizable Area Start
    render() {
        return (
            <div>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <CarouselCommonComponet />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        {this.renderSignInForm()}
                    </Col>
                </Row>
            </div>
        );
    }
    // Customizable Area End
}

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  selectedKey: string;
  name: string;
  flatHome: string;
  areasStreet: string;
  landmarks: string;
  pinCode: string;
  towncity: string;
  states: string;
  myAddressDetail: any;
  // myAddressDetails: res.data,

  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfilePageOutLet extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addsAdressApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      selectedKey: "0",
      name: "",
      flatHome: "0",
      areasStreet: "0",
      landmarks: "0",
      pinCode: "0",
      towncity: "0",
      states: "0",
      myAddressDetail: "any",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson.data);
      } else if (responseJson && responseJson.errors) {
        console.log(responseJson.errors);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }

      // Customizable Area End
    }
  };
  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    console.log("@@@ iresponseJson", responseJson);
    if (apiRequestCallId === this.addsAdressApiCallId) {
      console.log("@@@ Address Addded succefuffly", responseJson);
    }
  };
  handleChangesFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value });
  };
  handleChangesFlatHouse = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ flatHome: event.target.value });
  };
  handleChangesArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ areasStreet: event.target.value });
  };
  handleChangesLandmark = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ landmarks: event.target.value });
  };
  handleChangesPincode = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ pinCode: event.target.value });
  };
  handleChangesCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ towncity: event?.target?.value });
  };
  handleChangesState = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ states: event?.target?.value });
  };
  handleSubmits = async () => {
    const payLoads = {
      delivery_address: {
        patient_id: 2,
        full_name: this.state.name,
        building_name: this.state.flatHome,
        area: this.state.areasStreet,
        landmark: this.state.landmarks,
        pincode: this.state.pinCode,
        state: this.state.states,
        city: this.state.towncity,
        is_default: true,
      },
    };

    this.addsAdressApiCallId = ApiCall({
      contentType: configJSON.getAddressApiContentType,
      method: configJSON.getAddressAPiMethod,
      endPoint: `${configJSON.getAddressAPiEndPoint}`,
      body: payLoads,
    });
  };
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { Input } from "antd"
import { Box, Typography, Paper } from "@material-ui/core"
export const imgLocation = require("../assets/location.png");
import { location } from "../../splashscreen/src/assets";
import MerilLoader from '../../../components/src/MerilLoader.web';

// Customizable Area End

import LocationControllerWeb, {
    configJSON,
    Props
} from "./LocationController.web";


class Location extends LocationControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start



    // Customizable Area End

    render() {
        // Customizable Area Start
        
        return (
            <div>
                <div style={{ position: "relative" }}  >
                    <Input
                        disabled={Boolean(localStorage.getItem("is_kyc"))}
                        id="location-input"
                        className="location-input customise-input"
                        prefix={<img src={location}
                        style={{ width: 24, height: 24 }} alt="" />}
                        placeholder={configJSON.currentLocationPlaceholder}
                        onChange={this.handleInputChnage}
                        onClick={() => this.setState({ isLocationInputTouched: !this.state.isLocationInputTouched })}
                        value={this.state.cityname }
                        data-test-id="location-input-id"
                    />
                    {
                        this.state.isLocationInputTouched ? (
                            <Box component={Paper} p={2}
                                className="city-area-container"
                            >
                                <Box>
                                    <Typography data-test-id="useCurrentLocationId" onClick={() => this.useMyLocation()} color="primary" variant="body1" style={{ cursor: "pointer" }} >Use my location</Typography>
                                </Box>

                                {
                                  this.state.cityAreas &&  this.state.cityAreas.map((cityArea: string, index: any) => (
                                        <Typography data-test-id="cityAreaID" key={cityArea} variant="body2" onClick={()=>this.setState({selectedArea:cityArea,cityname:cityArea,isLocationInputTouched:false})} >{cityArea} </Typography>
                                    ))
                                }
                            </Box>
                        ) : null
                    }

                </div>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default MerilLoader(Location);
export { Location };
// Customizable Area End

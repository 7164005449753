export const deleteIcon = require("../assets/delete.png");
export const checkedIcon = require("../assets/checked.png");
export const uncheckedIcon = require("../assets/unchecked.png");
export const Edit = require("../assets/Editphoto.png");
export const edit = require("../assets/edit.png");
export const Delete = require("../assets/unchecked.png");
export const percentageOffer = require("../assets/Medicine.png");
export const MedicineImg = require("../assets/Medicine.png");
export const BanerImg = require("../assets/Baner.png");
export const dhoniImage = require("../assets/dhoniImage.png");
export const image = require("../assets/image.png");
export const images1 = require("../assets/img1.png");
export const images2 = require("../assets/img2.png");
export const images3 = require("../assets/img3.png");
export const images4 = require("../assets/img4.png");
export const images5 = require("../assets/img5.png");
export const femaleDoctor = require("../assets/femaleDoctor.png");
export const Maledoctor = require("../assets/Maledoctor.png");
export const merilLogo = require("../assets/meril.png");
export const RightOutlined = require("../assets/RightOutlined.png");
export const discount = require("../assets/discount.png");
export const checklist = require("../assets/checklist.png");
export const getmoney = require("../assets/getmoney.png");
export const placeholder = require("../assets/placeholder.png");
export const shoppingcart = require("../assets/shoppingcart.png");
export const minsesymbol = require("../assets/minsesymbol.png");
export const Plussymbol = require("../assets/Plussymbol.png");
import React from "react";
// Customizable Area Start
import {Typography,Button, Divider,List,Row,Col,Input,Card} from "antd"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {Component} from "react"
import { AntDesignOutlined } from '@ant-design/icons';
import { EnvironmentOutlined, SearchOutlined, ShoppingCartOutlined,AudioOutlined } from '@ant-design/icons';
//@ts-ignore
import shiled from "./shield.png";
//@ts-ignore
import folder from "./folder.png";
//@ts-ignore
import vaguaness from "./vagueness.png";
//@ts-ignore
import medal from "./medal.png"
// Customizable Area End


export const  CertificationComponent= ()=> {

    // Customizable Area Start

    // Customizable Area End

    return (
        <div className="certification-container" >
       <Card className="card-certification" >

        <div className="certificationWrapper" >
            <div className="certification-wrapper" >
            <img src={folder} alt="" className="card-certification-img" />
            <Typography.Text className="certification-text-label" >
            256 Bits 
            <br/>
           Encryptions
            </Typography.Text>
            </div>

            <div className="certification-wrapper" >
            <img src={vaguaness} alt="" className="card-certification-img" />
            <Typography.Text className="certification-text-label" >
            ISO 27001
            <br/>
            Certified
            </Typography.Text>
            </div>

            <div className="certification-wrapper" >
            <img src={medal} alt="" className="card-certification-img" />
            <Typography.Text className="certification-text-label" >
            HIPAA compliant
            <br/>
            data centers
            </Typography.Text>
            </div>

            <div className="certification-wrapper" >
            <img src={shiled} alt="" className="card-certification-img" />
            <Typography.Text className="certification-text-label" >
            DSCI Member
            </Typography.Text>
         </div>
        </div>
     </Card>

   </div>
     

    );
    // Customizable Area End
  
}

// Customizable Area Start

// Customizable Area End

import React from "react";
// Customizable Area Start
import { Row, Col, Card, Radio, Typography } from "antd";
import ProfilePageController from "./PatientProfilePageController.web";
import { voice, Search } from "./assets";
import PatientOutLet from "./ProfileOutlet.web";
import Loader from "../../../components/src/Loader";
let config = require("../../../framework/src/config.js").baseURL;
// Customizable Area End
class PatientProfilePage extends ProfilePageController {
  // Customizable Area Start
  recentOrderRender = () => {
    const myOrderDataList =
      this.state.searchTerm !== ""
        ? this.state.filteredMedicinList
        : this.state.myOrderDataList;

    return (
      <>
        {myOrderDataList?.map((item: any) => (
          <>
            <p
              style={{ margin: "28px 0 auto 0px", fontSize: "16px" }}
              className="profile-page-text"
            >
              Order on&nbsp;{item?.attributes?.order_on}
            </p>
            <Card className="recent-card">
              <Row>
                <Col flex="80%">
                  <h4 className="profile-page-text-main">
                    {item.attributes.medicine_details.medicine_name}
                  </h4>
                  <p className="profile-page-text">
                    Strip of 10 tablets
                    {item.attributes.medicine_details.medicine_quantity}
                  </p>
                  <p className="profile-page-text">Manufacturer</p>
                  <p className="profile-page-text">
                    {item.attributes.medicine_details.manufacturer_name}
                  </p>
                  <p className="profile-page-text">
                    Expected Delivery by {item.DeliveredDate}{" "}
                    {item.DeliveredTime}
                  </p>
                </Col>
                <Col>
                  <p className="profile-page-text-main">
                    Rs {item.attributes.medicine_details.discount_price}
                  </p>
                  <p className="mrp-amount">
                    MRP
                    <Typography.Text
                      delete
                      style={{ color: "rgba(53, 53, 53, 0.60)" }}
                    >
                      {" "}
                      {
                        item?.attributes?.medicine_details.medicine_total_amount
                      }{" "}
                    </Typography.Text>
                  </p>
                  <p className="qun-text">
                    Qty : {item.attributes.medicine_details.medicine_quantity}{" "}
                    Strip
                  </p>
                </Col>
              </Row>
            </Card>
          </>
        ))}
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  pastOrderRender = () => {
    const pass_orderData =
      this.state.searchTerm !== ""
        ? this.state.filteredMedicinList
        : this.state.pass_orderData;

    return (
      <>
        {pass_orderData?.map((item: any) => (
          <div key={item.id}>
            <p
              style={{ margin: "28px 0 auto 0px" }}
              className="profile-page-text"
            >
              Order on&nbsp;{item?.attributes?.order_on}
            </p>
            <Card className="recent-card">
              <Row>
                <Col flex="80%">
                  <h4
                    className="Medicine-page-text"
                    style={{ fontSize: "20px" }}
                  >
                    {item?.attributes?.medicine_details?.medicine_name}
                  </h4>
                  <p className="profile-page-text">
                    Strip of 10 tablets
                    {item?.attributes?.medicine_details?.medicine_quantity}
                  </p>
                  <p className="profile-page-text">Manufacturer</p>
                  <p className="profile-page-text">
                    {item.attributes.medicine_details?.manufacturer_name}
                  </p>
                  <p className="profile-page-text">
                    Expected Delivery by {item.DeliveredDate}{" "}
                    {item.DeliveredTime}
                  </p>
                </Col>
                <Col>
                  <h4 className="mrp-text">
                    Rs {item?.attributes?.medicine_details?.discount_price}
                  </h4>
                  <p className="mrp-amount">
                    MRP
                    <Typography.Text
                      delete
                      style={{ color: "rgba(53, 53, 53, 0.60)" }}
                    >
                      {" "}
                      {
                        item?.attributes?.medicine_details.medicine_total_amount
                      }{" "}
                    </Typography.Text>
                  </p>

                  <p className="qun-text">
                    Qty : {item?.attributes?.medicine_details.medicine_quantity}
                  </p>
                </Col>
              </Row>
            </Card>
          </div>
        ))}
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <div>
        <PatientOutLet {...this.props} selectedKey="0">
          <p className="Settingheading">My Orders</p>
          <div className="searchBoxDoc_profile">
            <div className="searchInput_profile">
              <img
                src={Search}
                alt="Right Arrow"
                style={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              />
              <input
                style={{ backgroundColor: "white" }}
                type="text"
                className="searchInput_profile"
                placeholder="Search Orders"
                data-test-id={"SearchId"}
                onChange={(e) => this.handleSearch(e, this.state.tabActiive)}
              />
              <img
                src={voice}
                alt="Right Arrow"
                style={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
          <Radio.Group
            className="toggleButton-css"
            defaultValue="recent-order"
            buttonStyle="solid"
            data-test-id={"recentorderId"}
            onChange={this.handleAlignment}
          >
            <Radio.Button
              value="recent-order"
              className={"toggleButton-css-first"}
              style={{ borderRadius: "0 !important" }}
            >
              Recent Order
            </Radio.Button>
            <Radio.Button
              className={"toggleButton-css-second"}
              style={{ borderRadius: "0 !important" }}
              value="pass-order"
              data-test-id={"passtorderId"}
            >
              Past Order
            </Radio.Button>
          </Radio.Group>
          <div style={{ position: "relative" }}>
            <Loader loading={this.state.loading} />

            {this.state.tabActiive === "recent-order"
              ? this.recentOrderRender()
              : this.pastOrderRender()}
          </div>
        </PatientOutLet>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default PatientProfilePage;
// Customizable Area End

import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
let config = require('../../framework/src/config.js').baseURL;
// Customizable Area End

type ProceduresCommonComponentProps = {
    // Customizable Area Start
    handleProceduresDataList: any,
    handleTrendingTherapies:any,
    dataList: any,
    heading:any,

    // Customizable Area End
};

const ProceduresCommonComponent = ({
    // Customizable Area Start
    handleProceduresDataList,
    handleTrendingTherapies,
    dataList,
    heading,
    // Customizable Area End
}: ProceduresCommonComponentProps) => {
    // Customizable Area Start

    return (
        <>
            <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>{heading}</h2>
                        <p className='ourServicesSubTitle' onClick={()=> handleProceduresDataList()} data-test-id="trendingtherapiesId">
                            {
                               dataList.length <= 4 ? "See All" : "View less"
                            }
                        </p>
                    </div>
                    <Row className='therapyRow' gutter={[20,20]}>
                        {dataList?.map((item: any) => {
                            return (
                                <Col style={{marginBottom:"28px"}} xl={6} lg={6} md={12} sm={12} xs={24} key={item.id} onClick={()=>handleTrendingTherapies(item)} >
                                    <div className='therapiesCard'>

                                        <img src={config+item?.attributes?.therapy_images[0]?.url ? config+item?.attributes?.therapy_images[0]?.url : ""} className={item?.attributes?.therapy_images ? "servicesCarddImg" : "imageHide"} />

                                        <p className='therapiesCardtext'> {item?.attributes?.therapy_name}</p>
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                </div>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default ProceduresCommonComponent
// Customizable Area End


import React from "react";
// Customizable Area Start
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import UploadDocumentController, { Props } from "./UploadDocumentController.web";
import {
    Box,
    Grid,
    styled,
    Typography
} from "@material-ui/core";
import { AiOutlinePlus } from "react-icons/ai";
const is_kyc = localStorage.getItem("is_kyc");
import { MdArrowBack } from "react-icons/md";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End


export default class UploadDocument extends UploadDocumentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPageHeader = () => {
        return (
            <EditPageHeader>
                <Link to={is_kyc === "approved" ? "/doctorhome" : "/uploaddoc"}><PageHeader>Home</PageHeader></Link>
                <Link to={is_kyc === "approved" ? "/doctorschedule" : "/uploaddoc"}><PageHeader>Schedule</PageHeader></Link>
                <Link to={is_kyc === "approved" ? "/addpatient" : "/uploaddoc"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderHeader = () => {
        return (
            <HeadarBox>
                <HeaderTitle data-test-id="verificationtext">
                    <BackArrow onClick={this.handleBack} data-test-id="previousButton"/>Verification</HeaderTitle>
                <HeaderSubTitle>Please upload below document to verify your identity.</HeaderSubTitle>
            </HeadarBox>
        )
    };

    renderDetailsForm = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DetailsLabelText>Identity Details</DetailsLabelText>
                        <Label htmlFor="identityDetailsFileInputBox">
                            <FileUploadLabelBox>
                                <PlusIconButton>
                                    <PlusIcon />
                                </PlusIconButton>
                                <ImageSizeText>Browse to upload (Max 10MB)</ImageSizeText>
                                <FileInputBox type="file" id="identityDetailsFileInputBox" onChange={this.onHendelIdentityDetails} data-test-id="identityId" />
                            </FileUploadLabelBox>
                        </Label>
                        {this.state.identityDetails.length > 0 && <ExtraInfoText>Uploaded {this.state.identityDetails.length} file.</ExtraInfoText>}
                        <ExtraInfoText> Upload any Gov. issued identity proof e.g Adhaar, PAN, etc (pdf only)</ExtraInfoText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DetailsLabelText>Degree Details</DetailsLabelText>
                        <Label htmlFor="degreeDetailsFileInputBox">
                            <FileUploadLabelBox>
                                <PlusIconButton>
                                    <PlusIcon />
                                </PlusIconButton>
                                <ImageSizeText>Browse to upload (Max 10MB)</ImageSizeText>
                                <FileInputBox type="file" id="degreeDetailsFileInputBox" onChange={this.onHendelDegreeDetails} data-test-id="degreeid" />
                            </FileUploadLabelBox>
                        </Label>
                        {this.state.degreeDetails.length > 0 && <ExtraInfoText>Uploaded {this.state.degreeDetails.length} file.</ExtraInfoText>}
                        <ExtraInfoText>Upload your degree certificate (pdf only)</ExtraInfoText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DetailsLabelText>Registration Details</DetailsLabelText>
                        <Label htmlFor="registrationDetailsFileInputBox">
                            <FileUploadLabelBox>
                                <PlusIconButton>
                                    <PlusIcon />
                                </PlusIconButton>
                                <ImageSizeText>Browse to upload (Max 10MB)</ImageSizeText>
                                <FileInputBox type="file" id="registrationDetailsFileInputBox" onChange={this.onHendelRegistrationDetails} data-test-id="registerId" />
                            </FileUploadLabelBox>
                        </Label>
                        {this.state.registrationDetails.length > 0 && <ExtraInfoText>Uploaded {this.state.registrationDetails.length} file.</ExtraInfoText>}
                        <ExtraInfoText>Upload your registration certificate (pdf only)</ExtraInfoText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DetailsLabelText>Clinic Registration Details</DetailsLabelText>
                        <Label htmlFor="clinicRegistrationDetailsFileInputBox">
                            <FileUploadLabelBox>
                                <PlusIconButton>
                                    <PlusIcon />
                                </PlusIconButton>
                                <ImageSizeText>Browse to upload (Max 10MB)</ImageSizeText>
                                <FileInputBox type="file" id="clinicRegistrationDetailsFileInputBox" onChange={this.onHendelClinicRegistrationDetails} data-test-id="clinicreg" />
                            </FileUploadLabelBox>
                        </Label>
                        {this.state.clinicRegistrationDetails.length > 0 && <ExtraInfoText>Uploaded {this.state.clinicRegistrationDetails.length} file.</ExtraInfoText>}
                        <ExtraInfoText>Upload your clinic registration certificate (pdf only)</ExtraInfoText>
                    </Grid>
                </Grid >
                <ContinueButton onClick={this.postDoctorVerify} data-test-id="continueID">Continue</ContinueButton>
            </>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUpDate} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogoutClick} showProfile={this.handleDocDetailsPageCall} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfileCall} handleClosePopup={this.handlePopupCloseCall} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderPageHeader()}
                    {this.renderHeader()}
                    {this.renderDetailsForm()}
                </ContainerBox>
                <FooterDoctor />
                <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PlusIcon = styled(AiOutlinePlus)({
    fontSize: "22px"
});

const Label = styled("label")({
    width: "100%"
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const KycBox = styled(Box)({
    display: "block",
    margint: "20px auto",
    paddding: "20px",
    boxSizing: "border-box",
});

const HeaderTitle = styled(Box)({
    fontWeight: 500,
    color: "#000",
    fontSize: "20px",
    margin: "10px 0px"
});

const HeaderSubTitle = styled(Typography)({
    fontSize: "16px",
    color: "gray"
});

const HeadarBox = styled(Box)({
    paddign: "10p   x 0"
});

const DetailsLabelText = styled(Typography)({
    fontWeight: 500,
    margin: "10px 0",
    fontSize: "16px"
});

const ExtraInfoText = styled(Typography)({
    color: "#808080",
    fontSize: "14px",
    margin: "10px 0"
});

const FileUploadLabelBox = styled(Box)({
    borderRadius: "10px",
    backgroundColor: "#f7f7f7",
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "5px",
    padding: "10px",
    boxSizing: "border-box"
});

const PlusIconButton = styled(Box)({
    height: "50px",
    width: "50px",
    backgroundColor: "#e5e5e5",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "gray"
});

const ImageSizeText = styled(Typography)({
    color: "gray",
    fontSize: "12px"
});

const ContinueButton = styled("button")({
    color: "#fff",
    padding: "15px",
    width: "250px",
    borderRadius: "50px",
    backgroundColor: "#3a79c1",
    fontSize: "15px",
    fontWeight: 500,
    border: "none",
    display: "block",
    margin: "20px auto"
});

const FileInputBox = styled("input")({
    display: "none"
});

const BackArrow = styled(MdArrowBack)({
    fontSize: "24px"
});
// Customizable Area End
import React from "react";
// Customizable Area Start
import { Box, Container, Grid } from "@material-ui/core";
import AccountCircle from '@material-ui/icons/AccountCircle';
import moment from 'moment';
// Customizable Area End

import HospitalAdminController, {
    Props,
} from "./HospitalAdminController.web";
import { Typography } from "antd";



// Customizable Area Start

// Customizable Area End

export default class AdminProfile extends HospitalAdminController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <Box>
                <Box style={Styles.mainHeader}>
                    <Container>
                        <Grid container>
                            <Grid item lg={6}>
                                <Typography style={Styles.font}>Admin Details</Typography>
                                <Grid container style={Styles.container}>
                                    <Grid item lg={3} style={Styles.gridItem}>
                                        <AccountCircle style={{ fontSize: '100px' }} />
                                    </Grid>
                                    <Grid item lg={9} style={{ margin: 'auto' }}>
                                        <Typography style={Styles.name}>{this.props.history.location.state.data.data.attributes.admin_name}</Typography>
                                        <Typography style={Styles.hospitalname}>Hospital Name: {this.props.history.location.state.data.data.attributes.hospital_name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={2} style={Styles.gridAuto}>
                                <Box style={Styles.AdminBox}>
                                    <Typography style={Styles.admintext}>{this.props.history.location.state.data.data.attributes.services[0]}</Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={4} style={Styles.gridAuto1}>
                                <Typography>{this.props.history.location.state.data.data.attributes.admin_contact_number ? this.props.history.location.state.data.data.attributes.admin_contact_number : '+91 - 9876543210'}</Typography>
                                <Typography>{this.props.history.location.state.data.data.attributes.admin_email}</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Box style={{ padding: '30px' }}>
                    <Container>
                        <Typography style={Styles.Detailtext}>Personal details</Typography>
                        <Typography style={{ fontSize: '20px', lineHeight: '40px', fontWeight:600 }}>Professional Information:</Typography>
                        <Grid container style={{ padding: '25px 0px' }}>
                            <Grid item lg={6}>
                                <Typography style={Styles.Detailtext1}>Name:</Typography>
                                <Typography style={Styles.Detailtext1}>Gender:</Typography>
                                <Typography style={Styles.Detailtext1}>Date of Birth:</Typography>
                                <Typography style={Styles.Detailtext1}>Contact Number:</Typography>
                                <Typography style={Styles.Detailtext1}>Email:</Typography>
                                <Typography style={Styles.Detailtext1}>Address:</Typography>
                            </Grid>
                            <Grid item lg={6}>
                                <Typography style={Styles.Detailtext1}>{this.props.history.location.state.data.data.attributes.admin_name}</Typography>
                                <Typography style={Styles.Detailtext1}>{this.props.history.location.state.data.data.attributes.gender}</Typography>
                                <Typography style={Styles.Detailtext1}>{moment(this.props.history.location.state.data.data.attributes.date_of_birth).format('LL')}</Typography>
                                <Typography style={Styles.Detailtext1}>{this.props.history.location.state.data.data.attributes.admin_contact_number ? this.props.history.location.state.data.data.attributes.admin_contact_number : '+91 - 9876543210'}</Typography>
                                <Typography style={Styles.Detailtext1}>{this.props.history.location.state.data.data.attributes.hospital_email}</Typography>
                                <Typography style={Styles.Detailtext1}>{this.props.history.location.state.data.data.attributes.address}</Typography>
                            </Grid>
                        </Grid>

                        <Typography style={{ fontSize: '20px', lineHeight: '40px', fontWeight:600 }}>Professional Information:</Typography>
                        <Grid container style={{ padding: '25px 0px' }}>
                            <Grid item lg={6}>
                                <Typography style={Styles.Detailtext1}>Specialty:</Typography>
                                <Typography style={Styles.Detailtext1}>Qualifications:</Typography>
                            </Grid>
                            <Grid item lg={6}>
                                <Typography style={Styles.Detailtext1}>{this.props.history.location.state.data.data.attributes.specialty}</Typography>
                                <Typography style={Styles.Detailtext1}>{this.props.history.location.state.data.data.attributes.qualification}</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        );
        // Customizable Area Start
    }
}

// Customizable Area Start
const Styles = {
    mainHeader: {
        backgroundColor: '#E4EDF5',
        width: '100%',
        minHeight: '100px',
        marginTop: '30px',
        padding: '20px'
    },
    font: {
        fontSize: "26px",
        fontWeight: 600
    },
    container: {
        marginTop: '5px'
    },
    gridItem: {
        textAlign: "center" as "center"
    },
    name: {
        fontSize: '22px',
        textTransform: 'capitalize' as 'capitalize',
        fontWeight: 600,
        color: '#498ecc'
    },
    hospitalname: {
        fontSize: '20px',
        textTransform: 'capitalize' as 'capitalize',
    },
    AdminBox: {
        backgroundColor: '#d8f0de',
        width: '100px',
        padding: '15px',
        textAlign: 'center' as 'center',
    },
    admintext: {
        color: '#358f4d'
    },
    gridAuto: {
        margin: 'auto'
    },
    gridAuto1: {
        margin: 'auto',
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'end' as 'end',
    },
    Detailtext: {
        color: '#498ecc',
        fontSize: '20px',
        textDecoration: 'underline',
        marginBottom: '20px',
    },
    Detailtext1: {
        fontSize: '20px',
        lineHeight: '40px'
    }
}
// Customizable Area End
import { BlockComponent } from "framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  languagesVisible: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfilePageOutLet extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      languagesVisible: false,
      // Customizable Area End
    };
  }

  // Customizable Area Start
  toggleLanguagesList = () => {
    this.setState({ languagesVisible: !this.state.languagesVisible });
  };
  // Customizable Area End
}


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
import React from "react";
import {notification } from "antd";
// Customizable Area End

export const configJSON = require("./config");


// Customizable Area Start
export type Props = RouterProps &
    withLoaderProps & {
      
        id: string;
        history: any;
        location: any;
     
    }
 // Customizable Area End


export interface S {
  // Customizable Area Start
   doctorList:any,
   selectedDoctorData:any,
   patientDetails:any,
   patientCity:string,
   patientFullName:any,
   searchTerm:string,
   filteredDoctorList:any,
   appointmentNavigationName:any,
   appointmentIds:any,
   doctorDetial:any,
   doctorID:any,
   showPopup:boolean,
   isLoginUser:boolean
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PatientAppointmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start 
  getAppointmentsListApiCallId: any;
  getDoctorsListApiCalledId:any;
  getPatientDetailsApiCalledId:any;
  getDoctorByIdApiCalledId:any;
  
  myRef:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      doctorList:[],
      selectedDoctorData:"",
      patientCity:"",
      patientDetails:[],
      patientFullName:"",
      searchTerm:"",
      filteredDoctorList:[],
      appointmentNavigationName:"",
      appointmentIds:"",
      doctorDetial:null,
      doctorID:"",
      showPopup:false,
      isLoginUser:false

      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
  );

  const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
  );

  
   if(apiRequestCallId !=null){

    if(apiRequestCallId === this.getDoctorByIdApiCalledId){
        this.setState({doctorDetial:responseJson?.data});
    }

    if(apiRequestCallId === this.getPatientDetailsApiCalledId ){
      this.setState({patientDetails:responseJson.data})
      this.setState({patientCity:responseJson.data?.attributes?.city})
      this.setState({patientFullName:responseJson.data?.attributes?.full_name})
      localStorage.setItem("patientFullName",JSON.stringify(responseJson.data?.attributes?.full_name))
      this.props.hideLoader();
    }
    
    if(apiRequestCallId === this.getDoctorsListApiCalledId){
    switch(true){

       case responseJson != null :
        this.setState({doctorList:responseJson.data})
        this.props.hideLoader();
        break;
    }
    this.showMessageWhenNoAnyDoctor(responseJson);

    }

   }


    // Customizable Area End
  };

  // Customizable Area Start

  showMessageWhenNoAnyDoctor=(response:any)=>{
    if( response.errors && response.errors[0].message === "Not found any Doctor"){
      notification["success"]({
        message: response.errors[0].message
      }); 
    }
  }

  getDoctorList() {
    let doctor_category = localStorage.getItem("doctor_category");
    let cityname = JSON.parse( localStorage.getItem("cityname") as any)
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDoctorsListApiCalledId = requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/search_doctor_categorywise?doctor_category=${doctor_category}&city=${cityname}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
    this.props.showLoader();
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  getPatientDetails() {
   
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token":localStorage.getItem("token")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPatientDetailsApiCalledId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPatientDetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
 this.props.showLoader();
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  getDoctorById(doctorID:string) {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDoctorByIdApiCalledId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDoctorByIdApiEndPoint}?id=${doctorID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getAppointmentNavigationCallBack=(data:any)=>{
    this.setState({appointmentNavigationName:data})
  }

  async componentDidMount() {
   let doctorID = await localStorage.getItem("doctor_id") ?? ""
   this.setState({doctorID});
    this.getPatientDetails();

    if(this.state.doctorID){
      this.getDoctorById(doctorID);
    }else{
      setTimeout(()=>{
        this.getDoctorList();
      },1000)

    }
    
    
   let doctorCategory:any =  localStorage.getItem("doctor_category");
   this.setState({appointmentNavigationName:doctorCategory})
    this.getAppointmentNavigationCallBack(
      this.props.location.state.state.navigateList,
    );
   
    this.scrollToTop();

}


scrollToTop=()=>{
  this.myRef.current.scroll(0,0);
  window.scroll(0,0);
  
}


handleSearch=(event:any)=>{
  let searchTerm = event.target.value;

  let filteredDoctorList = this.state.doctorList.filter((item:any) =>{
    return (
      item.attributes?.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||   item.attributes?.specialization.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }
 );

  this.setState({
    searchTerm,
    filteredDoctorList
  })

}
 
handleBookAppointment=(item:any)=>{
 const  {appointmentNavigationName}  = this.state;

let isUserLogin = localStorage.getItem("isLogin");
let isPatientGoogleLogin = localStorage.getItem("isPatientGoogleLogin");

 if(isUserLogin || isPatientGoogleLogin){

  localStorage.setItem("doctor_id",item.attributes?.doctor_id)
  this.props.history.push({pathname:'/doctorProfile',
  state:{selectedDoctorData:item,
    navigateList: [
      { name: appointmentNavigationName, route: "/patient-appointment" },
      { name:"DoctorProfile", route: "/doctorProfile" },
    ],
  }});
 }else{
  this.props.history.push({pathname:'/login'});
 }
}

handleNavigationToPatientHome=()=>{
  this.props.history.push("/patienthome")
}

handleNavigationToAppointmentPage=()=>{
  this.props.history.push("/patient-appointment-landingpage")
}

showDetailsHandler = () => {
  this.setState({ showPopup: !this.state.showPopup });
} 


handleLogout = () => {
  localStorage.clear();
  notification["success"]({
      message: "Logout successfully",
    });
}
 
handleDoctorConsultationFee=(consultationFee:any)=>{
  switch(true){
    case consultationFee.attributes.inclinic_consultation && consultationFee.attributes.inclinic_consultation.length>0:
      return consultationFee.attributes.inclinic_consultation[0].price
    case consultationFee.attributes.online_consultation && consultationFee.attributes.online_consultation.length>0:
      return consultationFee.attributes.online_consultation[0].price
    default:
      return ""
  }
}

handleDoctorKnowLanguage=(KnownLanguageArray:[])=>{
  const knownLanguage = KnownLanguageArray.filter((language)=>{
    return  language != ""
  } );
  return knownLanguage;
}
navigationToCheckoutPage=()=>{
  this.props.history.push("/patient-appointment-checkout")
}

showHidePopupModal = () => {
  this.setState({ showPopup: !this.state.showPopup });
} 

  // Customizable Area Start
  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
let config = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

// Customizable Area Start

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface InvalidResponseType {
  errors: "";
}

export interface ValidResponseType {
  message: object;
  data: object;
}

export interface DoctorDetailsType {
  data: DoctorDetailsData;
}

export interface DoctorDetailsData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    medical_representative_name: string;
    representative_contact_no: string;
    profile_image: string;
    degree_deatils: Array<string>;
    clinic_details: Array<string>;
    identity_details: Array<string>;
    registration_details: Array<string>;
    is_kyc: string;
    full_phone_number: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  doctorProfileData: {};
  identityDetails: FileList | any;
  degreeDetails: FileList | any;
  registrationDetails: FileList | any;
  showPopup: boolean;
  clinicRegistrationDetails: FileList | any;
  identityImage: Array<string>;
  degreeImage: Array<string>;
  cliniRegistrationImage: Array<string>;
  registrationImage: Array<string>;
  isLoader: boolean;
  error: boolean;
  contentHeight: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UploadDocumentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDetailsCallId: string = "";
  postDoctorVerifyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showPopup: false,
      doctorProfileData: {},
      identityDetails: "",
      degreeDetails: "",
      registrationDetails: "",
      clinicRegistrationDetails: "",
      identityImage: [],
      degreeImage: [],
      cliniRegistrationImage: [],
      registrationImage: [],
      isLoader: false,
      error: false,
      contentHeight: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handelShowDoctoPopUpDate = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorLogoutClick = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  handleDocDetailsPageCall = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleEditDocProfileCall = () => {
    this.props.history.push("/editdoctordetails")
  };

  handlePopupCloseCall = () => {
    this.setState({
      showPopup: false
    });
  };

  async componentDidMount() {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
    this.getProfileDetailsfunction();
  }

  apiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let token = localStorage.getItem("token");
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    let header = {
      "Content-Type": contentType,
      token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.validResponse(responseJson)) {
        this.apiInvoiceSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
  }

  validResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data);
  };

  apiInvoiceSuccessCallBacks = (apiRequestCallId: string, responseJson: DoctorDetailsType & object) => {
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      this.getProfileDetailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorVerifyApiCallId) {
      this.postDoctorVerifySuccessCallBack(responseJson)
    }
  };

  getProfileDetailsfunction = async () => {
    this.getProfileDetailsCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint
    });
  };

  getProfileDetailsSuccessCallBack = (response: DoctorDetailsType) => {
    localStorage.setItem("is_kyc", response.data?.attributes?.is_kyc);
    localStorage.setItem("doctorName", response.data.attributes.full_name);
  localStorage.setItem("doctorNum", response.data.attributes.full_phone_number);
    this.setState({
      doctorProfileData: response,
      identityImage: response.data.attributes.identity_details,
      degreeImage: response.data.attributes.degree_deatils,
      cliniRegistrationImage: response.data.attributes.clinic_details,
      registrationImage: response.data.attributes.registration_details
    });
  };

  onHendelIdentityDetails = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      const firstFile = selectedFiles[0];
      const fileExtension = firstFile.name.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        const identitySize = firstFile.size / (1024 * 1024)
        if (identitySize <= 10) {
          this.setState({
            identityDetails: selectedFiles
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  };

  onHendelDegreeDetails = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const degreeFiles = event.target.files;
    if (degreeFiles.length > 0) {
      const firstFile = degreeFiles[0];
      const fileExtension = firstFile.name.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        const degreeSize = firstFile.size / (1024 * 1024)
        if (degreeSize <= 10) {
          this.setState({
            degreeDetails: degreeFiles
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  };

  onHendelClinicRegistrationDetails = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const clinicRegistrationFile = event.target.files;
    if (clinicRegistrationFile.length > 0) {
      const firstFile = clinicRegistrationFile[0];
      const fileExtension = firstFile.name.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        const fileSize = firstFile.size / (1024 * 1024)
        if (fileSize <= 10) {
          this.setState({
            clinicRegistrationDetails: clinicRegistrationFile
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  };

  onHendelRegistrationDetails = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const registrationFile = event.target.files;
    if (registrationFile.length > 0) {
      const firstFile = registrationFile[0];
      const fileExtension = firstFile.name.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        const fileSizeInMB = firstFile.size / (1024 * 1024)
        if (fileSizeInMB <= 10) {
          this.setState({
            registrationDetails: registrationFile
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  };

  postDoctorVerify = async () => {
    if (this.state.identityDetails.length == 0) {
      return notification["error"]({
        message: "Please upload identity details document.",
      });
    }
    if (this.state.degreeDetails.length == 0) {
      return notification["error"]({
        message: "Please upload degree details document",
      });
    }
    if (this.state.clinicRegistrationDetails.length == 0) {
      return notification["error"]({
        message: "Please upload clinic details document.",
      });
    }
    if (this.state.registrationDetails.length == 0) {
      return notification["error"]({
        message: "Please upload registration details document.",
      });
    }
    let formdata = new FormData();
    if (this.state.identityDetails) {
      let fileListIdentifyImg = [...this.state.identityDetails]
      fileListIdentifyImg.forEach((item: File) => {
        formdata.append("identity_details[]", item, item?.name)
      });
    }
    if (this.state.degreeDetails) {
      let fileListDegreeImgg = [...this.state.degreeDetails]
      fileListDegreeImgg.forEach((item: File) => {
        formdata.append("degree_deatils[]", item, item?.name)
      });
    }
    if (this.state.registrationDetails) {
      let fileListRegistrationImg = [...this.state.registrationDetails]
      fileListRegistrationImg.forEach((item: File) => {
        formdata.append("registration_details[]", item, item?.name)
      });
    }
    if (this.state.clinicRegistrationDetails) {
      let fileListclinicRegImg = [...this.state.clinicRegistrationDetails]
      fileListclinicRegImg.forEach((item: File) => {
        formdata.append("clinic_details[]", item, item?.name)
      });
    }

    this.postDoctorVerifyApiCallId = this.apiCall({
      method: configJSON.postDoctorRegisterApiMethod,
      endPoint: `${configJSON.postDoctorVerifyAPiEndPoint}?is_request=web`,
      body: formdata,
      type: "formData"
    });
    this.setState({
      isLoader: true
    });
  };

  postDoctorVerifySuccessCallBack = (response: object) => {
    this.setState({
      isLoader: false
    });
    notification["success"]({
      message: "Documents upload successfully wait for verification",
    });
    localStorage.setItem("is_Upload", "uploaded")
    this.getProfileDetailsfunction();
    this.props.history.push("/doctorprofiledetails")
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleDownloadClinic = (image: Array<string>) => {
    let path = config + image[0];
    fetch(path)
      .then(response => response.arrayBuffer())
      .then(data => {
        let pdfBlob = new Blob([data], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = 'clinicregistration.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      })
      .catch(error => console.error('Error fetching the PDF file:', error));
  };

  handleDownloadIdentity = (image: Array<string>) => {
    let urlPath = config + image[0];
    fetch(urlPath)
      .then(response => response.arrayBuffer())
      .then(data => {
        const identityLink = document.createElement('a');
        const pdfBlob = new Blob([data], { type: 'application/pdf' });
        identityLink.href = window.URL.createObjectURL(pdfBlob);
        identityLink.download = 'identitydetails.pdf';
        document.body.appendChild(identityLink);
        identityLink.click();
        document.body.removeChild(identityLink);
        window.URL.revokeObjectURL(identityLink.href);
      })
      .catch(error => console.error('Error fetching the PDF file:', error));
  };

  handleDownloadDegree = (image: Array<string>) => {
    const downloadPath = config + image[0];
    fetch(downloadPath)
      .then(response => response.arrayBuffer())
      .then(data => {
        const urlLink = document.createElement('a');
        const blob = new Blob([data], { type: 'application/pdf' });
        urlLink.href = window.URL.createObjectURL(blob);
        urlLink.download = 'degree.pdf';
        document.body.appendChild(urlLink);
        urlLink.click();
        document.body.removeChild(urlLink);
        window.URL.revokeObjectURL(urlLink.href);
      })
      .catch(error => console.error('Error fetching the PDF file:', error));
  };

  handleDownloadRegistration = (image: Array<string>) => {
    let registrationPath = config + image[0];
    fetch(registrationPath)
      .then(response => response.arrayBuffer())
      .then(data => {
        const blobPDF = new Blob([data], { type: 'application/pdf' });
        let registrationLink = document.createElement('a');
        registrationLink.href = window.URL.createObjectURL(blobPDF);
        registrationLink.download = 'registration.pdf';
        document.body.appendChild(registrationLink);
        registrationLink.click();
        document.body.removeChild(registrationLink);
        window.URL.revokeObjectURL(registrationLink.href);
      })
      .catch(error => console.error('Error fetching the PDF file:', error));
  };

  handleVerifyKyc = () => {
    notification["error"]({
      message: "Please wait for the completion of the KYC process",
    });
  };

  handleLogoutClick = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  handelDoctoPopUpDate = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import {  Divider, Row } from "antd";
// Customizable Area End

type MinimalCommonComponentProps = {
    // Customizable Area Start
    handleMinimalDataList: any,
    miniimalDataList: any,
    topHeading:any,
    // Customizable Area End
};

const MinimallTherapy = ({
    // Customizable Area Start
    handleMinimalDataList,
    miniimalDataList,
    topHeading,
    // Customizable Area End
}: MinimalCommonComponentProps) => {
    // Customizable Area Start

    return (
        <>
          <div >
            <div  style={styles.headerContainer}>
              <h2 style={styles.headingTextCss}>{topHeading}</h2>
              <p className='ourServicesSubTitle' onClick={handleMinimalDataList} data-test-id="minimaltherapyId">
                            {
                               miniimalDataList.length <= 4 ? "See All" : "View less"
                            }
                 </p>
            </div>
            <Row>
              {miniimalDataList.map((item :any) => (
                <>
                  <p>
                    {item.therapy_name}
                  </p>
                  <Divider />
                </>
              ))

              }

            </Row>
           
          </div>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
const styles :any = {
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "70px",
        marginBottom: "15px"
    },
    headingTextCss :{
        color: '#353535',
        textAlign: 'center',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      sellAllStyle: {
        color: '#4d96dd',
        fontSize: '20px',
        fontWeight: 700,
        cursor: 'pointer',
        marginBottom: 0,
      }
  }
  // Customizable Area End
// Customizable Area Start
export default MinimallTherapy
// Customizable Area End


import React from "react";
// Customizable Area Start
import { Box, Input, Typography, styled } from "@material-ui/core";
import { IoIosSearch, IoMdDownload } from "react-icons/io";
import { IoMicOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { BsCameraVideo } from "react-icons/bs";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { LuSend } from "react-icons/lu";
import PatientChatController, { ChatMessage, Props , configJSON} from "./PatientChatController.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
let config = require("../../../framework/src/config.js").baseURL;
import { FaFileLines } from "react-icons/fa6";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class PatientChat extends PatientChatController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPatientChat = () => {
        return (
            <Wrapper>
            <LeftBox>
                <SearchBoxOuter>
                    <SearchBox>
                        <IoIosSearch className="iconSearch"/>
                        <SearchInput placeholder="Search for chats" value={this.state.searchDoctorQry} onChange={this.searchDoctor} data-test-id="searchId"/>
                        <IoMicOutline className="iconSearch"/>
                    </SearchBox>
                </SearchBoxOuter>

                <ChatListBox> 
                    {this.state.searchDoctorQry.length <= 0 ?
                    this.state.doctorLists?.data?.map((chatuser) => (
                        <ChatListUserBox onClick={() => this.doctorVerificationAndToChat(chatuser)} data-test-id="doctorListid">
                            <ChatListUserImg src={config + chatuser.attributes.profile_image}/>
                            <ChatListUserDetailBox>
                                <ChatListUserNameBox>
                                    <Typography className="username" data-test-id="nameId">
                                        {chatuser.attributes.full_name}
                                    </Typography>
                                </ChatListUserNameBox>
                                <ChatListUserDescBox>
                                    <Typography className="textSec">
                                       Inbox message seen here
                                    </Typography>
                                    <Typography className="textSec">
                                        Mon 12:00 PM
                                    </Typography>
                                </ChatListUserDescBox>
                            </ChatListUserDetailBox>
                        </ChatListUserBox>
                    ))
                    :
                    this.renderFilterDataContainer()
                    }
                </ChatListBox>
            </LeftBox>
            {
                this.state.patientChatBox &&
            <RightBox>
                <ChatUsersBox>
                    <ChatUserDetailBox>
                        <ChatListUserImg
                            src={this.state.doctorProfilePhoto ? config + this.state.doctorProfilePhoto : "https://i.imgur.com/IUw5yWb.png"}
                        />
                        <ChatUserDetailNameBox>
                            <Typography className="username" data-test-id="textId">Dr. {this.state.userDoctorDetails.full_name}</Typography>
                            <Typography className="textSec">
                                <span className="status"></span>Online
                            </Typography>
                        </ChatUserDetailNameBox>
                    </ChatUserDetailBox>
                    <ChatUserIconsBox>
                        <FiPhoneCall className="icons" />
                        <BsCameraVideo className="icons" />
                    </ChatUserIconsBox>
                </ChatUsersBox>
                <ChatsBox>
                    {this.state.messages.map((chat) => (
                        <Box>
                            {
                                chat.sender === this.state.patientChatDetails.username ?
                                    this.renderMessageBox(chat)
                                    :
                                    this.renderFileMessageBox(chat)
                            }
                        </Box>
                    ))}
                </ChatsBox>
                <SendMessageBox>
                    <SendMessageInputBox>
                        <MdOutlineEmojiEmotions className="iconInput" />
                        <SendMessageInput
                            placeholder="Write a message..."
                            disableUnderline
                            fullWidth
                            value={this.state.textMessage}
                            onChange={this.textMessageChange}
                            onKeyDown={this.onkeyDown}
                            data-test-id="messageId"
                        />
                        <input
                            data-test-id="fileUploadId"
                            multiple
                            style={{ display: "none" }}
                            onChange={this.handleUploadFile}
                            type="file"
                            name="image-upload"
                            id="input"
                        />
                        <label htmlFor='input' >
                            <GrAttachment className="iconInput" />
                        </label>
                    </SendMessageInputBox>
                    <Box className="sendIcon" onClick={()=>this.handleSend()} data-test-id="sendButtonId">
                    <LuSend />
                    </Box>
                </SendMessageBox>
            </RightBox>
            }
        </Wrapper>
        )
    };

    renderPatientHeader = () => {
        return (
            <NavigateBox>
              <NavigateTitle data-test-id="textmatch">{configJSON.home}</NavigateTitle>
              <NavigateTitle>{configJSON.appointments}</NavigateTitle>
              <NavigateTitle>{configJSON.service}</NavigateTitle>
              <NavigateTitleActive data-test-id="messagetextid">{configJSON.message}</NavigateTitleActive>
            </NavigateBox>
        )
    };

    renderFilterData = () => {
        return (
            <>
             {
                this.state.filterdDoctorList.map((doctor) => (
                    <ChatListUserBox onClick={() => this.doctorVerificationAndToChat(doctor)} data-test-id="doctorListid">
                        <ChatListUserImg src={config + doctor.attributes.profile_image} />
                        <ChatListUserDetailBox>
                            <ChatUserNameBox>
                                <Typography className="username" data-test-id="nameId">
                                    {doctor.attributes.full_name}
                                </Typography>
                            </ChatUserNameBox>
                            <ChatUserDescBox>
                                <Typography className="textSec">
                                    Hii rob
                                </Typography>
                                <Typography className="textSec">
                                    Tue 02:00 PM
                                </Typography>
                            </ChatUserDescBox>
                        </ChatListUserDetailBox>
                    </ChatListUserBox>
                ))
             }
            </>
        )
    };

    renderFilterDataContainer = () => {
        return (
            <>
            {
                this.state.filterdDoctorList.length == 0
                ?
                this.renderChatNoData()
                :
                this.renderFilterData()
            }
            </>
        )
    };

    renderMessageBox = (message: ChatMessage) => {
        return (
            <MessageContainer key={message._id}>
                <UserBox>
                    <ProfileImage src={this.state.patientChatDetails.avatar} />
                </UserBox>
                {
                    message.file ?
                        this.renderReceived(message)
                        :
                        <MessageDoctor>
                            <span style={{ fontSize: "14px", wordWrap: "break-word", overflowWrap: "break-word" }}>{message.text}</span>
                        </MessageDoctor>
                }
                <TimeForMessage>{this.timeMessageFormate(message.createdAt)}</TimeForMessage>
            </MessageContainer>
        )
    };

    renderFileMessageBox = (message: ChatMessage) => {
        return (
            <MessageBoxFiles key={message._id}>
                <UserProfileBox>
                    <ProfileImage src={this.state.userDoctorDetails.avatar} />
                </UserProfileBox>
                {
                    message.file ?
                        this.renderFileMessage(message)
                        :
                        <BoxTime>
                            <span style={{ fontSize: "14px", wordWrap: "break-word", overflowWrap: "break-word" }}>{message.text}</span>
                        </BoxTime>
                }
                <TimeForMessage style={{ fontSize: "10px" }}>{this.timeMessageFormate(message.createdAt)}</TimeForMessage>
            </MessageBoxFiles>
        )
    };

    renderReceived = (message: ChatMessage) => {
        return (
            <>
                {
                    message.type === "images" ?
                        <MeesageInFiles>
                            <img height={"100%"} width={"100%"} src={message.file} />
                            <IoMdDownload className="downLoadIcon"
                                data-test-id={"mediaDownloadButtonId"} onClick={() => this.mediaDownload(JSON.stringify(message.file))}/>
                        </MeesageInFiles> :
                        <ReceiveMediaBox>
                            <FaFileLines className="pdfIcon" />
                            <DownLoad className="downLoadIcon" data-test-id={"mediaDownloadButtonId"} onClick={() => this.mediaDownload(JSON.stringify(message.file))} />
                        </ReceiveMediaBox>
                }
            </>
        )
    };

    renderFileMessage = (message: ChatMessage) => {
        return (
            <>
                {
                    message.type === "images" ?
                        <DoctorFileMessage>
                            <img height={"100%"} width={"100%"} src={message.file} />
                            <IoMdDownload className="downLoadIcon"
                             onClick={() => this.mediaDownload(JSON.stringify(message.file))}
                            data-test-id={"mediaDownloadButtonId"} />
                        </DoctorFileMessage> :
                        <ReceiveDoctorMediaBox>
                            <FaFileLines className="pdfIcon" />
                            <DownLoad className="downLoadIcon" 
                            onClick={() => this.mediaDownload(JSON.stringify(message.file))} 
                            data-test-id={"mediaDownloadButtonId"} />
                        </ReceiveDoctorMediaBox>
                }
            </>
        )
    };

    renderChatNoData = () => {
        return (
          <NoDataBox>
            <p>No Doctor Found</p>
          </NoDataBox>
        )
    };
    // Customizable Area End

    render() {
    // Customizable Area Start
        return (
            <>
               <HeaderBox>
               <NavbarCommonComponent handleProfile={this.showDetailsHandler} data-test-id="navbarDataId"/>
                {this.renderPatientHeader()}
               </HeaderBox>
                <PatientProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} data-test-id="profilerId"/>
                <Container>
                  {this.state.isData ? this.renderPatientChat() : this.renderChatNoData() }
                </Container>
                <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const Container = styled(Box)({
  width: "90%",
  margin: "0 auto"
});

const NoDataBox = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "300px",
    boxShadow: "1px 1px 5px #aaaaaa",
    marginBottom: "20px",
    marginTop:"20px"
});

const DownLoad = styled(IoMdDownload)({
    fontSize: "24px",
    cursor: "pointer"
});

const HeaderBox = styled(Box)({
    boxShadow: "0px 8px 8px -6px rgba(211, 211, 211, 0.5)"
});

const NavigateTitle = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const Wrapper = styled(Box)({
    display: "flex",
    background: "#f4f4f4",
    border: "1px solid #f6efef",
    marginBottom: "15px",
    marginTop: "20px"
});

const NavigateBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    width: "80%",
    margin: "0 auto"
});

const NavigateTitleActive = styled("p")({
    fontSize: "16px",
    color: "rgb(90,140,196)",
    fontWeight: 600
});

const LeftBox = styled(Box)({
    minWidth: "350px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: "1px solid #e5e3e3",
    "@media(max-width:750px)": {
        alignItems: "flex-start",
        minWidth: "50px"
    }
});

const SearchBoxOuter = styled(Box)({
    width: "100%",
    padding: "12px 20px 12px 20px",
    borderBottom: "1px solid #e5e3e3",
    "@media(max-width:750px)": {
        width: "50px",
        padding: "20px 5px"
    }
});

const SearchBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    background: "#fff",
    padding: "10px",
    gap: "10px",
    borderRadius: "5px",
    "& .iconSearch":{
        fontSize:"30px",
        color: "grey"
    },
    "@media(max-width:750px)": {
        width: "100%",
        padding: "10px 0px"
    }
});

const SearchInput = styled("input")({
    border: "none",
    outline: "none",
    width: "75%",
    "@media(max-width:750px)": {
        width: "30px"
    }
});

const ChatListBox = styled(Box)({
    width: "100%",
    "@media(max-width:750px)": {
        width: "50px"
    }
});

const ChatListUserBox = styled(Box)({
    display: "flex",
    cursor: "pointer",
    borderBottom: "1px solid #e5e3e3",
    gap: "10px",
    padding: "10px",
    "@media(max-width:750px)": {
        padding: "5px",
    },
    "& .username": {
        fontSize: "17px",
        fontWeight: "500",
    },
    "& .textSec": {
        fontSize: "14px",
        fontWeight: "400",
    },
    "& .msg-count": {
        background: "#2596be",
        color: "white",
        padding: "5px",
        height: "10px",
        width: "10px",
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    }
});

const ChatListUserImg = styled("img")({
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    "@media(max-width:750px)": {
        width: "50px"
    }
});

const ChatListUserDetailBox = styled(Box)({
    width: "100%",
    "@media(max-width:750px)": {
        display: "none"
    }
});

const ChatListUserNameBox = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
});

const ChatListUserDescBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between"
});

const ChatUserDescBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between"
});

const RightBox = styled(Box)({
    width: "100%"
});

const ChatUsersBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    padding: "18px",
    background: "#f4f4f4",
    borderBottom: "1px solid #e5e3e3"
});

const ChatUserDetailBox = styled(Box)({
    display: "flex",
    gap: "10px",
    "& .username": {
        fontSize: "17px",
        fontWeight: "500",
    },
    "& .textSec": {
        fontSize: "14px",
        fontWeight: "400",
    },
    "& .status": {
        background: "#65ec5b",
        height: "10px",
        width: "10px",
        borderRadius: "50%",
        display: "inline-block",
        marginRight: "10px"
    }
});

const ChatUserNameBox = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
});

const ChatUserDetailNameBox = styled(Box)({});

const ChatUserIconsBox = styled(Box)({
    display: "flex",
    gap: "20px",
    "& .icons": {
        fontSize: "30px"
    }
});

const ChatsBox = styled(Box)({
    background: "#fff",
    height: "65vh",
    "@media(max-width:750px)": {
        height: "100vh",
    },
    overflowX: "scroll",
    padding: "10px",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    display: "flex",
    flexDirection: "column-reverse",
    gap: "10px",
    "& .img": {
        height: "50px",
        borderRadius: "50%"
    }
});

const ReceivedMessageBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .receivedMessage": {
        background: "#f3f4f6",
        fontSize: "15px",
        padding: "20px",
        borderRadius: "0 25px 25px 25px",
        marginLeft: "40px",
        maxWidth: "70%",
    },
    "& .time": {
        margin: "10px 0 0 50px",
        fontWeight: 400,
        fontSize: "10px"
    }
});

const SentMessageBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& .sentMessage": {
        background: "#f3f8fe",
        fontSize: "15px",
        padding: "20px",
        marginRight: "40px",
        borderRadius: "25px 0 25px 25px",
        maxWidth: "70%",
    },
    "& .time": {
        margin: "10px 50px 0 0",
        fontWeight: 400,
        fontSize: "10px"
    }
});

const SendMessageBox = styled(Box)({
    display: "flex",
    gap: "20px",
    alignItems: "center",
    cursor: "pointer",
    padding: "20px",
    background: "#fff",
    "& .sendIcon": {
        fontSize: "30px",
        background: "#5989bd",
        padding: "15px",
        color: "white",
        borderRadius: "50%"
    }
});

const SendMessageInputBox = styled(Box)({
    flex: 1,
    background: "#eee",
    padding: "10px",
    width: "100px",
    display: "flex",
    fontSize: "24px",
    borderRadius: "5px",
    alignItems: "center",
    "& .iconInput": {
        fontSize: "24px",
        marginInline: "10px",
        color: "grey"
    }
});

const SendMessageInput = styled(Input)({
    border: "none",
    outline: "none",
    background: "#eee",
    fontSize: "16px",
});

const TimeForMessage = styled("span")({
    fontSize: "10px"
});

const MessageBoxFiles = styled("div")({
    flexDirection: "column",
    rowGap: "5px",
    paddingLeft: "50px",
    position: "relative",
    margin: "10px 5px",
    display: "flex",
    alignItems: "flex-start",
});

const BoxTime = styled("div")({
    color: "#000",
    backgroundColor: "#f4f9ff",
    padding: "10px",
    borderRadius: "5px",
    maxWidth: "400px"
});

const MeesageInFiles = styled("div")({
    "& .downLoadIcon": {
        fontSize: "24px",
        cursor: "pointer",
        position: "absolute",
        bottom: 0,
        right: -25
    },
    backgroundColor: "#f4f9ff",
    width: "180px",
    height: "180px",
    padding: "5px",
    borderRadius: "5px",
    position: "relative"
});

const MessageContainer = styled("div")({
    rowGap: "5px",
    paddingRight: "50px",
    margin: "10px 5px",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    position: "relative"
});

const UserBox = styled(Box)({
    position: "absolute",
    top: -10,
    right: 10,
    height: "30px",
    width: "30px",
    backgroundColor: "transparent",
    borderRadius: "50%"
});

const UserProfileBox = styled(Box)({
    borderRadius: "50%",
    position: "absolute",
    top: -10,
    height: "30px",
    width: "30px",
    backgroundColor: "transparent",
    left: 0
});

const ProfileImage = styled("img")({
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "50%"
});

const ReceiveMediaBox = styled(Box)({
    display: "flex",
    margin: "10px 0 0 50px",
    alignItems: "baseline",
    gap: "5px",
    "& .pdfIcon": {
        fontSize: "50px",
        color: "#247EBF"
    },
    "& .downLoadIcon": {
        fontSize: "24px",
        cursor: "pointer"
    }
});

const DoctorFileMessage = styled("div") ({
    backgroundColor: "#f4f9ff",
    padding: "5px",
    borderRadius: "5px",
    width: "180px",
    height: "180px",
    position:"relative",
    "& .downLoadIcon" : {
      fontSize: "24px",
      cursor: "pointer",
      position:"absolute",
      bottom:0,
      right:-25
    }
});

const MessageDoctor = styled("div")({
    backgroundColor: "#f4f9ff",
    padding: "10px",
    borderRadius: "5px",
    color: "#000",
    maxWidth: "400px"
});

const ReceiveDoctorMediaBox = styled(Box)({
    display: "flex",
    marginTop: "10px",
    alignItems: "baseline",
    gap: "5px",
    "& .pdfIcon": {
        fontSize: "50px",
        color: "#247EBF"
    },
    "& .downLoadIcon": {
        fontSize: "24px",
        cursor: "pointer"
    }
});
// Customizable Area End
import React from "react";
// Customizable Area Start
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import UploadDocumentController, { Props } from "./UploadDocumentController.web";
import {
    Box,
    styled,
    Typography
} from "@material-ui/core";
import { pdfIcon } from "../../appointmentmanagement/src/assets";

import { MdArrowBack } from "react-icons/md";
// Customizable Area End

export default class ViewDocuments extends UploadDocumentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderViewPageHeader = () => {
        const is_kyc = localStorage.getItem("is_kyc");
        return (
            <>
            {is_kyc === "approved" ?
            <EditPageHeader>
                <Link to={is_kyc === "approved" ? "/doctorhome" : "/viewdocument"}><PageLinkHeader>Home</PageLinkHeader></Link>
                <Link to={is_kyc === "approved" ? "/doctorschedule" : "/viewdocument"}><PageLinkHeader>Schedule</PageLinkHeader></Link>
                <Link to={is_kyc === "approved" ? "/addpatient" : "/viewdocument"}><PageLinkHeader>Add Patient</PageLinkHeader></Link>
                <PageLinkHeader>Chats</PageLinkHeader>
            </EditPageHeader>
            :
            <EditPageHeader onClick={this.handleVerifyKyc} data-test-id="clickId">
            <PageLinkHeader>Home</PageLinkHeader>
            <PageLinkHeader>Schedule</PageLinkHeader>
            <PageLinkHeader>Add Patient</PageLinkHeader>
            <PageLinkHeader>Chats</PageLinkHeader>
          </EditPageHeader>
        }
        </>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUpDate} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogoutClick} showProfile={this.handleDocDetailsPageCall} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfileCall} handleClosePopup={this.handlePopupCloseCall} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderViewPageHeader()}
                    <PageHeader data-test-id="documentstext">
                    <BackArrow onClick={this.handleBack} data-test-id="previousViewButton"/>
                         Documents
                    </PageHeader>
                    <DownloadBox>
                        <FileInformationBox>
                            <FileIcon src={pdfIcon} />
                            <FileDetailsBox>
                                <FileTypeText>Identity Details</FileTypeText>
                            </FileDetailsBox>
                        </FileInformationBox>
                        <DownloadButton onClick={() => this.handleDownloadIdentity(this.state.identityImage)} data-test-id="identityId">Download</DownloadButton>
                    </DownloadBox>
                    <DownloadBox>
                        <FileInformationBox>
                            <FileIcon src={pdfIcon} />
                            <FileDetailsBox>
                                <FileTypeText>Degree Details</FileTypeText>
                            </FileDetailsBox>
                        </FileInformationBox>
                        <DownloadButton onClick={() => this.handleDownloadDegree(this.state.degreeImage)} data-test-id="degreeId">Download</DownloadButton>
                    </DownloadBox>
                    <DownloadBox>
                        <FileInformationBox>
                            <FileIcon src={pdfIcon} />
                            <FileDetailsBox>
                                <FileTypeText>Registration Details</FileTypeText>
                            </FileDetailsBox>
                        </FileInformationBox>
                        <DownloadButton onClick={() => this.handleDownloadRegistration(this.state.registrationImage)} data-test-id="registrationId">Download</DownloadButton>
                    </DownloadBox>
                    <DownloadBox>
                        <FileInformationBox>
                            <FileIcon src={pdfIcon} />
                            <FileDetailsBox>
                                <FileTypeText>Clinic Registration Details</FileTypeText>
                            </FileDetailsBox>
                        </FileInformationBox>
                        <DownloadButton data-test-id="clinicDownloadId" onClick={() => this.handleDownloadClinic(this.state.cliniRegistrationImage)}>Download</DownloadButton>
                    </DownloadBox>
                </ContainerBox>
                <FooterDoctor />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageLinkHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const PageHeader = styled(Box)({
    fontSize: "20px",
    margin: "10px 0",
    fontWeight: 400
});

const DownloadBox = styled(Box)({
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 0px 5px 0px rgba(128,128,128,0.5)",
    "- webkit - box - shadow": "0px 0px 5px 0px rgba(128,128,128,0.5)",
    "- moz - box - shadow": "0px 0px 5px 0px rgba(128,128,128,0.5)",
    borderRadius: "2px",
    margin: "10px 0"
});

const DownloadButton = styled("button")({
    color: "#4d96dd",
    fontSize: "14px",
    backgroundColor: "transparent",
    border: "none"
});

const FileInformationBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    columnGap: "10px"
});

const FileIcon = styled("img")({
    height: "25px",
    width: "25px"
});

const FileDetailsBox = styled(Box)({
    display: "flex",
    flexDirection: "column"
});

const DocIdText = styled(Typography)({
    fontSize: "10px",
    color: "#808080"
});

const FileTypeText = styled(Typography)({
    fontSize: "14px",
    fontWeight: 400
});

const BackArrow = styled(MdArrowBack)({
    fontSize: "24px"
});
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";
exports.orText = "or";
exports.signUpBodyText = "Value proposition: why users should sign up.";
exports.signUpTitleText = "Sign up";

exports.postSignUpAPiEndPoint = "account_block/create_otp";
exports.postSignUpAPiMethod = "POST";
exports.postSignUpApiContentType = "application/json";

exports.postDoctorSignUpAPiEndPoint = "account_block/create_otp_doctor";
exports.patientgoogleLoginEndPoint="account_block/social_login_patient"
exports.doctorgoogleLoginEndPoint="account_block/social_login_doctor"

// Customizable Area End
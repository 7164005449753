Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PhotoLibrary";
exports.labelBodyText = "PhotoLibrary Body";

exports.errorMessage = "Something went wrong!";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.getOurservicesAPiEndPoint = "account_block/list_all_services";
exports.getOurservicesAPiMethod = "GET";
exports.getOurservicesApiContentType = "application/json";

exports.getTherapyDetailsAPiEndPoint = "bx_block_categories/show_trending_therapies";
exports.getTherapyDetailsAPiMethod = "GET";
exports.getTherapyDetailsApiContentType = "application/json";

exports.getHospitalsAPiEndPoint = "bx_block_hospital/index";
exports.getDoctorListApiEndPoint = "account_block/doctors_from_categories?hospital="

exports.getMedicineAPiEndPoint = "bx_block_order_management/medicine_search"

exports.getMedicineDetailsAPiEndPoint = "bx_block_order_management/detail_medicine"

exports.getTherapyDetailsAPiEndPoint = "bx_block_categories/show_trending_therapies";
exports.getTherapyDetailsApiContentType = "application/json";

exports.getAddressAPiEndPoint = "bx_block_order_management/create_address";
exports.getAddressAPiMethod = "POST";
exports.getAddressApiContentType = "application/json";

exports.getSymptomps = "bx_block_categories/get_symptoms_list";
exports.getTopDoctorsAPiMethod = "GET";
exports.getTopDoctorsApiContentType = "application/json";

exports.getShopByCategoryAPiEndPoint = "bx_block_categories/shop_by_category";
exports.getBrandsAPiEndPoint = "bx_block_catalogue/brands";

exports.getCartDetailsAPiEndPoint = "bx_block_shopping_cart/detail";
exports.getCartDetailsAPiMethod = "GET";
exports.getCartDetailsApiContentType = "application/json"; 

exports.getAddressAPiEndPoint = "bx_block_order_management/detail_address"
exports.getAddressAPiMethod = "GET";
exports.getAddressApiContentType = "application/json"; 

exports.deleteAddressAPiEndPoint = "bx_block_order_management/delete_address"
exports.deleteAddressAPiMethod = "DELETE";
exports.deleteAddressApiContentType = "application/json";

exports.getApplyCuponAPiEndPoint = "bx_block_promo_codes/get_promo_code"
exports.getApplyCuponAPiMethod = "GET";
exports.getApplyCuponApiContentType = "application/json"; 

exports.photoLibraryApiEndpoint = "photo_library/photo_library";
// Customizable Area End

exports.textDelete = "Delete";
exports.textDeleteSelected = "Delete Selected";
exports.textDeleteGallery = "Delete Gallery";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textSelectImage = "Select Image";
exports.textAddNewImage = "Add New Image";
exports.textShowImages = "Show Images";
exports.noImageErrorMsg = "Please select an image";
exports.textShare = "Share";
exports.textAccountId = "Account ID";
exports.errorMsgAccountId = "Account ID cannot be empty";

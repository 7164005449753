import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    Modal,
    Select,
    MenuItem,
    Chip
} from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import FeespackagemanagementController, { Props } from "./FeespackagemanagementController.web";
import { FiPlus } from "react-icons/fi";
import { MdEdit, MdArrowBack } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaStethoscope } from "react-icons/fa";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class Feespackagemanagement extends FeespackagemanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeaderPage = () => {
        return (
            <EditPageHeader>
                <PageHeader onClick={()=>this.customNavigation("/doctorhome")} data-test-id="homenavigateId">Home</PageHeader>
                <PageHeader onClick={()=>this.customNavigation("/doctorschedule")} data-test-id="schedulenavigateId">Schedule</PageHeader>
                <PageHeader onClick={()=>this.customNavigation("/addpatient")} data-test-id="addnavigateId">Add Patient</PageHeader>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderHeader = () => {
        return (
            <>
                <HeaderTitle data-test-id="feesTextId">
                    <MdArrowBack onClick={this.handleGoBack} data-test-id="gobackTestid"/> Fee / Package Management
                </HeaderTitle>
            </>
        )
    };

    renderPackages = () => {
        return (
            <>
                <PackageOuterBox>
                    {
                        this.state.packagesData.data.map((packageItem, index: number) => {
                            return (
                                <PackageBox key={index}>
                                    <CustomMdEdit onClick={() => this.handleEditPackage(packageItem)} data-test-id="editButtonId"/>
                                    <CustomDelete onClick={() => this.handleDeletePackage(packageItem.id)} data-test-id="deletePackageId" />
                                    <PackageIconBox><FaStethoscope style={{ color: "#4d96dd" }} /></PackageIconBox>
                                    <PackagePriceText>₹{packageItem.attributes.consultation_fees}</PackagePriceText>
                                    <PackageNameText>{packageItem.attributes.name}</PackageNameText>
                                    <PackageDurationText>Duration : {packageItem.attributes.duration}</PackageDurationText>
                                    <PackageNoOfTestText>No. of tests : {packageItem.attributes.no_of_tests}</PackageNoOfTestText>
                                </PackageBox>
                            )
                        })
                    }
                </PackageOuterBox>
            </>
        )
    };

    renderNoDataFound = () => {
        return (
            <NoData>
                <p style={{marginBottom: "0px"}}>No data found</p>
            </NoData>
        )
    };

    renderSelectContainer = () => {
        return (
            <StyledSelect
                data-test-id="selectId"
                onChange={this.handleChange}
                fullWidth
                value={this.state.sample}
                labelId="set-time-select-placeholder"
                id="set-time-select-placeholder"
                displayEmpty
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    keepMounted: true
                }}
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select sample
                </MenuItem>
                {this.state.selectValue.map((item: string) => {
                    return (
                        <MenuItem value={item}>
                            {item}
                        </MenuItem>
                    )
                })
                }
            </StyledSelect>
        )
    };

    renderDurationContainer = () => {
        return (
            <StyledSelect
                displayEmpty
                data-test-id="durationId"
                onChange={this.durationHandelChange}
                fullWidth
                labelId="set-time-placeholder"
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    keepMounted: true,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
                value={this.state.duration}
                id="set-select-placeholder"
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select duration
                </MenuItem>
                {this.state.durationValue.map((item: string) => {
                    return (
                        <MenuItem value={item}>
                            {item}
                        </MenuItem>
                    )
                })
                }
            </StyledSelect>
        )
    };

    renderAddPackageModal = () => {
        return (
            <CustomModal
                open={this.state.isAddPackage}
                onClose={this.handleCloseModal}
            >
                <>
                    <FullBodyCheckUpBox>
                        <HeaderTitleBox>
                            Add new package
                        </HeaderTitleBox>
                        <FormBox>
                            <FullBodyCheckUpInputLable>Package name :</FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox onChange={this.handlePackageName} data-test-id="packageNameId" value={this.state.packageName} />
                            {this.state.packageErr && <ErrorTag>{this.state.packageErr}</ErrorTag>}
                            <FullBodyCheckUpInputLable>No. of tests :</FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox onChange={this.handleTests} data-test-id="testCoubntId" value={this.state.testCount} />
                            {this.state.testCountErr && <ErrorTag>{this.state.testCountErr}</ErrorTag>}
                            <FullBodyCheckUpInputLable>Consultation fee :</FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox onChange={this.consultationFeesHandelChange} data-test-id="consultationFee" value={this.state.testConsultationFees} />
                            {this.state.testConsultationFeesErr && <ErrorTag>{this.state.testConsultationFeesErr}</ErrorTag>}
                            <FullBodyCheckUpInputLable>Duration :</FullBodyCheckUpInputLable>
                            {this.renderDurationContainer()}
                            {this.state.durationErr && <ErrorTag>{this.state.durationErr}</ErrorTag>}
                            <FullBodyCheckUpInputLable>Description :</FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox onChange={this.descriptionHandelChange} value={this.state.description} data-test-id="descriptionId" />
                            {this.state.descriptionErr && <ErrorTag>{this.state.descriptionErr}</ErrorTag>}
                            <FullBodyCheckUpInputLable>Sample requirement :</FullBodyCheckUpInputLable>
                            {this.renderSelectContainer()}
                            {this.state.sampleRequireErr && <ErrorTag>{this.state.sampleRequireErr}</ErrorTag>}
                            <ButtonBox>
                                <ContinueButton onClick={this.handleCloseModal} data-test-id="cancelId">Cancel</ContinueButton>
                                <ContinueButton onClick={this.postAddPackageFunction} data-test-id="createPackageButtonId">Save</ContinueButton>
                            </ButtonBox>
                        </FormBox>
                    </FullBodyCheckUpBox>
                </>
            </CustomModal>
        )
    };

    renderDeletePackageModal = () => {
        return (
            <>
                <CustomModal
                    open={this.state.isDeletePackageModal}
                    onClose={this.closeDeleteModal}
                >
                    <DeleteModal>
                        <HeaderTitleBox data-test-id="textId">
                            Are you sure you want to delete this package
                        </HeaderTitleBox>
                        <ButtonBox>
                            <ContinueButton onClick={this.closeDeleteModal} data-test-id="cancelPackageId">Cancel</ContinueButton>
                            <ContinueButton onClick={this.deletePackageFunction} data-test-id="deletePackageButtonId">Delete</ContinueButton>
                        </ButtonBox>
                    </DeleteModal>
                </CustomModal>
            </>
        )
    };

    renderConsultation = () => {
        return (
            <FeesBox>
            {
                this.state.consultationData.data.map((item) => {
                    return (
                        <>
                            <ConsultationBox>
                                <EditBox>
                                <EditButton onClick={() => this.handleEditConsultationModal(item)} data-test-id="handleEditConsultationId"/>
                                </EditBox>
                                <PriceBox>
                                <ConsultationTypeText>{item.attributes.consultation_category}<br/>
                                    {item.attributes.consultation_category === "Online Consultation" &&
                                        <span>
                                            Consultation type: {item.attributes.consultation_type}
                                        </span>
                                    }
                                </ConsultationTypeText>
                                <ConsultationPriceText>₹ {item.attributes.price}</ConsultationPriceText>
                                </PriceBox>
                            </ConsultationBox>
                            <hr />
                        </>
                    )
                })
            }
        </FeesBox>
        )
    };

    renderAddConsultationModal = () => {
        return (
            <CustomModal
               data-test-id="modalllIdd"
                open={this.state.isAddConsultation}
                onClose={this.handleCancelConsultationModal}
            >
                <>
                    <FullBodyCheckUpBox>
                        <HeaderTitleBox data-test-id="newIdd">
                            Add new consultation
                        </HeaderTitleBox>
                        <FormBox>
                            <FullBodyCheckUpInputLable>Consultation category<sup style={{color: "red"}}>*</sup></FullBodyCheckUpInputLable>
                            {this.renderConsultationContainer()}
                            {this.state.categoryDataErr && <ErrorTag>{this.state.categoryDataErr}</ErrorTag>}
                            {
                            this.state.categoryData === "Online Consultation" &&
                            <>
                            <FullBodyCheckUpInputLable>Consultation type<sup style={{color: "red"}}>*</sup></FullBodyCheckUpInputLable>
                            {this.renderConsultationTypeContainer()}
                            {this.state.categoryTypeErr && <ErrorTag>{this.state.categoryTypeErr}</ErrorTag>}
                            </>
                            }
                            <FullBodyCheckUpInputLable>Price<sup style={{color: "red"}}>*</sup></FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox value={this.state.consultationFee} onChange={this.handleConsultationPrice} data-test-id="priceId"/>
                            {this.state.consultationFeeErr && <ErrorTag>{this.state.consultationFeeErr}</ErrorTag>}
                            {
                            this.state.categoryData === "Online Consultation" &&
                            <>
                            <FullBodyCheckUpInputLable >Description</FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox value={this.state.descriptions} onChange={this.handleDescription} data-test-id="descriptionIdd"/>
                            {this.state.descriptionsErr && <ErrorTag>{this.state.descriptionsErr}</ErrorTag>}
                            </>
                            }
                            <ButtonBox>
                                <ContinueButton onClick={this.handleCancelConsultationModal} >Cancel</ContinueButton>
                                <ContinueButton onClick={this.feesCreateFunction} data-test-id="createbuttonId">Create</ContinueButton>
                            </ButtonBox>
                        </FormBox>
                    </FullBodyCheckUpBox>
                </>
            </CustomModal>
        )
    };

    renderConsultationTypeContainer = () => {
        return (
            <StyledSelect
                displayEmpty
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    keepMounted: true,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
                onChange={this.handleConsultationType}
                value={this.state.categoryType}
                id="placeholder"
                data-test-id="typeIdTypee"
                fullWidth
                labelId="placeholder"
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select type
                </MenuItem>
                {this.state.consultationType.map((item: string) => {
                    return (
                        <MenuItem value={item}>
                            {item}
                        </MenuItem>
                    )
                })
                }
            </StyledSelect>
        )
    };

    renderConsultationContainer = () => {
        return (
            <StyledSelect
                displayEmpty
                MenuProps={{   
                    keepMounted: true,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                value={this.state.categoryData}
                id="time-placeholder"
                data-test-id="typeIdConsultationCategory"
                onChange={this.handleConsultationCategory}
                fullWidth
                labelId="time-placeholder"
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select category
                </MenuItem>
                {this.state.consultationCategory.map((item: string) => {
                    return (
                        <MenuItem value={item}>
                            {item}
                        </MenuItem>
                    )
                })
                }
            </StyledSelect>
        )
    };

    renderEditConsultationModal = () => {
        return (
            <CustomModal
               data-test-id="editmodalllIdd"
                open={this.state.isEditConsultation}
                onClose={this.handleEditCancelConsultationModal}
            >
                <>
                    <FullBodyCheckUpBox>
                        <HeaderTitleBox data-test-id="newIdd">
                            Edit consultation
                        </HeaderTitleBox>
                        <FormBox>
                            <FullBodyCheckUpInputLable>Consultation category<sup style={{color: "red"}}>*</sup></FullBodyCheckUpInputLable>
                            {this.renderEditConsultationContainer()}
                            {this.state.editCategoryDataErr&& <ErrorTag>{this.state.editCategoryDataErr}</ErrorTag>}
                            {
                            this.state.editCategoryData === "Online Consultation" &&
                            <>
                            <FullBodyCheckUpInputLable>Consultation type<sup style={{color: "red"}}>*</sup></FullBodyCheckUpInputLable>
                            {this.renderEditConsultationTypeContainer()}
                            {this.state.editCategoryTypeErr && <ErrorTag>{this.state.editCategoryTypeErr}</ErrorTag>}
                            </>
                            }
                            <FullBodyCheckUpInputLable>Price<sup style={{color: "red"}}>*</sup></FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox value={this.state.editConsultationFee} onChange={this.handleEditConsultationPrice} data-test-id="editpriceId"/>
                            {this.state.editConsultationFeeErr && <ErrorTag>{this.state.editConsultationFeeErr}</ErrorTag>}
                            {
                            this.state.editCategoryData === "Online Consultation" &&
                            <>
                            <FullBodyCheckUpInputLable >Description</FullBodyCheckUpInputLable>
                            <FullBodyCheckUpInputBox value={this.state.editDescriptions} onChange={this.handleEditDescription} data-test-id="editdescriptionIdd"/>
                            {this.state.editDescriptionsErr && <ErrorTag>{this.state.editDescriptionsErr}</ErrorTag>}
                            </>
                            }
                            <ButtonBox>
                                <ContinueButton onClick={this.handleEditCancelConsultationModal} >Cancel</ContinueButton>
                                <ContinueButton onClick={this.putDoctorConsultationFunction} data-test-id="editSavebuttonId">Save</ContinueButton>
                            </ButtonBox>
                        </FormBox>
                    </FullBodyCheckUpBox>
                </>
            </CustomModal>
        )
    };

    renderEditConsultationTypeContainer = () => {
        return (
            <StyledSelect
                displayEmpty
                labelId="placeholder"
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    keepMounted: true,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
                onChange={this.handleEditConsultationType}
                value={this.state.editCategoryType}
                id="placeholder"
                data-test-id="editTypeIdTypee"
                fullWidth
                readOnly
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select type
                </MenuItem>
                {this.state.consultationType.map((item: string) => {
                    return (
                        <MenuItem value={item}>
                            {item}
                        </MenuItem>
                    )
                })
                }
            </StyledSelect>
        )
    };

    renderEditConsultationContainer = () => {
        return (
            <StyledSelect
                data-test-id="edittypeIdConsultationCategory"
                displayEmpty
                fullWidth
                labelId="editplaceholder"
                MenuProps={{   
                    keepMounted: true,
                    anchorOrigin: {
                        horizontal: "left",
                        vertical: "bottom"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                value={this.state.editCategoryData}
                id="editplaceholder"   
                onChange={this.handleEditConsultationCategory}
                readOnly
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select category
                </MenuItem>
                {this.state.consultationCategory.map((item: string) => {
                    return (
                        <MenuItem value={item}>
                            {item}
                        </MenuItem>
                    )
                })
                }
            </StyledSelect>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handleShowPopupClose} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderHeaderPage()}
                    {this.renderHeader()}
                    <SubHeader>
                        <SubHeaderTitle>Consultation Fees</SubHeaderTitle>
                        <Box><FiPlus onClick={this.handleConsultationModal} style={{fontSize: "20px", cursor: "pointer"}} data-test-id="modalOpenId"/></Box>
                    </SubHeader>
                  {
                    this.state.consultationData?.data[0]?.attributes.consultation_category !== "" ?
                    this.renderConsultation() :
                    this.renderNoDataFound()
                  }
                    <SubHeader>
                        <SubHeaderTitle>My Packages</SubHeaderTitle>
                        <Box><FiPlus onClick={this.handleOpenPackage} style={{ fontSize: "22px", cursor: "pointer" }} data-test-id="openId" /></Box>
                    </SubHeader>
                    {this.state.packagesData.data.length > 0 ?
                        this.renderPackages() :
                        this.renderNoDataFound()
                    }
                    {this.renderAddPackageModal()}
                    {this.renderDeletePackageModal()}
                    {this.renderAddConsultationModal()}
                    {this.renderEditConsultationModal()}
                </ContainerBox>
                <FooterDoctor />
                <Loader loading={this.state.isLoader} height={this.state.contentHeight} data-test-id="loaderId"/>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ErrorTag = styled("p")({
    color: "red",
    fontSize: "14px",
    margin: "0 !important",
    width: "100%"
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const SubHeader = styled(Box)({
    display: "flex",
    margin: "20px 0",
    justifyContent: "space-between",
    alignItems: "center"
});

const SubHeaderTitle = styled(Box)({
    color: "#000",
    fontWeight: 500,
    fontSize: "18px"
});

const PackageOuterBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    gap: "20px",
    marginBottom: "20px",
    height: "200px",
    overflowX: "scroll"
});

const HeaderTitle = styled(Box)({
    fontSize: "20px",
    fontWeight: 500,
    margin: "10px 0"
});

const HeaderTitleBox = styled(Box)({
    fontSize: "20px",
    fontWeight: 500,
    margin: "10px 0",
    textAlign: "center"
});

const FeesBox = styled(Box)({
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    WebkitBoxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    MozBoxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    padding: "5px",
    borderRadius: "5px",
    height: "230px",
    overflowX: "scroll"
});

const ConsultationBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    margin: "10px",
});

const PriceBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const EditBox = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    marginBottom:"5px"
});

const ConsultationTypeText = styled(Box)({
    fontSize: "15px",
    color: "#000",
    fontWeight: 400
});

const ConsultationPriceText = styled(Box)({
    fontSize: "17px",
    color: "#000",
    fontWeight: 500
});

const PackageBox = styled(Box)({
    position: "relative",
    display: "flex",
    padding: "10px",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "180px",
    width: "180px",
    borderRadius: "5px",
    marginLeft: "3px",
    marginBottom: "5px",
    marginTop: "5px",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    "-webkit - box - shadow": "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    "-moz - box - shadow": "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
});

const PackagePriceText = styled(Box)({
    color: "#4d96dd",
    fontSize: "18px",
    fontWeight: 500
});

const StyledSelect = styled(Select)({
    marginBottom: "20px",
    "& .MuiSelect-selectMenu": {
        minHeight: "30px",
        display: "flex",
        alignItems: "center",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingLeft: "10px",
        borderRadius: "5px",
    },
    "& .MuiOutlinedInput-input": {
        padding: "0",
    }
});

const PackageNameText = styled(Box)({
    color: "#000",
    fontSize: "16px",
    fontWeight: 500
});

const PackageDurationText = styled(Box)({
    color: "#808080",
    fontSize: "14px",
    fontWeight: 400
});

const PackageNoOfTestText = styled(Box)({
    color: "#808080",
    fontSize: "14px",
    fontWeight: 400
});

const FullBodyCheckUpInputLable = styled("label")({
    color: "grey",
    fontWeight: 500,
    marginBottom: "5px",
    width: "100%"
});

const PackageIconBox = styled(Box)({
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    backgroundColor: "#eef4f4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const CustomMdEdit = styled(MdEdit)({
    position: "absolute",
    color: "#808080",
    top: "10px",
    right: "10px",
    fontSize: "20px",
    cursor: "pointer"
});

const CustomDelete = styled(RiDeleteBinLine)({
    position: "absolute",
    color: "#808080",
    top: "10px",
    right: "35px",
    fontSize: "20px",
    cursor: "pointer"
});

const FullBodyCheckUpBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    width: "600px",
});

const FullBodyCheckUpInputBox = styled("input")({
    border: "0",
    borderBottom: "1px solid #c6b7b7 !important",
    marginBottom: "12px",
    width: "100%"
});

const SelectChip = styled(Box)({
    display: "flex",
    gap: "6px",
    flexWrap: "wrap",
    padding: "10px 0",
});

const MenuItemNone = styled("p")({
    color: "gray",
    margin: "0px"
});

const SelectChipBox = styled(Chip)({
    "&.MuiChip-root": {
        backgroundColor: "transparent !important",
        border: "1px solid #4d96dd",
        color: "#4d96dd"
    }
});

const ContinueButton = styled("button")({
    color: "#fff",
    padding: "10px",
    width: "150px",
    borderRadius: "50px",
    backgroundColor: "#3a79c1",
    fontSize: "15px",
    fontWeight: 500,
    border: "none",
    display: "block",
    margin: "20px auto"
});

const CustomModal = styled(Modal)({
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "scroll",
    borderRadius: "10px",
    "@media (max-width:700px)": {
        padding: "0px"
    }
});

const FormBox = styled(Box)({
    width: "80%",
    margin: "0 auto",
    height: "100%",
    padding: "30px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
});

const ButtonBox = styled(Box)({
    display: "flex",
    width: "100%"
});

const DeleteModal = styled("div")({
    width: "400px",
    height: "160px",
    backgroundColor: "white",
    borderRadius: "10px"
});

const NoData = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "180px",
    width: "100%",
    marginBottom : "20px",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    "-webkit - box - shadow": "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    "-moz - box - shadow": "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
});

const EditButton = styled(MdEdit)({
    color: "#808080",
    fontSize: "20px",
    cursor: "pointer"
});
// Customizable Area End
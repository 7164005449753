import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  }

interface S {
  // Customizable Area Start
  isRegisterModal: boolean;
  aadharTaken: boolean;
  isDocumentModal: boolean;
  patientImage: any;
  fullName: string;
  email: string,
  phoneNum: number,
  dateOfBirth: string,
  gender: string,
  weight: string,
  bloodGroup: string,
  city: string,
  aadhar: string,
  health: string,
  ayushman: string,
getDisease:any,
  disease: string,
  patientName: string,
  patientNumber: string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class PatientRegistrationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postCreatePatientApiCallId: string = "";
  upload: any;
  // Customizable Area Enddata:

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      getDisease:[],
      patientImage: "",
      isRegisterModal: true,
      aadharTaken: false,
      isDocumentModal: false,
      fullName: "",
      email: "viv@gmail.com",
      phoneNum: 9109080842,
      dateOfBirth: "2000-10-5",
      gender: "",
      weight: "",
      bloodGroup: "",
      city: "",
      aadhar: "",
      health: "",
      ayushman: "",
      disease: "",
      patientName: "",
      patientNumber: "",
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      patientName: this.props.location.state?.patientDetails.data.attributes.full_name,
      patientNumber: this.remove_country_code(this.props.location.state?.patientDetails.data.attributes.full_phone_number) 
    })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isValidResponse(responseJson)) {
        this.apiPatientSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiPatientFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiPatientSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postCreatePatientApiCallId) {
      this.postCreatePatientSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiPatientFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postCreatePatientApiCallId) {
      this.postCreatePatientFailureCallBack(responseJson);
    }
  };
  // Customizable Area End


  onFinishCreatePatient = async (values: { fullName: string, PhoneNo: string, email: string, DOB: string, Gender: string, Weight: string, BloodGroup: string, city: string, AadharNo: string, Health: string, AyushmanBharat: string, disease: any }) => {
    this.postCreatePatient(values)
  }

  postCreatePatient = async (values: { fullName: string, PhoneNo: string, email: string, DOB: string, Gender: string, Weight: string, BloodGroup: string, city: string, AadharNo: string, Health: string, AyushmanBharat: string, disease: any }) => {
  let a = values.disease
  const array = this.state.getDisease
  array.push(a)
    let apiBodyWithImage = {
      data: {
        type: "sms_account",
        attributes: {
          image: {data:this.state.patientImage},
          full_name: this.state.patientName,
          full_phone_number: `91${this.state.patientNumber}`,
          email_id: values.email,
          date_of_birth: values.DOB,
          gender: values.Gender,
          weight: values.Weight,
          blood_group: values.BloodGroup,
          city: values.city,
          aadhar_no: values.AadharNo,
          health_id: values.Health,
          ayushman_bharat_id: values.AyushmanBharat,
          disease: array,
        }
      }
    }
    let apiBodyWithoutImage = {
      data: {
        type: "sms_account",
        attributes: {
          full_name: this.state.patientName,
          full_phone_number: `91${this.state.patientNumber}`,
          email_id: values.email,
          date_of_birth: values.DOB,
          gender: values.Gender,
          weight: values.Weight,
          blood_group: values.BloodGroup,
          city: values.city,
          aadhar_no: values.AadharNo,
          health_id: values.Health,
          ayushman_bharat_id: values.AyushmanBharat,
          disease: array,
        }
      }
    }
    this.postCreatePatientApiCallId = ApiCall({
      contentType: configJSON.postCreatePatientApiContentType,
      method: configJSON.postCreatePatientAPiMethod,
      endPoint: configJSON.postCreatePatientAPiEndPoint,
      body: this.state.patientImage ? apiBodyWithImage  : apiBodyWithoutImage,
    });
  };

  postCreatePatientSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token)
    localStorage.setItem("isLogin", JSON.stringify(true));
    localStorage.setItem(
      "patientDetails",
      JSON.stringify(res.data.attributes)
    );
    localStorage.setItem(
      "profile_photo",
      res.data.attributes.profile_photo
    );
    
    notification["success"]({
      message: "Patient created succesfully",
    });
    this.props.history.push("/patienthome")
  };

  postCreatePatientFailureCallBack = async (err: any) => {
    if (err.errors[0].aadhar_no === "has already been taken") {
      this.setState({
        aadharTaken: true
      })
    }
  }

  disabledPastDate = (current: any) => {
    return current && current > moment().startOf("day");
  }


  handleOnChangeValues = () => {
    this.setState({
      aadharTaken: false
    })
  }


  imgFilehandler = (e: any) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        let profileImage = reader.result;
        this.setState({ patientImage: profileImage });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  remove_country_code(phoneNumber:any) {
    if(phoneNumber?.length >10){
      return phoneNumber.replace(/^91/, '');
    }else{
      return phoneNumber
    }
  }

}
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchTerm:string,
  searchResult:any,
  errorMessage:any,
  isDoctorLogin:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Elasticsearch2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGlobalSearchApiCalledId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchTerm:"",
      searchResult:[],
      errorMessage:"",
      isDoctorLogin:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));


      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    // Customizable Area Start


    }
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
        
  );


  const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
  );
   
  if(apiRequestCallId !=null){
      if(apiRequestCallId === this.getGlobalSearchApiCalledId){
        if(responseJson.data){
          this.setState({searchResult:responseJson.data,errorMessage:""});

        }else{
          this.setState({errorMessage:responseJson.errors[0].message,searchResult:[]})
        }
       
      }
  }
  
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  getGlobalSearchResult=async(searchTerm:string)=>{

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    let cityName = await JSON.parse(localStorage.getItem("cityname") as any ) 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGlobalSearchApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.globalSearchEndPoint}?city=${cityName}&full_name=${searchTerm}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

  handleSearch=(event:any)=>{
    let searchTerm = event.target.value;

    this.setState({
      searchTerm
    })
    this.getGlobalSearchResult(searchTerm) 
  }

handleNavigationOnClick=(searchItem:any)=>{
  localStorage.setItem("doctor_id",searchItem.id)
  localStorage.setItem("doctor_category",searchItem.attributes.specialization)
  this.setState({searchTerm:""})
  if(window.location.pathname === "/patient-appointment"){
    window.location.reload();
  }
}
  // Customizable Area End
}

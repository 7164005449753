import React from "react";
// Customizable Area Start
import {Typography,Avatar,Card,Row,Col,Rate,Button} from "antd"
import { Link } from 'react-router-dom';
import FooterCommonComponent from "../../../components/src/FooterDoctor.web";

import { CertificationComponent } from "../../../components/src/CertificationComponent.web";
import MerilLoader from '../../../components/src/MerilLoader.web';
import {consultation,Clock,user,calender,discount,RightOutlined} from "./assets"
let config = require('../../../framework/src/config.js').baseURL;
import Box from "@material-ui/core/Box";
import Text from "antd/lib/typography/Text";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import {  Default } from "../../splashscreen/src/assets";
// Customizable Area End

import PatientAppointmentCheckoutController, {
  Props
} from "./PatientAppointmentCheckoutController.web";


 class PatientAppointmentCheckout extends PatientAppointmentCheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNavigationTitle2 = () => {

    let isAppointmentActiveOnCheckout =
    window.location.pathname === "/patient-appointment-checkout";
    let appointmentClassName = isAppointmentActiveOnCheckout ? "active" : "";

    return (
        <>
            <div className="navigationContainer">
                <Link to="/patienthome" >Home</Link>
                <Link className={appointmentClassName} to="/patient-appointment" >Appointment</Link>
                <Link to="/services" >Services</Link>
                <Link to="/message" >Message</Link>
            </div>
        </>
    )
    }

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
     <>
     
     <NavbarCommonComponent data-test-id="navbarCommonComponentID4"  handleProfile={this.showPatientDetailHandler} />
      <PatientProfilePopup data-test-id="handleLogoutId4" profileData={this.state.showPopup} logoutFunctiuon={this.patientLogout} />

     {this.renderNavigationTitle2()}
     <Box pb={3} pt={1} > 
        <div
          className="navigation-list-css-container3"
          style={{ paddingLeft: "5%" }}
        >
          <div key={"patientHome"}>
            <Text
              className="navigation-list-css3"
              style={{ color: "#498ECC" }}
              data-test-id="patientHomeID"
              onClick={() => this.props.history.push("/patienthome")}
            >
              Home
            </Text>
            <img className="rightArrowIcon" src={RightOutlined} alt=""/>
          </div>
          <div key={"ri425"}>
            <Text
            data-test-id="patientAppointmnetLandingpageID"
              key={"appointments"}
              style={{ color: "#498ECC" }}
              className="navigation-list-css3"
              onClick={() => this.props.history.push("/patient-appointment-landingpage")}
            >
           Appointments
            </Text>
          
            <img className="rightArrowIcon" style={{marginRight:"12px"}}  src={RightOutlined} alt=""/>
          </div>

          <div>
          {
            this.props.location?.state?.navigateList?.map((navigation: any, index: number) => (
              <>
                {index === this.props.location.state.navigateList.length - 1 ? (
                  <>
                    <Text
                      style={{ color: "#353535" }}
                      className="navigation-list-css"
                    >
                      {navigation.name}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                     data-test-id="navigationListID"
                      style={{ color: "#498ECC" }}
                      className="navigation-list-css"
                      onClick={() =>
                        this.props.history.push({
                          pathname: navigation.route,
                          state: {
                            navigateList: this.props.location.state.navigateList[0].name,
                            
                          }
                        })
                      }
                    >
                      {navigation.name}
                    </Text>
                    <img className="rightArrowIcon" style={{marginRight:"12px"}}  src={RightOutlined} alt="" />
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        </Box>

     <br/>
     <div className="doctorList" ref={this.myRef} >
        <div>
            <Row gutter={[48,24]} className="doctor_profile_container" >

            <Col xl={3} style={{display:"flex",flexDirection:"row",alignItems:"center"}} >
             <Avatar style={{width:"100px",height:"100px"}} size="large" src={this.state.image ? config + this.state.image :Default } />  
           </Col>

              <Col xl={6} >
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}} >
              <Typography className="doctorName">{this.state.doctor_full_name} </Typography>
              <Rate allowHalf disabled value={this.state.rating_average} />
            </div>
            <Typography className="doctorDescription" style={{margin:"8px 0"}} >{this.state.doctor_specialisation} </Typography>
            
            <div className="doctorExperienceWrapper">
               <Typography className="doctor_experience"  > {this.state.doctor_experience ? `${this.state.doctor_experience} Years Experience`:"" }  </Typography>
               <Typography>{this.state.reviews_count} reviews </Typography>
            </div>
          </Col>


                <Col xs={24} >
                       <Typography className="appointment-details" >
                            Appointment Details
                        </Typography> 
                </Col>


                <Col xs={24} sm={12}  >
                    <Card className="appointment_details_basic_container" >
                      <div className="appointment_details_wrapper" >

                      <div className="appointment-details-row-wrapper"  >
                      <img src={user} alt="" className="appoitment-details-icons"  />
                      <div className="appointment-details-label-content-wrapper" >
                      <Typography className="text-label" > Name</Typography>
                      <Typography className="text-label-value" >{this.state.patient_full_name} </Typography>
                      </div>

                      </div>

                      <div className="appointment-details-row-wrapper">
                      <img src={calender} alt="" className="appoitment-details-icons"  />
                      <div className="appointment-details-label-content-wrapper" >
                      <Typography className="text-label"> Date</Typography>
                        <Typography className="text-label-value">
                        {this.state.consultation_date}
                        </Typography>
                      </div>
                      </div>
                   
                    <div className="appointment-details-row-wrapper" >
                      <img src={Clock} alt="" className="appoitment-details-icons"  />
                      <div  className="appointment-details-label-content-wrapper">
                      <Typography className="text-label"> Time</Typography>
                     <Typography className="text-label-value">
                     {this.state.time_slot}
                     </Typography>
                      </div>
                    </div>

                    <div className="appointment-details-row-wrapper" >
                    <img src={consultation} alt="" className="appoitment-details-icons"  />
                    <div className="appointment-details-label-content-wrapper">
                    <Typography className="text-label">
                       Type of Consultation
                      </Typography>
                      <Typography className="text-label-value">
                      {this.state.consultation_type || "In - Clinic" }
                      </Typography>
                    </div>

                    </div>

                    </div>
                    </Card>
                </Col>

                <Col xs={24} sm={10} >

                    <div className="checkout-details-wrapper" >
                     <Typography className="checkout-details-label" >Payment Details</Typography>
                     <div className="checkout-details-text-container" >
                      <Typography className="checkout-details-consultation-fees" >Consultation fees</Typography>
                      <Typography className="payment-details-consultation-fees-amount" >Rs {this.state.consultation_fees}/ -</Typography>
                     </div>

                     <div className="checkout-details-text-container">
                      <Typography className="payment-details-total-amount" >Total Amount</Typography>
                      <Typography className="payment-details-total-amount-fees">Rs {this.state.consultation_fees}/ -</Typography>
                     </div>

                     <div className="checkout-details-text-container">
                      <Typography className="payment-details-faqa" >
                         Having trouble with the booking? Checkout  <span className="payment-details-FAQs-text" >
                         FAQs 
                         </span>
                      </Typography>
                    
                     </div>

                     <div className="checkout-details-text-container">
                      <Button 
                      data-test-id="checkoutButtonID"
                      onClick={this.openRazorpay} 
                      className="payment-details-checkout-button" >
                        Checkout
                      </Button>
                     </div>

                     <div>

                     </div>

                     
                    </div>
                </Col>

                <Col xs={24} sm={10} >
                <Card className="appointment_details_basic_container"  >
                  <div className="offer-section-container" >
                    <div>
                      <img src={discount} alt="" className="appoitment-details-icons"  />
                    </div>
                 
                  <div className="offers-label-text-wrapper" >
                <Typography className="text-label" >Offers</Typography>
                 <div className="offer-coupon-code-label-wrapper" >
                 <Typography className="checkout-offers-cuppon" > 
                 Apply  a coupon code
                </Typography>
                <Typography className="checkout-offers-cuppon" > 
                 View offers  
                </Typography>
                 </div>
                  </div>
                  </div>


               </Card>
            </Col>


            </Row>

        </div>

     <CertificationComponent/>
     </div>
      <FooterCommonComponent />

     </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default MerilLoader(PatientAppointmentCheckout);
export { PatientAppointmentCheckout };
// Customizable Area End

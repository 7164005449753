import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import FooterDoctor from "../../../../components/src/FooterDoctor.web"
import NavbarCommonComponent from "../../../../components/src/NavbarCommonComponent.web";
import { arrow1, arrow2 } from "../assets";
import DoctorUpcomingAppointment from "../../../appointmentmanagement/src/DoctorUpcomingAppointment.web";
import DoctorAvailability from "../../../appointmentmanagement/src/DoctorAvailability.web";
import LandingpageController from "../LandingpageController.web";
import DoctorProfilePopup from "../../../../components/src/DoctorProfilePopup.web";
import { Link as Redirect} from 'react-router-dom';
// Customizable Area End

export class DoctorDashboardHomePage extends LandingpageController {
  // Customizable Area Start
  renderDoctorDashboardHomePageNavbar = () => {
    return (
      <div>
        <ul className="DoctorDashboardHomePageNavbar-list">
          <li className="DoctorDashboardHomePageNavbar-list-item">
            <Redirect to="/doctorhome">
              <p className="activeColor">Home</p>
            </Redirect>
          </li>
          <li className="DoctorDashboardHomePageNavbar-list-item">
            <Redirect to="/doctorschedule">
              <p>Schedule</p>
            </Redirect>
          </li>
          <li className="DoctorDashboardHomePageNavbar-list-item">
          <Redirect to="/addpatient"><p>Add Patient</p></Redirect>
            
          </li>
          <li className="DoctorDashboardHomePageNavbar-list-item">
            <Redirect to="/chat">
              <p>Chats</p>
            </Redirect>
          </li>
        </ul>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderUpCommingAppointment = () => {
    return (
      <div>
        <div className="renderUpCommingAppointment-top-section">
          <h1 className="renderUpCommingAppointment-heading">
            Upcoming Appointments
          </h1>
        </div>
        <DoctorUpcomingAppointment/>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderMyAvailability = () => {
    return (
      <div>
        <div className="renderUpCommingAppointment-top-section hideData">
          <h1 className="renderUpCommingAppointment-heading">My Availability</h1>
          <p className="renderUpCommingAppointment-view-all-btn">(Today)</p>
        </div>
        <DoctorAvailability id={""} history={undefined} location={undefined} />
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderPerformanceSummary = () => {
    return (
      <div style={{marginBottom:"24px"}} >
        <div className="renderUpCommingAppointment-top-section">
          <h1 className="renderUpCommingAppointment-heading">
            Performance Summary
          </h1>
          <p className="renderUpCommingAppointment-view-all-btn">(Weekly)</p>
        </div>
        <div className="myAvailability-main">
          <div className="performanceSummary-card-main">
            <Row>
              <Col lg={8} md={8} sm={8} xs={8}>
                <div className="performanceSummary-card-left-section">
                  <p>Patient Consulted</p>
                </div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <div className="performanceSummary-card-center-section">
                  <div>
                    <span className="value1">54</span>
                    <span className="value2">5.6%</span>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <div className="performanceSummary-card-img-container">
                  <img
                    className="performanceSummary-arrow-img"
                    src={arrow1}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </div>
          <p className="horizontal-line"></p>
          <div className="performanceSummary-card-main">
            <Row>
              <Col lg={8} md={8} sm={8} xs={8}>
                <div className="performanceSummary-card-left-section">
                  <p>Payment Due (virtual)</p>
                </div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <div className="performanceSummary-card-center-section">
                  <div>
                    <span className="value1">5K</span>
                    <span className="value2">2%</span>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <div className="performanceSummary-card-img-container">
                  <img
                    className="performanceSummary-arrow-img"
                    src={arrow2}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <DoctorProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} showProfile={this.handleDoctorDetailsPage} doctorDetailsData={this.state.doctorDetailsData} handleEditProfile={this.handleEditDoctorProfile} handleClosePopup={this.handlePopupClose} />
        <div className="DoctorDashboardHomePage-main">
          {this.renderDoctorDashboardHomePageNavbar()}
          {this.renderUpCommingAppointment()}
          {this.renderMyAvailability()}
          {this.renderPerformanceSummary()}
        </div>
        <FooterDoctor />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DoctorDashboardHomePage;
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config.js");

// Customizable Area Start
export interface OurBrandType {
  id: string;

  type: string;
  attributes: {
    name: string;
    description: string;
    brand_image: string;
  };
}
// Customizable Area End

// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End

// Customizable Area Start
export interface TrendingType {
  id: string;
  type: string;
  attributes: {
    id: number;
    featured_therapy_id: number;
    therapy_name: string;
    therapy_images: Array<HospitalsImagesType>;
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  BrandsData: Array<OurBrandType>;
  symptoms: Array<any>;
  categoryData: Array<TrendingType>;
  trendingTherapiesDataSliced: boolean;
  SymptomDataSliced: boolean;
  BrandsDataSliced: boolean;
  getMedicineData: boolean;
  successPopUp: boolean;
  isModalOpen: boolean;
  selectedIndex: number | null;
  productCount: number;
  showPopup: boolean;
  isLoginUser: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class MedicinepageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMedicineApiCallId: string | null = null;
  getCreateCartSApiCallId: string | null = null;

  // Customizable Area Enddata:

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      BrandsData: [],
      symptoms: [],
      categoryData: [],
      trendingTherapiesDataSliced: true,
      SymptomDataSliced: true,
      BrandsDataSliced: false,
      getMedicineData: true,
      successPopUp: false,
      isModalOpen: false,
      selectedIndex: null,
      productCount: 0,
      showPopup: false,
      isLoginUser: true,
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getMedicineShow(this.props.location.state.therapieId);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson !== null) {
        this.apiSuccessOKCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidOkResponse(responseJson)) {
        console.log(responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  isInValidOkResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End
  // Customizable Area Start
  productIncreament = (type: number) => {
    if (type === 1) {
      this.setState({ productCount: this.state.productCount + 1 });
    } else if (type === 0) {
      if (this.state.productCount > 0) {
        this.setState({ productCount: this.state.productCount - 1 });
      }
    }
  };
  // Customizable Area End
  // Customizable Area Start
  apiSuccessOKCallBacks = (apiRequestCallId: any, responseJson: any) => {
    console.log("@@@ iresponseJson", responseJson);
    if (apiRequestCallId === this.getMedicineApiCallId) {
      this.getBrandsDataSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCreateCartSApiCallId) {
      this.setState({ isModalOpen: true });
    }
  };
  // Customizable Area End

  getMedicineShow = async (id: number) => {
    this.getMedicineApiCallId = ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_order_management/medicine_search?featured_therapy_id=${id}`,
    });
  };
  addToCartFunc = async (index: number) => {
    this.setState({ selectedIndex: index });
  };
  handleAddCreate = async (params: any) => {
    const payLoad = {
      cart: {
        patient_id: "986",
        promo_code: "discount appointment",
        cart_products_attributes: [
          {
            medicine_id: params.id,
            product_count: this.state.productCount,
          },
        ],
      },
    };
    if (this.state.productCount > 0) {
      this.AddCreateCartShow(payLoad);
    }
  };

  AddCreateCartShow = async (body: any) => {
    this.getCreateCartSApiCallId = ApiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_shopping_cart/create_cart`,
      body,
    });
  };

  getBrandsDataSuccessCallBack = (res: any) => {
    console.log(res, "resData");

    this.setState({
      BrandsData: res.data,
    });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout succesfully",
    });
  };
  // Customizable Area End
}

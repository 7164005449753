import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: object;
  data: Array<object>;
  settings: Array<object>;
}

export interface TermsType {
  settings: [
    {
      id: number;
      name: string;
      description: string;
    }
  ]
}

export interface PrivacyType {
  settings: [
    {
      id: number;
      name: string;
      description: string;
    }
  ]
}

export interface CheckEventType {
  target: {
    checked: boolean;
  }
}

export interface DoctorProfileDetailsType {
  data: ProfileData;
}

export interface ProfileData {
  id: string;
  type: string;
  attributes: {
    representative_contact_no: string;
    full_name: string;
    medical_representative_name: string;
    profile_image: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  termsConditionData: TermsType;
  privacyPolicyData: PrivacyType;
  checkboxCheck: boolean;
  contentHeight: number;
  isLoader: boolean;
  isLogin: string | null;
  isPatientGoogleLogin: string | null;
  healthId: string | null;
  doctorName: string | null;
  showPopup: boolean;
  doctorProfileData: DoctorProfileDetailsType;
  isKyc: string | null;
  isLoginUser: boolean;
  description: string;
  descriptionPrivacy: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionsContrller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsAndConditionsApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  getProfileDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      termsConditionData: {
        settings: [
          {
            id: 0,
            name: "",
            description: ""
          }
        ]
      },
      privacyPolicyData: {
        settings: [
          {
            id: 0,
            name: "",
            description: ""
          }
        ]
      },
      checkboxCheck: false,
      contentHeight: 0,
      isLoader: false,
      isLogin: "",
      isPatientGoogleLogin: "",
      healthId: "",
      doctorName: "",
      showPopup: false,
      doctorProfileData: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_name: "",
            profile_image: "",
            medical_representative_name: "",
            representative_contact_no: "",
          }
        }
      },
      isKyc: "",
      isLoginUser: false,
      description: "",
      descriptionPrivacy: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getTermsConditionsfunction();
    this.getPrivacyPolicyfunction();
    const isDoctorGoogleLogin = localStorage.getItem("isDoctorGoogleLogin");
    const isPatientGoogleLogin = localStorage.getItem("isLogin");
    const healthId = localStorage.getItem("health_id");
    const doctorName = localStorage.getItem("doctorName");
    const is_kyc = localStorage.getItem("is_kyc");
    this.setState({
      isLogin: isDoctorGoogleLogin,
      isPatientGoogleLogin: isPatientGoogleLogin,
      healthId: healthId,
      doctorName: doctorName,
      isKyc: is_kyc
    });
    this.getProfilefunction();
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
  }

  apiCall = (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = data;
    const token = localStorage.getItem("token");
    let header = {
      "Content-Type": contentType,
      token
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.validResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
  }

  validResponse = (responseJson: ValidResponseType) => {
    return (responseJson);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: TermsType & PrivacyType & DoctorProfileDetailsType) => {
    if (apiRequestCallId === this.getTermsAndConditionsApiCallId) {
      this.getTermsConditionsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPrivacyPolicyApiCallId) {
      this.getPrivacyPlicySuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      this.getProfileSuccessCallBack(responseJson);
    }
  };

  getTermsConditionsfunction = async () => {
    this.getTermsAndConditionsApiCallId = this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getTermsAndCondsApiEndPoint
    });
    this.setState({
      isLoader: true
    });
  };

  getTermsConditionsSuccessCallBack = (response: TermsType) => {
    this.setState({
      termsConditionData: response,
      isLoader: false
    });
    response.settings.map((item) => {
      this.setState({
        description : item.description
      });
    })
  };

  getPrivacyPolicyfunction = async () => {
    this.getPrivacyPolicyApiCallId = this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPrivacyPolicyApiEndPoint
    });
    this.setState({
      isLoader: true
    });
  };

  getPrivacyPlicySuccessCallBack = (response: PrivacyType) => {
    this.setState({
      privacyPolicyData: response,
      isLoader: false
    });
    response.settings.map((item) => {
      this.setState({
        descriptionPrivacy : item.description
      });
    })
  };

  handleCheckbox = (event: CheckEventType) => {
    this.setState({
      checkboxCheck: event.target.checked
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handelShowDoctoPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
    this.setState({
      isLoginUser: false
  })
  };

  handleDocDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleEditDocProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  handleShowPopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  getProfilefunction = async () => {
    this.getProfileDetailsCallId = this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint
    });
  };

  getProfileSuccessCallBack = (response: DoctorProfileDetailsType) => {
    this.setState({
      doctorProfileData: response
    });
  };

  handleVerifyKyc = () => {
    notification["error"]({
      message: "Please upload or verify your documents to complete the KYC process",
    });
  };
  // Customizable Area End
}
import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row, Button, Card } from "antd";
import {
  images2,
  images3,
  images4,
  images5,
  femaleDoctor,
  Maledoctor,
  BanerImg,
  RightOutlined,
} from "./assets";
import { Carousel } from "react-responsive-carousel";
import OrderMedicinePageController from "./OrderMedicinePageController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import FooterCardComponent from "../../shoppingcart/src/component/FooterCardComponent";
import { SearchOutlined } from "@material-ui/icons";
import Text from "antd/lib/typography/Text";
import { images } from "../../shoppingcart/src/assets";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";

let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class OrderMedicinePage extends OrderMedicinePageController {
  // Customizable Area Start
  renderOrderTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patientappointment">Appointments</Link>

          <Link to="/services">Services</Link>
          <Link to="/patienthome">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderOrderCarousel = () => {
    return (
      <>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={false}
          className="renderMainCarousel"
        >
          <div>
            <img className="carouselImage image" src={images5} />
          </div>
          <div>
            <img className="carouselImage image" src={images2} />
          </div>
          <div>
            <img className="carouselImage image" src={images3} />
          </div>
          <div>
            <img className="carouselImage image" src={images4} />
          </div>
          <div>
            <img className="carouselImage image" src={images} />
          </div>
        </Carousel>
      </>
    );
  };

  // Customizable Area End
  // Customizable Area Start

  renderOurBrands = () => {
    let ourBrandDataShow = this.state.BrandsDataSliced
      ? this.state.BrandsData
      : this.state.BrandsData.slice(0, 6);
    return (
      <>
        <div className="ourServicesContainer">
          <div className="ourServicesHeaderPart topmargin">
            <h4 className="ourServicesTitle">Shops by Brands</h4>
            <p
              data-test-id="categoryId"
              className="ourServicesSubTitle"
              onClick={this.handleServicesData}
            >
              {!this.state.BrandsDataSliced ? "See All" : "View less"}
            </p>
          </div>
          <Row className="cardRow" gutter={[20, 20]}>
            {ourBrandDataShow.map((item, index) => {
              return (
                <Col xl={4} lg={6} md={8} sm={12} xs={24} key={index + 1212}>
                  <div className="servicesCard">
                    <div
                      className="servicesCardImgBox"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}
                    >
                      <img
                        src={config + item.attributes.brand_image}
                        className="servicesCarddImg"
                        alt="Service Card"
                      />
                    </div>
                    <p className="servicesCardText">{item.attributes.name}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderOrderCarouselSecondPart = () => {
    return (
      <div>
        <img
          className="carouselImage firstCaroselImg"
          src={BanerImg}
          style={{ height: "40vh", marginTop: "5vh" }}
        />
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    const orderMedicineData = this.state.trendingTherapiesDataSliced
      ? this.state.categoryData.slice(0, 6)
      : this.state.categoryData;
    const dataSympList = this.state.SymptomListSliced
      ? this.state.symptoms.slice(0, 6)
      : this.state.symptoms;

    const CardStyleCss: any = {
      borderRadius: "4px",
      boxShadow: "rgba(0, 0, 0, 0.1) 5px 6px 14px",
      margin: "10px",
      width: 350,
      height: 150,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    };
    return (
      <div>
        <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup
          data-test-id="handleLogoutId"
          profileData={this.state.showPopup}
          logoutFunctiuon={this.handleLogout}
        />
        {this.renderOrderTitle()}
        {this.renderOrderCarousel()}
        <div className="itemContainerWiidth">
          <div
            className="navigation-list-css-container"
            style={{ paddingLeft: "5%" }}
          >
            <div key={"homeKey"}>
              <Text
                className="navigation-list-css"
                style={{ color: "#498ECC", fontSize: "18px" }}
                onClick={() => this.props.history.push("/patienthome")}
              >
                Home
              </Text>
              <img src={RightOutlined} alt="" style={{ width: "10px" }} />
            </div>
            <div key={"ri425"}>
              <Text
                key={"ServicesKey"}
                style={{ color: "#498ECC", fontSize: "18px" }}
                className="navigation-list-css"
                onClick={() => this.props.history.push("/services")}
              >
                Service
              </Text>

              <img src={RightOutlined} alt="" style={{ width: "10px" }} />
            </div>
            <div key={"ri425"}>
              <Text
                key={"ServicesKey"}
                style={{ color: "#498ECC", fontSize: "18px" }}
                className="navigation-list-css"
                onClick={() => this.props.history.push("/OrderMedicinePage")}
              >
                Order Medicine
              </Text>
            </div>

            <div>
              <Text
                style={{ color: "#353535" }}
                className="navigation-list-css"
              ></Text>
            </div>
          </div>
          {/* <div className='navigation-list-css-container' style={{ paddingLeft: '5%',margin:'2rem' }}>  <Text className='navigation-list-css' style={{ color: "#498ECC" }}>Home</Text>   <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text style={{ color: "#498ECC" }} className='navigation-list-css'>Services</Text> <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text style={{ color: "#498ECC" }} className='navigation-list-css'>Order Medicine</Text></div> */}

          <div
            className="searchBoxDoc"
            style={{
              width: "90%",
              justifyContent: "start",
              gap: "15px",
              marginBottom: "50px",
            }}
          >
            <span>
              <SearchOutlined />
            </span>
            <input
              type="text"
              className="searchInput"
              placeholder="Search For Medicine"
            />
          </div>
          <div>
            <Col md={12}>
              <Card
                style={{
                  ...CardStyleCss,
                  width: "530px",
                  padding: "1.4rem",
                  height: "200px",
                }}
              >
                <h6 className="title-color-css" style={{ padding: "0.4rem" }}>
                  Upload prescription
                </h6>
                <h6
                  className="title-color-css"
                  style={{
                    padding: "0.2rem",
                    fontSize: "12px",
                    color: "#353535",
                  }}
                >
                  Upload your prescription and order your medicines right away.
                </h6>
                <Button
                  style={{
                    background: "#498ECC",
                    color: "white",
                    borderRadius: "30px",
                    padding: "0.5rem",
                  }}
                >
                  Upload Now
                </Button>
              </Card>
            </Col>
          </div>
          <div>
            <Row gutter={[40, 24]} style={{ marginTop: "20px" }}>
              <Col md={8} sm={12} xs={24}>
                <Card style={CardStyleCss}>
                  <div className="top-doctor">
                    <Col>
                      <h6 className="title-color-css">
                        30% off on your first Consultation
                      </h6>
                      <Button className="code-coupun-class">
                        Code: Health 30
                      </Button>
                    </Col>

                    <img
                      alt="example"
                      className="female-doctor-css"
                      src={femaleDoctor}
                    />
                  </div>
                </Card>
              </Col>
              <Col md={8} sm={12} xs={24}>
                <Card style={CardStyleCss}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Col>
                      <h6 className="title-color-css">Doctor Verified</h6>
                      <img
                        src="https://www.pngitem.com/pimgs/m/302-3024199_instagram-verified-symbol-png-instagram-verified-logo-png.png"
                        style={{ width: "50px", height: "30px" }}
                      />
                    </Col>
                    <img
                      alt="example"
                      className="female-doctor-css"
                      src={Maledoctor}
                    />
                  </div>
                </Card>
              </Col>
              <Col md={8} sm={12} xs={24}>
                <Card style={CardStyleCss}>
                  <div className="display-flex-property-between">
                    <Col>
                      <h6 className="title-color-css">
                        30% off on your first Consultation
                      </h6>
                      <Button className="code-coupun-class">
                        Code: Health 30
                      </Button>
                    </Col>
                    <img
                      alt="example"
                      className="female-doctor-css"
                      src={femaleDoctor}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="ourServicesContainer">
            <div className="ourServicesHeaderPart">
              <h4 className="ourServicesTitle">Shops by Category</h4>
              <p
                data-test-id="featureTherapyId"
                className="ourServicesSubTitle"
                onClick={this.handleTherapiesData}
              >
                {orderMedicineData.length <= 6 ? "See All" : "View Less"}
              </p>
            </div>
            <Row gutter={[8, 16]}>
              {orderMedicineData.map((item: any, index: number) => {
                return (
                  <Col
                    xl={4}
                    lg={6}
                    md={8}
                    sm={12}
                    xs={24}
                    key={item.id + "weesada"}
                    onClick={() => this.navigateToDetailPage(item.id)}
                  >
                    <div>
                      <div className="shortTrendingCard" key={index + 123}>
                        <img
                          alt="example"
                          style={{ width: "175px", height: "98px" }}
                          src={
                            item.attributes.body_part_image
                              ? config + item.attributes.body_part_image
                              : ""
                          }
                        />
                        <p style={{ marginLeft: "20px" }}>
                          {item.attributes.body_part_name}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          {this.renderOrderCarouselSecondPart()}
          {this.renderOurBrands()}
          <div className="ourServicesContainer">
            <div className="ourServicesHeaderPart">
              <h2 className="ourServicesTitle">{"Shops by Symptoms"}</h2>
              <p
                data-test-id="symptomsId"
                className="ourServicesSubTitle"
                onClick={this.handleSymptonDataSlide}
              >
                {dataSympList.length <= 6 ? "See All" : "View less"}
              </p>
            </div>
            <Row gutter={[8, 16]}>
              {dataSympList.map((item: any, index: any) => {
                return (
                  <Col
                    xl={4}
                    lg={6}
                    md={8}
                    sm={12}
                    xs={24}
                    key={item.id + "ushgsa"}
                  >
                    <div>
                      <div className="shortTrendingCard">
                        <img
                          alt="example"
                          className=""
                          src={
                            item.attributes.symptom_image
                              ? config + item.attributes.symptom_image
                              : ""
                          }
                        />
                        <p className="margin-left-sympton">
                          {item.attributes.symptom_name}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <FooterCardComponent />
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default OrderMedicinePage;
export { OrderMedicinePage };
// Customizable Area End

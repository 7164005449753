import React from "react";
// Customizable Area Start
import { Card, Row, Button, Col } from "antd";
import { Link } from "react-router-dom";
import PatientOutLet from "./ProfileOutlet.web";
import { Plusimg, editimg, editname, deleteimag, deletename } from "./assets";
import PatientManageAddressPageController from "./PatientManageAddressPagecontroller.web";
let config = require("../../../framework/src/config.js").baseURL;
import { RiDeleteBinLine } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
// Customizable Area End

class PatientManageAddressPage extends PatientManageAddressPageController {
  // Customizable Area Start
  renderNavigationTitles = () => {
    return (
      <div>
        <PatientOutLet navigation={undefined} {...this.props} selectedKey="3">
          <h3 style={{ width: 400, marginTop: 50,marginBottom:"24px" }}>Manage Address</h3>
          <Row gutter={[16,24]}>
            {this.state.manageAdressList && this.state.manageAdressList.map((item) => (
              <Col  xs={24} sm={12} md={12} lg={12} xl={8}>
                <Card style={{ width: "100%",position:'relative' }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space between",
                      }}
                    >
                      <h6>{item.attributes.full_name}</h6>
                      <div style={{position:"absolute",display:"flex",gap:"20px",right:20}} >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.props.history.push({
                              pathname: "/UpdateManageAddressPage",
                              state: {
                                addressData: item,
                              },
                            })
                          }
                          data-test-id="updateAddress"
                        >
                          <MdEdit style={{ color: "#3a78bf", fontSize: "18px", }} />
                        </div>

                        <div
                          style={{ cursor:"pointer"}}
                          onClick={() => this.deleteManageAdrressDetailList(item.id)}
                          data-test-id="deleteAddress"
                        >
                          <RiDeleteBinLine style={{ color: "#3a78bf", fontSize: "18px", }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>{item.attributes.building_name},</p>
                  <p>{item.attributes.city}</p>
                  <p>
                    {item.attributes.state}- {item.attributes.pincode}
                  </p>
                </Card>
              </Col>
            ))}
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
            }}
          >
            <Link to="/PatientProfileAddressPage">
              <Button
                type="default"
                icon={
                  <img
                    src={Plusimg}
                    alt=""
                    style={{ height: "45px", width: "45px" }}
                  />
                }
                style={{
                  color: "#498ECC",
                  backgroundColor: "White",
                  borderRadius: "10px",
                  height: "50px",
                }}
              >
                Add New Address
              </Button>
            </Link>
          </div>
        </PatientOutLet>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return <>{this.renderNavigationTitles()}</>;
  }
  // Customizable Area End
  // Customizable Area Start

  // Customizable Area Start
}
// Customizable Area Start

export default PatientManageAddressPage;
export { PatientManageAddressPage };

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config.js");

// Customizable Area Start
export interface OurServicesType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    service_image: string;
  };
}
// Customizable Area End

// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End

// Customizable Area Start
export interface TrendingType {
  id: string;
  type: string;
  attributes: {
    id: number;
    featured_therapy_id: number;
    therapy_name: string;
    therapy_images: Array<HospitalsImagesType>;
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ourServicesData: Array<OurServicesType>;
  symptomsList: Array<any>;
  featureTherapiesData: Array<TrendingType>;
  trendingTherapiesDataSliced: boolean;
  SymptomDataSliced: boolean;
  ourServicesDataSliced: boolean;
  showPopup: boolean;
  isLoginUser:boolean;
  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class LandingpageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOurServicesApiCallId: string | null = null;
  getSymtomsApiCallId: string | null = null;
  getFetatureTherapiesApiCallId: string | null = null;
  // Customizable Area Enddata:

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      ourServicesData: [],
      symptomsList: [],
      featureTherapiesData: [],
      trendingTherapiesDataSliced: true,
      SymptomDataSliced: true,
      ourServicesDataSliced: false,
      showPopup:false,
      isLoginUser: true
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    localStorage.removeItem("doctor_id");
    localStorage.removeItem("doctor_category")
    window.scrollTo(0, 0);
    this.getOurServicesShow();
    this.getSymptomsShow();
    this.getfeaturedTherapiesShow();
  
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isValidOKResponse(responseJson)) {
        this.apiSuccessOKCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidOkResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidOKResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidOkResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessOKCallBacks = (apiRequestCallId: any, responseJson: any) => {
    console.log("@@@ iresponseJson", responseJson);
    if (apiRequestCallId === this.getOurServicesApiCallId) {
      this.getOurServicesDataSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getSymtomsApiCallId) {
      this.getSymptomsSuccessDataCallBack(responseJson);
    }
    if (apiRequestCallId === this.getFetatureTherapiesApiCallId) {
      this.getTrendingTherapiesDataSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getOurServicesApiCallId) {
      this.getOurServicesFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  handleServicesData = () => {
    this.setState({
      ourServicesDataSliced: !this.state.ourServicesDataSliced,
    });
  };

  handleTherapiesData = () => {
    this.setState({
      trendingTherapiesDataSliced: !this.state.trendingTherapiesDataSliced,
    });
  };

  handleSymptonData = () => {
    this.setState({
      SymptomDataSliced: !this.state.SymptomDataSliced,
    });
  };

  getOurServicesShow = async () => {
    this.getOurServicesApiCallId = ApiCall({
      contentType: configJSON.getOurservicesApiContentType,
      method: configJSON.getOurservicesAPiMethod,
      endPoint: `${configJSON.getOurservicesAPiEndPoint}?is_request=web`,
    });
  };

  getOurServicesDataSuccessCallBack = (res: any) => {
    this.setState({
      ourServicesData: res.data,
    });
  };
  getOurServicesFailureCallBack = (err: any) => {
    console.log("@@@----error service", err);
  };
  getSymptomsShow = async () => {
    this.getSymtomsApiCallId = ApiCall({
      contentType: configJSON.getTopDoctorsApiContentType,
      method: configJSON.getTopDoctorsAPiMethod,
      endPoint: `${configJSON.getSymptomps}?is_request=web`,
    });
  };
  getSymptomsSuccessDataCallBack = (res: any) => {
    this.setState({
      symptomsList: res.data,
    });
  };
  getfeaturedTherapiesShow = async () => {
    this.getFetatureTherapiesApiCallId = ApiCall({
      contentType: configJSON.getfeaturedTherapiesApiContentType,
      method: configJSON.getfeaturedTherapiesAPiMethod,
      endPoint: `${configJSON.getfeaturedTherapiesAPiEndPoint}?is_request=web`,
    });
  };
  getTrendingTherapiesDataSuccessCallBack = (res: any) => {
    this.setState({
      featureTherapiesData: res.data,
    });
  };
  navigateToTherapyPage = (id: any, name: string) => {
    this.props.history.push({
      pathname: "/Therapies",
      state: { ids: { therapyListID: id }, navigateList: name },
    });
   
  };
  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
} 

handleLogout = () => {
    localStorage.clear();
    notification["success"]({
        message: "Logout succesfully",
      });
}
}
// Customizable Area End

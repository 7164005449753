import React from 'react'
// Customizable Area Start
import { Row, Col } from "antd";
import LoginOtpController from "./LoginOtpController.web";
import CarouselCommonComponet from '../../../components/src/CarouselCommonComponet.web';
import { Link } from 'react-router-dom';
import OtpInput from "react-otp-input";
import OTPCounter from '../../../components/src/OtpCounter.web';
// Customizable Area End

export default class LoginOtp extends LoginOtpController {
    // Customizable Area Start
    renderLoginOtpForm = () => {
        return (
            <div className="signIn-container">
                <form className="signIn-form-style">
                    <p className="signIn-heading">Login</p>
                    {
                        this.state.doctorLoginOtpVerify ?
                            <p className="signIn-text">
                                We've sent you the verification code on your Email Id / Mobile No.
                            </p> :
                            <p className="signIn-text">
                                We've sent you the verification code on your Mobile No.
                            </p>
                    }
                    <p>Enter OTP</p>
                    <OtpInput
                        data-test-id="txtInputOtp"
                        onChange={this.state.doctorLoginOtpVerify ? this.handleDoctorOTPChange : this.handleOTPChange}
                        value={this.state.doctorLoginOtpVerify ? this.state.doctorLoginOtpVerify : this.state.loginOtpVerify}
                        numInputs={6}
                        containerStyle={"otp-style"}
                        inputStyle={"input-style"}
                        renderInput={(props) => <input {...props} />}
                    />
                    {this.state.invalidLoginOtp ? (
                        <p className="error-password">Please enter a valid OTP</p>
                    ) : (
                        ""
                    )}
                    <div className="horizonatal-login-otp-ruler" />
                    <div className="login-footer-container">
                        <p className="login-otp-notify-para"
                          
                        >
                            Didn't Receive the Code?&nbsp;
                            {this.state.IsLoginotpExpired ? (
                                <label
                                    data-test-id="callresendOtpId"
                                    className="otp-again"
                                    onClick={this.state.doctorLoginOtpVerify ? this.getResendDoctorLoginOtp : this.resendPatientOtp}
                                    >
                                    Resend OTP
                                </label>
                            ) : (
                                <OTPCounter
                                    data-test-id={"resendOtpId"}
                                    OtpFinishTime={this.OtpLoginFinishTime}
                                    Otptime={this.state.loginOtptime}
                                    className="otp-timer"
                                />
                            )}

                        </p>
                        <button data-test-id="buttonsubmit" type='button' className="login-button" onClick={this.state.doctorLoginOtpVerify ? this.getDoctorVerifyLoginOtp : this.getVerifyLoginOtp}>Login</button>
                    </div>
                </form>
                {this.renderLinkSignUp()}
            </div>
        );
    };
    // Customizable Area End

    // Customizable Area Start
    renderLinkSignUp = () => {
        return (
            <div className="logIn-otp-bottom-text">
                <p>Don't have an account? </p>
                <Link to="/signup">
                    <p className="sign-up-navigation">Sign Up</p>
                </Link>
            </div>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            <>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <CarouselCommonComponet />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        {this.renderLoginOtpForm()}
                    </Col>
                </Row>
            </>
        );
    }
    // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import { Rate } from 'antd';
import { rupay } from "../../blocks/splashscreen/src/assets";
let config = require('../../framework/src/config.js').baseURL;
import {  Default } from "../../../packages/blocks/splashscreen/src/assets";
// Customizable Area End

type DoctorsCommonComponentProps = {
    // Customizable Area Start
    handleDoctorsDataList: any,
    dataList: any,
    heading: any,
    // Customizable Area End
};

const DoctorsCommonComponent = ({
    // Customizable Area Start
    handleDoctorsDataList,
    dataList,
    heading,
    // Customizable Area End
}: DoctorsCommonComponentProps) => {
    // Customizable Area Start
console.log("dataList",dataList);
    return (
        <>
            <div className='ourServicesContainer marginCarousel'>
                <div className='ourServicesHeaderPart'>
                    <h2 className='ourServicesTitle'>{heading}</h2>
                    <p className='ourServicesSubTitle' onClick={() => handleDoctorsDataList()}>
                        {
                            dataList.length <= 3 ? "See All" : "View less"
                        }
                    </p>
                </div>
                <Row className='therapyRow' gutter={[20, 20]}>
                    {dataList.map((item: any) => {

                        return (
                            <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                                <div className='doctorsNearbyCard'>
                                    <div className='doctorsNearbyCardImgBox'>
                                        {
                                            heading === "Top Doctors Nearby" || heading === "Top Cardiologist" || heading === "Top Orthopaedic Doctors" ?
                                                <img src={ item?.attributes?.profile_image? config + item?.attributes?.profile_image :Default } className='doctorsNearbyCardImg' />
                                                :
                                                heading === "Lab Tests" ?
                                                    <img src={config + item?.attributes?.lab_image} className='doctorsNearbyCardImg' />
                                                    :
                                                    heading === "Radiology Tests" ?
                                                        <img src={config + item?.attributes?.radiology_image} className='doctorsNearbyCardImg' />
                                                        : ""
                                        }
                                    </div>
                                    <div className='doctorsNearbyCardText'>
                                        {
                                            heading === "Top Doctors Nearby" || heading === "Top Cardiologist" || heading === "Top Orthopaedic Doctors" ?
                                                <>
                                                    <h2 className='docNameTitle'>{item?.attributes?.full_name}</h2>
                                                    <p className='doc_specilization'>{item?.attributes?.specialization}</p>
                                                    <p className='doctext'>{item?.attributes?.experience ?  item?.attributes?.experience + "years of experience" : "" }</p>
                                                </>
                                                :
                                                heading === "Lab Tests" || heading === "Radiology Tests" ?
                                                    <>
                                                        <h2 className='docNameTitle'>{item?.attributes?.name}</h2>
                                                        <p className='doctext'>No. of Tests :&nbsp;{item?.attributes?.total_no_of_test}</p>
                                                        <p className='doctext'><img src={rupay} className="rupay"/>&nbsp;{item?.attributes?.price}&nbsp;onwards
                                                        </p>
                                                    </> : ""
                                        }
                                        {
                                            heading === "Lab Tests" || heading === "Radiology Tests" ? "" :
                                            <div className='rateBox'>
                                            <div>
                                                <span className='ratingCount'>{item?.attributes?.rating}</span>
                                                <Rate allowHalf value={item?.attributes?.rating_average} disabled />
                                            </div>
                                            {
                                                item?.attributes?.reviews_count && (
                                                    <p className='reviewText'>{item?.attributes?.reviews_count}&nbsp; {item?.attributes?.reviews_count ===1 ? "review":"reviews"}</p>
                                                )
                                            }

                                        </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default DoctorsCommonComponent
// Customizable Area End

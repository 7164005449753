import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config.js");

// Customizable Area Start
type ManageAddress = {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: string;
    building_name: string;
    pincode: string;
    city: string;
    state: string;
    area: string;
    landmark: string;
    created_at: string;
    updated_at: string;
    patient_id: number;
    phone_number: string;
    is_default: boolean;
  };
};
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  manageAdressList: ManageAddress[];
  offerSlices: boolean;
  addionalSlices: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class PatientManageAddressPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getManageAddressDetailsApiCallId: string | null = null;
  deleteManageAddressDetailsApiCallId: string | null = null;

  // Customizable Area Enddata:

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      manageAdressList: [],
      offerSlices: true,
      addionalSlices: true,

      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getManageAdrressDetailDetailsList();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.oKResponses(responseJson)) {
        this.apiSuccessOKCalledBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  oKResponses = (responseJson: any) => {
    return responseJson;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessOKCalledBacks = (apiRequestCallId: any, responseJson: any) => {
    console.log("@@@ iresponseJson", responseJson);
    if (apiRequestCallId === this.getManageAddressDetailsApiCallId) {
      this.getManageArressDetailsDataSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteManageAddressDetailsApiCallId) {
      this.getManageAdrressDetailDetailsList();
      notification["success"]({
        message: "Your address has been deleted successfully"
    });
    }
  };
  // Customizable Area End

  getManageAdrressDetailDetailsList = async () => {
    this.getManageAddressDetailsApiCallId = ApiCall({
      contentType: configJSON.getManageAddressApiContentType,
      method: configJSON.getManageAddressAPiMethod,
      endPoint: `${configJSON.getManageAddressAPiEndPoint}`,
    });
  };

  deleteManageAdrressDetailList = async (id: string) => {
    const payLoad = {
      id: id,
    };
    this.deleteManageAddressDetailsApiCallId = ApiCall({
      contentType: configJSON.deleteManageAddressApiContentType,
      method: configJSON.deleteManageAddressAPiMethod,
      endPoint: `${configJSON.deleteManageAddressAPiEndPoint}`,
      body: payLoad,
    });
  };

  getManageArressDetailsDataSuccessCallBack = (res: any) => {
    this.setState({
      manageAdressList: res.data,
    });
  };
}
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as H from "history";
import { notification } from "antd";
import firebase from "firebase";
let config = require('../../../framework/src/config.js').baseURL;
import {ChangeEvent} from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: object;
  data: Array<object>;
}

export interface PatientList {
  attributes: {
      first_name: string | null;
      last_name: string | null;
      full_name: string;
      full_phone_number: string;
      email_id: string;
      gender: string;
      weight: string;
      city: string;
      health_id: string;
      profile_photo: string;
      patient_id: number;
  };
}

export interface PatientListType {
  first_name: string | null;
  last_name: string | null;
  full_name: string;
  full_phone_number: string;
  email_id: string;
  gender: string;
  weight: string;
  city: string;
  health_id: string;
  profile_photo: string;
  patient_id: number;
  username: string;
  avatar: string;
}

export interface PatientData {
  data : Array<PatientList>
}

export interface MessageData {
  _id: number;
  createdAt: number;
  read: boolean;
  sender: string;
  text?: string;
  file?: string;
  type?: string;
}

interface Consultation {
  id: number;
  consultation_category: string;
  consultation_type: string;
  price: string;
  created_at: string;
  updated_at: string;
  description: string;
  status: boolean;
  created_by: string;
  doctor_id: number;
}

interface WeeklyAvailability {
  id: number;
  service_provider_id: number | null;
  start_time: string | null;
  end_time: string | null;
  unavailable_start_time: string | null;
  unavailable_end_time: string | null;
  availability_date: string | null;
  created_at: string;
  updated_at: string;
  timeslots: string | null;
  available_slots_count: number | null;
  doctor_id: number;
  day_of_week: string[];
  mode_type: string;
  status: boolean;
}

interface Doctor {
  data: {
    id: string;
    type: string;
    attributes: {
      image: string;
      gender: string | null;
      qualification: string;
      date_of_birth: string | null;
      is_kyc: string;
      hospital_admin_id: string | null;
      hospital: string | null;
      doctor_certificates: string[];
      profile_image: string;
      first_name: string | null;
      last_name: string | null;
      full_name: string;
      full_phone_number: string;
      medical_representative_name: string;
      representative_contact_no: string;
      experience: string;
      doctor_category: string;
      pin: number;
      degree_details: string[];
      language: string[];
      unique_auth_id: string | null;
      type: string | null;
      user_type: string;
      email: string;
      activated: boolean;
      device_detail: [];
      fees: number;
      online_consultation: Consultation[];
      inclinic_consultation: Consultation[];
      identity_details: string[];
      registration_details: string[];
      registration_no: string;
      registration_council: string;
      year: string;
      specialization: string;
      city: string;
      provider: string | null;
      about: string;
      doctor_availiablity_today: boolean;
      clinic_addresses: string[];
      device_language: string | null;
      service_offered: [];
      clinic_details: string[];
      doctor_id: number;
      text_size: string;
      mode: string;
      rating_average: number;
      reviews: [];
      reviews_count: number | null;
      doctor_weekly_availabilities: WeeklyAvailability[];
    }
  }
}

export interface DctorChatList {
    username: string;
    avatar: string;
    registration_council: string;
    year: string;
    specialization: string;
    city: string;
    medical_representative_name: string;
    representative_contact_no: string;
    experience: string;
    doctor_category: string;
    pin: number;
    language: string[];
    doctor_availiablity_today: boolean;
    clinic_addresses: string[];
    doctor_weekly_availabilities: WeeklyAvailability[];
    unique_auth_id: string | null;
    email: string;
    registration_no: string;
    qualification: string;
    online_consultation: Consultation[];
    date_of_birth: string | null;
    is_kyc: string;
    hospital_admin_id: string | null;
    hospital: string | null;
    doctor_certificates: string[];
    profile_image: string;
    reviews_count: number | null;
    device_detail: [];
    about: string;
    activated: boolean;
    device_language: string | null;
    service_offered: [];
    image: string;
    gender: string | null;
    first_name: string | null;
    last_name: string | null;
    full_name: string;
    full_phone_number: string;
    degree_details: string[];
    type: string | null;
    user_type: string;
    registration_details: string[];
    clinic_details: string[];
    doctor_id: number;
    text_size: string;
    mode: string;
    rating_average: number;
    reviews: [];
    identity_details: string[];
    provider: string | null;
    fees: number;
    inclinic_consultation: Consultation[];
}

export interface FreindsData {
  chatroomId: string;
  username: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  value: number;
  patientList: PatientData;
  doctorDetails: Doctor;
  doctorChatDetails: DctorChatList;
  patientChatDetails: PatientListType;
  chatRoomId: string;
  messages: Array<MessageData>;
  messageText: string;
  chatBox: boolean;
  filePath: string;
  contentHeight: number;
  isLoader: boolean;
  showPopup: boolean;
  isChatMessage: boolean;
  fileType: string;
  isData: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getPatientsListCallId: string = "";
  getDoctorDetailsCallId: string = "";
  subscribeMessageListener: firebase.database.Reference | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      value: 0,
      patientList: {
        data: [
          {
            attributes: {
          first_name: "",
          last_name: "",
          full_name: "",
          full_phone_number: "",
          email_id: "",
          gender: "",
          weight: "",
          city: "",
          health_id: "",
          profile_photo: "",
          patient_id: 0
          }
          }
        ]
      },
      doctorDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            first_name: "",
            last_name: "",
            full_name: "",
            full_phone_number: "",
            email: "",
            registration_no: "",
            registration_council: "",
            year: "",
            specialization: "",
            city: "",
            medical_representative_name: "",
            representative_contact_no: "",
            experience: "",
            doctor_category: "",
            pin: 0,
            language: [],
            unique_auth_id: null,
            type: null,
            user_type: "",
            provider: null,
            about: "",
            activated: false,
            device_language: null,
            service_offered: [],
            image: "",
            gender: "",
            qualification: "",
            date_of_birth: null,
            is_kyc: "",
            hospital_admin_id: null,
            hospital: null,
            doctor_certificates: [],
            profile_image: "",
            identity_details: [],
            degree_details: [],
            registration_details: [],
            clinic_details: [],
            doctor_id: 0,
            text_size: "",
            mode: "",
            rating_average: 0,
            reviews: [],
            reviews_count: null,
            device_detail: [],
            fees: 0,
            online_consultation: [],
            inclinic_consultation: [],
            doctor_availiablity_today: false,
            clinic_addresses: [],
            doctor_weekly_availabilities: []
          }
        }
      },
      doctorChatDetails: {
        username: "",
        avatar: "",
        first_name: "",
        last_name: "",
        full_name: "",
        full_phone_number: "",
        email: "",
        registration_no: "",
        registration_council: "",
        year: "",
        specialization: "",
        city: "",
        medical_representative_name: "",
        representative_contact_no: "",
        experience: "",
        doctor_category: "",
        pin: 0,
        language: [],
        unique_auth_id: null,
        type: "",
        user_type: "",
        provider: null,
        about: "",
        activated: false,
        device_language: null,
        service_offered: [],
        image: "",
        gender: "",
        qualification: "",
        date_of_birth: "",
        is_kyc: "",
        hospital_admin_id: null,
        hospital: null,
        doctor_certificates: [],
        profile_image: "",
        identity_details: [],
        degree_details: [],
        registration_details: [],
        clinic_details: [],
        doctor_id: 0,
        text_size: "",
        mode: "",
        rating_average: 0,
        reviews: [],
        reviews_count: null,
        device_detail: [],
        fees: 0,
        online_consultation: [],
        inclinic_consultation: [],
        doctor_availiablity_today: false,
        clinic_addresses: [],
        doctor_weekly_availabilities: []
      },
      patientChatDetails: {
        first_name: "",
        last_name: "",
        full_name: "",
        full_phone_number: "",
        email_id: "",
        gender: "",
        weight: "",
        city: "",
        health_id: "",
        profile_photo: "",
        patient_id: 0,
        username: "",
        avatar: ""
    },
      chatRoomId:"",
      messages: [],
      messageText: "",
      chatBox: false,
      filePath: "",
      contentHeight: 0,
      isLoader: false,
      showPopup: false,
      isChatMessage: false,
      fileType: "",
      isData: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight});
    }
    this.getPatientListfunction();
    super.componentDidMount();
    this.getDoctorDetails();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatView");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const accountId: number = messageData?.meta?.id;
      this.setState({ accountId });
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }

    if (this.validResponse(responseJson)) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
  }

  handletabItemChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  apiCall = (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body } = apiData;
    const token = localStorage.getItem("token");
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let header = {
      "Content-Type": contentType,
      token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  validResponse = (responseJson: ValidResponseType) => {
    return (responseJson);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: object & PatientData & Doctor) => {
    if (apiRequestCallId === this.getPatientsListCallId) {
      this.getPatientListSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorDetailsCallId) {
      this.getDoctorSuccessCallBack(responseJson);
    }
  };

  getPatientListfunction = async () => {
    this.getPatientsListCallId = this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.patientListApiEndPoint
    });
    this.setState({isLoader: true});
  };

  getPatientListSuccessCallBack = (response: PatientData) => {
    this.setState({
      isLoader: false,
      isData: response.data ? false: true,
      patientList: response
    }); 
  };

  getDoctorDetails = () => {
    this.getDoctorDetailsCallId = this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.doctorApiEndPoint
    }); 
  };

  getDoctorSuccessCallBack = (response: Doctor) => {
    this.setState({
      doctorDetails:response
    },() => {
      this.handleOpenMessage();
    });
  };

  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handlePopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  handleDoctorDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleEditDoctorProfile = () => {
    this.props.history.push("/editdoctordetails");
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  handleOpenMessage = () => {
    const userDetails = this.state.doctorDetails.data;
    const userNameFromMail = "doctor_" + userDetails.attributes.doctor_id;
    const userRef = firebase.database().ref(`users/${userNameFromMail}`);
    userRef.once('value', (snapshot) => {
      const user = snapshot.val() || null;
      if (user) {
        this.setState({
          doctorChatDetails: user
        });
      } else {
        const newUserObj = {
          username: userNameFromMail,
          avatar: userDetails.attributes.profile_image.length > 0 ? config + userDetails.attributes.profile_image  : "https://i.imgur.com/IUw5yWb.png",
          ...userDetails.attributes
        };
        userRef.set(newUserObj);
      }
    });

    userRef.on('value', (snapshot) => {
      const snapData = snapshot.val();
      this.setState({
        doctorChatDetails: snapData
      });
    });
};

  patientRegister = (patientDetails: PatientList) => new Promise<void>(async (resolve) => {
    const userDetails = patientDetails;
    const userNameFromMail = "patient_" + userDetails.attributes.patient_id;
    const userRef = firebase.database().ref(`users/${userNameFromMail}`);
    userRef.once('value', (snapshot) => {
      const user = snapshot.val() || null;
      if (user) {
        resolve(
          this.setState({
            patientChatDetails: user,
            chatBox: true,
            messages: []
          })
        )
      } else {
        const newUserObj = {
          username: userNameFromMail,
          avatar: userDetails.attributes.profile_photo.length > 0 ? config + userDetails.attributes.profile_photo : "https://i.imgur.com/IUw5yWb.png",
          ...userDetails.attributes
        };
        userRef.set(newUserObj);
      }
    });

    userRef.on('value', (snapshot) => {
      const snapData = snapshot.val();
      resolve(
        this.setState({
          patientChatDetails: snapData,
          chatBox: true,
          messages: []
        })
      )
    });
  });

  addPatientToDoctorChat = () => {
    return new Promise(async (resolve) => {
      try {
        const database = firebase.database();
        const { doctorChatDetails, patientChatDetails } = this.state;
        const ltsDrDetailsSnapshot = await database.ref(`users/${doctorChatDetails.username}`).once('value' , () => {});
        const ltsPtDetailsSnapshot = await database.ref(`users/${patientChatDetails.username}`).once('value', () => {});
        const ltsDrDetails = ltsDrDetailsSnapshot.val();
        const ltsPtDetails = ltsPtDetailsSnapshot.val();
        const docFdData = ltsDrDetails?.friends || [];
        const patFdData = ltsPtDetails?.friends || [];
        let docInPatientList = docFdData.find((element: DctorChatList) => element.username === patientChatDetails.username);
        let patInDoctorList = patFdData.find((element: PatientListType) => element.username === doctorChatDetails.username);
        if (docInPatientList && patInDoctorList) {
          return resolve({
            ...ltsDrDetails,
            ...ltsPtDetails
          });
        } else if (docInPatientList && !patInDoctorList) {
          this.addUserToFdList(
            ltsPtDetails, patFdData, docInPatientList.chatroomId, ltsDrDetails.username
          );
          return resolve({
            ...ltsDrDetails,
            ...ltsPtDetails
          });
        } else if (!docInPatientList && patInDoctorList) {
          this.addUserToFdList(
            ltsDrDetails, docFdData, patInDoctorList.chatroomId, ltsPtDetails.username
          );
          return resolve({
            ...ltsDrDetails,
            ...ltsPtDetails
          });
        } else if (!docInPatientList && !patInDoctorList) {
          const newChatroomRef = database.ref('chatrooms').push({
            firstUser: ltsDrDetails.username,
            secondUser: ltsPtDetails.username,
            messages: [],
          });
          const newChatroomId = newChatroomRef.key;
          this.addUserToFdList(
            ltsPtDetails, patFdData, newChatroomId, ltsDrDetails.username
          );
          this.addUserToFdList(
            ltsDrDetails, docFdData, newChatroomId, ltsPtDetails.username
          );
          return resolve({
            ...ltsDrDetails,
            ...ltsPtDetails
          });
        }
      } catch (error) {
        notification["error"]({
          message: "Something went wrong",
        });
      }
    });
  };

  addUserToFdList(ltsDetails: PatientListType, userFriends: Array<FreindsData>, newChatroomId: string | null, userToAdd: string) {
    this.setState({isLoader: false});
    const userRef = firebase.database().ref(`users/${ltsDetails.username}`);
    const updatedFriends = [
      ...userFriends,
      {
        username: userToAdd,
        chatroomId: newChatroomId,
      },
    ];
    userRef.update({
      friends: updatedFriends,
    });
  };

validateAndGoToChat =  (patientData: PatientList) => {
  this.setState({
    isLoader: true,
    isChatMessage: true
  }, () => {
    this.patientRegister(patientData).then(() => {
      this.addPatientToDoctorChat().then(() => {
       this.getChatRoomId();
      })
    }
    )
  });
}; 

getChatRoomId = async () => {
  const database = firebase.database();
  const userRef = database.ref(`users/${this.state.doctorChatDetails.username}`);
  try {
    const snapshot = await userRef.once('value',() => {});
    const friendsListDr = snapshot.val().friends;
    let chatroomId = "";
    for (const element of friendsListDr) {
      if (element.username === this.state.patientChatDetails.username) {
                chatroomId = element.chatroomId;
      }
    }
      this.setState({
      chatRoomId: chatroomId,
      isLoader: false
    }, () => {
      this.loadData();
      this.subscribeMessageListener = database.ref(`chatrooms/${this.state.chatRoomId}`);
      this.subscribeMessageListener.on('value', (snapshot: firebase.database.DataSnapshot) => {
        snapshot.val();
        this.loadData();
      });
    });
  } catch (error) {
    notification["error"]({
      message: "Something went wrong",
    });
  }
};

loadData = async () => {                                                                                          
  const myChatroom = await this.fetchMessages();
  this.setState({ messages: this.renderMessages(myChatroom.messages) });
};

fetchMessages = async () => {
  try {
    const database = firebase.database();
    const snapshot = await database.ref(`chatrooms/${this.state.chatRoomId}`).once('value', () => {});
    return snapshot.val();
  } catch (error) {
    return null;
  }
};

renderMessages = (msgs: Array<MessageData>) => {
  let revMessgae: Array<MessageData> = [];
  msgs.forEach(element => {
    revMessgae.unshift(element);
  });
  return msgs
    ? revMessgae.map((msgObj, msgIndex: number) => ({
      ...msgObj,
      _id: msgIndex,
      user: {
        _id:
          msgObj.sender === this.state.doctorChatDetails.username
            ? this.state.doctorChatDetails.username
            : this.state.patientChatDetails.username,
        avatar:
          msgObj.sender === this.state.doctorChatDetails.username
            ? this.state.doctorChatDetails.avatar
            : this.state.patientChatDetails.avatar,
        name:
          msgObj.sender === this.state.doctorChatDetails.username
            ? this.state.doctorChatDetails.username
            : this.state.patientChatDetails.username,
      },
    }))
    : [];
};

messageTextChange = (event: {target : {value : string}}) => {
  let messageText = event?.target.value;
  this.setState({
    messageText
  });
};

onSend = async (fullPath: string = "") => {
    try {
    const database = firebase.database();
    let objs = null;
    if(fullPath.length > 0){
      objs = {
        type: this.state.fileType,
        sender: this.state.doctorChatDetails.username,
        file: fullPath,
        read: false,
        createdAt: firebase.database.ServerValue.TIMESTAMP
      };
      let fileChatRoom = await this.fetchMessages();
      let fileLastmessage = fileChatRoom.messages || [];

      if (this.state.chatRoomId) {
        let fileRoomRef = database.ref(`chatrooms/${this.state.chatRoomId}`);
        await fileRoomRef.update({
          messages: [
            ...fileLastmessage,
            objs,
          ],
        });
      }
      this.setState({
        messageText: ""
      }, () => {
        this.loadData();
      });
    }
    if (this.state.messageText.length > 0) {
      objs = {
        text: this.state.messageText,
        sender: this.state.doctorChatDetails.username,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
        read: false,
      };
      const currentChatroom = await this.fetchMessages();
      const lastMessages = currentChatroom.messages || [];

      if (this.state.chatRoomId) {
        const chatroomRef = database.ref(`chatrooms/${this.state.chatRoomId}`);
        await chatroomRef.update({
          messages: [
            ...lastMessages,
            objs,
          ],
        });
      }
      this.setState({
        messageText: ""
      }, () => {
        this.loadData();
      });
    }
  } catch (error) {
    notification["error"]({
      message: "Something went wrong",
    });
  }
};

uploadImagesToFirebase = async (file: File) => {
  const fileName = file.name;
  let storage = firebase.storage();
    let reference;
  if(file.type.startsWith("image/")){
     reference = storage.ref(`/images/${fileName}`);
     this.setState({
      fileType:"images"
     });
  } else {
         reference = storage.ref(`/files/${fileName}`);
     this.setState({
      fileType:"files"
     });
  }
  try {
    const metaData = {
      contentDisposition: 'attachment'
    };
    const task = await reference.put(file,metaData);
    const fullPath = await task.ref.getDownloadURL(); 
    this.setState({ filePath: fullPath });
    this.onSend(fullPath);
  } catch (error) {
    notification["error"]({
      message: "Error in uploading file",
    });
  }
};

handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  if (event.target.files) {
    Array.from(event.target.files).forEach((file: File) => {
      if (file) {
        this.uploadImagesToFirebase(file);
      }
    });
  }
};

messageTimeFormate = (timestamp: number) =>{
  const date = new Date(timestamp);
  let timeConvert  = 5.5 * 60;
  const istTime = new Date(date.getTime() + timeConvert * 60000);
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dayOfWeek = daysOfWeek[istTime.getDay()];
  const hours = istTime.getHours();
  const minutes = istTime.getMinutes();
  const formattedDate = `${dayOfWeek.slice(0, 3)}, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return formattedDate;
};

handlekeyDown = (event: {target : {value : string}} & React.KeyboardEvent<HTMLInputElement>) => {
  if (event?.key === "Enter") {
    this.onSend();
  } else {
    this.messageTextChange(event);
  }
};

handleDownload = (fileUrl: string) => {
    const anchor = document.createElement('a');
    anchor.href = JSON.parse(fileUrl);
    anchor.download = "download";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(fileUrl)
};

handleNavigate = (patientId: number) => {
  this.props.history.push({pathname: "/chatview" , state: {patientId: patientId}})
};
  // Customizable Area End
}

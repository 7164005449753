import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    Select,
    MenuItem,
    Chip
} from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import PackageDetailsController, { Props } from "./PackageDetailsController.web";
import { MdArrowBack } from "react-icons/md";
// Customizable Area End

export default class PackageDetails extends PackageDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeader = () => {
        return (
            <>
                <HeaderTitle>
                    <MdArrowBack onClick={this.handleBackButton} data-test-id="backId"/> {this.state.editPackageName}
                </HeaderTitle>
            </>
        )
    };

    renderEditDurationContainer = () => {
        return (
            <SelectBox
                onChange={this.editDurationChange}
                fullWidth
                labelId="time-placeholder"
                MenuProps={{
                    getContentAnchorEl: null,
                    keepMounted: true,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    }
                }}
                value={this.state.editDuration}
                displayEmpty
                data-test-id="editDurationId"
                id="select-placeholder"
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select duration
                </MenuItem>
                {this.state.editDurationValue.map((item: any) => {
                    return (
                        <MenuItem value={item}>
                            {item}
                        </MenuItem>
                    )
                })
                }
            </SelectBox>
        )
    }

    renderPage = () => {
        return (
            <EditPageHeader>
                <Link to={"/doctorhome"}><PageHeader>Home</PageHeader></Link>
                <Link to={"/doctorschedule"}><PageHeader>Schedule</PageHeader></Link>
                <Link to={"/addpatient"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderSelectPackage = () => {
        return (
            <SelectBox
            onChange={this.handleSampleChange}
            fullWidth
            data-test-id="editSelectId"
            value={this.state.editSample}
            labelId="set-time-select-placeholder"
            id="set-time-select-placeholder"
            displayEmpty
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getContentAnchorEl: null,
                keepMounted: true
            }}
        >
            <MenuItem value="" disabled style={{display: "none"}}>
                Select sample
            </MenuItem>
            {this.state.selectValue.map((item: any) => {
                return (
                    <MenuItem value={item}>
                        {item}
                    </MenuItem>
                )
            })
            }
            </SelectBox>
        )
    };

    renderFullBodyCheckUp = () => {
        return (
            <>
                <PackageBox>
                    <PackaageLabel data-test-id="edotTextId">Package name :</PackaageLabel>
                    <PackageInput value={this.state.editPackageName} onChange={this.handleEditPackageName} data-test-id="editPackageName"/>
                    {this.state.editPackageNameErr && <ErrorTag>{this.state.editPackageNameErr}</ErrorTag>}
                    <PackaageLabel>No. of tests :</PackaageLabel>
                    <PackageInput value={this.state.editTestCount} onChange={this.handleEditTests} data-test-id="editTestCountId"/>
                    {this.state.editTestCountErr && <ErrorTag>{this.state.editTestCountErr}</ErrorTag>}
                    <PackaageLabel>Consultation Fee :</PackaageLabel>
                    <PackageInput value={this.state.editTestConsultationFees} onChange={this.handleEditFeesChange} data-test-id="editFeesId"/>
                    {this.state.editTestConsultationFeesErr && <ErrorTag>{this.state.editTestConsultationFeesErr}</ErrorTag>}
                    <PackaageLabel>Duration :</PackaageLabel>
                    {this.renderEditDurationContainer()}
                    {this.state.editDurationErr && <ErrorTag>{this.state.editDurationErr}</ErrorTag>}
                    <PackaageLabel>Description :</PackaageLabel>
                    <PackageInput value={this.state.editDescription} onChange={this.editDescriptionHandelChange} data-test-id="editDescriptionId"/>
                    {this.state.editDescriptionErr && <ErrorTag>{this.state.editDescriptionErr}</ErrorTag>}
                    <PackaageLabel>Sample Requirement :</PackaageLabel>
                    {this.renderSelectPackage()}
                    {this.state.editSampleErr && <ErrorTag>{this.state.editSampleErr}</ErrorTag>}
                    <ButtonBox onClick={this.editPackageFunction} data-test-id="editsaveId">Save</ButtonBox>
                </PackageBox>
            </>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handleShowPopupClose} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderPage()}
                    {this.renderHeader()}
                    {this.renderFullBodyCheckUp()}
                </ContainerBox>
                <FooterDoctor />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ChipBox = styled(Chip)({
    "&.MuiChip-root": {
        backgroundColor: "transparent !important",
        border: "1px solid #4d96dd",
        color: "#4d96dd"
    }
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const SelectBox = styled(Select)({
    marginBottom: "20px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingLeft: "10px",
        borderRadius: "5px",
    },
    "& .MuiOutlinedInput-input": {
        padding: "0",
    },
    "& .MuiSelect-selectMenu": {
        minHeight: "30px",
        display: "flex",
        alignItems: "center",
    },
});

const ChipContainer = styled(Box)({
    display: "flex",
    gap: "6px",
    flexWrap: "wrap",
    padding: "10px 0",
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const HeaderTitle = styled(Box)({
    fontSize: "20px",
    fontWeight: 500,
    margin: "10px 0"
});

const PackageBox = styled(Box)({
    display: "flex",
    flexDirection: "column"
});

const PackaageLabel = styled("label")({
    color: "grey",
    fontWeight: 500,
    marginBottom: "8px"
});

const PackageInput = styled("input")({
    border: "0",
    borderBottom: "1px solid #c6b7b7 !important",
    marginBottom: "15px"
});

const ButtonBox = styled("button")({
    borderRadius: "50px",
    backgroundColor: "#3a79c1",
    fontSize: "15px",
    padding: "10px",
    width: "150px",
    fontWeight: 500,
    border: "none",
    color: "#fff",
    display: "block",
    margin: "20px auto"
});

const ErrorTag = styled("p")({
    color: "red",
    fontSize: "14px",
    margin: "0 !important",
    width: "100%"
});
// Customizable Area End
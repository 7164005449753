import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Card, CardMedia, Grid } from "@material-ui/core";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment.web";

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
        marginTop: '50px'
      },
      // zIndex: -1,
      marginTop: '50px'
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      marginTop: '-45px',
      position: 'initial'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    media: {
      height: '70%',
      width: '85%',
      margin: '15px auto',
      display: 'flex'
    },
    highlighted: {
      backgroundColor: "#498ecc !important",
    },
  })
);

interface Props {
  madication: {
    id: number;
    name: string;
    image: any;
  }[];
  selectedIndex: number;
  childProps: any;
  mobileOpen: boolean;
  handleListItemClick: (index: number, text: string) => void;
  handleDrawerToggle: () => void;
  window?: () => Window;
  UserDetailList: (index: number, text: string) => void;
}

export default function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List style={{ paddingLeft: '30px' }}>
        {["Dashboard", "Manage Hospital", "Create a new user", "Payment management", "Setting", "logout", "Services"].map((text, index) => (
          <ListItem button key={text} selected={props.selectedIndex === index} onClick={() => props.handleListItemClick(index, text)} className={props.selectedIndex === index ? classes.highlighted : undefined}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;


  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={props.mobileOpen}
            onClose={props.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.childProps.location.pathname === "/AdminDashboard/Dashboard" ?
          <>
            <Typography style={Style.paragraph}>Select User</Typography>
            <Grid spacing={2} container style={{ marginTop: '15px' }}>
              {props.madication.map((data, index) => {
                return (
                  <>
                    <Grid item lg={3}>
                      <Card onClick={() => props.UserDetailList(index,data.name)} key={index} style={Style.card}>
                        <Typography style={Style.name}>{data.name}</Typography>
                        <img
                          className={classes.media}
                          src={data.image}
                        />
                      </Card>
                    </Grid>
                  </>
                )
              })}
            </Grid>
          </>
          : <>{props.childProps.location.pathname === "/AdminDashboard/Create a new user" ?
            <AddAppointment  navigation={undefined} id={""} history={props.childProps} /> :
            <> Comming Soon </>}</>}

      </main>
    </div>
  );
}

const Style = {
  paragraph: {
    fontWeight: 700,
    fontSize: '18px'
  },
  card: {
    padding: '15px 15px',
    height: '100%',
    cursor: 'pointer'
  },
  name: {
    fontSize: '22px',
    fontWeight: 600
  }
}

import React from "react";
// Customizable Area Start
import { Card, Row, Button, Steps, Col } from "antd";
import { Link } from "react-router-dom";

import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import DeliveryAddressPageController from "./DeliveryAddressPageController.web";
import { merilLogo } from "../../splashscreen/src/assets";
import {
  deleteIcon,
  shoppingcart,
  checklist,
  placeholder,
  getmoney,
  Plussymbol,
} from "./assets";
import FooterCardComponent from "../../shoppingcart/src/component/FooterCardComponent";
let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class DeliveryAddressPage extends DeliveryAddressPageController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <div>
        <div
          className="headerLogoBox"
          style={{ boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)" }}
        >
          <img className="headerLogoImg" src={merilLogo} />
        </div>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderDeleveryList = () => {
    return (
      <div>
        <h3 style={{ width: 400, marginTop: 50 }}>Delivery Address</h3>
        <Row gutter={[32, 24]}>
          {this.state.addressList.map((item) => (
            <Col span={8}>
              <Card style={{ width: 400, marginTop: 50 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space between ",
                    }}
                  >
                    <h6>{item.attributes.full_name}</h6>
                    <div style={{ marginLeft: "8rem" }}>
                    </div>
                  </div>
                </div>
                <p>{item.attributes.building_name},</p>
                <p>{item.attributes.city}</p>
                <p>
                  {item.attributes.state}- {item.attributes.pincode}
                </p>
                <div style={{ display: "flex" }}>
                  <img src={deleteIcon} style={{ height: "20px" }}></img>
                  {/* <img src={Delete}></img> */}
                  <p
                    style={{ color: "#B8B8D2" }}
                    onClick={() => this.deleteAdrressDetailList(item.id)}
                  >
                    Delete
                  </p>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        >
          <Link to="/NewAddressPage">
            <Button
              type="default"
              icon={<img src={Plussymbol} alt=""  style={{height:"45px", width:"45px"}}/>}
              style={{
                color: "#498ECC",
                backgroundColor: "White",
                border: "none",
              }}
            >
              Add New Address
            </Button>
          </Link>
        </div>
      </div>
    );
  };
  // Customizable Area En
  // Customizable Area Start
  render() {
    const itemstracker = [
      {
        title: "Cart",
        icon: <img src={shoppingcart} alt="" />,
      },
      {
        title: "Shopping",
        icon: <img src={checklist} alt="" />,
      },
      {
        title: "Address",
        icon: <img src={placeholder} alt="" />,
      },
      {
        title: "Payment",
        icon: <img src={getmoney} alt="" />,
      },
    ];
    return (
      <div>
        <div>
          <div className="box-with-shadow">
            <div
              style={{
                padding: "32px 6%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img src={merilLogo} alt="Meril Logo" />
              <div style={{ width: "27%" }}>
                <Steps
                  current={4}
                  items={itemstracker}
                  labelPlacement="vertical"
                ></Steps>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="itemContainerWiidth">
            {this.renderDeleveryList()}
            <div>
              <FooterCardComponent />
            </div>
          </div>
          <FooterCommonComponent />
        </div>
      </div>
    );
  }

  // Customizable Area Start
}
// Customizable Area Start

export default DeliveryAddressPage;
export { DeliveryAddressPage };

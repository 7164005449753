import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CarouselCommonComponet from '../../../components/src/CarouselCommonComponet.web';
import { Row, Col, Select, Input, Form, Button } from "antd";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CarouselCommonComponet />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="signIn-container">
              <Form
                data-test-id="formSubmitId"
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={this.getHospitalLoginOtp}
                autoComplete="off"
                requiredMark={false}
                className="signIn-form-style">
                <p className="signIn-heading">Login</p>
                <p className="signIn-text">
                  Welcome to Meril, please provide your login credentials below.
                </p>
                <Row className="">
                  <p className="form-email-text">Login as</p>
                </Row>
                <div className="selectItem">
                  <Select
                    data-test-id='btnLoginRoleId'
                    defaultValue={this.state.selectRole}
                    placeholder="Please select login role"
                    style={{ width: "100%" }}
                    onChange={this.handleSelectRole}
                  >
                    {this.state.RoleData.map((item) => (
                      <Select.Option value={item.role} key={item.id} name="btnSelectRole">
                        {item.role}
                      </Select.Option>
                    ))}
                  </Select>

                </div>
                <Row className="">
                  {
                    <p className="form-email-text">Enter your email id </p>
                  }
                </Row>
                <Form.Item
                  label=""
                  name="phonoNoName"
                  rules={[
                    {
                      required: true,
                      message: "Please input email id"
                    },
                    {
                      pattern: /^[6-9]\d{9}$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please input valid email id"
                    },
                  ]}
                >
                  <Input data-test-id="LoginEmail" onChange={this.handleHospitalLogin} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    data-test-id="btnsubmit"
                    className="genrate-otp-button"
                  >
                    Generate OTP
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
      // Customizable Area End
    );
  }
}

import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row, Button, Avatar } from "antd";
import { RightOutlined, pain, stroke } from "../../shoppingcart/src/assets";
import { Carousel } from "react-responsive-carousel";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import Text from "antd/lib/typography/Text";
import { CertificationComponent } from "../../../components/src/CertificationComponent.web";
import TherapiesDetailsController from "./TrendingTherapiesDetailsController.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
let config = require("../../../framework/src/config.js").baseURL;
import { MdArrowBack } from "react-icons/md";
// Customizable Area End

class TherapiesDetailspage extends TherapiesDetailsController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome" className="active">Home</Link>
          <Link to="/patient-appointment-landingpage">Appointments</Link>
          <Link to="/services"  >Services</Link>
          <Link to="/patienthome">Messages</Link>
        </div>
      </>
    );
  };

 renderNavigationTitleWithoutLogin = () => {
    return (
        <>
            <div className="navigationContainer">
                <Link to="/" >Doctor Appointment</Link>
                <Link to="/" >Surgical Procedures</Link>
                <Link to="/" >Therapies</Link>
                <Link to="/" >Lab Tests</Link>
            </div>
        </>
    )
}
  // Customizable Area End

  // Customizable Area Start

  renderTreatmentDetails = () => {
    return (
      <>
        <div className="ourServicesContainer">
          <div className="ourServicesHeaderPart margintop">
            <h2 className="ourServicesTitle">
              {this.state.therapiesDetails.attributes.therapy_name}
            </h2>
          </div>
          <>
            <Carousel
              interval={3000}
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              stopOnHover={false}
              className="secondCarousel"
            >
              {
                this.state.therapiesDetails.attributes.therapy_images?.map(
                  (terapyImage1) => (
                    <div key={terapyImage1.url}>
                      <img
                        className="carouselImage addCarouselImg"
                        src={config + terapyImage1.url}
                      />
                    </div>
                  )
                )}
            </Carousel>
          </>
        </div>
        <div style={{ margin: "3rem 0rem 4rem" }}>
          <Button type="primary" shape="round" data-test-id="getCompletePackage">
            Get Complete Package
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <div className="discription">
            <h2 className="angio-plastic-symptom-heading1"> Description</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: this.state.trendingTherapiesDetail.attributes?.description,
              }}
            ></p>
          </div>
          <div>
            <h2 className="angio-plastic-symptom-heading1">Symptoms</h2>
            <Row gutter={[16, 24]} style={{ width: "25%" }}>
              {
                this.state.trendingTherapiesDetail.attributes?.symptoms_name?.map(
                  (symtom:any) => (
                    <Col span={12} key={symtom.name}>
                      <Avatar
                        shape="circle"
                        size="small"
                        src={symtom.name === "Cough" ? pain : stroke}
                      />
                      <Text>{symtom.name}</Text>
                    </Col>
                  )
                )}
            </Row>
          </div>

          <div>
            <h2 className="angio-plastic-symptom-heading1">Diagnosis</h2>
            <Row>
              <ul className="angio-plastic-symptom-list">
                {
                  this.state.trendingTherapiesDetail.attributes?.diagnosis_name?.map(
                    (diagnosisItem:any) => <li key={diagnosisItem.name}>{diagnosisItem.name}</li>
                  )}
              </ul>
            </Row>
          </div>
          <div>
            <h2 className="angio-plastic-symptom-heading1">Treatment</h2>
            <ul className="angio-plastic-symptom-list">
              <li>{this.state.trendingTherapiesDetail.attributes?.treatment_id}</li>
            </ul>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    const isUserLogin = localStorage.getItem("isLogin")
    return (
      <div ref={this.myRef} >
         <NavbarCommonComponent data-test-id="navbarCommonComponentID" handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
        { isUserLogin ?  this.renderNavigationTitle() :this.renderNavigationTitleWithoutLogin() }

        {
            isUserLogin ? (
                <div
                className="navigation-list-css-container"
                style={{ paddingLeft: "5%" }}
              >
                <Text
                  className="navigation-list-css"
                  style={{ color: "#498ECC" }}
                  onClick={() => this.props.history.push("/patienthome")}
                  data-test-id="home-navigate-id"
                >
                  Home
                </Text>
                <img src={RightOutlined} alt="" style={{width:'10px'}}/>
                <Text
                  className="navigation-list-css"
                  data-test-id="sevices-navigate-id"
                  onClick={() => this.props.history.push("/patienthome")}
                >
                    Trending Therapy
                </Text>
              </div>
            ):(
                <div
                className="goback"
                style={{ paddingLeft: "5%" }}
              >
                <Text
                  className="navigation-list-css"
                  style={{ color: "#498ECC",fontSize:"18px" }}
                  onClick={() => this.props.history.push("/")}
                  data-test-id="home-navigate-id"
                >
                  <MdArrowBack  style={{ color: "#498ECC",fontSize:"28px",marginTop:"24px" }}/>
                </Text>
              </div>
            )
        }


        <div className="itemContainerWiidth">
          {this.renderTreatmentDetails()}

          <div>
            <CertificationComponent/>
          </div>
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default TherapiesDetailspage;
export { TherapiesDetailspage };
// Customizable Area End

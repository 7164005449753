import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import ApiCall from "../../../components/src/ApiCall.web";
import React from "react"
import { notification } from "antd";
// Customizable Area End
export const configJSON = require("./config.js");
// Customizable Area Start
export interface AppointmentImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export interface AppointmentListType {
  id: string;
  type: string;
  attributes: {
    id: number;
    doctor_full_name: string;
    patient_year: number;
    mode_type: string;
    doctor_gender: string;
    doctor_age: number;
    appointment_date: number;
    time_slot: number | string;
    appointment_type: string;
    doctor_profile_image: Array<AppointmentImagesType>;
  };
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  AppointmentlsDataList: Array<AppointmentListType>;
  bookedAppointmentDetail:any,
  isModalOpen:boolean,
  isrescheduleModalOpen:boolean,
  isCancellModalOpen:boolean,
  consultation_type:string,
  fees: number,
  mode_type: string,
  doctor_full_name:string,
  doctor_phone_number: string,
  appointment_date:string,
  time_slot:string,
  doctor_experience:string,
  doctor_category:string,
  doctor_profile_image:string,
  MultilineText:any,
  appointmentId:string,
  showPopup:boolean,
  consultation_category:string,
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class PatientAppointmentJoiningPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentlsApiCallId: string | null = null;
  cancelAppointmentApiCallId: string | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      AppointmentlsDataList: [],
      bookedAppointmentDetail:"",
      isModalOpen:false,
      isrescheduleModalOpen:false,
      isCancellModalOpen:false,
      consultation_type:"",
      mode_type:"",
      doctor_full_name:"",
      doctor_phone_number:"",
      fees:0,
      appointment_date:"",
      time_slot:"",
      doctor_experience:"",
      doctor_category:"",
      doctor_profile_image:"",
      MultilineText:[],
      appointmentId:"",
      showPopup:false,
      consultation_category:""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      doctor_full_name: this.props.location.state.item.attributes.doctor_full_name,
      appointment_date: this.props.location.state.item.attributes.appointment_date,
      consultation_type:this.props.location.state.item.attributes.consultation_type,
      time_slot:this.props.location.state.item.attributes.time_slot,
      doctor_experience:this.props.location.state.item.attributes.doctor_experience,
      fees:this.props.location.state.item.attributes.original_price, 
      doctor_category:this.props.location.state.item.attributes.doctor_category, 
      doctor_profile_image:this.props.location.state.item.attributes.doctor_profile_image,
      appointmentId:this.props.location.state.item.id,
      consultation_category:this.props.location.state.item.attributes.consultation_category,
    })
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId === this.cancelAppointmentApiCallId){
        if(responseJson.message){
          notification["success"]({
            message:`Your Appointment has been cancelled successfully`,
          });
          this.setState({isModalOpen:false,isrescheduleModalOpen:false,isCancellModalOpen:false});
          this.props.history.push("/PatientAppointmentsPage")
        }else{
          notification["error"]({
            message:`${responseJson.errors[0]}`,
          });
          this.setState({isModalOpen:false,isrescheduleModalOpen:false,isCancellModalOpen:false});
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
 
  // Customizable Area End

  cancelAppointment = async () => {
    this.cancelAppointmentApiCallId = ApiCall({
      contentType: configJSON.getAppointmentsApiContentType,
      method: configJSON.updateManageAddressAPiMethod,
      endPoint: `${configJSON.cancelAppointmentEndPoint}?id=${this.state.appointmentId}`,
    });
  };

  handleStartAppointments = () => {
    switch(this.state.consultation_type){
      case "Text Consultation":
        this.setState({isModalOpen:true});
        break;
      case "Phone Consultation":
        this.setState({isModalOpen:true});
        break;
      default:
        this.props.history.push({ pathname: "videoscreen", state: { item: this.props.location.state.item } }) 
    }   
  }

  handleModalClose=(event:any,reason:any)=>{
    if(reason !== 'backdropClick'){
      this.setState({isModalOpen:false,isrescheduleModalOpen:false,isCancellModalOpen:false});
    } 
  }

  handleCancelAppointment=(event:any,reason:any)=>{
    if(reason !== 'backdropClick'){
      this.cancelAppointment();
    } 
  }

  handleRescheduleAppointments=()=>{
    this.setState({isrescheduleModalOpen:true})
  }

  handleOpenCancelAppointmentModal=()=>{
    this.setState({isCancellModalOpen:true})
  }

  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleLogout=()=>{
    localStorage.clear();
    notification["success"]({
        message: "Logout succesfully",
      });
  };

  navigateToRescheduleAppointment=()=>{
    this.props.history.push({pathname:"/reschedule"});
    this.setState({isModalOpen:false});
    this.setState({isrescheduleModalOpen:false});
  }

  navigateToStartAppointment=()=>{
    this.props.history.push({pathname:"/startappointment"});
    this.setState({isrescheduleModalOpen:false});
    this.setState({isModalOpen:false});
  }

  navigateToCancellAppointment=()=>{
    this.props.history.push({pathname:"/cancelappointment"});
    this.setState({isCancellModalOpen:false});
    this.setState({isrescheduleModalOpen:false});
  }

  handleStartAppointment=()=>{
    localStorage.clear();
    notification["success"]({
        message: "Start appointment succesfully",
      });
  }

  handleRescheduleAppointment=()=>{
    localStorage.clear();
    notification["success"]({
        message: "Reschedule appointment succesfully",
      });
  }
  
}
// Customizable Area End
import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    MenuItem,
    Select as SelectTag
} from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import MypatientController, { Props } from "./MypatientController.web";
import { CgProfile } from "react-icons/cg";
import { IoIosAdd } from "react-icons/io";
import { DatePicker, Select } from "antd";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const { Option } = Select;
// Customizable Area End

export default class Addpatient extends MypatientController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeaderPage = () => {
        return (
            <EditPageHeader>
                <Link to={"/doctorhome"}><PageHeader>Home</PageHeader></Link>
                <Link to={"/doctorschedule"}><PageHeader>Schedule</PageHeader></Link>
                <Link to={"/addpatient"}><ActivePageHeader>Add Patient</ActivePageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderForm = () => {
        return (
            <>
                <PageHeading component={"h2"} data-test-is="addTextId">
                    Add Patient
                </PageHeading>
                <ProfileButton>
                    {
                        !this.state.patientImage ? <CgProfile /> : <ImageTag src={this.state.patientImage} />
                    }
                    <AddProfileButton htmlFor="identityDetailsFileInputBox">
                        <IoIosAdd />
                        <input type="file" id="identityDetailsFileInputBox" accept="image/*" style={{ background: "red", width: "30px", height: "42px", borderRadius: "50%" }} onChange={this.handlePatientImage} data-test-id="patientPicId" />
                    </AddProfileButton>
                </ProfileButton>
                <New container spacing={4}>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Full Name<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <CustomInput onChange={this.handlePatientName} value={this.state.patientFullName} data-test-id="patientNameId" placeholder='John lee' />
                        {this.state.patientNameErr && <ErrorTag>{this.state.patientNameErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Email Id<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <CustomInput onChange={this.handlePatientEmail} value={this.state.patientEmail} data-test-id="patientEmailId" placeholder="abc@gmail.com" />
                        {this.state.patientEmailErr && <ErrorTag>{this.state.patientEmailErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Phone no.<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <CustomInput onChange={this.handlePatientPhone} value={this.state.patientPhoneNum} data-test-id="patientNumId" placeholder="0000000000" />
                        {this.state.patientPhoneNumErr && <ErrorTag>{this.state.patientPhoneNumErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Date of Birth (YYYY-MM-DD)<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <CustomInputDate data-test-id="patientDateId" name='DOB' onChange={this.handleDate} disabledDate={this.disabledPastDate} />
                        {this.state.dateValueErr && <ErrorTag>{this.state.dateValueErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Gender<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <StyledSelect
                            data-test-id="patientgenderId"
                            fullWidth
                            variant="outlined"
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                keepMounted: true
                            }}
                            displayEmpty
                            IconComponent={ExpandMoreIcon}
                            value={this.state.patientGender}
                            onChange={this.handleGender}
                            renderValue={(value: any) => (value === '' ? <StyledPlaceholder>Select</StyledPlaceholder> : value)}
                        >
                            <CustomItem value={"Male"}>Male</CustomItem>
                            <CustomItem value={"Female"}>Female</CustomItem>
                        </StyledSelect>
                        {this.state.patientGenderErr && <ErrorTag>{this.state.patientGenderErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Weight (kg)<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <CustomInput onChange={this.handleWeight} value={this.state.weightValue} data-test-id="patientWeightId" placeholder="e.g. 150.50" />
                        {this.state.weightErr && <ErrorTag>{this.state.weightErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Blood Group<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <StyledSelect
                            data-test-id="patientBloodId"
                            fullWidth
                            variant="outlined"
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                keepMounted: true
                            }}
                            displayEmpty
                            IconComponent={ExpandMoreIcon}
                            value={this.state.bloodValue}
                            onChange={this.handleBlood}
                            renderValue={(value: any) => (value === '' ? <StyledPlaceholder>Select blood group</StyledPlaceholder> : value)}
                        >
                            <CustomItem value="A+">A+</CustomItem>
                            <CustomItem value="A-">A-</CustomItem>
                            <CustomItem value="B+">B+</CustomItem>
                            <CustomItem value="B-">B-</CustomItem>
                            <CustomItem value="O+">O+</CustomItem>
                            <CustomItem value="O-">O-</CustomItem>
                            <CustomItem value="AB+">AB+</CustomItem>
                            <CustomItem value="AB-">AB-</CustomItem>
                        </StyledSelect>
                        {this.state.bloodErr && <ErrorTag>{this.state.bloodErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>City<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <CustomInput onChange={this.handlePatientCity} value={this.state.patientCityName} data-test-id="citynameId" />
                        {this.state.patientCityErr && <ErrorTag>{this.state.patientCityErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Aadhar No.<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        <CustomInput onChange={this.handleAadharNum} placeholder='XXXXXXXXXXXX' data-test-id="patientAadharId" value={this.state.aadharNum} />
                        {this.state.aadharNumErr && <ErrorTag>{this.state.aadharNumErr}</ErrorTag>}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CustomInputLable>Disease (History or Current)<sup style={{ color: "red" }}>*</sup></CustomInputLable>
                        {this.renderDiseaseSelect()}
                        {this.state.diseaseNameErr && <ErrorTag>{this.state.diseaseNameErr}</ErrorTag>}
                    </Grid>
                </New>
                <CustomButton onClick={this.handleCreatePatient} data-test-id="addsavebuttonId">
                    Save
                </CustomButton>
            </>
        )
    };

    renderDiseaseSelect = () => {
        let children = this.state.allDiseaseData.data;
        return (
            <New>
                <Select
                    data-test-id="patientdiseasId"
                    mode="tags"
                    style={{ width: '100%', outline: "none", borderRadius: "5px", }}
                    tokenSeparators={[',']}
                    onChange={(value) => this.handleDiseaseChange(value)}
                    className= "selectDisease"
                    maxTagCount={5}
                >
                    {children?.map((item) => {
                        return (
                            <Option key={item.attributes.name}>{item.attributes.name}</Option>
                        )
                    })
                    }
                </Select>
            </New>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handlePopupClose} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderHeaderPage()}
                    {this.renderForm()}
                </ContainerBox>
                <FooterDoctor />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const New = styled(Grid)({
    "& .selectDisease": {
        border: "1px solid #808080",
        marginTop: "8px",
        "&.ant-select:not(.ant-select-disabled):hover .ant-select-selector": {
            borderColor: "#eee6e6 !important",
            outline: "none",
            borderWidth:"1px"
        },
    },
    "& .selectNoneDisease": {
        border: "1px solid #808080",
        marginTop: "8px",
        "&.ant-select-multiple .ant-select-selection-item": {
            display : "none !important"
        },
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#808080 !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5AA4F4 !important",
        borderWidth:"1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#808080"
    }
});

const CustomItem = styled(MenuItem)({
    "&.MuiListItem-root.Mui-selected":{
        backgroundColor:"#EBFAFE !important",
    }
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const PageHeading = styled(Box)({
    fontWeight: 500,
    marginTop: "10px",
    fontSize: "22px"
});

const CustomInputLable = styled("label")({
    fontSize: "14px",
    fontWeight: 400
});

const CustomInput = styled("input")({
    width: "100%",
    padding: "10px",
    border: "1px solid #808080",
    outline: "none",
    borderRadius: "5px",
    marginTop: "5px",
    boxSizing: "border-box",
    "&:focus":{
        borderColor:"#5AA4F4",
        borderWidth:"1px"
    }
});

const CustomInputDate = styled(DatePicker)({
    width: "100%",
    padding: "10px",
    border: "1px solid #808080",
    outline: "none",
    borderRadius: "5px",
    marginTop: "5px",
    boxSizing: "border-box"
});

const CustomButton = styled("button")({
    width: "300px",
    display: "block",
    textAlign: "center",
    padding: "10px",
    color: "#fff",
    backgroundColor: "#3b76bf",
    borderRadius: "35px",
    margin: "20px auto",
    border: "none",
    cursor: "pointer",
    "@media (max-width:400px)": {
        width: "200px"
    }
});

const ProfileButton = styled(Box)({
    height: "100px",
    width: "100px",
    borderRadius: "100px",
    color: "#fff",
    backgroundColor: "#3b76bf",
    margin: "10px 0",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "50px"
});

const AddProfileButton = styled("label")({
    height: "40px",
    width: "40px",
    position: "absolute",
    borderRadius: "40px",
    cursor: "pointer",
    top: -5,
    right: -5,
    border: "1px solid #fff",
    backgroundColor: "#3b76bf",
    fontSize: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff"
});

const ErrorTag = styled("p")({
    color: "red",
    fontSize: "14px",
    margin: "0 !important"
});

const ActivePageHeader = styled("p")({
    color: "#4d96dd",
    fontSize: "16px",
    fontWeight: 600
});

const StyledSelect = styled(SelectTag)({
    marginTop: "10px",
    height: "45px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingLeft: "10px",
        borderRadius: "5px",
    },
    "& .MuiSelect-selectMenu": {
        minHeight: "45px",
        display: "flex",
        alignItems: "center",
    },
    "& .MuiOutlinedInput-input": {
        padding: "0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
        borderWidth: "1px"
    }
    // "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "red"
    // }
});

const StyledPlaceholder = styled("span")({
    color: "gray"
});

const ImageTag = styled("img")({
    width: "100px",
    height: "100px",
    borderRadius: "50%"
});
// Customizable Area End
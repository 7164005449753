Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableuserprofiles2";
exports.labelBodyText = "customisableuserprofiles2 Body";
exports.profileTitleText = "Profile";
exports.doctorNameText = "Dr.Manish Mehta";
exports.personalDetailsText = "Personal Details";
exports.specializationText = "Cardiologist";
exports.verifiedText = "MEDICAL REGISTRATION VERIFIED";
exports.btnExampleTitle = "CLICK ME";
exports.getDoctorProfileDetailsAPiEndPoint = "account_block/show";
exports.getDoctorProfileApiContentType = "application/json";
exports.postEditDoctorProfileApiEndPoint = "account_block/update";
exports.putDoctorRegisterApiMethod = "PUT";
exports.putUpdateDoctorProfileApiEndPoint = "account_block/doctor_profile_image";
exports.postDoctorProfileImageApiMethod = "POST";
exports.postCreateAvailabilityApiEndPoint = "bx_block_calendar/create";
exports.postCreateClinicDestailsApiEndPoint = "bx_block_address/create";
exports.getClinicDetailsIndexApiEndPoint = "bx_block_address/index";  
exports.getShowClinicDetailsApiEndPoint = "bx_block_address/show";
exports.editClinicDetailsApiEndPoint = "bx_block_address/update";
exports.deleteImageApiEndPoint = "bx_block_address/destroy_attachment";
exports.putDeleteImageApiMethod = "DELETE";
exports.getAllPatientApiEndPoint = "bx_block_appointment_management/all_patient";
exports.getScheduledApiEndPoint = "bx_block_appointment_management/my_patient_schedule"; 
exports.getScheduledDetailsApiEndPoint = "bx_block_appointment_management/schedule_details";
exports.getTransactionDetailsApiEndPoint = "bx_block_appointment_management/transaction_details";
exports.getMedicalHistoryEndPoint = "bx_block_medicine/patient_medical_history";
exports.postAddPatientEndPoint = "account_block/create_doctor_patients";
exports.postDoctorRegisterApiContentType = "application/json";
exports.postDoctorRegisterApiMethod = "POST";
exports.postDoctorVerifyApiContentType = "multipart/form-data";
exports.postDoctorVerifyAPiEndPoint ="account_block/upload_documents";
exports.getPackagesApiEndPoint = "bx_block_fee_management/show";
exports.deletePackageApiEndPoint = "bx_block_fee_management/delete_package";
exports.getConsultationApiEndPoint = "bx_block_appointment_management/get_consultation_fees";
exports.addPackagesApiEndPoint = "bx_block_fee_management/create_package"
exports.getDoctorDocumentsApiEndPoint = "account_block/get_doctor_documents"
exports.postConsultationFeesApiEndPoint = "bx_block_appointment_management/create_consultation_fee";
exports.editPackagesApiEndPoint = "bx_block_fee_management/update_package";
exports.putPatientImageApiEndPoint = "account_block/add_patient_profile_photo"
exports.deleteClinicApiEndPoint = "bx_block_address/delete";
exports.getSpecializationAPiEndPoint ="bx_block_categories/index";
exports.getCategoryAPiEndPoint ="bx_block_categories/get_category_list";
exports.getAllDiseaseListAPiEndPoint = "bx_block_disease/diseases_list";
exports.putDoctorCreateAvailabilityAPiEndPoint = "bx_block_calendar/update";
exports.getAvailableAPiEndPoint = "bx_block_calendar/show";
exports.transactionApiContentType = "application/json";
exports.transactionApiMethodType = "GET";
exports.feesPackageAPiEndPoint = "bx_block_appointment_management/create_consultation_fee";
exports.feesPackageEditAPiEndPoint = "bx_block_appointment_management/update_consultation_fee";
// Customizable Area End
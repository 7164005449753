import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

// Customizable Area Start
export interface RoleOptionType {
  role: string,
  id: string
}

export interface ErrorType {
	errors: [
		{
			message: string;
		}
	]
}
	
export interface AvilabilityType {
  data: {
		attributes: {
			timeslots: Array<TimeslotType>
		}
	}
}

export interface TimeslotType {
  id: number;
  start_time: string;
  end_time:  string;
  unavailability_date: Array<string>;
  availability_id: number;
  mode: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  myAvailability : any;
  availabilityByDate: AvilabilityType;
  noSlot: boolean;
  selectedDate: Date | string;
  setShowCalendar: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class DoctorAvailabilityController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDoctorAvailabiltyApiCallId: string = "";
  getDoctorAvailabiltyByDateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      // Customizable Area Start
      myAvailability : [],
      availabilityByDate: {
        data: {
          attributes: {
            timeslots: [
              {
                id: 0,
                start_time: "",
                end_time:"",
                unavailability_date: [],
                availability_id: 0,
                mode: "",
              }
            ]
          }
        }
      },
      noSlot: false,
      selectedDate: new Date(),
      setShowCalendar: false
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
   // Customizable Area Start
  async componentDidMount() {
  this.getAvailabilityByDatefunction();
}
// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.isAvailabilityValidResponse(responseJson)) {
        this.availabilityApiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isAvailabilityInValidResponse(responseJson)) {
        this.availabilityApiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isAvailabilityValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isAvailabilityInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  availabilityApiSuccessCallBacks = (apiRequestCallId: string, responseJson: AvilabilityType) => {
    if (apiRequestCallId === this.getDoctorAvailabiltyByDateApiCallId) {
      this.getAvailabilityByDateSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  availabilityApiFailureCallBacks = (apiRequestCallId: string, responseJson: ErrorType) => {
    if (apiRequestCallId === this.getDoctorAvailabiltyByDateApiCallId) {
      this.getAvailabilityByDateFailureCallBack(responseJson);
    }
  };

  getAvailabilityByDatefunction = async () => {
    this.getDoctorAvailabiltyByDateApiCallId = ApiCall({
      contentType: configJSON.getDoctorUpcomingAppointmentApiContentType,
      method: configJSON.getDoctorUpcomingAppointmentAPiMethod,
      endPoint: `${configJSON.getAvailabilityByDateEndPoint}?date=${moment(this.state.selectedDate).format('DD-MM-YYYY')}`
    });
  };

  getAvailabilityByDateSuccessCallBack = (response: AvilabilityType) => {
    this.setState({
      noSlot: false,
      availabilityByDate: response
    })
  };

  getAvailabilityByDateFailureCallBack = async (error: ErrorType) => {
    this.setState({
      noSlot: true
    });
    notification["error"]({
      message: error.errors[0].message,
    });
  }

  handleDateChange = (date: Date | any) => {
    this.setState({
      selectedDate: date,
      setShowCalendar: false
    }, () => {
      this.getAvailabilityByDatefunction();
    })
  };

  handleShowCalendar = () => {
    this.setState({
      setShowCalendar: !this.state.setShowCalendar
    });
  };
  // Customizable Area End

  getAvailabilityfunction = async () => {
    this.getDoctorAvailabiltyApiCallId = ApiCall({
      contentType: configJSON.getDoctorUpcomingAppointmentApiContentType,
      method: configJSON.getDoctorUpcomingAppointmentAPiMethod,
      endPoint: configJSON.getAvailabilityAPiEndPoint,
    });
  };

  getAvailabilitySuccessCallBack = (res: any) => {
    this.setState({
   myAvailability : res.data.attributes.timeslots
    })
  };

  getAvailabilityFailureCallBack = async (err: any) => {
   console.log(err)
  }


  // Customizable Area End
}
// Customizable Area End

import React from "react";
// Customizable Area Start
import { Col, Row, Form, Input, Button, Select } from "antd";
import CarouselCommonComponet from "../../../components/src/CarouselCommonComponet.web";
import SignUpController from "./SignUpController.web";
import { Link } from "react-router-dom";
import { apple } from "../src/assets";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
// Customizable Area End

export default class SignUp extends SignUpController {
  // Customizable Area Start
  renderFormInput = () => {
    return (
      <Form
        data-test-id="generateOTPId"
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        requiredMark={false}
        className="form-style"
        onFinish={
          this.state.selectRole === "Patient"
            ? this.onFinishSignUp
            : this.signUpDoctorFunction
        }
      >
        <h1 className="signup-heading">Sign Up</h1>
        <Row className="">
          <p className="form-email-text">Sign Up as</p>
        </Row>
        <div className="selectItem">
          <Select
            data-test-id="btnSelectRoleId"
            defaultValue="Doctor"
            style={{ width: "100%" }}
            onChange={this.handleSelectRole}
          >
            {this.state.roleData.map((item: any) => (
              <Select.Option
                value={item.role}
                key={item.id}
                name="btnSelectRole"
              >
                {item.role}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Row className="">
          <p className="form-email-text">Full name</p>
        </Row>
        <Form.Item
          label=""
          name="fullName"
          rules={[
            { required: true, message: "Please input your full name" },
            {
              pattern: /^[a-zA-Z ]{3,32}$/,
              message: "Please input valid full name"
            }
          ]}
        >
          <Input
            data-test-id={"fullName"}
            name="fullName"
            onChange={this.handleInputs}
          />
        </Form.Item>
        <Row className="">
          {this.state.selectRole === "Patient" ? (
            <p className="form-email-text">Mobile number</p>
          ) : (
            <p className="form-email-text">Email id / Mobile number</p>
          )}
        </Row>
        <Form.Item
          label=""
          name="phonoNo"
          className="removeInvalidMargin"
          rules={[
            {
              required: true,
              message:
                this.state.selectRole === "Patient"
                  ? "Please input mobile number"
                  : "Please input email id / mobile no."
            },
            {
              pattern: /^[6-9]\d{9}$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message:
                this.state.selectRole === "Patient"
                  ? "Please input valid number"
                  : "Please input valid email id / mobile no."
            }
          ]}
        >
          <Input
            data-test-id={"phonoNoNameId"}
            onChange={
              this.state.setInputValue === "Patient"
                ? this.handleMobileNumber
                : this.validateInput
            }
            name="phonoNo"
          />
        </Form.Item>
        {this.state.invalidMobile ? (
          <p className="error-password">Invalid or Unrecognized Phone Number</p>
        ) : (
          ""
        )}
        {this.state.alreadyRegisterd ? (
          <p className="error-password">Account is already registered.</p>
        ) : (
          ""
        )}
        <Form.Item>
          <Button htmlType="submit" className="sign-up-button">
            Generate OTP
          </Button>
        </Form.Item>
      </Form>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderLoginNavigation = () => {
    return (
      <div className="social-login-text">
        <p className="already_accoiunt">Already have an account? </p>
        <Link to="/login" className="login-link">
          Login
        </Link>
      </div>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderForSignUpWithNavigation = () => {
    return (
      <div className="horizontal-ruler-container">
        <div className="horizonatal-ruler" />
        <p className="signInWith">Or Sign Up with</p>
        <div className="horizonatal-ruler" />
      </div>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderFormText = () => {
    return (
      <div className="signup-footer-container">
        {this.renderForSignUpWithNavigation()}
        <div className="social-login-container">
          <div className="social-login-item">
            <GoogleOAuthProvider clientId="247512604639-h7gesic8lut2m9pimah5tca0h8io8pia.apps.googleusercontent.com">
              <GoogleLogin
              type="icon"
                data-test-id="googleLoginId"
                onSuccess={credentialResponse => {
                  this.handleOnSuccess(credentialResponse);
                }}
                onError={() => {
                  this.handleOnFailure();
                }}
              />
            </GoogleOAuthProvider>
          </div>
          <div className="social-login-item socail_img">
            <img src={apple} className="socail_img" />
          </div>
        </div>
        {this.renderLoginNavigation()}
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderForm = () => {
    return (
      <div className="signup-container">
        {this.renderFormInput()}
        {this.renderFormText()}
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <div className="mainContainerheight">
        <Row>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <CarouselCommonComponet />
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            {this.renderForm()}
          </Col>
        </Row>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export { SignUp };
// Customizable Area End

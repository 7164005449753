import React from "react";
// Customizable Area Start
import { Col, Row, Switch } from "antd";
import { time } from "../../splashscreen/src/assets";
import DoctorAvailabilityController from "./DoctorAvailabilityController.web";
import { styled } from '@material-ui/core';
import Calendar from 'react-calendar';
import moment from "moment";
// Customizable Area End

export class DoctorAvailability extends DoctorAvailabilityController {

    // Customizable Area Start
    renderAvailability = () => {
        return (
            <>
                {this.state.availabilityByDate.data.attributes.timeslots.map((item) => {
                    return (
                        <>
                            <div className="myAvailability-card">
                                <Row>
                                    <Col lg={12} md={18} sm={18} xs={18}>
                                        <div className="myAvailability-card-left">
                                            <div className="myAvailabilty-time-icon-container">
                                                <img src={time} alt="" />
                                            </div>
                                            <div>
                                                <p className="myAvailabilty-time">{item.start_time} - {item.end_time}</p>
                                                <p className="myAvailabilty-location">
                                                    {item.mode} Appointment
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={6} sm={6} xs={6}>
                                        <div className="myAvailability-card-right">
                                            <Switch />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <p className="horizontal-line"></p>
                        </>
                    )
                })}
            </>
        )
    }

    renderNoData = () => {
        return (
            <NoDataBox>
                <p>No slots available</p>
            </NoDataBox>
        )
    }
    // Customizable Area End


    // Customizable Area Start
    render() {
        return (
            <div style={{position: "relative"}}>
                <div className="renderUpCommingAppointment-top-section">
                    <h1 className="renderUpCommingAppointment-heading" data-test-id="textId">My Availability</h1>
                    <p className="renderUpCommingAppointment-view-all-btn" data-test-id="dateId" onClick={this.handleShowCalendar}>({moment(this.state.selectedDate).format('DD-MM-YYYY')})</p>
                </div>
                {
                    this.state.setShowCalendar && 
                    <Box>
                        <Calendar
                            data-test-id="calendarId"
                            onChange={this.handleDateChange}
                            value={this.state.selectedDate}
                            onClickDay={this.handleDateChange}
                        />
                    </Box>
                }
                {
                    !this.state.noSlot ?
                        <div className="myAvailability-main">
                            {this.renderAvailability()}
                        </div>
                        :
                        this.renderNoData()
                }
            </div>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default DoctorAvailability;

const NoDataBox = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    boxShadow: "1px 1px 5px #aaaaaa",
    marginBottom: "20px"
});

const Box = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    position: "absolute", 
    zIndex:1 , 
    top: "30px"
});
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification, DatePickerProps } from "antd";
import * as H from "history";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
  message: object;
  data: object;
  create_doctor_to_patient: object;
  patient_detail: object;
}

export interface InvalidResponseType {
  message: "";
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string
}

export interface DoctorDetailsType {
  data: DoctorDetailsData;
}
export interface DoctorDetailsData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    medical_representative_name: string;
    representative_contact_no: string;
    profile_image: string;
  }
}

export interface AllPatient {
  id: string;
  type: string;
  attributes: {
    first_name: string;
    last_name: string;
    full_name: string;
    full_phone_number: string;
    email_id: string;
    date_of_birth: string;
    gender: string;
    weight: string;
    blood_group: string;
    city: string;
    aadhar_no: string;
    health_id: string;
    profile_photo: string;
    last_visit: string;
    disease: Array<string>;
    age: string;
    patient_id: number;
    appointment_id: number;
  }
}

export interface AllPatientType {
  data: Array<AllPatient>;
}

export interface MedicalHistory {
  data: Array<MedicalHistoryType>;
}

export interface MedicalHistoryType {
  id: string;
  type: string;
  attributes: {
    doctor_full_name: string;
    prescription_medicines: {
      data: [
        {
          id: string;
          type: string;
          attributes: {
            id: number;
            medicine_name: string;
            duration: string;
            time: string;
            comments: string;
            dose_time: string;
          }
        }
      ]
    }
  }
}

export interface AllDiseaseType {
  data : [
    {
      id: string;
      type: string;
      attributes: {
        id: string;
        name: string;
        image: string;
      }
    }   
  ]
}

export interface AddPatientError {
  errors:[
    {
      patient: string,
      full_phone_number: string;
    }
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoader: boolean;
  myPatientsTab: Array<string>;
  activePatientTab: string;
  showPopup: boolean;
  doctorProfileData: {};
  allPatientLists: AllPatientType;
  allScheduleList: AllPatientType;
  medicalHistory: boolean;
  medicalHistoryData: MedicalHistory;
  patientId: number;
  patientNameErr: string;
  patientFullName: string;
  patientEmailErr: string;
  patientEmail: string;
  patientCityErr: string;
  patientCityName: string;
  patientPhoneNum: string;
  patientPhoneNumErr: string;
  patientGenderErr: string;
  patientGender: string;
  aadharNumErr: string;
  aadharNum: string;
  bharatNum: string;
  bharatNumErr: string;
  diseaseNameErr: string;
  diseaseName: string;
  dateValue: string;
  dateValueErr: string;
  bloodErr: string;
  bloodValue: string;
  weightErr: string;
  weightValue: string;
  healthId: string;
  patientImage: any;
  contentHeight: number;
  allDiseaseData: AllDiseaseType;
  diseaseValue: Array<string> | any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class MypatientController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDetailsCallId: string = "";
  getAllPatientsListCallId: string = "";
  getScheduledCallId: string = "";
  getScheduledDetailsCallId: string = "";
  getTransactionDetailsCallId: string = "";
  getMedicalHistoryApiCallId: string = "";
  postAddpatientApiCallId: string = "";
  putImageApiCallId: string = "";
  getDiseaseListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoader: false,
      myPatientsTab: ["All", "Scheduled"],
      activePatientTab: "All",
      showPopup: false,
      doctorProfileData: {},
      allPatientLists: {
        data: [
          {
            id: "",
            type: "",
            attributes: {
              first_name: "",
              last_name: "",
              full_name: "",
              full_phone_number: "",
              email_id: "",
              date_of_birth: "",
              gender: "",
              weight: "",
              blood_group: "",
              city: "",
              aadhar_no: "",
              health_id: "",
              profile_photo: "",
              last_visit: "",
              disease: [],
              age: "",
              patient_id: 0,
              appointment_id: 0
            }
          }
        ]
      },
      allScheduleList: {
        data: [
          {
            id: "",
            type: "",
            attributes: {
              first_name: "",
              last_name: "",
              full_name: "",
              full_phone_number: "",
              email_id: "",
              date_of_birth: "",
              gender: "",
              weight: "",
              blood_group: "",
              city: "",
              aadhar_no: "",
              health_id: "",
              profile_photo: "",
              last_visit: "",
              disease: [],
              age: "",
              patient_id: 0,
              appointment_id: 0
            }
          }
        ]
      },
      medicalHistory: false,
      medicalHistoryData: {
        data: []
      },
      patientId: 0,
      patientNameErr: "",
      patientFullName: "",
      patientEmailErr: "",
      patientEmail: "",
      patientCityErr: "",
      patientCityName: "",
      patientPhoneNum: "",
      patientPhoneNumErr: "",
      patientGenderErr: "",
      patientGender: "",
      aadharNumErr: "",
      aadharNum: "",
      bharatNum: "",
      bharatNumErr: "",
      diseaseNameErr: "",
      diseaseName: "",
      dateValue: "",
      dateValueErr: "",
      bloodErr: "",
      bloodValue: "",
      weightErr: "",
      weightValue: "",
      healthId: "",
      patientImage: "",
      contentHeight: 0,
      allDiseaseData: {
        data: [
          {
            id: "",
            type: "",
            attributes: {
                id: "",
                name: "",
                image: ""
            }
          }
        ]
      },
      diseaseValue: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  changeTab = (tabName: string) => {
    this.setState({ activePatientTab: tabName });
  };

  handelShowDoctoPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  handleDocDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleEditDocProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  handlePopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  async componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
    this.getProfileDetailsfunction();
    this.getAllPatientsfunction();
    this.getScheduledPatientsfunction();
    this.getAllDiseasefunction();
  }

  apiCall = (data: APIPayloadType) => {
    let token = localStorage.getItem("token");
    const { contentType, method, endPoint, body } = data;
    let header = {
      "Content-Type": contentType,
      token
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.patientValidResponse(responseJson)) {
        this.apiInvoiceSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidAddPatient(responseJson)) {
        this.apiFailureAddPatient(apiRequestCallId, responseJson);
      }
    }
  }

  apiFailureAddPatient = (apiRequestCallId: string, responseJson: AddPatientError) => {
    if (apiRequestCallId === this.postAddpatientApiCallId) {
      this.addPatientFailureCallBack(responseJson);
    }
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getMedicalHistoryApiCallId) {
      this.getMedicalHistoryFailureCallBack(responseJson);
    }
  };

  patientValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data) || (responseJson && responseJson.create_doctor_to_patient || responseJson.patient_detail);
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.message;
  };

  isInValidAddPatient = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };

  apiInvoiceSuccessCallBacks = (apiRequestCallId: string, responseJson: DoctorDetailsType & AllPatientType & MedicalHistory & object & AllDiseaseType) => {
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      this.getProfileDetailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAllPatientsListCallId) {
      this.getAllPatientsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getScheduledCallId) {
      this.getScheduledPatientsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getMedicalHistoryApiCallId) {
      this.getMedicalHistorySuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postAddpatientApiCallId) {
      this.addPatientSuccessCalback(responseJson);
    }
    if (apiRequestCallId === this.putImageApiCallId) {
      this.putImageSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDiseaseListApiCallId) {
      this.getAllDiseaseSuccessCallBack(responseJson);
    }
  };

  getProfileDetailsfunction = async () => {
    this.getProfileDetailsCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint
    });
  };

  getProfileDetailsSuccessCallBack = (response: object) => {
    this.setState({
      doctorProfileData: response
    });
  };

  getAllPatientsfunction = async () => {
    this.getAllPatientsListCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAllPatientApiEndPoint
    });
  };

  getAllPatientsSuccessCallBack = (response: AllPatientType) => {
    this.setState({
      allPatientLists: response,
    });
  };

  getScheduledPatientsfunction = async () => {
    this.getScheduledCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getScheduledApiEndPoint
    });
  };

  getScheduledPatientsSuccessCallBack = (response: AllPatientType) => {
    this.setState({
      allScheduleList: response,
    });
  };

  navigateToTransactionScreen = (helthId: string) => {
    this.props.history.push({ pathname: "/scheduledetails", state: { scheduledHelthId: helthId } });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleAppointmentsDetails = (id: number) => {
    this.props.history.push({
      pathname: "/appointmentsdetails",
      state: {
        appointmentId: id
      }
    })
  };

  onHistoryCloseModal = () => {
    this.setState({
      medicalHistory: false,
      medicalHistoryData: {
        data: []
      }
    });
  };

  handleMedicalHistory = (patientId: number) => {
    this.setState({
      medicalHistory: true,
      patientId: patientId
    }, () => {
      this.getMedicalHistoryFunction();
    });
  };

  getMedicalHistoryFunction = async () => {
    this.setState({ isLoader: true })
    this.getMedicalHistoryApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getMedicalHistoryEndPoint}?patient_id=${this.state.patientId}`
    });
  };

  getMedicalHistorySuccessCallBack = (response: MedicalHistory) => {
    this.setState({
      medicalHistoryData: response,
      isLoader: false
    });
  };

  getMedicalHistoryFailureCallBack = (errorResponse: InvalidResponseType) => {
    this.setState({ isLoader: false })
  };

  handlePatientName = (event: { target: { value: string } }) => {
    const inputValue = event.target.value;
    const nameRegex = /^[A-Za-z ']+$/;
    this.setState({
      patientFullName: inputValue,
    }, () => {
      if (nameRegex.test(inputValue)) {
        this.setState({
          patientNameErr: '',
        });
      } else {
        this.setState({
          patientNameErr: inputValue ? 'Please enter a valid full name' : 'This field is required.',
        });
      }
    });
  };

  handlePatientEmail = (event: { target: { value: string } }) => {
    const emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailInputValue = event.target.value;
    this.setState({
      patientEmail: emailInputValue,
    }, () => {
      if (emailRegex.test(emailInputValue)) {
        this.setState({
          patientEmailErr: '',
        });
      } else {
        this.setState({
          patientEmailErr: emailInputValue ? 'Please enter a valid email address' : 'This field is required.',
        });
      }
    });
  };

  handlePatientCity = (event: { target: { value: string } }) => {
    const cityInputValue = event.target.value;
    const cityNameRegex = /^[A-Za-z ']+$/;
    this.setState({
      patientCityName: cityInputValue,
    }, () => {
      if (cityNameRegex.test(cityInputValue)) {
        this.setState({
          patientCityErr: '',
        });
      } else {
        this.setState({
          patientCityErr: cityInputValue ? 'Please enter a valid city name' : 'This field is required.',
        });
      }
    });
  };

  handlePatientPhone = (event: { target: { value: string } }) => {
    const numberRegex = /^[6-9]\d{9}$/;
    const patientInputValue = event.target.value;
    this.setState({
      patientPhoneNum: event?.target.value
    }, () => {
      if (numberRegex.test(patientInputValue)) {
        this.setState({
          patientPhoneNumErr: "",
        });
      } else {
        this.setState({
          patientPhoneNumErr: patientInputValue ? "Please enter a valid contact number" : "This field is required.",
        });
      }
    })
  };

  handleGender = (event: any | React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      patientGender: event.target.value
    })
  };

  handleAadharNum = (event: { target: { value: string } }) => {
    const aadharRegex = /^[2-9]{1}\d{3}\d{4}\d{4}$/;
    const aadhartInputValue = event.target.value;
    this.setState({
      aadharNum: event.target.value
    }, () => {
      if (aadharRegex.test(aadhartInputValue)) {
        this.setState({
          aadharNumErr: "",
        });
      } else {
        this.setState({
          aadharNumErr: aadhartInputValue ? "Please enter valid Aadhar card no." : "This field is required.",
        });
      }
    })
  };

  handleDisease = (event: { target: { value: string } }) => {
    const diseaseRegex = /^[A-Za-z ']+$/;
    const bharatInputValue = event.target.value;
    this.setState({
      diseaseName: bharatInputValue,
    }, () => {
      if (diseaseRegex.test(bharatInputValue)) {
        this.setState({
          diseaseNameErr: '',
        });
      } else {
        this.setState({
          diseaseNameErr: bharatInputValue ? 'Please enter a valid disease name' : 'This field is required.',
        });
      }
    });
  };

  handleDate: DatePickerProps['onChange'] = (date, dateString) => {
    this.setState({
      dateValue: dateString
    });
  };

  disabledPastDate = (current: any) => {
    return current && current > moment().startOf("day");
  };

  handleBlood = (event: any | React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      bloodValue: event.target.value
    })
  };

  handleWeight = (event: { target: { value: string } }) => {
    const WeightRegex = /^[1-9]\d{0,2}(?:\.\d{1,2})?(?:kg|lbs)?$/;
    const dateInputValue = event.target.value;
    this.setState({
      weightValue: event.target.value
    }, () => {
      if (WeightRegex.test(dateInputValue)) {
        this.setState({
          weightErr: "",
        });
      }
      else {
        this.setState({
          weightErr: dateInputValue ? "Please enter valid weight" : "This field is required.",
        });
      }
    })
  };

  handleCreatePatient = async () => {
    const nameRegex = /^[A-Za-z ']+$/;
    let emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const cityNameRegex = /^[A-Za-z ']+$/;
    let numberRegex = /^[6-9]\d{9}$/;
    const aadharRegex = /^[2-9]{1}\d{3}\d{4}\d{4}$/;
    const diseaseRegex = /^[A-Za-z]+(?:,[A-Za-z]+)*$/;
    if (this.state.patientFullName.trim() == "") {
      return notification["error"]({
        message: "Please fill the full name field",
      });
    }
    if (!nameRegex.test(this.state.patientFullName)) {
      return this.setState({
        patientNameErr: "Please enter a valid full name",
      });
    }
    if (this.state.patientEmail.trim() == "") {
      return notification["error"]({
        message: "Please fill the email address field",
      });
    }
    if (!emailRegex.test(this.state.patientEmail)) {
      return this.setState({
        patientEmailErr: "Please enter a valid email address",
      });
    }
    if (!numberRegex.test(this.state.patientPhoneNum)) {
      return this.setState({
        patientPhoneNumErr: "Please enter a valid contact number",
      });
    }
    if (this.state.dateValue.trim() == "") {
      return notification["error"]({
        message: "Please fill the date field",
      });
    }
    if (this.state.patientGender.trim() == "") {
      return notification["error"]({
        message: "Please select gender",
      });
    }
    if (this.state.weightValue.trim() == "") {
      return notification["error"]({
        message:"Please fill the weight"
      });
    }
    if (this.state.bloodValue.trim() == "") {
      return notification["error"]({
        message: "Please select blood group",
      });
    }
    if (this.state.patientCityName.trim() == "") {
      return notification["error"]({
        message: "Please fill the city name field",
      });
    }
    if (!cityNameRegex.test(this.state.patientCityName)) {
      return this.setState({
        patientCityErr: "Please enter a valid city name",
      });
    }
    if (!aadharRegex.test(this.state.aadharNum)) {
      return this.setState({
        aadharNumErr: "Please enter valid Aadhar card no.",
      });
    }
    if (this.state.aadharNum.trim() == "") {
      return notification["error"]({
        message:"Please fill the aadhar number"
      });
    }
    if(this.state.diseaseName.length > 5){
      return  this.setState({
        diseaseNameErr: "Please select or add upto 5 disease"
       });
    }
    if(!diseaseRegex.test(this.state.diseaseName)){
      return  this.setState({
        diseaseNameErr: "Please enter a valid disease name"
       });
    }
    let apiBody = {
      data: {
        attributes: {
          full_name: this.state.patientFullName,
          full_phone_number: `91${this.state.patientPhoneNum}`,
          date_of_birth: this.state.dateValue,
          weight: this.state.weightValue,
          city: this.state.patientCityName,
          blood_group: this.state.bloodValue,
          disease: this.state.diseaseName,
          aadhar_no: this.state.aadharNum,
          email_id: this.state.patientEmail,
          gender: this.state.patientGender
        }
      }
    }
    this.postAddpatientApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.postDoctorProfileImageApiMethod,
      endPoint: configJSON.postAddPatientEndPoint,
      body: apiBody,
    });
    this.setState({isLoader: true});
  };

  addPatientSuccessCalback = (response: any) => {
    if(this.state.patientImage){
      this.setState({
        healthId: response.patient_detail.data.attributes.health_id,
      }, () => {
        this.putImageDeletefunction();
      });
    } else {
      this.setState({
        isLoader: false,
        patientFullName: "",
        patientPhoneNum: "",
        dateValue: "",
        weightValue: "",
        patientCityName: "",
        bloodValue: "",
        diseaseName: "",
        aadharNum: "",
        patientEmail: ""
      });
      notification["success"]({
        message: "Patient created successfully.",
      });
      this.props.history.push("/mypatient")
      this.getAllPatientsfunction();
    }
  };

  addPatientFailureCallBack = (responseJson: AddPatientError) => {
    this.setState({isLoader: false});
    if(responseJson.errors[0].full_phone_number) {
      return notification["error"]({
        message: "Phone number already exist",
      });
    }
    if(responseJson.errors[0].patient) {
      return notification["error"]({
        message: "Aadhar number already exist",
      });
    }
  };

  putImageDeletefunction = async () => {
    let apiBody = {
      data: {
        image: {
          data: this.state.patientImage
        }
      }
    }
    this.putImageApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: `${configJSON.putPatientImageApiEndPoint}?patient_health_id=${this.state.healthId}`,
      body: apiBody,
    });
    this.setState({isLoader: true});
  };

  putImageSuccessCallBack = (response: object) => {
    this.setState({
      isLoader: false,
      patientFullName: "",
      patientPhoneNum: "",
      dateValue: "",
      weightValue: "",
      patientCityName: "",
      bloodValue: "",
      diseaseName: "",
      aadharNum: "",
      patientEmail: ""
    });
    notification["success"]({
      message: "Patient created successfully.",
    });
    this.props.history.push("/mypatient")
    this.getAllPatientsfunction();
  };

  handlePatientImage = (event: any) => {
    let files = [...event.target.files];
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif','image/jpg'];
    files.forEach((file: any, index: number) => {
      if (allowedImageTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result;
          this.setState({ patientImage: base64Image});
      };
      reader.readAsDataURL(file);
    } else {
      notification["error"]({
        message: "Please upload valid image file.",
      });
    }
    });
  };

  handleDoctorPatientCity = (event: { target: { value: string } }) => {
    let input = event.target.value;
    const regex = /^[A-Za-z ']+$/;
    this.setState({
      patientCityName: input,
    }, () => {
      if (regex.test(input)) {
        this.setState({
          patientCityErr: '',
        });
      } else {
        this.setState({
          patientCityErr: input ? 'Please enter a valid city name' : 'This field is required.',
        });
      }
    });
  };

  handlepatientDisease = (event: { target: { value: string } }) => {
    const inputValue = event.target.value;
    let diseaseRegex = /^[A-Za-z ']+$/;
    this.setState({
      diseaseName: inputValue,
    }, () => {
      if (diseaseRegex.test(inputValue)) {
        this.setState({
          diseaseNameErr: '',
        });
      } else {
        this.setState({
          diseaseNameErr: inputValue ? 'Please enter a valid disease name' : 'This field is required.',
        });
      }
    });
  };

  onCloseModal = () => {
    this.setState({
      medicalHistory: false,
      medicalHistoryData: {
        data: []
      }
    });
  };

  handleEditAadharNum = (event: { target: { value: string } }) => {
    const aadhartInputValue = event.target.value;
    this.setState({
      aadharNum: event.target.value
    }, () => {
        this.setState({
          aadharNumErr: aadhartInputValue ? "Please enter valid Aadhar card no." : "This field is required.",
        });
      });
  };

  handleEditWeight = (event: { target: { value: string } }) => {
    const dateInputValue = event.target.value;
    this.setState({
      weightValue: event.target.value
    }, () => {
        this.setState({
          weightErr: dateInputValue ? "Please enter valid weight" : "This field is required.",
        });
      });
  };

  handleDetails = (id: number) => {
    this.props.history.push({
      pathname: "/appointmentsdetails",
      state: {
        appointmentId: id
      }
    })
  };

  getAllDiseasefunction = async () => {
    this.getDiseaseListApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAllDiseaseListAPiEndPoint
    });
  };

  getAllDiseaseSuccessCallBack = (response: AllDiseaseType) => {
    this.setState({
      allDiseaseData: response,
    });
  };

  handleDiseaseChange = (value: string) => {
    const diseaseRegex = /^[A-Za-z]+(?:,[A-Za-z]+)*$/;
    this.setState({
      diseaseName: value
    }, () => {
      if(this.state.diseaseName?.length > 5){
        return  this.setState({
          diseaseNameErr: "Please select or add upto 5 disease"
         });
      }
      else if(!diseaseRegex.test(value)){
        return  this.setState({
          diseaseNameErr: "Please enter a valid disease name"
         });
      }
      else {
        return this.setState({
          diseaseNameErr: ""
         })
      }
    }
    )
    
  };

  onModalClose = () => {
    this.setState({
      medicalHistory: false,
      medicalHistoryData: {
        data: []
      }
    });
  };
  // Customizable Area End
}

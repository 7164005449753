export const imgSplash = require("../assets/logo.png");
export const imgloader = require("../assets/loader.png");
export const merilLogo = require("../assets/meril.png");
export const image = require("../assets/cimg.png");
export const image2 = require("../assets/main.png");
export const image3 = require("../assets/main2.png");
export const image4 = require("../assets/main1.png");
export const image5 = require("../assets/main3.png");
export const image6 = require("../assets/main4.png");
export const FacebookIcon = require("../assets/facebookIcon.png");
export const YoutubeIcon = require("../assets/youtubeIcon.png");
export const LinkedInIcon = require("../assets/linkedinIcon.png");
export const AppStroeIcon = require("../assets/Appstore.png");
export const instaIcon = require("../assets/Instagram.png");
export const playStore = require("../assets/PlayStore.png");
export const searchIcon = require("../assets/loupe grey.png");
export const filterShapeIcon = require("../assets/filtershape.png");
export const Trash = require("../assets/Trash.png");
export const deleteIcon = require("../assets/Trash.png");
export const Penciledit = require("../assets/penciledit.png");
export const editIcon = require("../assets/penciledit.png");
export const locationIcon = require("../assets/penciledit.png");
export const profilePic = require("../assets/profilepic.png");
export const pdf = require("../assets/pdf.png");
export const rupay = require("../assets/rupay.png");
export const landingpageBanner = require("../assets/landingpagebanner.svg");
export const rupaypese = require("../assets/pngwing.png");
export const doctor = require("../assets/doc.jpeg");
export const Default = require("../assets/Default.png");
export const carousel1 = require("../assets/Corosel1.png");
export const carousel2 = require("../assets/Corosel 02.png");
export const carousel3 = require("../assets/Corosel 03.png");
export const subimg2 = require("../assets/Corosel01.png");
export const subimg4 = require("../assets/Corosel2.png");
export const subimg5 = require("../assets/Corosel3.png");
export const subimg3 = require("../assets/Corosel4.png");
export const subimg6 = require("../assets/Corosel5.png");
export const mainsub = require("../assets/cccc.png");
export const ortho = require("../assets/ortho.png");
export const time = require("../assets/time.png");
export const calendar = require("../assets/calender.png");
export const location = require("../assets/location.png");
export const arrow1 = require("../assets/arrow1.png");
export const arrow2 = require("../assets/arrow2.png");
export const searchimg = require("../assets/search.png");
export const notification = require("../assets/notification.png");

export const banner1 = require("../assets/banner1.png")
export const banner2 = require("../assets/banner2.jpg")
export const banner3 = require("../assets/banner3.jpg")
export const banner4 = require("../assets/banner4.jpg")
export const banner7 = require("../assets/banner7.png")
export const banner9 = require("../assets/Banner9.png")
export const banner10 = require("../assets/Banner10.png")



import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  selectedKey: string;
  fullName: string;
  email: string;
  contactUs: string;
  description: string;
  isModalsOpen: boolean;
  fullName_error: string;
  email_error: string;
  contactUs_error: string;
  description_error: string;
  contactMessage: string;

  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileContactUsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContactApiCallId: string | null = null;
  addContactApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      selectedKey: "0",
      fullName: "",
      email: "",
      contactUs: "",
      description: "",
      isModalsOpen: false,
      fullName_error: "",
      email_error: "",
      contactUs_error: "",
      description_error: "",
      contactMessage: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson.data);
      } else if (responseJson && responseJson.errors) {
        this.setState({
          isModalsOpen: true,
          contactMessage: responseJson.errors[0].contact[0],
        });
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
      // Customizable Area End
    }
  };
  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.addContactApiCallId) {
      this.setState({
        isModalsOpen: true,
        contactMessage: "ContactUs Details successfully Submited",
      });
      this.setState({
        fullName: "",
        email: "",
        contactUs: "",
        description: "",
        fullName_error: "",
        email_error: "",
        contactUs_error: "",
        description_error: "",
      });
    }
  };

  handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = event.target.value;
    const alphaFullName = fullName.replace(/[^a-zA-Z ]/g, "");
    this.setState({ fullName: alphaFullName });
  };

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };

  handleChangeContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    const contactus = event.target.value;

    if (/[a-zA-Z]/.test(contactus)) {
      return;
    }
    this.setState({ contactUs: contactus });
  };

  handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: event.target.value });
  };

  handleBack = () => {
    this.setState({ isModalsOpen: false });
  };

  validate = () => {
    const { fullName, email, contactUs, description } = this.state;

    if (fullName === "") {
      this.setState({ fullName_error: "Please Enter Your full Name" });
    }
    if (email === "") {
      this.setState({ email_error: "Please Enter Your Email" });
    }
    if (contactUs === "") {
      this.setState({ contactUs_error: "Please Enter Your Phone No" });
    }
    if (description === "") {
      this.setState({ description_error: "Please Enter Your description" });
    }
  };

  handleSubmit = async (e: any) => {
    e?.preventDefault();
    this.validate();
    if (
      this.state.fullName !== "" &&
      this.state.email !== "" &&
      this.state.contactUs !== "" &&
      this.state.description !== ""
    ) {
      const payLoad = {
        data: {
          name: this.state.fullName,
          email: this.state.email,
          phone_number: this.state.contactUs,
          description: this.state.description,
        },
      };
      this.addContactApiCallId = ApiCall({
        contentType: configJSON.postContactUsApiContentType,
        method: configJSON.postContactUsAPiMethod,
        endPoint: `${configJSON.postContactUsAPiEndPoint}`,
        body: payLoad,
      });
    }
  };
  // Customizable Area End
}

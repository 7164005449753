Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

      // Customizable Area Start
exports.postSignUpOtpAPiMethod = "POST";
exports.postSignUpOtpApiContentType = "application/json";
exports.postSignUpOtpAPiEndPoint = "account_block/verify_otp";

exports.postDoctorSignUpOtpAPiEndPoint = "account_block/doctor_verify_otp";

exports.getVerifyDoctorLoginOtpAPiEndPoint = "bx_block_login/verify_doctor_otp";


exports.postResendOtpAPiEndPoint = "account_block/create_otp";

exports.postDoctorResendOtpAPiEndPoint = "account_block/create_otp_doctor";

exports.getLoginOtpApiContentType = "application/json";
exports.getLoginOtpApiMethod = "GET";
exports.getVerifyLoginOtpAPiEndPoint = "bx_block_login/verify_otp";

exports.getResendDoctorLoginAPiEndPoint = "bx_block_login/send_sms_otp";
exports.getResendpatientOtpAPiEndPoint = "bx_block_login/send_otp";

exports.getPatientDetailsAPiEndPoint = "account_block/current_patient_detail";
exports.getPatientDetailsApiContentType = "application/json";
exports.getPatientDetailsMethod = "GET";

exports.getTermsAndCondsApiEndPoint = "bx_block_settings/index_term_condition";
exports.getPrivacyPolicyApiEndPoint = "bx_block_settings/index_privacy";
exports.getApiMethod = 'GET';
exports.apiContentType = 'application/json';
exports.getPrivacyPolicyApiEndPoint = "bx_block_settings/index_privacy";

    // Customizable Area End
exports.submitButtonColor = "#6200EE";

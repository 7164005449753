import React from "react";
// Customizable Area Start
import { Container, Box, styled, Grid, TextField, Select, MenuItem } from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import { FaCamera } from 'react-icons/fa';
import Customisableuserprofiles2Controller, {
    Props,
} from "./Customisableuserprofiles2Controller";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import { Link } from 'react-router-dom';
const is_kyc = localStorage.getItem("is_kyc");
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End

export default class EditDoctorProfileDetails extends Customisableuserprofiles2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderEditProfileNavigatePage = () => {
        const is_kyc = localStorage.getItem("is_kyc");
        return (
            <>
                {is_kyc === "approved" ?
                    <EditPageHeader>
                        <Link to={is_kyc === "approved" ? "/doctorhome" : "/editdoctordetails"}><PageHeader>Home</PageHeader></Link>
                        <Link to={is_kyc === "approved" ? "/doctorschedule" : "/editdoctordetails"}><PageHeader>Schedule</PageHeader></Link>
                        <Link to={is_kyc === "approved" ? "/addpatient" : "/editdoctordetails"}><PageHeader>Add Patient</PageHeader></Link>
                        <PageHeader>Chats</PageHeader>
                    </EditPageHeader>
                    :
                    <EditPageHeader onClick={this.handleVerifyKyc} data-test-id="clickId">
                        <PageHeader>Home</PageHeader>
                        <PageHeader>Schedule</PageHeader>
                        <PageHeader>Add Patient</PageHeader>
                        <PageHeader>Chats</PageHeader>
                    </EditPageHeader>
                }
            </>
        )
    };

    renderEditProfileForm = () => {
        return (
            <Container style={{ marginBottom: "20px" }}>
                <Box pl={1} component={"h4"} data-test-id="textId" style={{marginBottom: "20px"}}>Profile</Box>
                <Grid container spacing={5} direction="row-reverse" style={{height: "620px", overflowX: "scroll"}}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <ProfileDiv>
                            <img
                                src={this.imageShowSrc()}
                                alt=""
                                style={{ width: "130px", height: "130px", borderRadius: "50%" }}
                            />
                            <CameraBtn>
                                <input
                                    data-test-id="imgUploadId"
                                    type="file"
                                    accept="image/*"
                                    name="image-upload"
                                    id="input"
                                    style={{ display: "none" }}
                                    onChange={this.handleProfilePicUpdate}
                                />
                                <label htmlFor='input' >
                                    <FaCamera style={{cursor: "pointer"}}/>
                                </label>
                            </CameraBtn>
                        </ProfileDiv>
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <ProfileInputLable placeholder="Name" aria-required >Full Name<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox data-test-id="fullNameId" onChange={this.handleDoctorName} variant="outlined" value={this.state.doctorFullName} />
                        {this.state.userNameErr && <ErrorTag>{this.state.userNameErr}</ErrorTag>}
                        <ProfileInputLable>Email Id<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="abc@gmail.com" data-test-id="emailId" onChange={this.handleDoctorEmail} variant="outlined" value={this.state.doctorEmail} />
                        {this.state.userEmailErr && <ErrorTag>{this.state.userEmailErr}</ErrorTag>}
                        <ProfileInputLable>City<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="Delhi" data-test-id="cityId" onChange={this.handleDoctorCity} variant="outlined" value={this.state.doctorCityName} />
                        {this.state.userCityErr && <ErrorTag>{this.state.userCityErr}</ErrorTag>}
                        <ProfileInputLable>Speciality<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        {this.renderSpecializationSelect()}
                        {this.state.specialityErr && <ErrorTag>{this.state.specialityErr}</ErrorTag>}
                        <ProfileInputLable>Registration No. <sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="0000000000" data-test-id="registerNumId" onChange={this.handleDoctorRegistration} variant="outlined" value={this.state.doctorRegistrationNumber} />
                        {this.state.userRegErr && <ErrorTag>{this.state.userRegErr}</ErrorTag>}
                        <ProfileInputLable>Registration Council<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="Delhi" data-test-id="registration-council-id" onChange={this.handleDoctorRegistrationCouncil} variant="outlined" value={this.state.doctorRegistrationConsil} />
                        {this.state.doctorRegConsilErr && <ErrorTag>{this.state.doctorRegConsilErr}</ErrorTag>}
                        <ProfileInputLable>Passing Year<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="2010" data-test-id="passingYearId" onChange={this.handleDoctorPassingYear} variant="outlined" value={this.state.doctorPassingYear} />
                        {this.state.doctorPassingErr && <ErrorTag>{this.state.doctorPassingErr}</ErrorTag>}
                        <ProfileInputLable>Phone Number<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="9178573959" data-test-id="phneNumId" onChange={this.handleDoctorPhone} variant="outlined" value={this.state.doctorPhoneNum} />
                        {this.state.doctorPhoneNumErr && <ErrorTag>{this.state.doctorPhoneNumErr}</ErrorTag>}
                        <ProfileInputLable>Experience<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="1" data-test-id="experienceId" variant="outlined" onChange={this.handleDoctorExperience} value={this.state.experience} />
                        {this.state.experienceErr && <ErrorTag>{this.state.experienceErr}</ErrorTag>}
                        <ProfileInputLable>Qualification<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="MBBS,MD,B pharma" data-test-id="qualificationId" variant="outlined" value={this.state.qualification} onChange={this.handleDoctorQualification} />
                        {this.state.qualificationErr && <ErrorTag>{this.state.qualificationErr}</ErrorTag>}
                        <ProfileInputLable>Category<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        {this.renderCategorySelect()}
                        {this.state.categoryErr && <ErrorTag>{this.state.categoryErr}</ErrorTag>}
                        <ProfileInputLable>About<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="Dr. John Doe, MD, specializes in cardiology." data-test-id="aboutId" variant="outlined" value={this.state.doctorsAboutInput} onChange={this.handleDoctorAbout} />
                        {this.state.doctorsAboutInputErr && <ErrorTag>{this.state.doctorsAboutInputErr}</ErrorTag>}
                        <ProfileInputLable>Medical Representative Name<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="John" data-test-id="repreId" variant="outlined" value={this.state.representativeName} onChange={this.handleDoctorMRName} />
                        {this.state.representativeNameErr && <ErrorTag>{this.state.representativeNameErr}</ErrorTag>}
                        <ProfileInputLable>Contact of Medical Representative<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        <ProfileInputBox placeholder="0000000000" data-test-id="numberId" variant="outlined" value={this.state.representativeMobileNo} onChange={this.handleDoctorMRNumber} />
                        {this.state.representativeMobileNoErr && <ErrorTag>{this.state.representativeMobileNoErr}</ErrorTag>}
                        <ProfileInputLable>Known languages<sup style={{color: "red"}}>*</sup></ProfileInputLable>
                        {this.renderLanguageSelect()}
                        {this.state.languageErr && <ErrorTag>{this.state.languageErr}</ErrorTag>}
                        <ProfileFormSubmitBtn data-test-id="saveButtonId" onClick={() => this.postEditDoctorDetails()}>Save</ProfileFormSubmitBtn>
                    </Grid>
                </Grid>
            </Container>
        )
    };

    renderCategorySelect = () => {
        return (
            <StyledSelect
                data-test-id="categoryId"
                fullWidth
                variant="outlined"
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    keepMounted: true
                }}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                value={this.state.category}
                onChange={this.handleCategory}
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select category
                </MenuItem>
                {
                    this.state.categoryData.data.map((item) => {
                        return (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                        )
                    })
                }
            </StyledSelect>
        )
    };

    renderLanguageSelect = () => {
        return (
            <StyledSelect
            data-test-id="languageid"
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
            fullWidth
                variant="outlined"
                displayEmpty
            labelId="multiple-select-label"
            id="multiple-select"
            multiple
            value={this.state.selectedValues}
            onChange={this.handleChange}
            inputProps={{ id: 'select-multiple' }}
      >                
        <MenuItem value="" disabled style={{ display: "none" }}>
            Select language
        </MenuItem>
        <CustomMenuItem value={"Hindi"}>{"Hindi"}</CustomMenuItem>
        <CustomMenuItem value={"English"}>{"English"}</CustomMenuItem>
        <CustomMenuItem value={"Bengali"}>{"Bengali"}</CustomMenuItem>
        <CustomMenuItem value={"Marathi"}>{"Marathi"}</CustomMenuItem>
        <CustomMenuItem value={"Telugu"}>{"Telugu"}</CustomMenuItem>
        <CustomMenuItem value={"Gujarati"}>{"Gujarati"}</CustomMenuItem>
        <CustomMenuItem value={"Urdu"}>{"Urdu"}</CustomMenuItem>
        <CustomMenuItem value={"Kannada"}>{"Kannada"}</CustomMenuItem>
        <CustomMenuItem value={"Odia"}>{"Odia"}</CustomMenuItem>
        <CustomMenuItem value={"Malayalam"}>{"Malayalam"}</CustomMenuItem>
        <CustomMenuItem value={"Tamil"}>{"Tamil"}</CustomMenuItem>
        <CustomMenuItem value={"Punjabi"}>{"Punjabi"}</CustomMenuItem>
      </StyledSelect>
        )
    };

    renderSpecializationSelect = () => {
        return (
            <StyledSelect
                IconComponent={ExpandMoreIcon}
                data-test-id="specialityId"
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    keepMounted: true
                }}
                fullWidth
                variant="outlined"
                displayEmpty
                value={this.state.doctorSpeciality}
                onChange={this.handleSpecialization}
            >
                <MenuItem value="" disabled style={{ display: "none" }}>
                    Select specialization
                </MenuItem>
                {
                    this.state.specializationData.specialization.map((item) => {
                        return (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                        )
                    })
                }
            </StyledSelect>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent data-test-id="doctorProfilePopupId" handleProfile={this.handelDoctorProfilePopupShowFunction} />
                <DoctorProfilePopup data-test-id="doctorProfileId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDoctorProfile} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDoctor} handleClosePopup={this.handlePopupClose} />
                {this.renderEditProfileNavigatePage()}
                {this.renderEditProfileForm()}
                <FooterDoctor />
                <Loader height={this.state.contentHeight} loading={this.state.isLoader} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledSelect = styled(Select)({
    marginTop: "15px",
    marginLeft: "10px",
    marginBottom: "15px",
    width: "97%",
    borderRadius: "10px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingLeft: "10px",
        borderRadius: "10px",
    },
    "& .MuiSelect-selectMenu": {
        minHeight: "45px",
        display: "flex",
        alignItems: "center",
    },
    "& .MuiOutlinedInput-input": {
        padding: "0",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey"
    }
});

const ProfileInputLable = styled('label')({
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "10px"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    width: "89%",
    margin: "0 auto"
});

const ProfileInputBox = styled(TextField)({
    width: "100%",
    margin: "5px 0 8px 0",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    fontSize: "16px",
    boxSizing: "border-box",
    '& .MuiOutlinedInput-root': {
        height: '40px',
        borderRadius: "10px",
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'grey',
        },
        '&:focus fieldset': {
            borderColor: 'grey',
        },
        "&.Mui-focused fieldset": {
            borderColor: "grey",
        },
        "&.Mui-error fieldset": {
            borderColor: "red",
        },
    }
});

const ProfileFormSubmitBtn = styled('button')({
    width: "40%",
    padding: "10px",
    borderRadius: "20px",
    border: "none",
    fontWeight: 500,
    color: "#ffffff",
    backgroundColor: "#4d96dd",
    cursor: "pointer"
});

const ProfileDiv = styled('div')({
    fontSize: "150px",
    color: "#4d96dd",
    textAlign: "center",
    position: "relative",
    width: "max-content",
    margin: "0 auto"
});

const CameraBtn = styled(Box)({
    position: "absolute",
    top: 0,
    right: 10,
    fontSize: "25px",
    height: "35px",
    width: "35px",
    color: "#fff",
    backgroundColor: "#4d96dd",
    padding: "5px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ffffff"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const ErrorTag = styled("p")({
    color: "red",
    fontSize: "14px",
    marginLeft: "10px"
});

const CustomMenuItem = styled(MenuItem)({
    "&.MuiListItem-root.Mui-selected":{
        backgroundColor:"#d0d0d0 !important",
    },
    "&.MuiListItem-root.Mui-selected:hover":{
        backgroundColor:"##d0d0d0",
    },
});
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
// Customizable Area End

// Customizable Area Start

export interface HospitalImage {
  id: number;
  url: string;
}
export interface ClinicDetailsAttribute {
  id: string;
  attributes: {
    name: string;
    address: string;
    city: string;
    area: string;
    contact_no: string;
    doctor_id: number;
    link: string;
    latitude: string;
    longitude: string;
    clinic_images: [
      {
        id: number;
        url: string;
      }
    ]
  }
}

export interface ValidResponseType {
  message: object;
  data: object;
}

export interface InvalidResponseType {
  errors: "";
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}

export interface ClinicDetails {
  data: [
    {
      id: string;
      type: string;
      attributes: {
        name: string;
        address: string;
        city: string;
        area: string;
        contact_no: string;
        doctor_id: number;
        link: string;
        latitude: string;
        longitude: string;
        clinic_images: [
          {
            id: number;
            url: string;
          }
        ]
      }
    }
  ]
}

export interface DoctorDetailsType {
  data: DoctorDetailsData;
}
export interface DoctorDetailsData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    medical_representative_name: string;
    representative_contact_no: string;
    profile_image: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addClinicModal: boolean;
  clinicDetails: ClinicDetails;
  newClinicName: string;
  newClinicNameErr: string;
  newClinicAddress: string;
  newClinicAddressErr: string;
  newClinicContactErr: string;
  newClinicContact: string;
  newClinicLinkErr: string;
  newClinicLink: string;
  clinicImage: Array<string>;
  showPopup: boolean;
  clinicEditModal: boolean;
  doctorProfileData: DoctorDetailsType;
  editNewClinicNameErr: string;
  editClinicName: string;
  editNewClinicAddressErr: string;
  editClinicAddress: string;
  editPresentImage: Array<any>;
  editNewClinicAreaErr: string;
  editClinicArea: string;
  editNewClinicCityErr: string;
  editClinicCity: string;
  editContactErr: string;
  editConatct: string;
  editClinicLink: string;
  editClinicLinkErr: string;
  editClinicImages: Array<any>;
  clinicId: string;
  editClinicImageName: string;
  deleteClinicImage: boolean;
  getImageId: number;
  isLoader: boolean;
  deleteClinicId: string;
  isClinicDelete: boolean;
  contentHeight: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class MyClinicController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClinicDetailsApiCallId: string = "";
  postNewCreateClinicDestailsApiCallId: string = "";
  getProfileDetailsCallId: string = "";
  editClinicApiCallId: string = "";
  deleteImageApiCallId: string = "";
  deleteClinicApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      addClinicModal: false,
      isLoader: false,
      clinicDetails: {
        data: [
          {
            id: "",
            type: "",
            attributes: {
              name: "",
              address: "",
              city: "",
              area: "",
              contact_no: "",
              doctor_id: 0,
              link: "",
              latitude: "",
              longitude: "",
              clinic_images: [
                {
                  id: 0,
                  url: ""
                }
              ]
            }
          }
        ]
      },
      newClinicName: "",
      newClinicNameErr: "",
      newClinicAddress: "",
      newClinicAddressErr: "",
      newClinicContactErr: "",
      newClinicContact: "",
      newClinicLinkErr: "",
      newClinicLink: "",
      clinicImage: [],
      showPopup: false,
      clinicEditModal: false,
      doctorProfileData: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_name: "",
            medical_representative_name: "",
            representative_contact_no: "",
            profile_image: ""
          }
        }
      },
      editNewClinicNameErr: "",
      editClinicName: "",
      editNewClinicAddressErr: "",
      editClinicAddress: "",
      editNewClinicAreaErr: "",
      editClinicArea: "",
      editNewClinicCityErr: "",
      editClinicCity: "",
      editContactErr: "",
      editConatct: "",
      editClinicLinkErr: "",
      editClinicLink: "",
      editClinicImages: [],
      clinicId: "",
      editClinicImageName: "",
      editPresentImage: [],
      deleteClinicImage: false,
      getImageId: 0,
      deleteClinicId: "",
      isClinicDelete: false,
      contentHeight: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scroll({
      top:0,
      left:0
    });
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
    this.getClinicDetailsIndexfunction();
    this.getProfilefunction();
  }

  showModal = () => {
    this.setState({ addClinicModal: true });
  };

  closeModal = () => {
    this.setState({ 
      addClinicModal: false,
      newClinicNameErr: "",
      newClinicAddressErr: "",
      newClinicLinkErr: "",
      newClinicContactErr: "",
      newClinicName: "",
      newClinicAddress: "",
      newClinicContact: "",
      newClinicLink: "",
      clinicImage: [],
    });
  };

  apiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint, body } = data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let token = localStorage.getItem("token");
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    let header = {
      "Content-Type": contentType,
      token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isClinicValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
  }

  isClinicValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data) || (responseJson);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ClinicDetails & DoctorDetailsType & object) => {
    if (apiRequestCallId === this.getClinicDetailsApiCallId) {
      this.getClinicDetailsIndexSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postNewCreateClinicDestailsApiCallId) {
      this.addHandleNewClinicSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.editClinicApiCallId) {
      this.editClinicSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      this.getProfileSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteImageApiCallId) {
      this.clinicImageDeleteSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteClinicApiCallId) {
      this.deleteClinicFunctionSuccessCallBack(responseJson);
    }
  };

  getClinicDetailsIndexfunction = async () => {
    this.setState({isLoader : true})
    this.getClinicDetailsApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getClinicDetailsIndexApiEndPoint,
    });
  };

  getClinicDetailsIndexSuccessCallBack = (response: ClinicDetails) => {
    this.setState({
      clinicDetails: response,
      isLoader: false
    });
  };

  newHandleClinicName = (event: { target: { value: string } }) => {
    const inputValue = event.target.value;
    const clinicNameRegex = /^[A-Za-z ']{2,40}$/;
    this.setState({
      newClinicName: event.target.value,
    }, () => {
      if (clinicNameRegex.test(inputValue)) {
        this.setState({
          newClinicNameErr: "",
        });
      }
      else if (inputValue.length > 40) {
        this.setState({
          newClinicNameErr: "Please enter a clinic name within character limit",
        });
      }
      else {
        this.setState({
          newClinicNameErr: inputValue ? "Please enter a valid clinic name" : "This field is required.",
        });
      }
    });
  };

  newHandleClinicAddress = (event: { target: { value: string } }) => {
    const value = event.target.value;
    const address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    this.setState({
      newClinicAddress: event?.target.value,
    }, () => {
      if (address.test(value)) {
        this.setState({
          newClinicAddressErr: "",
        });
      } else if (value.length > 100){
        this.setState({
          newClinicAddressErr: "Please enter the clinic address within character limit",
        });
      }
      else {
        this.setState({
          newClinicAddressErr: value ? "Please enter a valid clinic address" : "This field is required.",
        });
      }
    });
  };

  newHandleClinicContactDetails = (event: { target: { value: string } }) => {
    const clinicNumberRegex = /^[6-9]\d{9}$/;
    const clinicInputValue = event.target.value;
    this.setState({
      newClinicContact: event?.target.value
    }, () => {
      if (clinicNumberRegex.test(clinicInputValue)) {
        this.setState({
          newClinicContactErr: "",
        });
      } else {
        this.setState({
          newClinicContactErr: clinicInputValue ? "Please enter a valid clinic number" : "This field is required.",
        });
      }
    })
  };

  newHandleClinicLink = (event: { target: { value: string } }) => {
    const clincLinkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    const clinicInputValue = event.target.value;
    this.setState({
      newClinicLink: event?.target.value
    }, () => {
      if (clincLinkRegex.test(clinicInputValue)) {
        this.setState({
          newClinicLinkErr: "",
        });
      } else {
        this.setState({
          newClinicLinkErr: clinicInputValue ? "Please enter a valid link address" : "This field is required.",
        });
      }
    })
  };

  addHandleNewClinic = async () => {
    const clinicNameRegex = /^[A-Za-z ']{2,40}$/;
    const address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    const clinicNumberRegex = /^[6-9]\d{9}$/;
    const clincLinkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    if (this.state.newClinicName.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic name field",
      });
    }
    if (this.state.newClinicAddress.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic address field",
      });
    }
    if (this.state.newClinicContact.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic contact field",
      });
    }
    if (this.state.newClinicLink.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic link field",
      });
    }
    if (!clinicNameRegex.test(this.state.newClinicName)) {
      return this.setState({
        newClinicNameErr: "Please enter a valid clinic name",
      });
    }
    if (!address.test(this.state.newClinicAddress)) {
      return this.setState({
        newClinicAddressErr: "Please enter a valid clinic address",
      });
    }
    if (!clinicNumberRegex.test(this.state.newClinicContact)) {
      return this.setState({
        newClinicContactErr: "Please enter a valid clinic number",
      });
    }
    if (!clincLinkRegex.test(this.state.newClinicLink)) {
      return this.setState({
        newClinicLinkErr: "Please enter a valid clinic link",
      });
    }
    let apiBody = {
      name: this.state.newClinicName,
      address: this.state.newClinicAddress,
      contact_no: this.state.newClinicContact,
      link: this.state.newClinicLink,
      images: this.state.clinicImage
    }
    this.postNewCreateClinicDestailsApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.postDoctorProfileImageApiMethod,
      endPoint: configJSON.postCreateClinicDestailsApiEndPoint,
      body: apiBody
    });
    this.setState({isLoader : true})
  };

  addHandleNewClinicSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Clinic created successfully",
    });
    this.setState({
      addClinicModal: false,
      isLoader: false
    })
    this.getClinicDetailsIndexfunction();
    this.setState({
      newClinicName: "",
      newClinicAddress: "",
      newClinicContact: "",
      newClinicLink: "",
      clinicImage: [],
      newClinicNameErr:"",
      newClinicAddressErr:"",
      newClinicContactErr:"",
      newClinicLinkErr:""
    })
  };

  clinicImgFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files: File[] = [...event.target.files as any];
    let base64Images: string[] = [];
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif','image/jpg'];
    files.forEach((file: any, index: number) => {
      if (allowedImageTypes.includes(file.type)) {
      const reader = new FileReader()
      reader.onload = () => {
        base64Images.push(reader.result as string);
        if (base64Images.length === files.length) {
          this.setState({ clinicImage: base64Images });
        }
      };
      reader.readAsDataURL(file);
    } else {
      notification["error"]({
        message: "Please upload valid image file.",
      });
    }
    });
  };

  handelShowPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handlePopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  handleEditProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  handleLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  closeEditModal = () => {
    this.setState({ 
      clinicEditModal: false ,
      editNewClinicNameErr: "",
      editNewClinicAddressErr: "",
      editContactErr: "",
      editClinicLinkErr: "",
      editClinicImages: []
    });
  };

  openEditModal = (clinic: ClinicDetailsAttribute) => {
    this.setState({
      clinicEditModal: true,
      clinicId: clinic.id,
      editClinicName: clinic.attributes.name,
      editClinicAddress: clinic.attributes.address,
      editClinicArea: clinic.attributes.area,
      editClinicCity: clinic.attributes.city,
      editConatct: clinic.attributes.contact_no,
      editClinicLink: clinic.attributes.link,
      editPresentImage: clinic.attributes.clinic_images
    });
  };

  getProfilefunction = async () => {
    this.getProfileDetailsCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint
    });
  };

  getProfileSuccessCallBack = (response: DoctorDetailsType) => {
    this.setState({
      doctorProfileData: response
    });
  };

  editHandleClinicName = (event: { target: { value: string } }) => {
    const editInputValue = event.target.value;
    const editClinicNameRegex = /^[A-Za-z ']{2,40}$/;
    this.setState({
      editClinicName: event.target.value,
    }, () => {
      if (editClinicNameRegex.test(editInputValue)) {
        this.setState({
          editNewClinicNameErr: "",
        });
      } 
      else if (editInputValue.length > 40) {
      this.setState({
        editNewClinicNameErr: "Please enter a clinic name within character limit",
      });
      }
      else {
        this.setState({
          editNewClinicNameErr: editInputValue ? "Please enter a valid clinic name" : "This field is required.",
        });
      }
    });
  };

  
  editHandleClinicAddress = (event: { target: { value: string } }) => {
    const address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    const input = event.target.value;
    this.setState({
      editClinicAddress: event?.target.value,
    }, () => {
      if (address.test(input)) {
        this.setState({
          editNewClinicAddressErr: "",
        });
      } else if(input.length > 100){
        this.setState({
          editNewClinicAddressErr: "Please enter the clinic address within character limit",
        });
      }
       else {
        this.setState({
          editNewClinicAddressErr: input ? "Please enter a valid clinic address" : "This field is required.",
        });
      }
    });
  };

  editHandleClinicContactDetails = (event: { target: { value: string } }) => {
    const contactRegex = /^[6-9]\d{9}$/;
    const inputValue = event.target.value;
    this.setState({
      editConatct: event?.target.value
    }, () => {
      if (contactRegex.test(inputValue)) {
        this.setState({
          editContactErr: "",
        });
      } else {
        this.setState({
          editContactErr: inputValue ? "Please enter a valid clinic number" : "This field is required.",
        });
      }
    })
  };

  editHandleClinicLink = (event: { target: { value: string } }) => {
    const clinicInputValue = event.target.value;
    const clincLinkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    this.setState({
      editClinicLink: event?.target.value
    }, () => {
      if (clincLinkRegex.test(clinicInputValue)) {
        this.setState({
          editClinicLinkErr: "",
        });
      } else {
        this.setState({
          editClinicLinkErr: clinicInputValue ? "Please enter a valid link address" : "This field is required.",
        });
      }
    })
  };

  editImgFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const base64Images: string[] = [];
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif','image/jpg'];
    let files: File[] = [...event.target.files as any];
    files.forEach((file: any, index: number) => {
      if (allowedImageTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        base64Images.push(reader.result as string);
        if (base64Images.length === files.length) {
          this.setState({ editClinicImages: base64Images ,editClinicImageName:file.name  });
        }
      };
      reader.readAsDataURL(file);
    } else {
      notification["error"]({
        message: "Please upload valid image file.",
      });
    }
    });
  };

  editClinicDetails = async () => {
    const editClinicNameRegex = /^[A-Za-z ']{2,40}$/;
    let address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    let contactRegex = /^[6-9]\d{9}$/;
    const clincLinkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    if (this.state.editClinicName.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic name field",
      });
    }
    if (this.state.editClinicAddress.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic address field",
      });
    }
    if (this.state.editConatct.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic contact field",
      });
    }
    if (this.state.editClinicLink.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic link field",
      });
    }
    if (!editClinicNameRegex.test(this.state.editClinicName)) {
      return this.setState({
        editNewClinicNameErr: "Please enter a valid clinic name",
      });
    }
    if (!address.test(this.state.editClinicAddress)) {
      return this.setState({
        editNewClinicAddressErr: "Please enter a valid clinic address",
      });
    }
    if (!contactRegex.test(this.state.editConatct)) {
      return this.setState({
        editContactErr: "Please enter a valid clinic number",
      });
    }
    if (!clincLinkRegex.test(this.state.editClinicLink)) {
      return this.setState({
        editClinicLinkErr: "Please enter a valid clinic link",
      });
    }

    let editClinicBodyImage = {
      name: this.state.editClinicName,
      address: this.state.editClinicAddress,
      contact_no: this.state.editConatct,
      link: this.state.editClinicLink,
      images: this.state.editClinicImages 
    }
    let editClinicBody = {
      name: this.state.editClinicName,
      address: this.state.editClinicAddress,
      contact_no: this.state.editConatct,
      link: this.state.editClinicLink,
    }
    this.editClinicApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: `${configJSON.editClinicDetailsApiEndPoint}?id=${this.state.clinicId}`,
      body: this.state.editClinicImageName ? editClinicBodyImage : editClinicBody
    });
    this.setState({isLoader : true})
  };

  editClinicSuccessCallBack = (response: object) => {
    this.setState({
      isLoader : false,
      clinicEditModal: false,
      editClinicName: "",
      editClinicAddress: "",
      editConatct: "",
      editClinicLink: "",
      editClinicImages: [],
      editNewClinicNameErr: "",
      editNewClinicAddressErr: "",
      editContactErr: "",
      editClinicLinkErr: ""
    }, () => {
      this.getClinicDetailsIndexfunction();
      notification["success"]({
        message: "Clinic details updated.",
      });
    });
  };

  editImage = () => {
    this.setState({
      deleteClinicImage: true
    });
  };

  handleCheckBoxClick = (item: HospitalImage, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target.checked) {
      this.setState({
        getImageId: item.id
      });
    } else {
      this.setState({
        getImageId: 0
      });
    }
  };

  clinicImageDeletefunction = async () => {
    this.deleteImageApiCallId = this.apiCall({
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: `${configJSON.deleteImageApiEndPoint}?id=${this.state.getImageId}`,
    });
    this.setState({isLoader : true})
  };

  clinicImageDeleteSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Clinic image deleted successfully.",
    });
    this.setState({
      deleteClinicImage: false,
      getImageId: 0,
      clinicEditModal: false,
      isLoader : false
    }, () => {
      this.getClinicDetailsIndexfunction();
    });
  };

  handleDeleteClinic = (clinicId: string) => {
    this.setState({
     deleteClinicId : clinicId,
     isClinicDelete: true
    });
 };

 closeDeleteModal = () => {
  this.setState({
    isClinicDelete : false,
    deleteClinicId: ""
   });
 };

 deleteClinicFunction = async () => {
  this.deleteClinicApiCallId = this.apiCall({
    contentType: configJSON.exampleApiContentType,
    method: configJSON.putDeleteImageApiMethod,
    endPoint: `${configJSON.deleteClinicApiEndPoint}?id=${this.state.deleteClinicId}`
  });
};

deleteClinicFunctionSuccessCallBack = (response: object) => {
  notification["success"]({
    message: "Clinic deleted successfully",
  });
  this.setState({
    isClinicDelete : false,
    deleteClinicId: ""
   });
   this.getClinicDetailsIndexfunction();
};

handleCopyLink = () => {
  const text: any = document.getElementById("clinicCopyId");
    navigator.clipboard?.writeText(text.innerText)
    notification["success"]({
      message: "Clinic link copied",
    });
};

clinicImageFunction = () => {
  this.setState({
    deleteClinicImage: true
  });
};

closeDeleteClinicModal = () => {
  this.setState({
    isClinicDelete : false,
    deleteClinicId: ""
   });
 };

 handleEditCopyLink = () => {
  const text: any = document.getElementById("copyLinkId");
    navigator.clipboard?.writeText(text.value)
    notification["success"]({
      message: "Clinic link copied",
    });
};

handleNewCopyLink = () => {
  const link: any = document.getElementById("newClinicId");
    navigator.clipboard?.writeText(link.value)
    notification["success"]({
      message: "Clinic link copied",
    });
};
  // Customizable Area End
}

//** App.tsx - WEB ***//
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import firebase from "firebase";
import 'antd/dist/antd.css';
import "./App.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import 'react-calendar/dist/Calendar.css';

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyAo58geA_TETmelHGlHqjFcw2kJzizfIYY",
  authDomain: "chat-app-71311.firebaseapp.com",
  projectId: "chat-app-71311",
  databaseURL: "https://chat-app-71311-default-rtdb.asia-southeast1.firebasedatabase.app",
  storageBucket: "chat-app-71311.appspot.com",
  messagingSenderId: "305921683470",
  appId: "1:305921683470:web:13d1ec5aa5d8ea034e4c5f",
  measurementId: "G-463N7Y9R4M"
});

class App extends Component {

  render() {
    return (
      <>
        <WebRoutes />
      </>
    );
  }
}

export default App;

import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { FacebookIcon,YoutubeIcon,LinkedInIcon,AppStroeIcon,instaIcon,playStore } from "../../blocks/splashscreen/src/assets";
// Customizable Area End

type FooterCommonComponentProps = {
    // Customizable Area Start

    // Customizable Area End
};

const FooterCommonComponent = ({
    // Customizable Area Start

    // Customizable Area End
}: FooterCommonComponentProps) => {
    // Customizable Area Start

    return (
        <div className="footer-main-container">
        <Row gutter={[16, 32]}>
          <Col lg={6} md={6} sm={12} xs={24}>
            <h2 className="footer-section-heading">Know Us</h2>
            <ul className="footer-section-list">
              <li>
                <Link className="footer-section-list-item" to="/">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="footer-section-list-item" to="/contactus">
                  Contact Us
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <h2 className="footer-section-heading">Our Policies</h2>
            <ul className="footer-section-list">
              <li>
                <Link className="footer-section-list-item" to="/privacypolicy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="footer-section-list-item" to="/termsandconditions">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <h2 className="footer-section-heading">Our Services</h2>
            <ul className="footer-section-list">
              <li>
                <Link className="footer-section-list-item" to="/">
                  Book Lab Tests
                </Link>
              </li>
              <li>
                <Link className="footer-section-list-item" to="/">
                  Consult a Doctor
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <h2 className="footer-section-heading">Connect</h2>
            <div className="footer-social-media-container">
              <img
                className="footer-social-icon"
                src={FacebookIcon}
                alt="facebook-icon"
              />
              <img
                className="footer-social-icon"
                src={instaIcon}
                alt="linked-in"
              />
              <img
                className="footer-social-icon footer-youtube-icon"
                src={YoutubeIcon}
                alt="youtube-icon"
              />
              <img
                className="footer-social-icon"
                src={LinkedInIcon}
                alt="linked-in"
              />
            </div>
            <h2 className="footer-section-heading-2">Download App</h2>
            <div className="footer-social-media-container">
              <img
                className="footer-social-icon"
                src={playStore}
                alt="facebook-icon"
              />
              <img
                className="footer-social-icon"
                src={AppStroeIcon}
                alt="linked-in"
              />
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <p className="bottom-text">
              &#169; 2022 Meril Health. All Rights Reserved
            </p>
          </Col>
        </Row>
      </div>
    );
    // Customizable Area End
};
// Customizable Area Start
export default FooterCommonComponent
// Customizable Area End


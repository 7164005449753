import React from "react";
// Customizable Area Start
import { Input, Button, Steps } from "antd";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import NewAddressPageController from "./NewAddressPageController.web";
import {
  merilLogo,
  shoppingcart,
  checklist,
  placeholder,
  getmoney,
} from "./assets";

let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class NewAddressPage extends NewAddressPageController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <div>
        <div>
          <img src={merilLogo} />
        </div>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderAddress = () => {
    return (
      <>
        <div>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginTop: "70px",
            }}
          >
            <p className="heading">Add New Address</p>
            <div style={{ width: "40%" }}>
              <label className="text-label-css">Full Name</label>
              <Input
                className="text-input-css"
                name="fullName"
                value={this.state.name}
                data-test-id={"fullNameId"}
                onChange={this.handleChangesFullName}
              />
            </div>

            <div style={{ width: "40%" }}>
              <label className="text-label-css">
                Flat, House no, Buildng name
              </label>
              <Input
                className="text-input-css"
                name="flathouse"
                data-test-id={"buildingId"}
                onChange={this.handleChangesFlatHouse}
              />
            </div>
            <div style={{ width: "40%" }}>
              <label className="text-label-css">Area / Street Name</label>
              <Input
                className="text-input-css"
                name="areasStreet"
                data-test-id={"areaId"}
                onChange={this.handleChangesArea}
              />
            </div>
            <div style={{ width: "40%" }}>
              <label className="text-label-css">Landmark</label>
              <Input
                className="text-input-css"
                name="landmark"
                data-test-id={"landmarkId"}
                onChange={this.handleChangesLandmark}
              />
            </div>
            <div style={{ width: "40%" }}>
              <label className="text-label-css">Pincode</label>
              <Input
                className="text-input-css"
                name="pincode"
                data-test-id={"pincodeId"}
                maxLength={6}
                onChange={this.handleChangesPincode}
              />
            </div>

            <div style={{ width: "40%" }}>
              <label className="text-label-css">Town / City</label>

              <Input
                className="text-input-css"
                name="townCity"
                data-test-id={"cityId"}
                onChange={this.handleChangesCity}
              />
            </div>
            <div style={{ width: "40%" }}>
              <label className="text-label-css">State</label>

              <Input
                className="text-input-css"
                name="state"
                data-test-id={"stateId"}
                onChange={this.handleChangesState}
              />
            </div>

            <Button
              className="save-btn-form"
              data-test-id={"continueId"}
              onClick={this.handleSubmits}
            >
              Save
            </Button>
          </form>
        </div>
      </>
    );
  };
  // Customizable Area En
  // Customizable Area Start
  render() {
    const itemsOverview = [
      {
        title: "Cart",
        icon: <img src={shoppingcart} />,
      },
      {
        title: "Shopping",
        icon: <img src={checklist} />,
      },
      {
        title: "Address",
        icon: <img src={placeholder} />,
      },
      {
        title: "Payment",
        icon: <img src={getmoney} />,
      },
    ];
    return (
      <div>
        <div>
          <div className="box-with-shadow">
            <div
              style={{
                padding: "32px 6%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img src={merilLogo} alt="Meril Logo" />
              <div style={{ width: "27%" }}>
                <Steps
                  current={4}
                  items={itemsOverview}
                  labelPlacement="vertical"
                ></Steps>
              </div>
            </div>
          </div>
        </div>
        <div className="itemContainerWiidth">{this.renderAddress()}</div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default NewAddressPage;
export { NewAddressPage };
// Customizable Area Start

// Customizable Area End

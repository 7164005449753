import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter, RouteProps, Redirect } from 'react-router-dom';
/** Routes List start */
import LandingPage from "../../blocks/splashscreen/src/Landingpage.web";
import SignUp from "../../blocks/social-media-account-registration/src/SignUp.web";
import SignUpOtp from "../../blocks/otp-input-confirmation/src/SignUpOtp.web";
import Login from "../../blocks/email-account-login/src/Login.web";
import PatientAppointment from "../../blocks/appointmentmanagement/src/PatientAppointment.web";
import DoctorProfile from "../../blocks/appointmentmanagement/src/DoctorProfile.web"
import Services from "../../blocks/shoppingcart/src/Service.web"
import Therapies from "../../blocks/shoppingcart/src/Therapiespage.web"
import PatientAppointmentCheckout from "../../blocks/appointmentmanagement/src/PatientAppointmentCheckout.web"
import LoginOtp from "../../blocks/otp-input-confirmation/src/LoginOtp.web";
import PatientRegistration from "../../blocks/splashscreen/src/PatientRegistration.web"
import PatientUpdate from "../../blocks/splashscreen/src/PatientUpdate.web"
import PatientAppointmentPayment from "../../blocks/appointmentmanagement/src/PatientAppointmentPayment.web"
import PatientAppointmentDashboard from "../../blocks/appointmentmanagement/src/PatientAppointmentDashboard.web"
import PatientAppointmentLandingpage from "../../blocks/splashscreen/src/PatientAppointmentLandingpage.web"
import TherapiesDetailspage from "../../blocks/shoppingcart/src/TherapiesDetailspage.web";
import TopTherapiesDetailspage from "../../blocks/splashscreen/src/TrendingTherapiesDetials.web";
import HospitalListPage from "../../blocks/shoppingcart/src/HospitalListPage.web";
import DoctorListPage from "../../blocks/shoppingcart/src/DoctorListPage.web";
import DoctorRegister from "../../blocks/email-account-registration/src/DoctorRegister.web";
import PatientProfilePage from "../../blocks/dataencryption/src/PatientProfilePage.web";
import ProfileContactUsPage from "../../blocks/dataencryption/src/ProfileContactUsPage.web";
import PatientManageAddressPage from "../../blocks/dataencryption/src/PatientManageAddressPage.web";
import UpdateManageAddressPage from "../../blocks/dataencryption/src/UpdateManageAddressPage.web";
import DoctorHome from "../../blocks/splashscreen/src/DoctorDashboard/DoctorDashboardHomePage.web";
import PatientProfileAddressPage from "../../blocks/dataencryption/src/PatientProfileAddressPage.web";
import ProfileSettingsPage from "../../blocks/dataencryption/src/ProfileSettingsPage.web";
import PatientAppointmentsPage from "../../blocks/dataencryption/src/PatientAppointmentsPage.web";
import FeatureComingSoon from "../../blocks/dataencryption/src/FeatureComingSoon.web";
import DeliveryAddressPage from "../../blocks/photolibrary/src/DeliveryAddressPage.web";
import MedicineCartPage from "../../blocks/photolibrary/src/MedicineCartPage.web";
import MedicineDetailsPage from "../../blocks/photolibrary/src/MedicineDetailsPage.web";
import MedicineServicesPage from "../../blocks/photolibrary/src/MedicineServicesPage.web";
import NewAddressPage from "../../blocks/photolibrary/src/NewAddressPage.web";
import OrderMedicinePage from "../../blocks/photolibrary/src/OrderMedicinePage.web";
import DoctorSchedule from "../../blocks/scheduling/src/DoctorSchedule.web";
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2.web";
import EditDoctorProfileDetails from "../../blocks/customisableuserprofiles2/src/EditDoctorProfileDetails.web";
import AppointmentsDetails from "../../blocks/appointmentmanagement/src/AppointmentsDetails.web";
import { DoctorUpcomingAppointment } from '../../blocks/appointmentmanagement/src/DoctorUpcomingAppointment.web';
import ViewPrescription from "../../blocks/appointmentmanagement/src/ViewPrescription.web";
import InvoiceDetails from "../../blocks/appointmentmanagement/src/InvoiceDetails.web";
import Mypatient from "../../blocks/customisableuserprofiles2/src/Mypatient.web";
import ScheduleDetailsScreen from "../../blocks/customisableuserprofiles2/src/ScheduleDetailsScreen.web";
import Addpatient from "../../blocks/customisableuserprofiles2/src/Addpatient.web";
import MyClinic from "../../blocks/customisableuserprofiles2/src/Myclinic.web";
import UploadDocument from "../../blocks/customisableuserprofiles2/src/UploadDocument.web";
import ViewDocuments from "../../blocks/customisableuserprofiles2/src/ViewDocuments.web";
import Feespackagemanagement from "../../blocks/customisableuserprofiles2/src/Feespackagemanagement.web";
import PackageDetails from "../../blocks/customisableuserprofiles2/src/PackageDetails.web";
import Video from "../../blocks/videos/src/Videos.web";
import Summary from "../../blocks/appointmentmanagement/src/Summary.web"
import PatientAppointmentJoiningPage from "../../blocks/dataencryption/src/PatientAppointmentConfirmationPage.web";
import TermsAndConditions from "../../blocks/termsconditions/src/TermsAndCondtions.web";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import HospitalAdmin from "../../blocks/customisableuserprofiles2/src/HospitalAdmin.web";
import AdminProfile from "../../blocks/customisableuserprofiles2/src/AdminProfile.web"
// import encodeSans from 'typeface-encode-sans';
import Contactus from "../../blocks/contactus/src/Contactus.web";
import AdminLogin from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DoctorsList from '../../blocks/appointmentmanagement/src/DoctorList.web';
import Chat from '../../blocks/chat/src/Chat.web';
import ChatView from '../../blocks/chat/src/ChatView.web';
import PatientChat from '../../blocks/chat/src/PatientChat.web';
/** Routes List End */


const encodeSans: any = {
    fontFamily: 'Encode Sans semibold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400, 
    src: `
      local('Encode Sans'),
      local('EncodeSans-Regular'),
      url(https://fonts.gstatic.com/s/encode-sans/v3/2-cgHY4CSsqTlCyX1Rn4l-wl61U.woff2) format('woff2')
    `,
    unicodeRange: 'U+0000-FFFF',
  };

const theme = createTheme({
    typography: {
        fontFamily: 'Encode Sans semibold !important'
    },
    palette: {
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF'
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                  '@font-face': [encodeSans]
            }
        }
    }
});
// /** Private Routes start */
const routeMap = {
    AdminLogin: {
        component: AdminLogin,
        path: '/AdminLogin',
    },
    AdminProfile: {
        component: AdminProfile,
        path: '/AdminProfile',
        roles: ['Admin']
    },
    HospitalAdmin: {
        component: HospitalAdmin,
        path: '/AdminDashboard/:name',
        roles: ['Admin']
    },
    DoctorsList: {
        component: DoctorsList,
        path: '/DoctorsList',
        roles: ['Admin']
    }
}
// /** Private Roues End */

function WebRoutes() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Router>
                    <Suspense fallback={null}>
                        <Switch>
                            <Route
                                path="/"
                                exact
                                render={props => <LandingPage {...props} />}
                            />
                            <Route
                                path="/signup"
                                exact
                                render={props => <SignUp id={''} {...props} />}
                            />
                            <Route
                                path="/otp"
                                exact
                                render={props => <SignUpOtp id={''} navigation={undefined} {...props} />}
                            />
                            <Route
                                path="/patient-appointment-landingpage"
                                exact
                                render={props => <PatientAppointmentLandingpage {...props} />}
                            />
                            <Route
                                path="/patient-appointment"
                                exact
                                render={props => <PatientAppointment {...props} />}
                            />
                            <Route
                                path="/patient-appointment-checkout"
                                exact
                                render={props => <PatientAppointmentCheckout {...props} />}
                            />
                            <Route
                                path="/patient-appointment-payment"
                                exact
                                render={props => <PatientAppointmentPayment {...props} />}
                            />
                            <Route
                                path="/patient-appointment-dashboard"
                                exact
                                render={props => <PatientAppointmentDashboard {...props} />}
                            />
                            <Route
                                path="/doctorprofile"
                                exact
                                render={props => <DoctorProfile displaySpinner={function (): void {
                                    throw new Error('Function not implemented.');
                                }} hideLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} showLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} id={''} {...props} />}
                            />

                            <Route
                                path="/login"
                                exact
                                render={props => <Login id={''} {...props} />}
                            />
                            <Route
                                path="/loginotp"
                                exact
                                render={props => <LoginOtp id={''} {...props} />}

                            />
                            <Route
                                path="/Services"
                                exact
                                render={props => <Services id={''} {...props} />}

                            />
                            <Route
                                path="/Therapies"
                                exact
                                render={props => <Therapies id={''} {...props} />}
                            />

                            <Route
                                path="/signup-patient"
                                exact
                                render={props => <PatientRegistration displaySpinner={function (): void {
                                    throw new Error('Function not implemented.');
                                }} hideLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} showLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} id={''} {...props} />}
                            />
                            <Route
                                path="/patienthome"
                                exact
                                render={props => <LandingPage {...props} />}
                            />
                            <Route
                                path="/TherapiesDetailspage"
                                exact
                                render={props => <TherapiesDetailspage id={''} {...props} />}
                            />

                            <Route
                                path="/patienthome/therapiesDetails"
                                exact
                                render={props => <TopTherapiesDetailspage id={''} {...props} />}
                            />
                            <Route
                                path="/therapiesDetails"
                                exact
                                render={props => <TopTherapiesDetailspage id={''} {...props} />}
                            />
                            <Route
                                path="/HospitalListPage"
                                exact
                                render={props => <HospitalListPage id={''} {...props} />}
                            />
                            <Route
                                path="/DoctorListPage"
                                exact
                                render={props => <DoctorListPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/doctorregister"
                                exact
                                render={props => <DoctorRegister displaySpinner={function (): void {
                                    throw new Error('Function not implemented.');
                                }} hideLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} showLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} id={''} {...props} />}
                            />
                            <Route
                                path="/PatientOrder"
                                exact
                                render={props => <PatientProfilePage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/PatientProfileAddressPage"
                                exact
                                render={props => <PatientProfileAddressPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/ProfileSettingsPage"
                                exact
                                render={props => <ProfileSettingsPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/DeliveryAddressPage"
                                exact
                                render={props => <DeliveryAddressPage id={''} {...props} />}
                            />
                            <Route
                                path="/MedicineCartPage"
                                exact
                                render={props => <MedicineCartPage id={''} {...props} />}
                            />
                            <Route
                                path="/MedicineDetailsPage"
                                exact
                                render={props => <MedicineDetailsPage id={''} {...props} />}
                            />
                            <Route
                                path="/MedicineServicesPage"
                                exact
                                render={props => <MedicineServicesPage id={''} {...props} />}
                            />
                            <Route
                                path="/NewAddressPage"
                                exact
                                render={props => <NewAddressPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/OrderMedicinePage"
                                exact
                                render={props => <OrderMedicinePage id={''} {...props} />}
                            />
                            <Route
                                path="/doctorhome"
                                exact
                                render={props => <DoctorHome displaySpinner={function (): void {
                                    throw new Error('Function not implemented.');
                                }} hideLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} showLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} id={''} {...props} />}
                            />
                            <Route
                                path="/PatientProfileAddressPage"
                                exact
                                render={props => <PatientProfileAddressPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/ProfileSettingsPage"
                                exact
                                render={props => <ProfileSettingsPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/doctorschedule"
                                exact
                                render={props => <DoctorSchedule id={''} {...props} />}
                            />
                            <Route
                                path="/ProfileContactUsPage"
                                exact
                                render={props => < ProfileContactUsPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/doctorprofiledetails"
                                exact
                                render={props => <Customisableuserprofiles2 navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/editdoctordetails"
                                exact
                                render={props => <EditDoctorProfileDetails navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/FeatureComingSoon"
                                exact
                                render={props => < FeatureComingSoon navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/PatientUpdate"
                                exact
                                render={props => < PatientUpdate displaySpinner={function (): void {
                                    throw new Error('Function not implemented.');
                                }} hideLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} showLoader={function (): void {
                                    throw new Error('Function not implemented.');
                                }} id={''} {...props} />}
                            />
                            <Route
                                path="/PatientManageAddressPage"
                                exact
                                render={props => < PatientManageAddressPage id={''} {...props} />}
                            />
                            <Route
                                path="/UpdateManageAddressPage"
                                exact
                                render={props => <UpdateManageAddressPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/PatientAppointmentsPage"
                                exact
                                render={props => <PatientAppointmentsPage navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/appointmentsdetails"
                                exact
                                render={props => <AppointmentsDetails  {...props} />}
                            />
                            <Route
                                path="/upcomingavailability"
                                exact
                                render={props => <DoctorUpcomingAppointment  {...props} />}
                            />
                            <Route
                                path="/viewprescription"
                                exact
                                render={props => <ViewPrescription  {...props} />}
                            />
                            <Route
                                path="/invoicedetails"
                                exact
                                render={props => <InvoiceDetails {...props} />}
                            />
                            <Route
                                path="/mypatient"
                                exact
                                render={props => <Mypatient {...props} />}
                            />
                            <Route
                                path="/scheduledetails"
                                exact
                                render={props => <ScheduleDetailsScreen {...props} />}
                            />
                            <Route
                                path="/addpatient"
                                exact
                                render={props => <Addpatient {...props} />}
                            />
                            <Route
                                path="/myclinics"
                                exact
                                render={props => <MyClinic {...props} />}
                            />
                            <Route
                                path="/uploaddoc"
                                exact
                                render={props => <UploadDocument {...props} />}
                            />
                            <Route
                                path="/viewdocument"
                                exact
                                render={props => <ViewDocuments {...props} />}
                            />
                            <Route
                                path="/feespackagemanagement"
                                exact
                                render={props => <Feespackagemanagement {...props} />}
                            />
                            <Route
                                path="/packagedetails"
                                exact
                                render={props => <PackageDetails {...props} />}
                            />
                            <Route
                                path="/videoscreen"
                                exact
                                render={props => <Video   {...props} id="" navigation={() => { }} />}

                            />

                            <Route
                                path="/patientAppointmentjoiningPage"
                                exact
                                render={props => <PatientAppointmentJoiningPage {...props} id='' navigation={() => { }} />}
                            />
                            <Route
                                path="/termsandconditions"
                                exact
                                render={props => <TermsAndConditions {...props} />}
                            />
                            <Route
                                path="/privacypolicy"
                                exact
                                render={props => <PrivacyPolicy {...props} />}
                            />
                            <Route
                                path="/Summary"
                                exact
                                render={props => <Summary navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/contactus"
                                exact
                                render={props => <Contactus navigation={undefined} id={''} {...props} />}
                            />
                            <Route
                                path="/chat"
                                exact
                                render={props => <Chat navigation={undefined} id={''} {...props}/>}
                            />
                            <Route
                                path="/chatview"
                                exact
                                render={props => <ChatView navigation={undefined} id={''} {...props}/>}
                            />
                            <Route
                                path="/patientmessage"
                                exact
                                render={props => <PatientChat {...props}/>}
                            />
                            <ThemeProvider theme={theme}>
                                {WebRoutesGenerator({ routeMap })}
                            </ThemeProvider>
                        </Switch>
                    </Suspense>
                </Router>
                <ToastContainer />
            </ThemeProvider>
        </>
    );
}
export default withRouter(WebRoutes);

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  selected_Patient: null | number;
  Name: string;
  house: string;
  street: string;
  landmarked: string;
  pincodeNo: string;
  townCityName: string;
  state: string;
  isModal: boolean;
  Name_error: string;
  house_error: string;
  street_error: string;
  pincodeNo_error: string;
  townCityName_error: string;
  state_error: string;
  landMarkedError: string;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UpdateManageAddressPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateManageAddressDetailsApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      selected_Patient: null,
      Name: "",
      house: "",
      street: "",
      landmarked: "",
      pincodeNo: "",
      townCityName: "",
      state: "",
      isModal: false,
      Name_error: "",
      house_error: "",
      street_error: "",
      pincodeNo_error: "",
      townCityName_error: "",
      state_error: "",
      landMarkedError: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (res.status === 500) {
        MESSAGE.error(`${res.error}. Please try again later.`, 4);
        return;
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (res && res.data) {
        this.updateManageArressDetailsDataSuccessCallBack(
          apiRequestCallId,
          res.data
        );
      } else if (res && res.errors) {
        notification["error"]({
          message: res.errors,
        });
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error",
        });
      }
      // Customizable Area End
    }
  };
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const addressData = this.props.location.state.addressData;
    console.log(addressData, "address");
    this.setState({
      Name: addressData.attributes.full_name,
      landmarked: addressData.attributes.landmark,
      house: addressData.attributes.building_name,
      pincodeNo: addressData.attributes.pincode,
      state: addressData.attributes.state,
      townCityName: addressData.attributes.city,
      street: addressData.attributes.area,
      selected_Patient: addressData.id
    });

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  updateManageArressDetailsDataSuccessCallBack = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.updateManageAddressDetailsApiCallId) {
      notification["success"]({
        message: "Your address has been updated successfully",
    });
      this.props.history.push("/PatientManageAddressPage")
    }
  };

  handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const Name = event.target.value;
    const alphaName = Name.replace(/[^a-zA-Z ]/g, "");
    this.setState({ Name: alphaName });
  };

  handleChangeHouse = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ house: event.target.value });
  };
  handleChangeAreaName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ street: event.target.value });
  };

  handleChangelandmarked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const landmark = event.target.value;
    const alphaLandmarkName = landmark.replace(/[^a-zA-Z\s]/g, "");
    this.setState({ landmarked: alphaLandmarkName });
  };

  handleChangepincodeNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numericPincode = event.target.value.replace(/\D/g, "").slice(0, 6);
    this.setState({ pincodeNo: numericPincode });
  };

  handleChangeCityName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cityName = event.target.value;
    const alphaCityName = cityName.replace(/[^a-zA-Z]/g, "");
    this.setState({ townCityName: alphaCityName });
  };
  handleChangeStateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stateName = event.target.value;
    const alphaStateName = stateName.replace(/[^a-zA-Z]/g, "");
    this.setState({ state: alphaStateName });
  };
  apihandleOnSubmit = async () => {
    
    const body = {
      data:{
      attributes: {
        full_name: this.state.Name,
        building_name: this.state.house,
        area: this.state.street,
        landmark: this.state.landmarked,
        pincode: this.state.pincodeNo,
        state: this.state.state,
        city: this.state.townCityName,
      },
    }
    
    };
    this.updateManageAddressDetailsApiCallId = ApiCall({
      contentType: configJSON.updateManageAddressApiContentType,
      method: configJSON.updateManageAddressAPiMethod,
      endPoint: `${configJSON.updateManageAddressAPiEndPoint}?id=${this.state.selected_Patient}`,
      body,
    });
  };

  handleOnCancel = () => {
    this.props.history.push("/PatientManageAddressPage")
    this.setState({ isModal: false });
  };
  validateForm = () => {
    const { Name, house, street, landmarked, pincodeNo, townCityName, state } =
      this.state;

    if (Name === "") {
      this.setState({ Name_error: "Please Enter Your full Name" });
    }
    if (house === "") {
      this.setState({ house_error: "Please Enter Your flathouse Name" });
    }
    if (street === "") {
      this.setState({ street_error: "Please Enter Your areaStreet" });
    }
    if (landmarked === "") {
      this.setState({ landMarkedError: "Please Enter Your landmark" });
    }

    if (pincodeNo === "") {
      this.setState({ pincodeNo_error: "Please Enter Your pincode" });
    }
    if (townCityName === "") {
      this.setState({ townCityName_error: "Please Enter Your townCity" });
    }
    if (state === "") {
      this.setState({ state_error: "Please Enter Your state" });
    }
  };

  handleOnSubmit = async (e: any) => {
    e?.preventDefault();
    this.validateForm();
    if (
      this.state.Name !== "" &&
      this.state.house !== "" &&
      this.state.street !== "" &&
      this.state.pincodeNo !== "" &&
      this.state.townCityName !== "" &&
      this.state.landmarked !== "" &&
      this.state.state !== ""
    ) {
      this.apihandleOnSubmit();
    }
  };
  // Customizable Area End
}

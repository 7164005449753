import { carouselImg1 } from "../../blocks/social-media-account-registration/src/assets";
import { carouselImg2 } from "../../blocks/social-media-account-registration/src/assets";
import { carouselImg3 } from "../../blocks/social-media-account-registration/src/assets";
import { carouselImg4 } from "../../blocks/social-media-account-registration/src/assets";
import { carouselImg5 } from "../../blocks/social-media-account-registration/src/assets";

export const carouselData = [
    {
        image: carouselImg1,
        title: "Book Appointments with Doctors",
        description: "Consult top doctors near you from the comfort of your home"
    },
    {
        image: carouselImg2,
        title: "Book High-End Surgical Procedures",
        description: "Get insured and advanced surgeries done by top doctors of India"
    },
    {
        image: carouselImg3,
        title: "Order medicines",
        description: "Get quality medicines deleivered to your door step"
    },
    {
        image: carouselImg4,
        title: "Book Lab Testing",
        description: "Get your test done by reputed and certified laboratories"
    },
    {
        image: carouselImg5,
        title: "Data Privacy & Security",
        description: "All of your data is private and encrypted using 256 bit encryption"
    }
]
import React from "react";
// Customizable Area Start
import {Typography,Card,Row,Col,Button} from "antd"
import { Link } from 'react-router-dom';
import FooterCommonComponent from "../../../components/src/FooterDoctor.web";
import NavBarCommonComponent from "../../../components/src/NavbarCommonComponent.web"
import { CertificationComponent } from "../../../components/src/CertificationComponent.web";
import MerilLoader from '../../../components/src/MerilLoader.web';
import {finalImg} from "./assets"
let config = require('../../../framework/src/config.js').baseURL;
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
// Customizable Area End

import PatientAppointmentPaymentController, {
  Props
} from "./PatientAppointmentPaymentController.web";


 class PatientAppointmentDashboard extends PatientAppointmentPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPatientDashboardNavigationTitle = () => {
    let isAppointmentActiveOnCheckout =
    window.location.pathname === "/patient-appointment-dashboard";
    let appointmentClassName = isAppointmentActiveOnCheckout ? "active" : "";
    return (
        <>
            <div className="navigationContainer">
                <Link to="/patienthome" >Home</Link>
                <Link className={appointmentClassName} to="/patient-appointment-landingpage" >Appointment</Link>
                <Link to="/services" >Services</Link>
                <Link to="/message" >Message</Link>
            </div>
        </>
    )
    }


  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
     <>
     <NavBarCommonComponent handleProfile={this.showDetailsHandler} data-test-id="handlePatientProfilePopup"/>
     <PatientProfilePopup data-test-id="handlePatientLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
     {this.renderPatientDashboardNavigationTitle()}

     <div className="doctorList">
        <div>
            <Row gutter={[48,24]} >
           <Col xs={24}  >
                <div className="logo-text-container" >
                  <div style={{marginTop:"32px"}}>

                    <img src={finalImg} alt="" className="successful-logo" />
                  </div>

                    <div>
                        <Typography className="booking-successful-message" data-test-id="textId">
                    Booking Successful
                    </Typography>

                    <Typography className="booking-appointment" >
                    You can see your booking in appointments
                    </Typography>
                    </div>

                  </div>
  
                   
           </Col>

            <Col xs={24} sm={16}  >

                  {
                    this.state.bookedAppointmentDetails?.map((bookedAppointment:any)=>(

                      <Card className="booking-detail-card" key={bookedAppointment.id} >
                      <Typography className="patient-appoitment-title" >Your Appointment</Typography>
                       <div className="patient-appointment-container"  >
                        <div>
                       <Typography className="appointment-subtitle" >
                       Mode of Consultation
                       </Typography>
                       <Typography className="appointment-text" >
                       {bookedAppointment.attributes.mode_type}
                       </Typography>

                       </div>

                       <div>
                       <Typography className="appointment-subtitle">
                       Date & Time
                       </Typography>
                       <Typography className="appointment-text" >
                        {bookedAppointment.attributes.appointment_date}, {bookedAppointment.attributes.time_slot} 
                    
                       </Typography>

                       </div>

                       <div>
                       <Typography className="appointment-subtitle" >
                       Meet
                       </Typography>
                       <Typography className="appointment-text" >
                       {bookedAppointment.attributes.doctor_full_name} 
                       </Typography>

                       </div>

                       </div>
                   </Card>

                    ))
                  }
                   

            </Col>


              <Col xs={24} sm={12}  >
                   
                   <Link to="/PatientAppointmentsPage" >
                   <Button className="go-to-appointment" >
                     Go to Appointments
                  </Button> 
                   </Link>

              </Col>

            </Row>

        </div>

     <CertificationComponent/>
     </div>
     <FooterCommonComponent/>
     </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default MerilLoader(PatientAppointmentDashboard);
export { PatientAppointmentDashboard };
// Customizable Area End

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End
export const configJSON = require("./config.js");
// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start
export interface SymptomNameType {
  id: number;
  name: string;
}
// Customizable Area End

// Customizable Area Start
export interface MinimalType {
  id: string;
  therapy_name: string;
}
// Customizable Area End
// Customizable Area Start
export interface TherapDetailsType {
  id: string;
  type: string;
  attributes: {
    id: number;
    therapy_type: string;
    therapy_name: string;
    featured_therapy_id: number;
    description: string;
    is_trading: boolean;
    is_top_cardiovascular_procedures: boolean;
    is_top_orthopaedics_procedures: boolean;
    symptoms_name: Array<SymptomNameType>;
    diagnosis_name: Array<SymptomNameType> | null;
    treatment_id: string;
    therapy_images: Array<HospitalsImagesType>;

  };
}
// Customizable Area End
// Customizable Area Start
export interface HospitalsListType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    distance: number;
    hospital_image: Array<HospitalsImagesType>;
  };
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  therapiesDetails: TherapDetailsType;
  PackageData: any;
  navigationList: any;
  showPopup: boolean;
  isLoginUser:boolean;


  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class TherapiesDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTherapyDetailsApiCallId: string | null = null;
  getHospitalsApiCallId: string | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      showPopup: false,
      isLoginUser: true,
      therapiesDetails: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          therapy_type: "",
          therapy_name: "",
          featured_therapy_id: 0,
          description: "",
          is_trading: false,
          is_top_cardiovascular_procedures: false,
          is_top_orthopaedics_procedures: false,
          symptoms_name: [],
          diagnosis_name: [],
          treatment_id: "",
          therapy_images: [],
        },
      },
      PackageData: [
        {
          id: 1,
          name: "Gold Package",
          label1: "General / Special Room",
          label2: "24 hrs Nurse available",
          label3: "Free Doctor Visit",
          img1: "https://s3-alpha-sig.figma.com/img/2a56/c8bb/540c4ab394dbe74620c9b90e8695fcd8?Expires=1691971200&Signature=Egb29QtvocZ9mo7lLTV-LmEhpuZLGtm7CAKILrcV1sCCJV5nHs5KB0KhwRsxAkUPPB3VuPYQtKTI0A2Gk0-3yjti-zzutp~O9ZT-iHsS909inUyosCuGzsH2Vi~wSgHP3Hi9xJxgFp5y4wsipd0ei4~3lb7oQweoV179hSPNyzqFoLYb45qc7RtJJz04oxq39~QUPPUe5ahpM4TLZfnt84bVjXjSnVRnaxvJ5EjaHbm2ELuvsebsSQlAdJK1qnYiufCLAWV~dCEDovOPlPJvmjQ8LCjXQQHFhjhqqJgyqX95FYYPY7AZ43VxVuPvj4r2QhJcKrwxh8t7afS~HEpSoA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
          img2: require("../assets/doc_female.png"),
          img3: require("../assets/doc.png"),
          priceRange: "starts from Rs 50,000",
        },
        {
          id: 2,
          name: "Platinum Package",
          label1: "General / Special Room",
          label2: "24 hrs Nurse available",
          label3: "Free Doctor Visit",
          img1: "https://s3-alpha-sig.figma.com/img/2a56/c8bb/540c4ab394dbe74620c9b90e8695fcd8?Expires=1691971200&Signature=Egb29QtvocZ9mo7lLTV-LmEhpuZLGtm7CAKILrcV1sCCJV5nHs5KB0KhwRsxAkUPPB3VuPYQtKTI0A2Gk0-3yjti-zzutp~O9ZT-iHsS909inUyosCuGzsH2Vi~wSgHP3Hi9xJxgFp5y4wsipd0ei4~3lb7oQweoV179hSPNyzqFoLYb45qc7RtJJz04oxq39~QUPPUe5ahpM4TLZfnt84bVjXjSnVRnaxvJ5EjaHbm2ELuvsebsSQlAdJK1qnYiufCLAWV~dCEDovOPlPJvmjQ8LCjXQQHFhjhqqJgyqX95FYYPY7AZ43VxVuPvj4r2QhJcKrwxh8t7afS~HEpSoA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
          img2: require("../assets/doc_female.png"),
          img3: require("../assets/doc.png"),
          priceRange: "starts from Rs 50,000",
        },
        {
          id: 3,
          name: "Diamond Package",
          label1: "General / Special Room",
          label2: "24 hrs Nurse available",
          label3: "Free Doctor Visit",
          img1: "https://s3-alpha-sig.figma.com/img/2a56/c8bb/540c4ab394dbe74620c9b90e8695fcd8?Expires=1691971200&Signature=Egb29QtvocZ9mo7lLTV-LmEhpuZLGtm7CAKILrcV1sCCJV5nHs5KB0KhwRsxAkUPPB3VuPYQtKTI0A2Gk0-3yjti-zzutp~O9ZT-iHsS909inUyosCuGzsH2Vi~wSgHP3Hi9xJxgFp5y4wsipd0ei4~3lb7oQweoV179hSPNyzqFoLYb45qc7RtJJz04oxq39~QUPPUe5ahpM4TLZfnt84bVjXjSnVRnaxvJ5EjaHbm2ELuvsebsSQlAdJK1qnYiufCLAWV~dCEDovOPlPJvmjQ8LCjXQQHFhjhqqJgyqX95FYYPY7AZ43VxVuPvj4r2QhJcKrwxh8t7afS~HEpSoA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
          img2: require("../assets/doc_female.png"),
          img3: require("../assets/doc.png"),
          priceRange: "starts from Rs 50,000",
        },
      ],
      navigationList: [],
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getNavigationData(this.props.location.state.navigateList);
    this.getTherapiesDetails(this.props.location.state.ids.Id)
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isTherapiesDetailsResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInTherapiesDetailaResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isTherapiesDetailsResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInTherapiesDetailaResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getTherapyDetailsApiCallId) {
      this.getTherapiesDetailsSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {};
  // Customizable Area End
  getNavigationData = (data: any) => {
    this.setState({ navigationList: data });
  };

  getTherapiesDetails = async (id: string) => {
    this.getTherapyDetailsApiCallId = ApiCall({
      contentType: configJSON.getTherapyDetailsApiContentType,
      method: configJSON.getTherapyDetailsAPiMethod,
      endPoint: `${configJSON.getTherapyDetailsAPiEndPoint}?id=${id}`,
    });
  };
  getTherapiesDetailsSuccessCallBack = (res: any) => {
    this.setState({
      therapiesDetails: res?.data,
    });
  };

  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout succesfully",
    });
  };
}
// Customizable Area End

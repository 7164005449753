import React from "react";
// Customizable Area Start
import { Carousel } from "react-responsive-carousel";
import { carouselData } from "./CarouselData";
import { merilLogo } from "../../blocks/splashscreen/src/assets";
import {Link} from "react-router-dom";
// Customizable Area End


type CarouselCommonComponetProps = {
    // Customizable Area Start

    // Customizable Area End     
};

// Customizable Area Start
const logoRender = () => {
    return (
        <div className="logo-container_2">
            <Link to="/" >
            <img src={merilLogo} className="logo_2" alt=""/>
            </Link> 
        </div>
    );
};
// Customizable Area End

// Customizable Area Start
const carouselRender = () => {
    return (
        <Carousel
            interval={2000}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={false}
            className="carousel-style"
        >
            {carouselData.map((item: any, index: any) => {
                return (
                    <div className="signup-carousel" key={index}>
                        <div className="carousel-image">
                            <img src={item.image} className="img-1" alt="" />
                        </div>
                        <div className="signup-slider-content">
                            <div className="signup-adjust-size">
                                <p className="signup-slider-signup-heading">
                                    {item.title}
                                </p>
                                <span className="signup-slider-para">
                                    {item.description}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};
// Customizable Area End

const CarouselCommonComponet = ({
    // Customizable Area Start

    // Customizable Area End
}: CarouselCommonComponetProps) => {
    // Customizable Area Start
    return (
        <div className="slider-container">
            <div className="left-signup-container">
                {logoRender()}
                {carouselRender()}
            </div>
        </div>
    )
    // Customizable Area End
}
// Customizable Area Start
export default CarouselCommonComponet;
// Customizable Area End
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  selectedKey: string;
  fullName: string;
  flathouse: string;
  areaStreet: string;
  landmark: string;
  pincode: string;
  townCity: string;
  state: string;
  isModalOpen: boolean;
  fullName_error: string;
  flathouse_error: string;
  areaStreet_error: string;
  pincode_error: string;
  townCity_error: string;
  state_error: string;
  landmark_error: string;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfilePageOutLet extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAddressApiCallId: string | null = null;
  addAdressApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      selectedKey: "0",
      fullName: "",
      flathouse: "",
      areaStreet: "",
      landmark: "",
      pincode: "",
      townCity: "",
      state: "",
      isModalOpen: false,
      fullName_error: "",
      flathouse_error: "",
      areaStreet_error: "",
      pincode_error: "",
      townCity_error: "",
      state_error: "",
      landmark_error: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson.data);
      } else if (responseJson && responseJson.errors) {
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
      // Customizable Area End
    }
  };
  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.addAdressApiCallId) {
      notification["success"]({
        message: "Your address has been saved successfully",
    });
      this.props.history.push("/PatientManageAddressPage")
      this.setState({
        fullName: "",
        flathouse: "",
        areaStreet: "",
        landmark: "",
        pincode: "",
        townCity: "",
        state: "",
        fullName_error: "",
        townCity_error: "",
        flathouse_error: "",
        areaStreet_error: "",
        landmark_error: "",
        pincode_error: "",
        state_error: "",
      });
    }
  };

  handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = event.target.value;
    const alphaFullName = fullName.replace(/[^a-zA-Z ]/g, "");
    this.setState({ fullName: event.target.value  });
  };

  handleChangeFlatHouse = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ flathouse: event.target.value });
  };
  handleChangeArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ areaStreet: event.target.value });
  };

  handleChangeLandmark = (event: React.ChangeEvent<HTMLInputElement>) => {
    const landmarkName = event.target.value;
    const alphaLandmarkName = landmarkName.replace(/[^a-zA-Z\s]/g, "");
    this.setState({ landmark: alphaLandmarkName });
  };

  handleChangePincode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pincode = event.target.value;
    const numericPincode = event.target.value.replace(/\D/g, "").slice(0, 6);
    this.setState({ pincode: numericPincode });
  };

  handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cityName = event.target.value;
    const alphaCityName = cityName.replace(/[^a-zA-Z]/g, "");
    this.setState({ townCity: alphaCityName });
  };
  handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stateName = event.target.value;
    const alphaStateName = stateName.replace(/[^a-zA-Z]/g, "");
    this.setState({ state: alphaStateName });
  };
  apihandleSubmit = async () => {
    const patientDetails = localStorage.getItem("patientDetails");
    let mobile;
    let patientId;
    if (patientDetails !== null) {
      const parseDetails = JSON.parse(patientDetails);
      mobile = parseDetails.full_phone_number;
      patientId = parseDetails.patient_id;
    }
    const payLoad = {
      data:{
        attributes: {
          patient_id: patientId,
          full_name: this.state.fullName,
          building_name: this.state.flathouse,
          area: this.state.areaStreet,
          landmark: this.state.landmark,
          pincode: this.state.pincode,
          state: this.state.state,
          city: this.state.townCity,
          phone_number: mobile,
          is_default: true,
        },
      }

    };
    this.addAdressApiCallId = ApiCall({
      contentType: configJSON.getAddressApiContentType,
      method: configJSON.getAddressAPiMethod,
      endPoint: `${configJSON.getAddressAPiEndPoint}`,
      body: payLoad,
    });
  };
  
  validate = () => {
    const {
      fullName,
      flathouse,
      areaStreet,
      landmark,
      pincode,
      townCity,
      state,
    } = this.state;

    if (fullName === "") {
      this.setState({ fullName_error: "Please Enter Your full Name" });
    }
    if (flathouse === "") {
      this.setState({ flathouse_error: "Please Enter Your flathouse Name" });
    }
    if (areaStreet === "") {
      this.setState({ areaStreet_error: "Please Enter Your areaStreet" });
    }
    if (landmark === "") {
      this.setState({ landmark_error: "Please Enter Your landmark" });
    }

    if (pincode === "") {
      this.setState({ pincode_error: "Please Enter Your pincode" });
    }
    if (townCity === "") {
      this.setState({ townCity_error: "Please Enter Your townCity" });
    }
    if (state === "") {
      this.setState({ state_error: "Please Enter Your state" });
    }
  };

  handleSubmit = async (e: any) => {
    e?.preventDefault();
    this.validate();
    if (
      this.state.fullName !== "" &&
      this.state.flathouse !== "" &&
      this.state.areaStreet !== "" &&
      this.state.pincode !== "" &&
      this.state.townCity !== "" &&
      this.state.landmark !== "" &&
      this.state.state !== ""
    ) {
      this.apihandleSubmit();
    }
  };
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { Statistic } from "antd";
// import "./OtpConfirmation.web.css";
const { Countdown } = Statistic;
// Customizable Area End

// Customizable Area Start
const OTPCounter = (props: any) => {
  return (
    <>
      <Countdown
        className="otp-timer"
        title="Resend OTP in 00 : "
        value={props.Otptime}
        onFinish={props.OtpFinishTime}
        format="ss"
      />
    </>
  );
};
// Customizable Area End
// Customizable Area Start
export default OTPCounter;
export { OTPCounter };
// Customizable Area End



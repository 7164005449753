import React from "react";
// Customizable Area Start
import DoctorListController, {
    Props,
    configJSON,
} from "./DoctorListController.web";
import { Backdrop, Box, Card, CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { adminProfile } from "../../customisableuserprofiles2/src/assets";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff", 
        },
    },
});
// Customizable Area End

export default class DoctorList extends DoctorListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start    
        return (
            <Box>
            <Backdrop open={this.state.open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                {this.state.doctorsListData?.data?.map((data: any) => (
                    <Card style={Styles.card}>
                        <Grid container>
                            <Grid item lg={3} md={3} sm={6} xs={6}>
                                <Box style={{ display: 'flex' }}>
                                    <img width="100px" src={adminProfile} />
                                    <Box style={{ margin: 'auto 20px' }}>
                                        <Typography>Dr.</Typography>
                                        <Typography>{data.attributes.first_name} {data.attributes.last_name}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={6} style={{ margin: 'auto' }}>

                                <Typography style={{
                                    background: 'rgb(216, 240, 222)',
                                    width: 'fit-content',
                                    padding: '5px',
                                    color: 'rgb(53, 143, 77)'
                                }}>
                                    {data.attributes.specialty}
                                </Typography>

                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={6} style={{ margin: 'auto' }}>
                                <Typography style={{ color: 'rgb(53, 143, 77)' }}>{data.attributes.status}</Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={6} style={{ margin: 'auto' }}>
                                <Box style={{ textAlign:'center' }}>
                                    <Typography style={{ color: 'rgb(53, 143, 77)' }}>{data.attributes.phone_number}</Typography>
                                    <Typography style={{ color: 'rgb(53, 143, 77)' }}>{data.attributes.email}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                ))}
            </Container>
        </Box>
        );
        // Customizable Area Start
    }
}

// Customizable Area Start
const Styles = {
    card: {
        margin: '40px 0px',
        boxShadow: '0px 0px 16px rgba(153, 149, 149, 0.35)',
        borderRadius: '15px',
        padding: '10px'
    }
}
// Customizable Area End

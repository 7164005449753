Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.timeout = 2000

exports.getOurservicesAPiEndPoint = "account_block/list_all_services";
exports.getOurservicesAPiMethod = "GET";
exports.getOurservicesApiContentType = "application/json";

exports.getSymptomps = "bx_block_categories/get_symptoms_list";
exports.getTopDoctorsAPiMethod = "GET";
exports.getTopDoctorsApiContentType = "application/json";

exports.getTrendingTherapiesAPiEndPoint = "bx_block_categories/top_therapy_name_details";
exports.getTrendingTherapiesAPiMethod = "GET";
exports.getTrendingTherapiesApiContentType = "application/json";

exports.getfeaturedTherapiesAPiEndPoint = "bx_block_categories/get_featured_therapies";
exports.getfeaturedTherapiesAPiMethod = "GET";
exports.getfeaturedTherapiesApiContentType = "application/json";

exports.getMinimallyListAPiEndPoint = "bx_block_categories/minimally_invasive_therapies";
exports.getMinimallyListAPiMethod = "GET";
exports.getMinimallyListApiContentType = "application/json";

exports.getNonMinimallyListAPiEndPoint = "bx_block_categories/non_invasive_therapies";
exports.getNonMinimallyListAPiMethod = "GET";
exports.getNonMinimallyListApiContentType = "application/json";

exports.getTherapyDetailsAPiEndPoint = "bx_block_categories/show_trending_therapies";
exports.getTherapyDetailsAPiMethod = "GET";
exports.getTherapyDetailsApiContentType = "application/json";


exports.getShopByCategoryAPiEndPoint = "bx_block_categories/shop_by_category";
exports.getBrandsAPiEndPoint = "bx_block_catalogue/brands";




exports.getHospitalsAPiEndPoint = "bx_block_hospital/index";
exports.getDoctorListApiEndPoint = "account_block/doctors_from_categories?hospital="

// Customizable Area End

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
}
interface OrderItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    order_id: number;
    quantity: number;
    unit_price: string;
    total_price: string;
    old_unit_price: string | null;
    status: string;
    medicine_id: number;
    order_status_id: string | null;
    placed_at: string | null;
    confirmed_at: string | null;
    in_transit_at: string | null;
    delivered_at: string | null;
    cancelled_at: string | null;
    refunded_at: string | null;
    manage_placed_status: boolean;
    manage_cancelled_status: boolean;
    created_at: string;
    updated_at: string;
    order_on: string;
    medicine_details: {
      medicine_quantity: string;
      medicine_total_amount: string;
      medicine_discount: string;
      manufacturer_name: string;
      medicine_name: string;
      discount_price: string;
    };
    order_statuses: any | null;
    delivery_addresses: any | null;
  };
}
interface MedicineDetails {
  medicine_quantity: string;
  medicine_total_amount: string;
  medicine_discount: string;
  manufacturer_name: string;
  medicine_name: string;
  discount_price: string;
}

interface IPassAttributes {
  id: number;
  order_id: number;
  quantity: number;
  unit_price: string;
  total_price: string;
  old_unit_price: string | null;
  status: string;
  medicine_id: number;
  order_status_id: number;
  placed_at: string | null;
  confirmed_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  manage_placed_status: boolean;
  manage_cancelled_status: boolean;
  created_at: string;
  updated_at: string;
  order_on: string;
  medicine_details: MedicineDetails;
  order_statuses: any;
  delivery_addresses: any;
}
interface OrderAttributes {
  id: number;
  order_number: string;
  amount: number;
  patient_id: number;
  sub_total: string;
  total: string;
  status: string;
  applied_discount: string;
  cancellation_reason: string | null;
  order_date: string;
  is_gift: boolean;
  placed_at: string | null;
  confirmed_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  source: string | null;
  delivery_charges: string | null;
  tracking_url: string | null;
  schedule_time: string | null;
  payment_failed_at: string | null;
  payment_pending_at: string | null;
  returned_at: string | null;
  tax_charges: string;
  deliver_by: string | null;
  tracking_number: string | null;
  is_error: boolean;
  delivery_error_message: string | null;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: string | null;
  shipping_discount: string | null;
  shipping_net_amt: string | null;
  shipping_total: string | null;
  total_tax: string | null;
  created_at: string;
  updated_at: string;
  razorpay_order_id: string;
  order_items: Array<{ type: string; attributes: IPassAttributes }>;
}
export interface S {
  // Customizable Area Start
  myOrderDataList: Array<OrderItem>;
  pass_orderData: Array<OrderAttributes>;
  selectedKey: string;
  loading: boolean;
  tabActiive: string;
  searchTerm: string;
  filteredMedicinList: any[];
  // Customizable Area End
}

export interface SS {
  id: any;
}

export default class PatientProfilePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMyOrderApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      myOrderDataList: [],
      pass_orderData: [],
      selectedKey: "1",
      loading: false,
      tabActiive: "recent-order",
      searchTerm: "",
      filteredMedicinList: [],
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() { 
    this.getMyOrderShow();
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  };
  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getMyOrderApiCallId) {
      this.getMyOrderDataSuccessCallBack(responseJson);
    }
  };

  getMyOrderDataSuccessCallBack = (res: any) => {
    this.setState({
      loading: false,
      myOrderDataList: res?.recent_orders?.data?.attributes?.order_items,
    });

    if (res?.past_orders?.data != null || "") {
      let pastData: any[] = res?.past_orders?.data;
      let tempData: any[] = [];
      pastData.forEach((item) => {
        if (item?.attributes?.order_items) {
          tempData.push(...item.attributes.order_items);
        }
        item.order_on = res?.past_orders?.data?.attributes?.order_on;
      });

      this.setState({ pass_orderData: tempData });
    }
  };

  getMyOrderShow = async () => {
    this.setState({ loading: true });
    this.getMyOrderApiCallId = ApiCall({
      contentType: configJSON.getMyOrderApiContentType,
      method: configJSON.getMyOrderAPiMethod,
      endPoint: `${configJSON.getMyOrderAPiEndPoint}`,
    });
  };
  handleAlignment = (event: any) => {
    this.setState({ tabActiive: event?.target?.value });
  };

  handleSearch = (event: any, tab?: string) => {
    let searchTerm = event.target.value;

    if (tab === "recent-order") {
      let filteredMedicinList = this.state.myOrderDataList.filter(
        (item: any) => {
          return item.attributes.medicine_details.medicine_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        }
      );

      this.setState({
        searchTerm,
        filteredMedicinList,
      });
    } else {
      let filteredMedicinList = this.state.pass_orderData.filter(
        (item: any) => {
          return item?.attributes?.medicine_details?.medicine_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        }
      );

      this.setState({
        searchTerm,
        filteredMedicinList,
      });
    }
  };
  // Customizable Area End
}

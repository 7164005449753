import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE , notification } from "antd";
import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
    withLoaderProps & {
        // Customizable Area Start
        id: string;
        history: any;
        location: any;
        // Customizable Area End
    }

 
// Customizable Area Start

export interface PatientPaymentType {
    id: string,
    type: string,
    attributes: {
        name: string
        identifier: string,
        description: string,
        category_type: string
        image:string
    }
}

// Customizable Area End



interface S {
    // Customizable Area Start
    patientPaymentData:Array<PatientPaymentType>;
    showPopup: boolean;
    isLoginUser: boolean;
    selectedPaymentMethod:string;
    bookedAppointmentDetails:any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class PatientAppoitnmentPaymentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getpaymentMethodApiCallId: string | null = null;
    getPatientAppointmentDashboardApiCallId:string | null = null;
    myRef:any;
    // Customizable Area Enddata:

    constructor(props: Props) {
        super(props);
        this.myRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            // Customizable Area Start
          
            
            patientPaymentData:[],
            selectedPaymentMethod:"",
            bookedAppointmentDetails:[
        
            ],
            showPopup: false,
            isLoginUser: true
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async componentDidMount(){
        this.getPatientAppointDetails();
        this.scrollToTop();
        
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            if(responseJson){
                if(apiRequestCallId === this.getPatientAppointmentDashboardApiCallId){
                    this.setState({bookedAppointmentDetails:responseJson.data})
                    this.props.hideLoader();
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    // Customizable Area Start

    // Customizable Area End

    // Customizable Area Start

    // Customizable Area End

    async getPatientAppointDetails() {
       
        let userToken = localStorage.getItem("token")

        const header = {
           "Content-Type": "application/json",
           token: userToken,
        };
        const requestMessageDoc = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getPatientAppointmentDashboardApiCallId = requestMessageDoc.messageId;
        let upcomingAppointmentsApiEndpoint = `bx_block_appointment_management/upcoming_appointment`;
     
        requestMessageDoc.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          upcomingAppointmentsApiEndpoint
        );
    
        requestMessageDoc.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessageDoc.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
        this.props.showLoader();
        runEngine.sendMessage(requestMessageDoc.id, requestMessageDoc);
    
        return true;
      }

    // Customizable Area Start

    // Customizable Area End

   // Customizable Area Start

    scrollToTop=()=>{
        this.myRef.current.scroll(0,0);
        window.scroll(0,0);
        
      }


    showDetailsHandler = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };

    handleLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
        });
        this.setState({
            isLoginUser: false
        });
    };
 // Customizable Area End
}
// Customizable Area End

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config.js");

// Customizable Area Start
type DeliveryAddress = {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: string;
    building_name: string;
    pincode: string;
    city: string;
    state: string;
    area: string;
    landmark: string;
    created_at: string;
    updated_at: string;
    patient_id: number;
    phone_number: string;
    is_default: boolean;
  };
};
// Customizable Area End

// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start
export interface SymptomNameType {
  id: number;
  name: string;
}
// Customizable Area End

// Customizable Area Start
export interface MedicineDetailsType {
  id: string;
  type: string;
  attributes: {
    id: number;
    medicine_name: string;
    medicine_quantity: number;
    total_amount: number;
    discount: number;
    manufacturer_name: string;
    brand_id: number;
    featured_therapy_id: number;
    symptom_id: number;
    add_offers: string;
    stock_qty: number;
    product_details: string;
    medicine_images: Array<HospitalsImagesType>;
    discounted_price: number;
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addressList: DeliveryAddress[];
  offerSlice: boolean;
  addionalSlice: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class MedicinepageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAddressDetailsApiCallId: string | null = null;

  // Customizable Area Enddata:

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      addressList: [],
      offerSlice: true,
      addionalSlice: true,

      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getAdrressDetailDetailsList();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.oKResponse(responseJson)) {
        this.apiSuccessOKCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  oKResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessOKCallBacks = (apiRequestCallId: any, responseJson: any) => {
    console.log("@@@ iresponseJson", responseJson);
    if (apiRequestCallId === this.getAddressDetailsApiCallId) {
      this.getArressDetailsDataSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getAddressDetailsApiCallId) {
      this.getAddressFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  handleAdrressDetailsData = (res: any) => {
    this.setState({
      addressList: res.data,
    });
  };

  getAdrressDetailDetailsList = async () => {
    this.getAddressDetailsApiCallId = ApiCall({
      contentType: configJSON.getAddressApiContentType,
      method: configJSON.getAddressAPiMethod,
      endPoint: `${configJSON.getAddressAPiEndPoint}`,
    });
  };

  deleteAdrressDetailList = async (id: string) => {
    const payLoad = {
      id: id,
    };
    this.getAddressDetailsApiCallId = ApiCall({
      contentType: configJSON.deleteAddressApiContentType,
      method: configJSON.deleteAddressAPiMethod,
      endPoint: `${configJSON.deleteAddressAPiEndPoint}`,
      body: payLoad,
    });
  };

  getArressDetailsDataSuccessCallBack = (res: any) => {
    console.log(res, "resData");

    this.setState({
      addressList: res.data,
    });
  };
  getAddressFailureCallBack = (err: any) => {
    console.log("@@@----error service", err);
  };
}
// Customizable Area End

import React from "react";
// Customizable Area Start
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { merilLogo, notification } from "../../splashscreen/src/assets";
import { adminProfile } from "../src/assets";
import { Box } from '@material-ui/core';
import { Redirect } from "react-router-dom";
// Customizable Area End

import AdminNavbarController, {
    Props,
} from "./AdminNavbarController.web";



// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'flex',
            alignItems: 'center', // Align items vertically
            textDecoration: 'none',
        },
        headerLogoImg: {
            width: 'auto',
            height: '30px', // Adjust height as needed
            marginRight: theme.spacing(1), // Add some space between logo and other elements if needed
        },
        search: {
            position: 'relative',
            border: '1px solid #f4f4f4',
            backgroundColor: '#f4f4f4',
            height: '50px',
            minWidth: '500px',
            borderRadius: '10px',
            alignItems: 'center',
            display: 'flex',
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    });
// Customizable Area End

class AdminNavbar extends AdminNavbarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        if (this.state.redirectToLogin) {
            // If redirectToLogin is true, redirect to login page
            return <Redirect to="/AdminLogin" />;
          }
        const { classes } = this.props;
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const menuId = 'primary-search-account-menu';
        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem data-test-id="gotoAdminProfile" onClick={this.GoToAdminProfile}>Profile</MenuItem>
                {window.location.pathname === `/AdminDashboard/${"Dashboard"}` ? "" : <MenuItem data-test-id="gotoAdminDashboard" onClick={this.GoToAdminDashBoard}>DashBoard</MenuItem>}
            </Menu>
        );

        const mobileMenuId = 'primary-search-account-menu-mobile';
        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem>
                    <IconButton aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>Messages</p>
                </MenuItem>
                <MenuItem>
                    <IconButton aria-label="show 11 new notifications" color="inherit">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p>Notifications</p>
                </MenuItem>
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        );
        return (
            <div className={classes.grow}>
                <AppBar style={{ padding: "10px 0px 10px 35px", backgroundColor: "#fff", color: "black" }} position="static">
                    <Toolbar>
                        <Typography className={classes.title} variant="h6" noWrap>
                            <Box>
                                <img className='headerLogoImg' src={merilLogo} />
                            </Box>
                        </Typography>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <IconButton aria-label="show 17 new notifications" color="inherit">
                                <img src={notification} className='notificationImg' />
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                            >
                                {this.state.profileData?.data?.attributes.profile_image ? this.state.profileData?.data?.attributes.profile_image : <img src={adminProfile} width="50px" height="50px" />}{/* <AccountCircle /> */}
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                data-test-id="MobileMenuOpen"
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                onClick={this.handleMobileMenuOpen}
                                aria-label="open drawer"
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </div>
        );
        // Customizable Area Start
    }
}

// Customizable Area Start
export default withStyles(styles)(AdminNavbar);
// Customizable Area End
export const location = require("../assets/location.png");
export const doctorImg = require("../assets/doctor.png");
export const searchIcon = require("../assets/search.png")
export const shieldIcon = require("../assets/shield.png")

export const speechBubble = require("../assets/speech-bubble.png");
export const telephoneCall = require("../assets/telephone-call.png");
export const video = require("../assets/video.png");

export const language = require("../assets/language.png");
export const health = require("../assets/health.png");

export const sunrise = require("../assets/sunrise.svg");
export const sunnyDay = require("../assets/sunny-day.svg");
export const moon = require("../assets/moon.svg");

export const  gPay = require("../assets/google-pay.png");
export const phonePay = require("../assets/phonepe.png");
export const bhimUPI = require("../assets/Bhim-upi.png")
export const cashOnDelivery = require("../assets/cash-on-delivery.png")

export const finalImg = require("../assets/final.png");
export const bank = require("../assets/bank.png")

export const locationIcon = require("../assets/location.png");
export const RightOutlined = require("../assets/back.png");

/* Checkout-details-icons */
export const user = require("../assets/user.png");
export const calender = require("../assets/Calender.png")
export const Clock = require("../assets/clock.png");
export const consultation = require("../assets/consulting.png");
export const discount = require("../assets/discount.png");

export const pulse = require("../assets/Group2.png");
export const heart = require("../assets/Group4.png");
export const weight = require("../assets/Group5.png");
export const pdfIcon = require("../assets/pdf.png");

export const dummy = require("../assets/dummy.png")

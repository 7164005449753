export const imgSplashs = require("../assets/logo.png");
export const imgloaders = require("../assets/loader.png");
export const merilLogos = require("../assets/meril.png");
export const images = require("../assets/copy.jpg");
export const images2 = require("../assets/copy2.jpg");
export const images3 = require("../assets/img3.jpg");
export const images4 = require("../assets/img4.jpg");
export const images5 = require("../assets/Mask.png");
export const FacebookIcons = require("../assets/facebookIcon.png");
export const  YoutubeIcons = require("../assets/youtubeIcon.png");
export const LinkedInIcons = require("../assets/linkedinIcon.png");
export const AppStroeIcons = require("../assets/Appstore.png");
export const instaIcons = require("../assets/Instagram.png");
export const playStores = require("../assets/PlayStore.png");
export const searchIcons = require("../assets/loupe grey.png");
export const filterShapeIcons = require("../assets/filtershape.png");
export const Trashs = require("../assets/Trash.png");
export const deleteIcons = require("../assets/Trash.png");
export const Penciledit = require("../assets/penciledit.png");
export const editIcons = require("../assets/penciledit.png");
export const locationIcons = require("../assets/penciledit.png");
export const profilePics = require("../assets/profilepic.png");
export const pdfs = require("../assets/pdf.png");
export const dhoniImage = require("../assets/dhoniImage.png");
export const fileIcon = require("../assets/file.png");
export const batchIcon = require("../assets/batch.png");
export const compliantIcon = require("../assets/complaint.png");
export const verifiedIcon = require("../assets/verified.png");
export const heartImage = require("../assets/heart.png");
export const headImage = require("../assets/head.png");
export const jointImage = require("../assets/joint.png");
export const stomachImage = require("../assets/stomach.png");
export const AngioplastyCarasoule = require("../assets/Angioplastycarasoule.png");
export const chestpain = require("../assets/chestpain.png");
export const fatigue = require("../assets/fatigue.png");
export const stroke = require("../assets/stroke.png");
export const pain = require("../assets/pain.png");
export const heartreplacement = require("../assets/heartreplacement.png");
export const femaleDoctor = require("../assets/femaleDoctor.png");
export const Maledoctor = require("../assets/Maledoctor.png");
export const doctorImg = require("../assets/Maledoctor.png");
export const RightOutlined = require("../assets/back.png");


import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import jwt_decode from "jwt-decode";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

// Customizable Area Start
export interface RoleOptionType {
  role: string,
  id: string
}
// Customizable Area End

interface S {
  // Customizable Area Start
  invalidMobile: boolean;
  fullName: string;
  mobileNumber: string;
  roleData: Array<RoleOptionType>;
  selectRole: string,
  setInputValue: any;
  setValidationMessage: string;
  sendEmailOrNot: boolean,
  alreadyRegisterd: boolean
  userDetails:any,
  isGoogleLogin:boolean,
  selected_role:string,
  googleLoginProfile:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class SignUpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postSignUpApiCallId: string = "";
  postDoctorSignUpApiCallId: string = "";
  apiGmailLoginCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      // Customizable Area Start
      invalidMobile: false,
      fullName: "",
      mobileNumber: "1234567890",
      roleData: [
        {
          role: "Doctor",
          id: "1"
        },
        {
          role: "Patient",
          id: "2"
        },
      ],
      selectRole: "",
      setInputValue: "",
      setValidationMessage: "",
      sendEmailOrNot: true,
      alreadyRegisterd: false,
      userDetails:null,
      isGoogleLogin:false,
      selected_role:"",
      googleLoginProfile:""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.isSignUpValidResponse(responseJson)) {
        this.signUpApiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isSignUpInValidResponse(responseJson)) {
        this.signUpApiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isSignUpValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isSignUpInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  signUpApiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postSignUpApiCallId) {
      this.signUpSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorSignUpApiCallId) {
      this.signUpDoctorSuccessCallBack(responseJson);
    }
    if(apiRequestCallId === this.apiGmailLoginCallId){
      this.handleGoogleloginSuccessCallBack(responseJson)
    }
  };
  // Customizable Area End

  // Customizable Area Start
  signUpApiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postSignUpApiCallId) {
      this.signUpFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorSignUpApiCallId) {
      this.signUpDoctorFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start

  signUpFunction = async (values: { fullName: string, phonoNo: string }) => {
    let apiBody = {
      data: {
        attributes: {
          full_name: values.fullName,
          full_phone_number: `91${values.phonoNo}`
        }
      }

    };
    this.postSignUpApiCallId = ApiCall({
      contentType: configJSON.postSignUpApiContentType,
      method: configJSON.postSignUpAPiMethod,
      endPoint: configJSON.postSignUpAPiEndPoint,
      body: apiBody,
    });
  };

  signUpSuccessCallBack = (res: any) => {
   localStorage.setItem("token", res.meta.token);
   console.log(res ,'resSign');
   
    this.props.history.push({ pathname: "/otp", state: { signUpData: res } })
  };

  signUpFailureCallBack = async (err: any) => {
    if (err.errors[0].full_phone_number === "Invalid or Unrecognized Phone Number") {
      this.setState({
        invalidMobile: true,
        alreadyRegisterd:false
      });
    }
    if (err.errors[0].account === "Phone Number Already Present") {
      this.setState({alreadyRegisterd:false})
      notification["error"]({
        message: "Phone Number Already Present",
      });
    }
  }


  onFinishSignUp = async (values: { fullName: string, phonoNo: string }) => {
    this.signUpFunction(values)
  }

  handleMobileNumber = (e: any) => {
    this.setState({
      mobileNumber: e.target.value,
    })
    this.setState({
      invalidMobile: false,
      alreadyRegisterd: false,
    })
  }

  validateInput = (e: any) => {
    let value = e.target.value
    const emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const numberRegex = /^-?\d+(\.\d+)?$/;

    if (emailRegex.test(value)) {
      this.setState({
        setInputValue: value,
        sendEmailOrNot: true
      })
    }
    else if (numberRegex.test(value)) {
      this.setState({
        setInputValue: value,
        sendEmailOrNot: false,
        alreadyRegisterd: false,
      })
    }
  };


  handleInputs = (e: any) => {
    this.setState({
      fullName: e.target.value,
    })
  }

  handleSelectRole = (value: any) => {
    this.setState({
      selectRole: value,
    })
  }

  signUpDoctorFunction = async () => {
    let apiBody = {
      data: {
        attributes: {
          full_name: this.state.fullName,
          full_phone_number: this.state.sendEmailOrNot ? "" : `91${this.state.setInputValue}`,
          email: this.state.sendEmailOrNot ? this.state.setInputValue : ""
        }
      }
    };
    this.postDoctorSignUpApiCallId = ApiCall({
      contentType: configJSON.postSignUpApiContentType,
      method: configJSON.postSignUpAPiMethod,
      endPoint: configJSON.postDoctorSignUpAPiEndPoint,
      body: apiBody,
    });
  };

  signUpDoctorSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    this.props.history.push({ pathname: "/otp", state: { signUpDoctorData: res } })
  };

  handleGoogleloginSuccessCallBack = (responseJson: any) => {

    localStorage.setItem("token",responseJson.meta.token);
    localStorage.setItem("is_new",responseJson.meta.is_new);
    localStorage.setItem("health_id",responseJson.data.attributes.health_id);

    switch(this.state.selected_role){
      case "Doctor":
        if(responseJson.data.attributes.is_kyc === "pending"){
          if(responseJson.data.attributes?.registration_no){
            this.props.history.push({ pathname: "/doctorprofiledetails", state: { userDetails: this.state.userDetails } });
            localStorage.setItem("is_kyc", responseJson.data.attributes.is_kyc);
           localStorage.setItem("doctorId", responseJson.data.attributes.doctor_id);
        }else{
            this.props.history.push({ pathname: "/editdoctordetails", state: { userDetails: this.state.userDetails } });
            localStorage.setItem("is_kyc", responseJson.data.attributes.is_kyc);
           localStorage.setItem("doctorId", responseJson.data.attributes.doctor_id);
        }
        }else{
          localStorage.setItem("is_kyc", responseJson.data.attributes.is_kyc);
          localStorage.setItem("doctorId", responseJson.data.attributes.doctor_id);
          this.props.history.push({ pathname: "/doctorhome", state: { userDetails: this.state.userDetails } });
        }
        break;
      case "Patient":
        localStorage.setItem("full_name", responseJson.data.attributes.full_name);
        if (responseJson.meta.is_new) {
          this.props.history.push({ pathname: "/PatientUpdate", state: { userDetails: this.state.userDetails,comingfrom_signup:true } })
        } else {
          this.props.history.push({ pathname: "/patienthome", state: { userDetails: this.state.userDetails } })
        }
        break;
      default:
    }
   
  };
  signUpDoctorFailureCallBack = async (err: any) => {
    if (err.errors[0].account === "Account is already registered.") {
      this.setState({
        alreadyRegisterd: true
      })
    }
  }

  handleOnSuccess=(credentialResponse:any)=>{

    const decoded:any = jwt_decode(credentialResponse.credential)

    localStorage.setItem("jwt_token",decoded?.token);
    localStorage.setItem("isPatientGoogleLogin", JSON.stringify(true));
    localStorage.setItem("profile_pic",decoded?.picture);
    localStorage.setItem("googleLoginDetails", JSON.stringify(decoded));

    this.setState({googleLoginProfile:decoded?.picture})

    const selected_role = this.state.selectRole || "Doctor";

    if(selected_role === "Doctor"){
      localStorage.setItem("isDoctorGoogleLogin", JSON.stringify(true));
      localStorage.removeItem("isPatientGoogleLogin")
    }else{
      localStorage.setItem("isPatientGoogleLogin", JSON.stringify(true));
      localStorage.removeItem("isDoctorGoogleLogin");
    }

    this.doGoogleLogin(decoded,selected_role);
    
    this.setState({userDetails:decoded,isGoogleLogin:true,selected_role:selected_role});  
  }

  handleOnFailure=()=>{

  }


  doGoogleLogin = (userInfo: any,selected_role:string) => {
    
    const Patient_signup_body = {
      "data": {
        "attributes": {
          "full_name": userInfo?.name,
           "email_id": userInfo?.email,
          "unique_auth_id": userInfo?.sub,
          "provider": "google"
        }
      }
    }

    const Doctor_signup_body = {
      "data": {
        "attributes": {
          "full_name":userInfo?.name,
          "email": userInfo?.email,
          "unique_auth_id":userInfo?.sub,
          "provider": "google"
             }   
      }
    }

    const header = {
      "Content-Type": "application/json",
    };

    let body = selected_role === "Doctor" ? Doctor_signup_body : Patient_signup_body
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${ selected_role === "Doctor" ? configJSON.doctorgoogleLoginEndPoint : configJSON.patientgoogleLoginEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
    id: any;
    anchorEl: HTMLElement | null;
    mobileMoreAnchorEl: HTMLElement | null;
    getToken: string;
    profileData: any,
    redirectToLogin: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminNavbarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  AdminProfileDetail: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      getToken: "",
      profileData: {},
      redirectToLogin: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.AdminProfileDetail:
                        // this.setState({ profileData: responseJson })
                        // Handle expired token
                        if (responseJson?.status) {
                            const { status } = responseJson;
                            // Handle expired token
                            if (status === 401) {
                                toast.error("Your session has expired. Please log in again");
                            } else if (status === 422) {
                                toast.error("Your account is not valid");
                            } else {
                                toast.error("Something went wrong");
                            }
                        } else {
                            // Handle data
                            this.setState({ profileData: responseJson })
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getHospitalAdminProfile();
    this.getStorageData()
}

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
};

handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
};

handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
};

GoToAdminProfile = () => {
    this.props.history.push("/AdminProfile", { data: this.state.profileData })
}

GoToAdminDashBoard = () => {
    this.props.history.push(`/AdminDashboard/${'Dashboard'}`, { profileData: this.state.profileData })
}

getStorageData = async () => {
    const response = await getStorageData("AdminToken")
    this.setState({ getToken: response });
    if (!response) {
        // If token is not present, redirect to login page
        this.setState({ redirectToLogin: true });
      }
}

handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
};

getHospitalAdminProfile = () => {
    const header = {
        "Content-Type": "application/json",
        token: this.state.getToken,
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AdminProfileDetail = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/account_block/hospital_detail?id=1`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
  // Customizable Area End
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { removeStorageData } from "../../../framework/src/Utilities";
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: any;
  mobileOpen: boolean;
  selectedIndex: number;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HospitalAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      id: 0,
      mobileOpen: false,
      selectedIndex: 0,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

    // Customizable Area Start
     handleDrawerToggle = () => {
      this.setState({mobileOpen:!this.state.mobileOpen})
    };
  
     handleListItemClick = (index: number, text: string) => {
      this.setState({selectedIndex:index});
      if (text === "logout") {
         this.props.history.push('/AdminLogin')
         removeStorageData('AdminToken')
        }else{
          this.props.history.push(`/AdminDashboard/${text}`);
        }
    };

     UserDetailList = (index: number, text: string) => {
         if (text === "Doctor"){
          this.props.history.push('/DoctorsList')
         }else{
          toast.success("Comming Soon")
         }
     }
    // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}

import React, { Component } from "react";
import { Col, Row } from "antd";
import { FacebookIcon,YoutubeIcon,LinkedInIcon} from "../../blocks/splashscreen/src/assets";
import { Link } from "react-router-dom";
export class FooterDoctor extends Component {
  renderfooterCommonSection = (heading: any) => {
    return (
      <div>
        <h2 className="footer-section-heading">{heading}</h2>
        <ul className="footer-section-list">
          <li>
              Doctor consultation
          </li>
          <li>
              Surgical Procedures
          </li>
          <li>
              Blood Test
          </li>
          <li>
              Radiology Tests
          </li>
          <li>
              Genetic Tests
          </li>
        </ul>
      </div>
    );
  };
  render() {
    return (
      <div className="footer-main-container">
        <Row>
          <Col lg={4} md={8} sm={8} xs={24}>
            <h2 className="footer-section-heading">Meril Health</h2>
            <ul className="footer-section-list">
              <li>
                  About Us
              </li>
              <li>
              <Link className="footer-section-list-item" to="/contactus">
                  Contact Us
              </Link>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={8} sm={8} xs={24}>
            {this.renderfooterCommonSection("For Patient")}
          </Col>
          <Col lg={4} md={8} sm={8} xs={24}>
            {this.renderfooterCommonSection("For Doctor")}
          </Col>
          <Col lg={4} md={8} sm={8} xs={24}>
            {this.renderfooterCommonSection("For Hospitals")}
          </Col>
          <Col lg={4} md={8} sm={8} xs={24}>
            <h2 className="footer-section-heading">More</h2>
            <ul className="footer-section-list">
              <li>
                <Link className="footer-section-list-item" to="/privacypolicy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="footer-section-list-item" to="/termsandconditions">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                  Help
              </li>
            </ul>
          </Col>
          <Col lg={4} md={8} sm={8} xs={24}>
            <h2 className="footer-section-heading">Social</h2>
            <div className="footer-social-container">
              <img className="footer-social-icon"  src={FacebookIcon} alt="facebook-icon" />
              <img className="footer-social-icon footer-youtube-icon" src={YoutubeIcon} alt="youtube-icon" />
              <img className="footer-social-icon" src={LinkedInIcon} alt="linked-in" />
            </div>
          </Col>
        </Row>
        <p className="bottom-text footerDoctorMargin">
              &#169; 2022 Meril Health. All Rights Reserved
            </p>
      </div>
    );
  }
}

export default FooterDoctor;

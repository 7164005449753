import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Modal,
    Typography,
    styled,
    Checkbox
} from '@material-ui/core';
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import { BsPlusLg } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdEdit , MdOutlineContentCopy} from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { Carousel } from 'react-responsive-carousel';
import MyClinicController, { HospitalImage, ClinicDetailsAttribute, Props } from "./MyClinicController.web";
let config = require("../../../framework/src/config.js").baseURL;
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class MyClinic extends MyClinicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderNavigatePage = () => {
        return (
            <EditPageHeader>
                <Link to={"/doctorhome"}><PageHeader>Home</PageHeader></Link>
                <Link to={"/doctorschedule"}><PageHeader>Schedule</PageHeader></Link>
                <Link to={"/addpatient"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderPageHeading = () => {
        return (
            <>
                <ClinicHeader>
                    <PageTitle data-test-id="myClinicId">My Clinics</PageTitle>
                    <AddClinicButton onClick={this.showModal} data-test-id="addclinicId">Add Clinic<span style={{fontSize: "20px"}}><BsPlusLg /></span></AddClinicButton>
                </ClinicHeader>
            </>
        )
    }

    renderClinicBox = (clinic: ClinicDetailsAttribute, index: number) => {
        const { name, address, contact_no, clinic_images , link} = clinic?.attributes;
        return (
            <>
                <ClinicBox key={index}>
                    <ClinicDetailsBox>
                        <ClinicDataHeaderBox>
                            <ClinicNameText>{name}</ClinicNameText>
                            <ClinicButtonBox>
                                <ClinicActionButton ><MdEdit data-test-id="editclinicid" onClick={() => this.openEditModal(clinic)} /></ClinicActionButton>
                                <ClinicActionButton><RiDeleteBinLine onClick={() => this.handleDeleteClinic(clinic.id)} data-test-id="deleteClinicId"/></ClinicActionButton>
                            </ClinicButtonBox>
                        </ClinicDataHeaderBox>
                        <ClinicDataText>Address: {address}</ClinicDataText>
                        <ClinicDataText>Phone: {contact_no}</ClinicDataText>
                        <ClinicDataText>Hours: Monday to friday 9AM - 9PM </ClinicDataText>
                        <ClinicDataText style={{display: "none"}}>Clinic link:&nbsp;<span id="clinicCopyId"> {link}</span>&nbsp;&nbsp;&nbsp;<MdOutlineContentCopy onClick={this.handleCopyLink} style={{fontSize: "20px", color: "#3a78bf" , cursor: "pointer"}} data-test-id="copyId"/></ClinicDataText>
                    </ClinicDetailsBox>
                    <CustomCarousel
                        autoPlay={true}
                        showArrows={false}
                        showStatus={false}
                        infiniteLoop={true}
                    >
                        {
                            clinic_images?.map((imgUrl: HospitalImage, index: number) => {
                                return (
                                    <ClinicImageBox key={index}>
                                        <ClinicImage src={config + imgUrl.url} />
                                    </ClinicImageBox>
                                )
                            })
                        }
                    </CustomCarousel>
                </ClinicBox>
            </>
        )
    }

    renderClinicNoData = () => {
        return (
            <NoDataBox>
                <p>No clinic found</p>
            </NoDataBox>
        )
    };

    renderLink = () => {
        return (
            <>
                {
                    this.state.newClinicLink ?
                        <MdOutlineContentCopy onClick={this.handleNewCopyLink} style={{ fontSize: "20px", color: "#3a78bf", cursor: "pointer" }} data-test-id="copyId" />
                        :
                        <MdOutlineContentCopy style={{ cursor: "none", fontSize: "20px", color: "#3a78bf" }} />
                }
            </>
        )
    }

    renderClinicModal = () => {
        return (
            <>
                <CustomModal
                    open={this.state.addClinicModal}
                    onClose={this.closeModal}
                >
                    <ModalContent container>
                        <ClinicDetailsFormBox item lg={4} md={6} xs={12}>
                            <ModalHeader>
                                <ModalTitle>Clinic Details</ModalTitle>
                            </ModalHeader>
                            <InputLable> Clinic Name</InputLable>
                            <InputBox data-test-id="clinicNameId" placeholder="Type clinic name" onChange={this.newHandleClinicName} value={this.state.newClinicName} />
                            {this.state.newClinicNameErr && <ErrorTag>{this.state.newClinicNameErr}</ErrorTag>}
                            <InputLable>Clinic Address</InputLable>
                            <InputBox data-test-id="clinicAddressid" placeholder="Type clinic address" onChange={this.newHandleClinicAddress} value={this.state.newClinicAddress} />
                            {this.state.newClinicAddressErr && <ErrorTag>{this.state.newClinicAddressErr}</ErrorTag>}
                            <InputLable>Contact Details</InputLable>
                            <InputBox data-test-id="clinicContactid" placeholder="0000000000" onChange={this.newHandleClinicContactDetails} value={this.state.newClinicContact} />
                            {this.state.newClinicContactErr && <ErrorTag>{this.state.newClinicContactErr}</ErrorTag>}
                            <InputLable style={{ display: "flex", justifyContent: "spaceBetween"}}><div style={{ width: "95%" }}>Clinic Link </div>{this.renderLink()}</InputLable>
                            <InputBox data-test-id="clinicLinkid" placeholder="www.xyz.com" onChange={this.newHandleClinicLink} value={this.state.newClinicLink} id="newClinicId"/>
                            {this.state.newClinicLinkErr && <ErrorTag>{this.state.newClinicLinkErr}</ErrorTag>}
                            <InputLable style={{ display: "none" }}>Clinic Area</InputLable>
                            <InputBox placeholder="Ahemdabad" style={{ display: "none" }} />
                        </ClinicDetailsFormBox>
                        <ClinicImagesGrid item lg={8} md={6} xs={12}>
                            <ClinicImagesGridHeader>Clinic Images</ClinicImagesGridHeader>
                            <SelectClinicImageBox>
                                <input
                                    data-test-id="clinicImageId"
                                    type="file"
                                    accept="image/*"
                                    name="image-upload"
                                    multiple
                                    id="inputid"
                                    onChange={this.clinicImgFileHandler}
                                />
                                <label htmlFor='inputid' >
                                    <FaPlus />
                                </label>
                            </SelectClinicImageBox>
                            <ImgBox>
                                {this.state?.clinicImage?.map((item) => {
                                    return (
                                        <ImgTag src={item} />
                                    )
                                })
                                }
                            </ImgBox>
                        </ClinicImagesGrid>
                        <ButtonGrid item xs={12}>
                            <CloseButton data-test-id="closeClinicId" onClick={this.closeModal}>Close</CloseButton>
                            <SaveButton data-test-id="saveClinicId" onClick={this.addHandleNewClinic}>Save</SaveButton>
                        </ButtonGrid>
                    </ModalContent>
                </CustomModal>
                <CustomModal
                    open={this.state.clinicEditModal}
                    onClose={this.closeEditModal}
                >
                    <ModalContent container>
                        <ClinicDetailsFormBox item lg={4} md={6} xs={12}>
                            <ModalHeader>
                                <ModalTitle>Clinic Details</ModalTitle>
                            </ModalHeader>
                            <InputLable> Clinic Name</InputLable>
                            <InputBox placeholder="Type clinic name" onChange={this.editHandleClinicName} value={this.state.editClinicName} data-test-id="editClinicNameId" />
                            {this.state.editNewClinicNameErr && <ErrorTag>{this.state.editNewClinicNameErr}</ErrorTag>}
                            <InputLable>Clinic Address</InputLable>
                            <InputBox placeholder="Type clinic address" onChange={this.editHandleClinicAddress} value={this.state.editClinicAddress} data-test-id="editClinicAddressId" />
                            {this.state.editNewClinicAddressErr && <ErrorTag>{this.state.editNewClinicAddressErr}</ErrorTag>}
                            <InputLable>Contact Details</InputLable>
                            <InputBox data-test-id="editClinicContact" placeholder="0000000000" onChange={this.editHandleClinicContactDetails} value={this.state.editConatct} />
                            {this.state.editContactErr && <ErrorTag>{this.state.editContactErr}</ErrorTag>}
                            <InputLable style={{ display: "flex", justifyContent: "spaceBetween"}}>
                                <div style={{ width: "95%" }}>Clinic Link </div>
                                <MdOutlineContentCopy onClick={this.handleEditCopyLink} style={{ fontSize: "20px", color: "#3a78bf", cursor: "pointer" }} data-test-id="copyId" />
                            </InputLable>
                            <InputBox placeholder="www.xyz.com" onChange={this.editHandleClinicLink} value={this.state.editClinicLink} data-test-id="editClinicID" id="copyLinkId" />
                            {this.state.editClinicLinkErr && <ErrorTag>{this.state.editClinicLinkErr}</ErrorTag>}
                            <InputLable style={{ display: "none" }}>Clinic Area</InputLable>
                            <InputBox placeholder="Ahemdabad" style={{ display: "none" }} value={this.state.editClinicArea} />
                        </ClinicDetailsFormBox>
                        <ClinicImagesGrid item lg={8} md={6} xs={12}>
                            <div style={{ display: "flex" }}>
                                <ClinicImagesGridHeader>Clinic Images</ClinicImagesGridHeader>
                                <ClinicActionButton><MdEdit data-test-id="editclinicimageid" onClick={this.editImage} style={{ marginLeft: "10px" }} /></ClinicActionButton>
                                {this.state.getImageId ? <ClinicActionButton onClick={this.clinicImageDeletefunction} data-test-id="deleteImmageId" ><RiDeleteBinLine /></ClinicActionButton> : ""}
                            </div>
                            <SelectClinicImageBox>
                                <input
                                    data-test-id="editClinicImageId"
                                    type="file"
                                    accept="image/*"
                                    name="image-upload"
                                    multiple
                                    id="input"
                                    onChange={this.editImgFileHandler}
                                />
                                <label htmlFor='input' >
                                    <FaPlus />
                                </label>
                            </SelectClinicImageBox>
                            <ImgBox>
                                {this.state?.editClinicImages.map((item) => {
                                    return (
                                        <ImgTag src={item} />
                                    )
                                })
                                }
                                {this.state?.editPresentImage?.map((item) => {
                                    return (
                                        <div style={{ position: "relative" }}>
                                            <ImgTag src={config + item.url} />
                                            <p style={{ position: "absolute", bottom: "20px", }}></p>
                                            {this.state.deleteClinicImage &&
                                                <CheckboxTag data-test-id="deleteCheckboxId" onChange={(event) => this.handleCheckBoxClick(item, event)} />
                                            }
                                        </div>
                                    )
                                })
                                }
                            </ImgBox>
                        </ClinicImagesGrid>
                        <ButtonGrid item xs={12}>
                            <CloseButton data-test-id="closeButtonId" onClick={this.closeEditModal}>Close</CloseButton>
                            <SaveButton data-test-id="editSaveButtonId" onClick={this.editClinicDetails}>Save</SaveButton>
                        </ButtonGrid>
                    </ModalContent>
                </CustomModal>
            </>
        )
    };

    renderDeleteClinicModal = () => {
        return (
            <>
                <CustomModal
                    open={this.state.isClinicDelete}
                    onClose={this.closeDeleteModal}
                >
                    <DeleteModal>
                        <HeaderTitleBox data-test-id="textId">
                            Are you sure you want to delete this clinic ?
                        </HeaderTitleBox>
                        <ButtonBox>
                            <SaveBtn onClick={this.closeDeleteModal} data-test-id="cancelClinicId">Cancel</SaveBtn>
                            <ContinueButton  data-test-id="deleteClinicButtonId" onClick={this.deleteClinicFunction}>Delete</ContinueButton>
                        </ButtonBox>
                    </DeleteModal>
                </CustomModal>
            </>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowPopUp} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} showProfile={this.handleDoctorPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditProfile} handleClosePopup={this.handlePopupClose} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderNavigatePage()}
                    {this.renderPageHeading()}
                    {
                        this.state?.clinicDetails?.data ?
                            this.state?.clinicDetails?.data?.map((clinicDetails: ClinicDetailsAttribute, index: number) => {
                                return (
                                    this.renderClinicBox(clinicDetails, index)
                                )
                            })
                            :
                            this.renderClinicNoData()
                    }
                    {this.renderClinicModal()}
                    {this.renderDeleteClinicModal()}
                </ContainerBox>
                <FooterDoctor />
                <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CheckboxTag = styled(Checkbox)({
    position: "absolute",
    bottom: "20px",
    left: "20px",
    padding: "0px"
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const ImgTag = styled("img")({
    width: "150px",
    height: "100px",
    borderRadius: "5px",
    marginLeft: "20px",
    marginTop: "10px"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const ClinicHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0"
});

const PageTitle = styled(Typography)({
    fontSize: "20px",
    color: "#000",
    fontWeight: "bold"
});

const AddClinicButton = styled("button")({
    color: "#3a78bf",
    display: "flex",
    alignItems: "center",
    columnGap: "5px",
    fontSize: "14px",
    backgroundColor: "transparent",
    border: "none",
    fontWeight: 600
});

const ClinicImageBox = styled(Box)({

});

const ClinicImage = styled("img")({
    height: "200px",
    width: "300px",
    borderRadius: "10px"
});

const ClinicDetailsBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    rowGap: "10px"
});

const ClinicBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
    padding: "10px 0",
    boxSizing: "border-box",
    borderBottom: "2px solid #D3D3D3",
    "@media (max-width:715px)": {
        flexDirection: "column",
        rowGap: "10px"
    }
});

const ClinicDataText = styled(Box)({
    fontSize: "14px",
    fontWeight: 400,
    color: "#000",
    width: "300px",
    display: "flex",
    flexFlow: "wrap"
});

const ClinicDataHeaderBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
});

const ClinicNameText = styled(Box)({
    fontSize: "16px",
    fontWeight: 600,
    color: "#000",
    width: "300px",
    display: "flex",
    flexFlow: "wrap"
});

const ClinicButtonBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    cursor: "pointer"
});

const ClinicActionButton = styled(Box)({
    color: "#3a78bf",
    fontSize: "18px",
});

const CustomCarousel = styled(Carousel)({
    height: "200px",
    width: "300px",
    "@media (max-width:730px)": {
        display: "block",
        margin: "0 auto"
    }
});

const CustomModal = styled(Modal)({
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "scroll",
    "@media (max-width:700px)": {
        padding: "0px"
    }
});

const ModalContent = styled(Grid)({
    width: "900px",
    height: "600px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    outline: "none",
    padding: "10px",
    boxSizing: "border-box",
    overflowX: "scroll",
    "@media (max-width:1000px)": {
        width: "90%"
    }
});

const InputLable = styled("label")({
    color: "#1D1414",
    fontWeight: 500,
    marginTop: "10px",
    fontSize: "12px"
});

const InputBox = styled("input")({
    border: "none",
    borderBottom: "1px solid #D3D3D3",
    padding: "8px 0",
    fontSize: "12px",
    fontWeight: 400,
    margin: "10px 0",
    width: "100%",
    outline: "none",
    fontStyle: "14px"
});

const ModalHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 500,
    color: "#000"
});

const ClinicDetailsFormBox = styled(Grid)({
    padding: "20px 0 20px 40px"
});

const ClinicImagesGrid = styled(Grid)({
    padding: "20px 10px 10px"
});

const ClinicImagesGridHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 500,
    color: "#000"
});

const ModalTitle = styled(Box)({
    marginLeft: -20,
});

const SelectClinicImageBox = styled(Box)({
    height: "100px",
    width: "150px",
    borderRadius: "5px",
    backgroundColor: "#f1f5ff",
    color: "#3a78bf",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
});

const SaveButton = styled("button")({
    color: "#fff",
    padding: "10px",
    width: '100px',
    border: "none",
    borderRadius: "25px",
    backgroundColor: "#3a78bf",
    cursor: "pointer"
});

const CloseButton = styled("button")({
    color: "#fff",
    padding: "10px",
    width: '100px',
    border: "none",
    borderRadius: "25px",
    backgroundColor: "#D3D3D3",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#3a78bf"
    }
});

const ButtonGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "10px"
});

const ErrorTag = styled("p")({
    color: "red",
    fontSize: "14px",
    margin: "0 !important"
});

const ImgBox = styled("div")({
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    height: "230px",
    overflowX: "scroll"
});

const NoDataBox = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    boxShadow: "1px 1px 5px #aaaaaa",
    marginBottom: "20px"
});

const DeleteModal = styled("div")({
    width: "460px",
    backgroundColor: "white",
    borderRadius: "10px"
});

const HeaderTitleBox = styled(Box)({
    fontSize: "20px",
    fontWeight: 500,
    margin: "10px 0",
    textAlign: "center"
});

const ButtonBox = styled(Box)({
    display: "flex",
    width: "60%",
    margin: "0 auto"
});

const ContinueButton = styled("button")({
    color: "#fff",
    padding: "10px",
    width: "100px",
    borderRadius: "50px",
    backgroundColor: "#3a79c1",
    fontSize: "15px",
    fontWeight: 500,
    border: "none",
    display: "block",
    margin: "20px 10px 20px 0px"
});

const SaveBtn = styled("button")({
    color: "grey",
    padding: "10px",
    width: "100px",
    borderRadius: "50px",
    backgroundColor: "white",
    fontSize: "15px",
    fontWeight: 500,
    border: "1px solid grey",
    display: "block",
    margin: "20px auto",
    "&:hover": {
        backgroundColor: "#3a79c1",
        color: "#fff",
        border: "none",
        padding: "10px",
        margin: "20px auto",
    }
});
// Customizable Area End
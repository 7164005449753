import React from "react";
// Customizable Area Start
import { Button, Input, Modal } from "antd";
import ProfileContactUsPageController from "./ProfileContactUsPageController.web";
import PatientOutLet from "./ProfileOutlet.web";
// Customizable Area End
class ProfileContactUsPage extends ProfileContactUsPageController {
  // Customizable Area Start
  render() {
    return (
      <div>
        <PatientOutLet {...this.props} selectedKey={"6"}>
          <form
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <p className="Settingheading">Contact Us</p>
            <div>
              <label className="text-label-css-input">Full Name</label>
              <Input
                className="text-input-css"
                name="fullName"
                value={this.state.fullName}
                data-test-id={"fullNameId"}
                onChange={this.handleChangeFullName}
                placeholder="eg.Jhon Jacob"
              />
              {this.state.fullName_error !== "" &&
                this.state.fullName === "" && (
                  <p style={{ color: "red" }}>{this.state.fullName_error}</p>
                )}
            </div>
            <div>
              <label className="text-label-css-input">Email</label>
              <Input
                className="text-input-css"
                name="email"
                value={this.state.email}
                data-test-id={"emailId"}
                onChange={this.handleChangeEmail}
                placeholder="email@gmail.com"
              />
              {this.state.email_error !== "" && this.state.email === "" && (
                <p style={{ color: "red" }}>{this.state.email_error}</p>
              )}
            </div>
            <div>
              <label className="text-label-css-input">Contact Number</label>
              <Input
                className="text-input-css"
                name="Contact Number"
                value={this.state.contactUs}
                data-test-id={"contactId"}
                maxLength={13}
                onChange={this.handleChangeContact}
                placeholder="+91-XXXXX-XXXXX"
                inputMode="numeric"
              />
              {this.state.contactUs_error !== "" &&
                this.state.contactUs === "" && (
                  <p style={{ color: "red" }}>{this.state.contactUs_error}</p>
                )}
            </div>
            <div>
              <label className="text-label-css-input">Description</label>
              <Input
                className="text-input-css"
                name="Description"
                value={this.state.description}
                onChange={this.handleChangeDescription}
                data-test-id={"descriptionId"}
                placeholder="Type your issue here.."
                style={{ height: "120px" }}
              />
              {this.state.description_error !== "" &&
                this.state.description === "" && (
                  <p style={{ color: "red" }}>{this.state.description_error}</p>
                )}
            </div>
            <button
              className="submit-btn-form"
              data-test-id={"submitId"}
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </form>
        </PatientOutLet>
        <Modal
          title={this.state.contactMessage}
          visible={this.state.isModalsOpen}
          onCancel={this.handleBack}
          style={{
            maxWidth: "500px",
            fontSize: "20px",
          }}
          bodyStyle={{
            padding: "20px",
            textAlign: "center",
          }}
          closeIcon={
            <span style={{ color: "#333", fontSize: "21px" }}>&times;</span>
          }
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={this.handleBack}
              style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                color: "#fff",
              }}
            >
              OK
            </Button>,
            <Button
              key="cancel"
              onClick={this.handleBack}
              style={{
                color: "#007bff",
              }}
              data-test-id="handle-back"
            >
              Cancel
            </Button>,
          ]}
        ></Modal>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default ProfileContactUsPage;
// Customizable Area End

import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { dhoniImage ,RightOutlined } from "./assets";
import { Carousel } from "react-responsive-carousel";
import TherapiesController from "./TherapiespageController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import Text from "antd/lib/typography/Text";
import FooterCardComponent from "./component/FooterCardComponent";
import MinimallTherapy from "../../../components/src/MinimallTherapy";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class Therapies extends TherapiesController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patient-appointment">Appointments</Link>

          <Link to="/services" className="active">Services</Link>
          <Link to="/patienthome">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start

  renderOurTherapies = () => {
    return (
      <>
        <div className="ourServicesContainer">
          <div className="ourServicesHeaderPart topmargin">
            <h2 className="ourServicesTitle">Top Therapies</h2>
            <p
              data-test-id="toptrendingid"
              className="ourServicesSubTitle"
              onClick={this.handleServicesData}
            >
              {"See All"}
            </p>
          </div>
          <Row
            className="cardRow"
            gutter={[20, 20]}
            data-test-id="therapy-list"
          >
            {this.state.topTherapiesData.map(
              (therapiItem: any, index: number) => {
                return (
                  <Col xl={4} lg={6} md={8} sm={12} xs={24} key={index + 12}>
                    <div className="servicesCard">
                      <div className="servicesCardImgBox">
                        <img
                          data-test-id={`therapy-${index}`}
                          onClick={() =>
                            this.navigateToDetailPage(
                              therapiItem?.attributes?.id,
                              therapiItem.attributes.therapy_name
                            )
                          }
                          src={
                            config +
                            therapiItem?.attributes?.therapy_images[0]?.url
                          }
                          className={"servicesCarddImg"}
                        />
                      </div>
                      <p className="servicesCardText">
                        {therapiItem.attributes.therapy_name}
                      </p>
                    </div>
                  </Col>
                );
              }
            )}
          </Row>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderTherarapyCarouselPart = () => {
    return (
      <>
        <Carousel
          interval={3000}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={false}
          className="secondCarousel"
        >
          <div>
            <img className="carouselImage secondCarouselImg" src={dhoniImage} />
          </div>
          <div>
            <img className="carouselImage secondCarouselImg" src={dhoniImage} />
          </div>
          <div>
            <img className="carouselImage secondCarouselImg" src={dhoniImage} />
          </div>
          <div>
            <img className="carouselImage secondCarouselImg" src={dhoniImage} />
          </div>
          <div>
            <img className="carouselImage secondCarouselImg" src={dhoniImage} />
          </div>
        </Carousel>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    const { navigationName } = this.state;

    return (
      <div ref={this.myRef}>
        <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
        {this.renderNavigationTitle()}
        <div
          className="navigation-list-css-container"
          style={{ paddingLeft: "5%" }}
        >
          <div key={"homeKey"}>
            <Text
              className="navigation-list-css"
              style={{ color: "#498ECC" }}
              onClick={() => this.props.history.push("/patienthome")}
            >
              Home
            </Text>
            <img src={RightOutlined} alt="" style={{width:'10px'}}/>
          </div>
          <div key={"ri425"}>
            <Text
              key={"ServicesKey"}
              style={{ color: "#498ECC" }}
              className="navigation-list-css"
              onClick={() => this.props.history.push("/services")}
            >
              Services
            </Text>
          
            <img src={RightOutlined} alt="" style={{width:'10px'}}/>
          </div>

          <div>
            <Text style={{ color: "#353535" }} className="navigation-list-css">
              {navigationName}
            </Text>
          </div>
        </div>

        <div className="itemContainerWiidth">
          {this.renderOurTherapies()}
          <MinimallTherapy
            data-test-id="minimallInvocesId"
            handleMinimalDataList={this.handleMinimalData}
            miniimalDataList={this.state.minimalTherapieData}
            topHeading={"Minimally invasive therapies"}
          />

          {this.renderTherarapyCarouselPart()}
          <MinimallTherapy
            data-test-id="minimallNonInvocesId"
            handleMinimalDataList={this.handleNonMinimalData}
            miniimalDataList={this.state.minimalnonTherapieData}
            topHeading={"Minimally Non invasive therapies"}
          />
          <FooterCardComponent />
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default Therapies;
export { Therapies };
// Customizable Area End

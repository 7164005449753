import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  styled,
  Chip,
  Avatar
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import CallIcon from '@material-ui/icons/Call';
import VideocamIcon from '@material-ui/icons/Videocam';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link as Redirect} from 'react-router-dom';
let configBaseURL = require('../../../framework/src/config.js').baseURL;
import Loader from "../../../components/src/Loader.web";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatViewController, { IMessage, Props } from "./ChatViewController";
import { configJSON } from "./ChatController";

// Customizable Area Start
// Customizable Area End

export default class ChatView extends ChatViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChatView = () => {
    return (
    <>
      <SecondBox>
        <PaitentsChipStack>
          <PaitentsChip label="Patient Logs"></PaitentsChip>
          <PaitentsChip label="Medicine Info"></PaitentsChip>
          <PaitentsChip label="Reports"></PaitentsChip>
        </PaitentsChipStack>
        <SecondHeadTypography variant="h6">Appointments</SecondHeadTypography>
        <CompletedBox>
            {this.state.patientDetails.appointments_details.map((appointmentItem) => {
              return (
                <>
                  <Menulist>
                    <CheckCircleIcon color="primary"></CheckCircleIcon>
                  </Menulist>
                  <ParagraphTypography>Meeting on {appointmentItem.appointment_date} {appointmentItem.time_slot}</ParagraphTypography>
                  <Breaker />
                </>
              )
            })}
        </CompletedBox>
      </SecondBox>
    </>
    )
  };

  renderProfileDetails = () => {
    return (
      <ProfileBox>
        <MainBox>
          <HeadingTypography data-test-id="personalId">Personal Details</HeadingTypography>
          <FirstStack >
            <DisplayStack>
              <PersonImg src={configBaseURL+ this.state.patientDetails.profile_photo}></PersonImg>
              <PersonDetails>
                <Typography>{this.state.patientDetails.full_name}</Typography>
                <Typography>
                {this.state.patientDetails.gender} | {this.state.patientDetails.age} yrs | #{this.state.patientDetails.patient_id}
                </Typography>
              </PersonDetails>
            </DisplayStack>
            <Box className="meetinglist">
              <IconButton>
                <CallIcon color="primary"></CallIcon>
              </IconButton>
              <IconButton>
                <VideocamIcon color="primary"></VideocamIcon>
              </IconButton>
            </Box>
          </FirstStack>
        </MainBox>
        </ProfileBox>
    )
  };

  renderTopbar = () => {
    return (
      <>
        <TopBox>
          <Redirect to={"/doctorhome"}><Header data-test-id="homeTextId">Home</Header></Redirect>
          <Redirect to={"/doctorschedule"}><Header>Schedule</Header></Redirect>
          <Redirect to={"/addpatient"}><Header>Add Patient</Header></Redirect>
          <Redirect to={"/chat"}><NavigateTitleActive>Chats</NavigateTitleActive></Redirect>
        </TopBox>
       </>
    )
  };

  renderDetails = () => {
    return (
      <>
        <ListContainer>
          <ListItem>
            <SpanBoxC><SpanBox>Gender</SpanBox> : {this.state.patientDetails.gender}</SpanBoxC>
          </ListItem>
          <ListItem>
            <SpanBoxC><SpanBox>Age</SpanBox> : {this.state.patientDetails.age}</SpanBoxC>
          </ListItem>
          <ListItem>
            <SpanBoxC><SpanBox>Weight</SpanBox> : {this.state.patientDetails.weight} kgs</SpanBoxC>
          </ListItem>
        </ListContainer>
      </>
    )
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <HeaderBox>
      <NavbarCommonComponent handleProfile={this.patientDetailsHandler} data-test-id="navbarId"/>
      {this.renderTopbar()}
      </HeaderBox>
      <DoctorProfilePopup  logoutFunctiuon={this.handleLogout} showProfile={this.handleDetailsPage} doctorDetailsData={this.state.doctorDetails} handleEditProfile={this.handleEditProfile} handleClosePopup={this.handlePopupClose} profileData={this.state.showPopup} data-test-id="doctorPopUpId"/>
      {this.renderProfileDetails()}
      <ContainerBox>
      {this.renderDetails()}
      {this.renderChatView()}
      </ContainerBox>
      <FooterDoctor />
      <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const HeaderBox = styled(Box)({
  boxShadow: "0px 8px 8px -6px rgba(211, 211, 211, 0.5)"
});

const ContainerBox = styled(Box)({ 
  paddingTop: "15px",
  width: "89%",
  margin: "0 auto"
});

const TopBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "wrap",
  width: "89%",
  margin: "0 auto",
  paddingBottom: "15px"
});

const CompletedBox = styled(Box)({
  marginBottom: "40px",
  height: "270px",
  overflowY: "scroll"
});

const ProfileBox = styled(Box)({
  background: "#f3f9ff",
  marginTop: "7px"
});

const SpanBox = styled("span")({
  fontWeight: 500,
  color: "#151111c9"
});

const SpanBoxC = styled(ListItemText)({
  fontWeight: 600
});

const Header = styled("p")({
  fontSize: "16px",
  color: "black",
  fontWeight: 600
});

const NavigateTitleActive = styled("p")({
  fontSize: "16px",
  color: "rgb(90,140,196)",
  fontWeight: 600
});

const ListContainer = styled(List)({
  "& .MuiListItem-root":
  {
    paddingTop: "3px",
    paddingBottom: "3px"
  }
});

const Menulist = styled(IconButton)({
  "&.MuiIconButton-root": {
    padding: "10px 10px 12px 0px"
  },
  "& .MuiSvgIcon-colorPrimary": {
    color: "rgb(90,140,196)"
  }
});

const ParagraphTypography = styled(Typography)({
  display: "inline-block"
});

const FirstStack = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  spacing: 2,
  "& .MuiSvgIcon-colorPrimary":{
    color: "rgb(90,140,196)"
  }
});

const MainBox = styled(Box)({
  background: "#f3f9ff",
  width: "89%",
  margin: "0 auto"
});

const HeadingTypography = styled(Typography)({
  fontSize: "24px",
  "@media(max-width: 800px)": {
    fontSize: "18px"
  }
});

const SecondHeadTypography = styled(Typography)({
  margin: "1.5vw 0px",
  fontSize: "18px"
});

const PersonImg = styled(Avatar)({
  margin: "1vw",
  width: "90px",
  height: "90px",
  "@media(max-width: 800px)": {
    width: "65px",
    height: "65px"
  }
});

const SecondBox = styled(Box)({
  marginLeft: "0px"
});

const PersonDetails = styled(Box)({
  margin: "1vw",
  fontSize: "rem"
});

const DisplayStack = styled(Box)({
  display: "flex",
  flexDirection: "row"
});

const PaitentsChipStack = styled(Box)({
  display: "flex",
  flexDirection: "row"
});

const PaitentsChip = styled(Chip)({
  backgroundColor: "#fff6d0",
  marginRight: "30px"
});

const Breaker = styled(Divider)({
  backgroundColor: "rgba(0, 0, 0, 0.52)",
  marginRight: "30px"
});
// Customizable Area End

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {  Default } from "../../splashscreen/src/assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Rate } from "antd"
let config = require('../../../framework/src/config.js').baseURL;
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Elasticsearch2Controller, {
  Props,
  configJSON,
} from "./Elasticsearch2Controller";

export default class Elasticsearch2 extends Elasticsearch2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"} style={{ paddingLeft: "10px", paddingRight: 0 }} >
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.inputStyle}>
              <input
                disabled={Boolean(localStorage.getItem("is_kyc"))}
                data-test-id="searchInputID"
                type='text'
                className='searchInput search_text_style'
                placeholder={this.state.isDoctorLogin ? "Search Patient/Upcoming appointments" : configJSON.searchInputPlaceholder}
                value={this.state.searchTerm}
                onChange={(event: any) => this.handleSearch(event)}
              />
            </Box>
          </Box>
          {
            !!this.state.searchResult.length && (
              <Box 
              data-test-id="searchResultShowBoxID"
              p={2}
               className="searchContainer setbackground "
               component={Paper}
               onMouseLeave={()=>this.setState({searchResult:""})}
               
               >
                {
                  this.state.searchResult.map((searchItem: any, index: any) => (                   
                      <Link key={searchItem.id} style={{ textDecoration: "none", color: "#000" }} data-test-id="navigateToBookappointmentID" to= {  localStorage.getItem("isLogin") || localStorage.getItem("isPatientGoogleLogin") ? "patient-appointment":"login"} onClick={() => { this.handleNavigationOnClick(searchItem) }} >
                        <Box className="search-doctor-row" p={2} key={searchItem.id}   >

                          <div>
                            <img className="doctor_search_profile_pic" src={searchItem.attributes.profile_image ? config + searchItem.attributes.profile_image:Default} alt={searchItem.attributes.full_name[0]} />
                          </div>

                          <div className="src_doctorname_container" >

                            <Typography className="search_doctorfullname">
                              Dr. {searchItem.attributes.full_name}
                            </Typography>

                            <Typography className="search_doctorfullname_category" >
                              {searchItem.attributes.specialization}{searchItem.attributes.doctor_category ? ", " + searchItem.attributes.doctor_category:""}
                            </Typography>


                          </div>

                          <div className="search_specialization">
                            <Typography className="search_specialization_text"> {searchItem.attributes.qualification} </Typography>
                            <Typography className="search_doctorfullname_category"> {searchItem.attributes?.experience && searchItem.attributes?.experience + " Years Experience"}</Typography>
                          </div>

                          <div className="search_specialization">
                            <Rate value={searchItem.attributes?.rating_average} disabled />
                          </div>


                        </Box>
                      </Link>

                  ))
                }
              </Box>

            )
          }

          {
            !!this.state.errorMessage.length && (
              <Box p={5}
                data-test-id="showMessageErrorID"
                className="searchContainer no_result_found "
                component={Paper}
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                onMouseLeave={()=>this.setState({errorMessage:""})}
              >
                {
                  <Typography variant="h6" > {this.state.errorMessage} </Typography>
                }

              </Box>

            )
          }

        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    // position:"absolute"
    // paddingBottom: "30px",
    // background: "#fff",
    maxWidth: "800px"
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    position: "absolute",
    top: 60,
    left: "-50%",
    width: "700px",
    maxHeight: 500,
    overflow: "auto",
    zIndex: 999, backgroundColor: "#f4f4f4",
    border: "1px solid red"

  },
  inputStyle: {
    // position:"absolute",
    // width: "100%",
    height: "48px",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
  },
  // buttonStyle: {
  //   width: "100%",
  //   height: "45px",
  //   marginTop: "40px",
  //   border: "none",
  //   backgroundColor: "rgb(98, 0, 238)",
  // },
};
// Customizable Area End

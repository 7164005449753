import React from "react";
// Customizable Area Start
import { Box, Button, FormControl, FormHelperText, Grid, MenuItem, Radio, Select, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import { Formik } from "formik";
import * as yup from "yup";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const Styles = {
  cameraIcon: {
    color: '#fff',
  },
  cameraIconBox: {
    backgroundColor: 'rgb(73, 142, 204)',
    minWidth: '35px',
    height: '40px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center' as 'center',
    alignItems: 'center',
    position: 'absolute' as 'absolute',
    top: 30,
    right: 30,
    transform: 'translate(50%, -50%)',
    border: '1px solid #fff'
  },
  root: {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
  },
  uploadBox: {
    backgroundColor: 'rgb(236, 236, 236)',
    width: '150px',
    height: '150px',
    marginTop: '5px',
    borderRadius: '15px',
    // display: 'flex',
    padding: '28px 0px'
  },
  uploadBox1: {
    width: '150px',
    height: '150px',
    marginTop: '50px',
    borderRadius: '15px',
    display: 'flex'
  },
  label: {
    marginTop: "15px",
    marginLeft: "10px",
    fontSize: '18px',
    fontWeight: 500
  },
  label1: {
    marginTop: "25px",
    marginLeft: "10px",
    fontSize: '18px',
    fontWeight: 500
  },
  TextField: {
    marginTop: "10px",
    width: '90%'
  },
  buttonlabel: {
    margin: 'auto',
    boxShadow: 'none',
    backgroundColor: '#fff',
    borderRadius: '25px',
    width: '50px',
    height: '50px',
    display: 'flex'
  },
  formControl: {
    minWidth: "80%",
  },
  imgURL: {
    width: '150px',
    height: '150px',
    borderRadius: 100
  },
  addMore: {
    display: 'flex',
    alignItems: 'center'
  },
  addMore1: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  addMoreText: {
    marginTop: '6%',
    textDecoration: 'underline',
    color: '#498ecc',
    cursor: 'pointer'
  },
  CreateButton: {
    background: '#498ecc',
    height: '50px',
    width: '35%',
    borderRadius: '20px',
    color: '#fff',
    margin: '30px'
  }
}
// Customizable Area End

import AppointmentmanagementController, {
  Props,
  configJSON,
} from "./AppointmentmanagementController";

export default class AddAppointment extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start    
    return (
      <Box>
        <Box position="relative" display="inline-flex">
          {this.state.selectedFile ? (
            <img style={Styles.imgURL} src={URL.createObjectURL(this.state.selectedFile)} />
          ) : (
            <AccountCircleIcon style={{ color: '#498ecc', fontSize: '150px' }} />
          )}
          <Button component="label" style={Styles.cameraIconBox}>
            <input data-test-id = "handleFileChange"
              hidden
              accept="file/*, image/*, .pdf, .doc, .docx"
              onChange={(event) => this.handleFileChange(event)}
              type="file"
            />
            <PhotoCameraIcon style={Styles.cameraIcon} />
          </Button>
        </Box>

        <Formik data-test-id="FormikData" initialValues={{
          speciality: "",
          firstname: "",
          lastname: "",
          phoneno: "",
          emailid: "",
          address: "",
          hospitalname: "",
        }}
          onSubmit={async (values) => {
            this.CreateNewUser(values);
          }}
          validationSchema={yup.object().shape({
            speciality: yup.string().required("Please enter speciality").min(5, "min 5 characters required").max(150, "max 150 characters required"),
            firstname: yup.string().required("Please enter firstname").min(5, "min 3 characters required"),
            lastname: yup.string().required("Please enter firstname").min(5, "min 3 characters required"),
            phoneno: yup.string().required("Please enter Phone No").matches(/^\+?[1-9]\d{1,14}$/, 'Enter a valid phone number'),
            emailid: yup
              .string()
              .required("Please enter Email Id")
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                'Enter a valid email address!'
              ),
            address: yup.string().required("Please enter address").min(5, "min 5 characters required").max(150, "max 150 characters required"),
            hospitalname: yup.string().required("Please enter hospital name"),
          })}>
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Box>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4} style={{ marginTop: '0px' }}>
                    <Grid item lg={6}>
                      <Box style={Styles.root} >
                        <label style={Styles.label}>Select Role</label>
                        <FormControl variant="outlined" style={Styles.TextField}>
                          <Select data-test-id = "SelecthandleChange"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select"
                            value={this.state.value}
                            onChange={this.handleChange}
                            label="Age"
                            inputProps={{ 'aria-label': 'Without label' }}
                            MenuProps={{ PaperProps: { style: { marginTop: '55px' } } }}
                          >
                            {this.state.selectAdminRole.map((name, index) => (
                              <MenuItem key={index} value={name.name}>
                                <Radio color="primary" style={{ padding: '0px 10px', color: '#498ecc' }} checked={this.state.value === name.name} />
                                {name.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box style={Styles.root} >
                        <label style={Styles.label}>First Name</label>
                        <TextField
                          style={Styles.TextField}
                          id="outlined-basic"
                          variant="outlined"
                          name="firstname"
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstname && Boolean(errors.firstname)}
                          helperText={touched.firstname && errors.firstname}
                        />
                      </Box>

                      <Box style={Styles.root} >
                        <label style={Styles.label}>Phone No.</label>
                        <TextField
                          style={Styles.TextField}
                          id="outlined-basic"
                          variant="outlined"
                          name="phoneno"
                          value={values.phoneno}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.phoneno && Boolean(errors.phoneno)}
                          helperText={touched.phoneno && errors.phoneno}
                        />
                      </Box>

                      <Box style={Styles.root} >
                        <label style={Styles.label}>Address</label>
                        <TextField
                          style={Styles.TextField}
                          id="outlined-basic"
                          variant="outlined"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.address && Boolean(errors.address)}
                          helperText={touched.address && errors.address}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={6}>
                      <Box style={Styles.root} >
                        <label style={Styles.label}>Speciality</label>
                        <TextField
                          style={Styles.TextField}
                          id="outlined-basic"
                          variant="outlined"
                          name="speciality"
                          value={values.speciality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.speciality && Boolean(errors.speciality)}
                          helperText={touched.speciality && errors.speciality}
                        />
                      </Box>

                      <Box style={Styles.root} >
                        <label style={Styles.label}>Last Name</label>
                        <TextField
                          style={Styles.TextField}
                          id="outlined-basic"
                          variant="outlined"
                          name="lastname"
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.lastname && Boolean(errors.lastname)}
                          helperText={touched.lastname && errors.lastname}
                        />
                      </Box>

                      <Box style={Styles.root} >
                        <label style={Styles.label}>Email Id</label>
                        <TextField
                          style={Styles.TextField}
                          id="outlined-basic"
                          variant="outlined"
                          name="emailid"
                          value={values.emailid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.emailid && Boolean(errors.emailid)}
                          helperText={touched.emailid && errors.emailid}
                        />
                      </Box>

                      <Box style={Styles.root} >
                        <label style={Styles.label}>Hospital Name</label>
                        <TextField
                          style={Styles.TextField}
                          id="outlined-basic"
                          variant="outlined"
                          name="hospitalname"
                          value={values.hospitalname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.hospitalname && Boolean(errors.hospitalname)}
                          helperText={touched.hospitalname && errors.hospitalname}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box style={this.state.UploadCertificate.length > 0 ? Styles.addMore1 : Styles.addMore}>
                    <Box style={this.state.UploadCertificate.length > 0 ? { width: '30%' } : { width: 'auto' }}>
                      <label style={Styles.label1}>Upload Certificates</label>
                      {this.state.UploadCertificate.length > 0 ? (
                        this.state.UploadCertificate.map((imageUrl: any, index: number) => (
                          <Box style={{ width: '100%', minHeight: '50px' }}>
                            <Box style={{ position: 'relative', borderRadius: '20px', border: '1px solid rgb(181, 184, 189)', width: '100%', height: '50px', marginTop: '15px', marginBottom: '15px' }}>
                              <img src={URL.createObjectURL(imageUrl)} style={{ width: "85%", borderRadius: '20px', height: 50 }} />
                              <CancelIcon data-test-id = "removeImage" onClick={() => this.removeImage(index)} style={{ cursor: 'pointer', position: 'absolute', top: '50%', left: '90%', transform: 'translate(-50%, -50%)' }} />
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box style={Styles.uploadBox}>
                          <Button style={Styles.buttonlabel} component="label" variant="contained" className="upload-btn">
                            <input data-test-id = "handleFileChange1"
                              hidden
                              accept="file/*, image/*, .pdf, .doc, .docx"
                              onChange={(event) => this.handleFileChange1(event)}
                              type="file"
                            />
                            <AddIcon style={{ color: '#498ecc' }} />
                          </Button>
                          <Box>
                            <FormHelperText style={{ textAlign: 'center' }}>Browse to upload</FormHelperText>
                            <FormHelperText style={{ textAlign: 'center' }}>(Max 10MB)</FormHelperText>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {/* Add more button */}
                    <Button component="label" style={Styles.addMoreText}>
                      + Add More
                      <input data-test-id = "AddMore"
                        hidden
                        accept="file/*, image/*, .pdf, .doc, .docx"
                        onChange={(event) => this.handleFileChange1(event)}
                        type="file"
                      />
                    </Button>
                  </Box>

                  <Button type='submit' style={Styles.CreateButton}>Create</Button>
                </form>
              </Box>
            );
          }}
        </Formik>
      </Box>
    );
    // Customizable Area Start
  }
}

import React from "react";
// Customizable Area Start
import ProfilePageController from "./PatientProfilePageController.web";
import PatientOutLet from "./ProfileOutlet.web";
// Customizable Area End

class FeatureComingSoon extends ProfilePageController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <PatientOutLet {...this.props} selectedKey={this.props.location.state?.selectedN}>
          <div
            style={{
              height: "100%",
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 style={{ textAlign: "center" }}>Feature coming soon...</h1>
          </div>
        </PatientOutLet>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return <>{this.renderNavigationTitle()}</>;
  }
}
// Customizable Area Start
export default FeatureComingSoon;
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";

export interface TermsType {
  settings : [
    {
     id : number;
     name : string;
      description: string;
    }
  ]
}

export interface PrivacyType {
  settings : [
    {
     id : number;
     name : string;
      description: string;
    }
  ]
}

export interface CheckEventType {
  target: {
    checked: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  location: any;
  navigation: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otpInput: string;
  invalidOtp: boolean;
  checkboxCheck: boolean;
  doctorPin: string;
  sessionOtp: boolean;
  Otptime: any;
  IsotpExpired: boolean;
  doctorName: string;
  doctorEmail: string;
  doctorNumber: string;
  termsConditionData: TermsType;
  isTermModal: boolean;
  contentHeight: number;
  isLoader: boolean;
  isPrivacyModal: boolean;
  privacyPolicyData: PrivacyType;
  privacyCheckox: boolean;
  termsCheckbox: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class SignUpOtpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postSignUpOtpApiCallId: string = "";
  postDoctorSignUpOtpApiCallId: string = "";
  postResendOtpApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  postDoctorResendOtpApiCallId: string = "";
  getTermsAndConditionsApiCallId: string = "";
  otpTimer: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      // Customizable Area Start
      otpInput: this.props.location.state?.signUpData?.data?.attributes?.pin,
      doctorName: this.props.location.state?.signUpDoctorData?.data?.attributes?.full_name,
      doctorEmail: this.props.location.state?.signUpDoctorData?.data?.attributes?.email,
      doctorNumber: this.props.location.state?.signUpDoctorData?.data?.attributes?.full_phone_number,
      invalidOtp: false,
      checkboxCheck: false,
      doctorPin: "",
      sessionOtp: false,
      Otptime: Date.now() + 60 * 1000,
      IsotpExpired: false,
      termsConditionData: {
        settings: [
          {
              id : 0,
              name : "",
               description: ""
          }
        ]
    },
    isTermModal: false,
    contentHeight: 10,
    isLoader: false,
    isPrivacyModal: false,
    privacyPolicyData: {
      settings: [
        {
            id : 0,
            name : "",
             description: ""
        }
      ]
  },
  privacyCheckox: false,
  termsCheckbox: false
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      doctorPin: this.props.location.state?.signUpDoctorData?.data?.attributes?.pin
    });
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.isSignUpOtpValidResponse(responseJson)) {
        this.signUpOtpApiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isSignUpOtpInValidResponse(responseJson)) {
        this.signUpOtpApiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isSignUpOtpValidResponse = (responseJson: any) => {
    return (responseJson && responseJson.data) || (responseJson && responseJson.settings);
  };
  // Customizable Area End

  // Customizable Area Start
  isSignUpOtpInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  signUpOtpApiSuccessCallBacks = (apiRequestCallId: any, responseJson: any ) => {
    if (apiRequestCallId === this.postSignUpOtpApiCallId) {
      this.signUpOtpSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorSignUpOtpApiCallId) {
      this.signUpDoctorOtpSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postResendOtpApiCallId) {
      this.resendOtpSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorResendOtpApiCallId) {
      this.resendDoctorOtpSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getTermsAndConditionsApiCallId) {
      this.getTermsConditionsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPrivacyPolicyApiCallId) {
      this.getPrivacyPlicySuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  signUpOtpApiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postSignUpOtpApiCallId) {
      this.signUpOtpFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorSignUpOtpApiCallId) {
      this.signUpDoctorOtpFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postResendOtpApiCallId) {
      this.resendOtpFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start

  handleOTPChange = (otp: string) => {
    this.setState({
      otpInput: otp,
      invalidOtp: false,
      sessionOtp: false
    });
  };

  handleDoctorOTPChange = (otp: string) => {
    this.setState({
      doctorPin: otp,
      invalidOtp: false,
      sessionOtp: false
    });
  };


  handleVerifyOtp = async () => {

    let apiBody = {
      pin: this.state.otpInput
    };
    this.postSignUpOtpApiCallId = ApiCall({
      contentType: configJSON.postSignUpOtpApiContentType,
      method: configJSON.postSignUpOtpAPiMethod,
      endPoint: configJSON.postSignUpOtpAPiEndPoint,
      body: apiBody,
    });
  };

  signUpOtpSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    notification["success"]({
      message: res.meta.message,
    });
    this.props.history.push({ pathname: "/signup-patient", state: { patientDetails: res } })
    this.setState({ otpInput: "" })
  };

  signUpOtpFailureCallBack = async (err: any) => {
    if (err.errors[0].pin === "Please enter a valid OTP") {
      this.setState({
        invalidOtp: true,
      });
    }
    if (err.errors[0].pin === "OTP has expired, please request new OTP") {
      this.setState({
        sessionOtp: true,
      });
    }
  }

  handleCheckbox = (e: any) => {
    this.setState({
      checkboxCheck: e.target.checked,
      privacyCheckox: e.target.checked,
      termsCheckbox: e.target.checked
    });
  }

  handleDoctorVerifyOtp = async () => {
    let apiBody = {
      pin: this.state.doctorPin
    };
    this.postDoctorSignUpOtpApiCallId = ApiCall({
      contentType: configJSON.postSignUpOtpApiContentType,
      method: configJSON.postSignUpOtpAPiMethod,
      endPoint: configJSON.postDoctorSignUpOtpAPiEndPoint,
      body: apiBody,
    });
  };

  signUpDoctorOtpSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    notification["success"]({
      message: res.meta.message,
    });
    this.props.history.push({ pathname: "/doctorregister", state: { doctorDetails: res.data } })
    this.setState({doctorPin: ""});
  };

  signUpDoctorOtpFailureCallBack = (err: any) => {
    if (err.errors[0].pin === "Please enter a valid OTP") {
      this.setState({
        invalidOtp: true,
      });
    }
    if (err.errors[0].pin === "This Pin has expired, please request a new pin code.") {
      this.setState({
        sessionOtp: true,
      });
    }
  }


  signUpResendFunction = async () => {
    this.setState({
      otpInput: ""
    })
    localStorage.clear();
    let apiBody = {
      data: {
        attributes: {
          full_name: this.props.location.state?.signUpData?.data?.attributes?.full_name,
          full_phone_number: this.props.location.state?.signUpData?.data?.attributes?.full_phone_number,
        }
      }

    };
    this.postResendOtpApiCallId = ApiCall({
      contentType: configJSON.postSignUpOtpApiContentType,
      method: configJSON.postSignUpOtpAPiMethod,
      endPoint: configJSON.postResendOtpAPiEndPoint,
      body: apiBody,
    });
  };

  resendOtpSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    this.setState({
      otpInput: res.data.attributes.pin,
      IsotpExpired: false,
      Otptime: Date.now() + 60 * 1000
    })
  };

  resendOtpFailureCallBack = (err: any) => {
    console.log(err)
  };

  signUpDoctorResendFunction = async () => {
    this.setState({
      doctorPin: ""
    })
    localStorage.clear();
    let apiBody = {
      data: {
        attributes: {
          full_name: this.state.doctorName,
          full_phone_number: this.state.doctorNumber,
          email: this.state.doctorEmail
        }
      }

    };
    this.postDoctorResendOtpApiCallId = ApiCall({
      contentType: configJSON.postSignUpOtpApiContentType,
      method: configJSON.postSignUpOtpAPiMethod,
      endPoint: configJSON.postDoctorResendOtpAPiEndPoint,
      body: apiBody,
    });
  };

  resendDoctorOtpSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    this.setState({
      doctorPin: res.data.attributes.pin,
      IsotpExpired: false,
      Otptime: Date.now() + 60 * 1000
    })
  };

  getTermsConditionsSuccessCallBack = (response: TermsType) => {
    this.setState({
        termsConditionData: response,
        isLoader: false
    });
};

  resendDoctorOtpFailureCallBack = (err: any) => {
    console.log(err)
  };

  OtpFinishTime = () => {
    this.setState({
      IsotpExpired: true,
    });
  };
 
  getTermsConditionsfunction = async () => {
    this.getTermsAndConditionsApiCallId = ApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getTermsAndCondsApiEndPoint
    });
    this.setState({isLoader: true});
  };

  handleTerms = () => {
     this.setState({isTermModal : true} , () => {
      this.getTermsConditionsfunction();
     });
  };

  closeModal = () => {
    this.setState({
      isTermModal : false,
      isPrivacyModal : false
    });
  };

  handlePrivacy = () => {
    this.setState({isPrivacyModal : true} , () => {
     this.getPrivacyPolicyfunction();
    });
 };

  handleConfirm = () => {
    this.setState({
      isTermModal: false
    });
  };

  getPrivacyPolicyfunction = async () => {
    this.getPrivacyPolicyApiCallId = ApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPrivacyPolicyApiEndPoint
    });
    this.setState({isLoader: true});
  };

  getPrivacyPlicySuccessCallBack = (response: PrivacyType) => {
      this.setState({
          privacyPolicyData: response,
          isLoader: false
      });
  };

  handlePrivacySubmit = () => {
     this.setState({isPrivacyModal: false})
  };

  handleBack = () => {
    this.setState({
      isPrivacyModal : false,
      isTermModal : false
    });
  };

  handlePrivacyCheckbox = (event: CheckEventType) => {
    this.setState({
      privacyCheckox: event.target.checked,
      checkboxCheck: event.target.checked
    });
  };

  handleTermsCheckbox = (event: CheckEventType) => {
    this.setState({
      termsCheckbox: event.target.checked,
      checkboxCheck: event.target.checked
    });
  };
  // Customizable Area End
}
// Customizable Area End

import React from "react";
// Customizable Area Start
import PatientAppointmentJoiningPageController from "./PatientAppointmentConfirmationPageController.web";
import { Col, Row, Typography, Avatar,Rate } from "antd";
import {Box,Button,styled,Grid,Container} from "@material-ui/core"
import { calendarimg, clockimg } from "./assets";
import {consultation,discount} from "../../appointmentmanagement/src/assets"
import { Default } from "../../splashscreen/src/assets";
let config = require("../../../framework/src/config.js").baseURL;
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PiWarningBold } from "react-icons/pi";
import {Link} from "react-router-dom" 
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
// Customizable Area End
class PatientAppointmentJoiningPage extends PatientAppointmentJoiningPageController {
  // Customizable Area Start

  renderNavigationTitle = () => {
    return (
      <>
        <div
          className="navigationContainer"
          style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.03)" }}
        >
          <Link to="/patienthome">Home</Link>
          <Link to="/patient-appointment-landingpage">Appointments</Link>
          <Link to="/services">Services</Link>
          <Link to="/patienthome">Messages</Link>
        </div>
      </>
    );
  };

  renderModalTitle = () => {
    let titleContent;
  
    if (this.state.isModalOpen) {
      titleContent = (
        <Box p={0} className="modal--header-container" >
          <PiWarningBold className="warning-icons" /> <span className="model-title" >Appointment Not yet Started</span>
        </Box>
      );
    } else if (this.state.isCancellModalOpen) {
      titleContent = <span className="model-title">Cancel Appointment ?</span>;
    } else {
      titleContent = (
        <Box p={0} className="modal--header-container" >
          <PiWarningBold className="warning-icons" /> <span className="model-title" >Reschedule Appointment</span>
        </Box>
      );
    }
  
    return <DialogTitle>{titleContent}</DialogTitle>;
  }
  

  renderModalContentText = () => {
    let contentText;
  
    if (this.state.isModalOpen) {
      contentText = (
        <Box textAlign="center" className="model-description"> You can start the appointment 5 mins before from the scheduled appointment time </Box>
      );
    } else if (this.state.isCancellModalOpen) {
      contentText = (
        <Box textAlign="center" className="model-description">Are you sure you want to cancel the appointment</Box>
      );
    } else {
      contentText = (
        <Box textAlign="center" className="model-description"> Coming soon....</Box>
      );
    }
  
    return <DialogContentText>{contentText}</DialogContentText>;
  }
  

  renderActions = () => {
    if (this.state.isModalOpen) {
      return (
        <DialogActions>
          <ModalOktButton data-test-id="modalCloseOkID" onClick={(event) => this.handleModalClose(event, "abc")} color="primary">
            OK
          </ModalOktButton>
        </DialogActions>
      );
    } else if (this.state.isCancellModalOpen) {
      return (
        <DialogActions>
          <DoNotWantToCancelButton data-test-id="NO_cancel-appointment-button-id" onClick={(event) => this.handleModalClose(event, "Nocncell")}>
            No I don't want to cancel
          </DoNotWantToCancelButton>
          <YesCancelButton data-test-id="cancel-appointment-button-id" onClick={(event) => this.handleCancelAppointment(event, "abc")}>
            Yes, Cancel
          </YesCancelButton>
        </DialogActions>
      );
    } else if (this.state.isrescheduleModalOpen) {
      return (
        <DialogActions>
          <ModalOktButton data-test-id="rescheduleOkID" onClick={(event) => this.handleModalClose(event, "ok")}>OK</ModalOktButton>
        </DialogActions>
      );
    } else {
      return null;
    }
  }
  

  renderModal = () => {
    return (
      <Dialog
        maxWidth="sm"
        open={this.state.isModalOpen || this.state.isrescheduleModalOpen || this.state.isCancellModalOpen}
        onClose={this.handleModalClose}
      >
       {this.renderModalTitle()}
       {this.renderModalContentText()}
       {this.renderActions()}
      </Dialog>
    )
  }


  render() {
    return (
      <div>
          <NavbarCommonComponent
          handleProfile={this.showDetailsHandler}
          data-test-id="handleshowId"
        />
        <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
        {this.renderModal()}
        {this.renderNavigationTitle()}
        <Container style={{width:"95%", paddingBottom:"24px"}} >
        <Box style={{padding: "16px 0"}} >
            <Row gutter={[24, 24]} >
              <Col xl={3} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <Avatar style={{ width: "100px", height: "100px" }} size="large" src={ config + this.state.doctor_profile_image || Default} />
              </Col>
              <Col xl={7} >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                  <Typography className="doctorName">Dr. {this.state.doctor_full_name}</Typography>
                  <Rate allowHalf disabled value={4} />
                </div>
                <Typography className="doctorDescription" style={{ margin: "8px 0" }} >{this.state.doctor_category} </Typography>
                <div className="doctorExperienceWrapper">
                  <Typography >{this.state.doctor_experience} Years Experience </Typography>
                  <Typography>0 reviews </Typography>
                </div>
              </Col>
            </Row>
          </Box>
          <Box pt={2} mb={2}>
            <Typography className="doctorName">Appointments Details</Typography>
          </Box>
          <AppointmentDetyailsBox >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
              <div className="appointment-details-wrapper" >
                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={consultation} />
                    <Typography>{this.state.consultation_type || this.state.consultation_category } </Typography>
                  </div>

                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={calendarimg} />
                    <Typography>{this.state.appointment_date}</Typography>
                  </div>

                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={clockimg} />
                    <Typography>{this.state.time_slot} </Typography>
                  </div>


                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={discount} />
                    <Typography>Fees: Rs. {this.state.fees} </Typography>
                  </div>

                </div>
              </Grid>

              <Grid item xs={12} sm={6} >
                <div className="appointments-button-wrapper" >
                  {
                    this.state.consultation_category != "In Clinic Consultation" ?  <StartAppointmentButton data-test-id="start_appointment_ID" onClick={() => this.handleStartAppointments()} fullWidth >Start Appointment</StartAppointmentButton> :null
                   }

                  <RescheduleAppointmentButton data-test-id="reschedule_appointment_ID" onClick={() => this.handleRescheduleAppointments()} fullWidth >Reschedule Appointment</RescheduleAppointmentButton>
                  <CancelAppointmentButton data-test-id="cancel_appointment_ID" onClick={() => this.handleOpenCancelAppointmentModal()} fullWidth  >Cancel Appointment</CancelAppointmentButton>
                </div>
              </Grid>
            </Grid>
          </AppointmentDetyailsBox>
        </Container>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default PatientAppointmentJoiningPage;

const BoxContainer= styled(Box)({
  borderRadius:'10px'
})
const StartAppointmentButton=styled(Button)({
  background:"#498ECC",
  backgroundColor:"#498ECC",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  maxWidth:"350px",
  fontSize:"18px",
  color:"#ffff",
  '&:hover': {
    backgroundColor: '#498ECC',
  },

})

const RescheduleAppointmentButton=styled(Button)({
  background:"#FBBB2C",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  maxWidth:"350px",
  fontSize:"18px",
  color:"#ffff",
  '&:hover': {
    backgroundColor: '#FBBB2C',
  },
})

const CancelAppointmentButton=styled(Button)({
  background:"red",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  fontSize:"18px",
  maxWidth:"350px",
  color:"#ffff",
  '&:hover': {
    backgroundColor: 'red',
  },
})

const ModalOktButton=styled(Button)({
  background:"#498ECC",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  fontSize:"18px",
  width:"130px",
  color:"#fff",
  '&:hover': {
    backgroundColor: '#498ECC',
  },
})

const  DoNotWantToCancelButton=styled(Button)({
  background:"#fff",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  marginLeft:"24px",
  border:"1px solid black",
  fontSize:"16px",
  color:"#000",
  '&:hover': {
    backgroundColor: '#fff',
  },
})


const YesCancelButton=styled(Button)({
  background:"red",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  marginLeft:"24px",
  fontSize:"16px",
  color:"#ffff",
  width:"180px",
  '&:hover': {
    backgroundColor: 'red',
  },
})

const AppointmentDetyailsBox= styled(Box)({
  padding:"24px",
  borderRadius:"12px",
  width:"100%",
  boxShadow: " 0px 2px 13px 0px rgba(88, 87, 87, 0.16)",
  marginBottom:"24px",
  paddingBottom:"24px"
})

// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUpdateModal: boolean;
  aadharTaken: boolean;
  isDocumentModal: boolean;
  patientImage: any;
  fullName: string;
  email: string;
  phoneNum: string;
  dateOfBirth: string;
  PatientGender: string;
  Weights: string;
  BloodGroups: string;
  patientCity: string;
  aadhar: string;
  health: string;
  ayushman: string;
  getDisease: any;
  disease: string;
  patientName: string;
  patientEmail: string;
  PhoneNumber: string;
  selectedImage: string;
  isUpdate: boolean;
  patientDetails: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class PatientUpdateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUpdatePatientApiCallId: string = "";
  upload: any;
  // Customizable Area Enddata:

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      getDisease: [],
      patientImage: "",
      selectedImage: "",
      isUpdateModal: true,
      aadharTaken: false,
      isDocumentModal: false,
      fullName: "",
      email: "",
      phoneNum: "",
      dateOfBirth: "",
      PatientGender: "",
      Weights: "",
      BloodGroups: "",
      patientCity: "",
      aadhar: "",
      health: "",
      ayushman: "",
      disease: "",
      patientName: "",
      patientEmail: "",
      PhoneNumber: "",
      isUpdate: false,
      patientDetails: "",
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const parsedDate = await this.props.location.state?.patientData?.date_of_birth;
    let isPatientGoogleLogin = await JSON.parse(localStorage.getItem("isPatientGoogleLogin") as any);
    const patientDetails = await JSON.parse(localStorage.getItem("patientDetails") as any) ;
    const health_id = await JSON.parse(  localStorage.getItem("health_id") as any );

    if(isPatientGoogleLogin && this.props.location.state.comingfrom_signup ){
     this.setState({
       patientName: this.props.location.state.userDetails.name,
       patientEmail: this.props.location.state.userDetails.email,
       health: health_id
     })
    } 

    if(parsedDate === undefined){
      this.setState({dateOfBirth:new Date().toDateString()})
    }else{
      this.setState({dateOfBirth:parsedDate})
    }

    if(patientDetails.full_name){
      this.setState({Weights:this.props.location.state?.patientData.weight})
      this.setState({
        patientName: this.props.location.state.patientData.full_name,
        PhoneNumber: this.remove_countryCode(this.props.location.state?.patientData?.full_phone_number), 
        patientEmail: this.props.location.state.patientData.email_id,
        PatientGender: this.props.location.state?.patientData.gender,
        BloodGroups: this.props.location.state?.patientData.blood_group,
        patientCity: this.props.location.state?.patientData?.city,
        aadhar: this.props.location.state?.patientData?.aadhar_no,
        health: this.props.location.state?.patientData?.health_id,
        selectedImage: this.props.location?.state?.patientData?.profile_photo,
        getDisease: this.props.location?.state?.patientData?.disease,
      });
     }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isValidResponses(responseJson)) {
        this.apiPatientSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponses(responseJson)) {
        this.apiPatientFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  disableDateFuction=()=>{
    let isLogin = JSON.parse(localStorage.getItem("isLogin") as any) ;
    return isLogin
  }

  isValidResponses = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidResponses = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiPatientSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postUpdatePatientApiCallId) {
      this.postUpdatePatientSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiPatientFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postUpdatePatientApiCallId) {
      this.postUpdatePatientFailureCallBack(responseJson);
    }
  };

  handleCode(phoneNumber:any) {
    if(phoneNumber?.length <11){
      return phoneNumber
    }else{
      return phoneNumber.replace(/^91/, '');
    }
  }
  // Customizable Area End

  onFinishUpdatePatient = async (values: {
    patientName: string;
    PhoneNumber: string;
    patientEmail: string;
    patientImage: string;
    DOB: string;
    PatientGender: string;
    Weights: string;
    BloodGroups: string;
    patientCity: string;
    AadharNo: string;
    Health: string;
    AyushmanBharat: string;
    disease: any;
  }) => {
    this.postUpdatePatient(values);
  };

  postUpdatePatient = async (values: {
    patientName: string;
    PhoneNumber: string;
    patientEmail: string;
    DOB: string;
    PatientGender: string;
    Weights: string;
    BloodGroups: string;
    patientCity: string;
    AadharNo: string;
    Health: string;
    AyushmanBharat: string;
    disease: any;
  }) => {
    let a:string = values.disease;
    this.handleDisease(values.disease)
    let apiBodyWithImages = {
      data: {
        type: "sms_account",
        attributes: {
          profile_photo: { data: this.state.patientImage },
          full_name: values.patientName,
          full_phone_number: `91${values.PhoneNumber}`,
          email_id: values.patientEmail,
          date_of_birth: values.DOB,
          gender: values.PatientGender,
          weight: values.Weights,
          blood_group: values.BloodGroups,
          city: values.patientCity,
          aadhar_no: values.AadharNo,
          health_id: values.Health,
          ayushman_bharat_id: values.AyushmanBharat,
          disease: this.state.getDisease
        },
      },
    };
    let apiBodyWithoutImages = {
      data: {
        type: "sms_account",
        attributes: {
          full_name: values.patientName,
          full_phone_number: `91${values.PhoneNumber}`,
          email_id: values.patientEmail,
          date_of_birth: values.DOB,
          gender: values.PatientGender,
          weight: values.Weights,
          blood_group: values.BloodGroups,
          city: values.patientCity,
          aadhar_no: values.AadharNo,
          health_id: values.Health,
          ayushman_bharat_id: values.AyushmanBharat,
          disease: this.state.getDisease,
        },
      },
    };
    this.postUpdatePatientApiCallId = ApiCall({
      contentType: configJSON.postUpdatePatientApiContentType,
      method: configJSON.postUpdatePatientAPiMethod,
      endPoint: configJSON.postUpdatePatientAPiEndPoint,
      body: this.state.patientImage ? apiBodyWithImages : apiBodyWithoutImages,
    });
  };

  postUpdatePatientSuccessCallBack = (res: any) => {
    localStorage.setItem("patientDetails", JSON.stringify(res.data.attributes));
    localStorage.setItem("isLogin", JSON.stringify(true));
    if(res.data.attributes.profile_photo.length >0){
      localStorage.setItem("profile_pic", res.data.attributes.profile_photo);
    }
    notification["success"]({
      message: "Patient updated successfully",
    });
    this.props.history.push("/patienthome")
  };

  postUpdatePatientFailureCallBack = async (err: any) => {
    if (err.errors[0].aadhar_no === "has already been taken") {
      this.setState({
        aadharTaken: true,
      });
    }
  };

  imgFilehandlers = (e: any) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        let profileImage = reader.result;
        this.setState({ patientImage: profileImage });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  handleDateDisable=()=>{
    let isGoogleLogin = JSON.parse(localStorage.getItem("isPatientGoogleLogin") as any) ;
    return !isGoogleLogin
  }

  handleDateDisablee = (current: any) => {
    return current && current > moment().startOf("day");
  }
  remove_countryCode(phoneNumber:any) {
    if(phoneNumber?.length >10){
      return phoneNumber.replace(/^91/, '');
    }else{
      return phoneNumber
    }
  }
  
  handleDisease=(disease:string)=>{
    let newArray:any = [];
    newArray.push(disease);
    this.setState({getDisease:newArray})
  }
 
}
// Customizable Area End

import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row, Button, Modal, Typography } from "antd";
import { RightOutlined, BanerImg, Plussymbol, minsesymbol } from "./assets";
import MedicineServicesPageController from "./MedicineServicesPageController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import FooterCardComponent from "../../shoppingcart/src/component/FooterCardComponent";
import { SearchOutlined } from "@material-ui/icons";
import Text from "antd/lib/typography/Text";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";

let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class MedicineServicesPage extends MedicineServicesPageController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patientappointment">Appointments</Link>

          <Link to="/services" className="active">Services</Link>
          <Link to="/patienthome">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const dataList = this.state.BrandsDataSliced
      ? this.state.BrandsData.slice(0, 6)
      : this.state.BrandsData;

    return (
      <div>
        <NavbarCommonComponent
          handleProfile={this.showDetailsHandler}
          data-test-id="showDetailsHandlerId"
        />
        <PatientProfilePopup
          data-test-id="handleLogoutId"
          profileData={this.state.showPopup}
          logoutFunctiuon={this.handleLogout}
          
        />
        {this.renderNavigationTitle()}
        <div
          className="itemContainerWiidth"
          style={{ width: "90%", justifyContent: "space-around" }}
        >
          <div
            className="navigation-list-css-container"
            style={{ paddingLeft: "3%" }}
          >
            <div key={"homeKey"}>
              <Text
                className="navigation-list-css"
                style={{ color: "#498ECC", fontSize: "18px" }}
                onClick={() => this.props.history.push("/patienthome")}
                data-test-id="home-navigate-id"
              >
                Home
              </Text>
              <img src={RightOutlined} alt="" style={{ width: "10px" }} />
            </div>
            <div key={"ri425"}>
              <Text
                key={"ServicesKey"}
                style={{ color: "#498ECC", fontSize: "18px" }}
                className="navigation-list-css"
                onClick={() => this.props.history.push("/services")}
                data-test-id="sevices-navigate-id"
              >
                Service
              </Text>

              <img src={RightOutlined} alt="" style={{ width: "10px" }} />
            </div>
            <div key={"ordermedicinekey"}>
              <Text
                key={"ordermedicinekey"}
                style={{ color: "#498ECC", fontSize: "18px" }}
                className="navigation-list-css"
                onClick={() => this.props.history.push("/OrderMedicinePage")}
                data-test-id="ordermedicine-navigate-id"
              >
                Order Medicine
              </Text>

              <img src={RightOutlined} alt="" style={{ width: "10px" }} />

            </div>
            <Text
          key={"ordermedicinekey"}
                style={{ color: "#498ECC", fontSize: "18px" }}
                className="navigation-list-css"
                onClick={() => this.props.history.push("/MedicineServicesPage")}
                data-test-id="ordermedicine-navigate-id"
              >
               Heart
              </Text>
          </div>
         
          {/* <div className='navigation-list-css-container' style={{ paddingLeft: '5%',margin:'2rem' }}>  <Text className='navigation-list-css' style={{ color: "#498ECC" }}>Home</Text>   <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text style={{ color: "#498ECC" }} className='navigation-list-css'>Services</Text> <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text style={{ color: "#498ECC" }} className='navigation-list-css'>Order Medicine</Text></div> */}

          <div
            className="searchBoxDoc"
            style={{
              width: "90%",
              justifyContent: "start",
              gap: "15px",
              marginBottom: "50px",
            }}
          >
            <span>
              <SearchOutlined />
            </span>
            <input
              type="text"
              className="searchInput"
              placeholder="Search For Medicine"
            />
          </div>
          <div className="ourServicesContainer">
            <div className="ourServicesHeaderPart">
              <h2 className="ourServicesTitle" style={{marginBottom:"50px"}}>Heart</h2>
            </div>
            <Row gutter={[8, 16]}>
              {dataList?.map((item: any, index: any) => {
                return (
                  <Col xl={6} sm={12}>
                    <div className="Cardwrapper">
                      <div className="cardMedicineImgWrapper">
                        <img
                          src={
                            item.attributes.medicine_images[0] &&
                            config + item.attributes.medicine_images[0].url
                          }
                          alt=""
                         className="cardMedicineImg"
                        />
                        <h3 className="cardImageText">
                          {item.attributes.medicine_name}
                        </h3>
                        <h3 className="cardImageTextSmall">
                          {item.attributes.medicine_quantity}
                        </h3>
                      </div>

                      <br />
                      <div>
                        <p className="paragraphStyle">Manufacturer</p>
                        <p className="paragraphStyle">
                          {item.attributes.manufacturer_name}
                        </p>
                        <p className="paragraphStyle">
                          MRP Rs {item.attributes.total_amount}{" "}
                          <span style={{ color: "#2AE867" }}>
                            {item.attributes.discount}% OFF
                          </span>
                        </p>
                      </div>
                      <p
                        style={{
                          color: "#353535",
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        Rs 64.03
                      </p>
                      {this.state.selectedIndex === index && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            marginTop: "30px",
                          }}
                        >
                          <Button
                            type="text"
                            shape="circle"
                            size="small"
                            icon={<img src={minsesymbol} alt="" style={{height:"16px", width:"16px"}} />}
                            onClick={() => this.productIncreament(0)}
                            data-test-id="decrementId"
                          />

                          <Typography.Text>
                            {this.state.productCount}
                          </Typography.Text>
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            onClick={() => this.productIncreament(1)}
                            data-test-id="incrementId"
                            icon={ <img src={Plussymbol} alt="" style={{height:"22px", width:"22px"}} />}
                          />
                        </div>
                      )}

                      {this.state.selectedIndex === index ? (
                        <Button
                          onClick={() => this.handleAddCreate(item)}
                          data-test-id="createCartId"
                          style={{
                            background: "#498ECC",
                            color: "white",
                            borderRadius: "30px",
                            padding: "0.5rem",
                            width: "160px",
                            height: "32px",
                          }}
                        >
                          ADD TO CART
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.addToCartFunc(index)}
                          data-test-id="addCartId"
                          style={{
                            background: "#498ECC",
                            color: "white",
                            borderRadius: "30px",
                            padding: "0.5rem",
                            width: "160px",
                            height: "32px",
                          }}
                        >
                          ADD
                        </Button>
                      )}
                    </div>
                    <Modal
                      title="Successful Response"
                      visible={this.state.isModalOpen}
                      onCancel={this.handleCancel}
                      style={{
                        maxWidth: "500px",
                        borderRadius:'10px',
                        margin:'auto'
                      }}
                      bodyStyle={{
                        padding: "20px",
                        textAlign: "center",
                      }}
                      closeIcon={
                        <span style={{ color: "#333", fontSize: "20px" }}>
                          &times;
                        </span>
                      }
                      footer={[
                        <Button
                          key="ok"
                          onClick={()=>this.props.history.push("MedicineCartPage")}
                          style={{
                            backgroundColor: "#007bff",
                            borderColor: "#007bff",
                            color: "#fff",
                          }}
                        >
                         Go To Cart
                        </Button>,
                        <Button
                          key="cancel"
                          onClick={this.handleCancel}
                          data-test-id="handleCancel-id"
                          style={{
                            color: "#007bff",
                          }}
                        >
                          Add More
                        </Button>,
                      ]}
                    >
                      <p>Your Product has been added to cart.</p>
                    </Modal>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div>
            <Row justify={"center"} style={{ padding: "1rem" }}>
              <img className="bannerStyle" src={BanerImg} />
            </Row>
          </div>
          <FooterCardComponent />
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default MedicineServicesPage;
export { MedicineServicesPage };
// Customizable Area End

import React from "react";
// Customizable Area Start
import PatientUpdateController from "./PatientUpdateController.web";
import { Modal, Form, Input, Select, Row, Col, Button, DatePicker } from "antd";
import { FiCamera } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import moment from "moment";
let config = require('../../../framework/src/config.js').baseURL;

// Customizable Area End
export class PatientUpdate extends PatientUpdateController {
  // Customizable Area Start
  renderPatientUpdateModal = () => {
    
    return (
      
      <div className="patientRegisterContainer">
        <Modal
          centered
          open={this.state.isUpdateModal}
          footer={null}
          width={920}
          closable={false}
        >
          <p className="patientRegHeading">Update Patient</p>
          <div className="patientForm">
            <div className="patientRegFormRightContainer">
              <div className="uploadIPatientmageContainer">
                {this.state?.patientImage ? (
                  <img
                    src={this.state.patientImage}
                    alt=""
                    className="uploadedPatientImage"
                  />
                ) : (
                  <>
                  {this.state.selectedImage ?   <img
                    src={config + this.state.selectedImage}
                    alt=""
                    className="uploadedPatientImage"
                  /> :    <p>
                  <AiOutlineUser />
                </p>

                  }
                  </>
                )}
                <button
                  data-test-id="uploadImageId"
                  className="uploadPatientImageAction"
                >
                  <input
                    data-test-id="imgUploadId"
                    type="file"
                    accept="image/*"
                    name="imageupload"
                    id="input"
                    style={{ display: "none" }}
                    onChange={this.imgFilehandlers}
                  />
                  <label htmlFor="input">
                    <FiCamera />
                  </label>
                </button>
              </div>
            </div>
            <div className="patientRegFormLeftContainer">
              <Form
                data-test-id="submitFormId"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                onFinish={this.onFinishUpdatePatient}
                className="patient-registration-style"
                fields={[
                  {
                    name: ["patientName"],
                    value: this.state.patientName,
                  },
                  {
                    name: ["PhoneNumber"],
                    value: this.state.PhoneNumber,
                  },
                  {
                    name: ["patientEmail"],
                    value: this.state.patientEmail,
                  },
                  {
                    name: ["PatientGender"],
                    value: this.state.PatientGender,
                  },
                  {
                    name: ["Weights"],
                    value: this.state.Weights,
                  },
                  {
                    name: ["BloodGroups"],
                    value: this.state.BloodGroups,
                  },
                  {
                    name: ["patientCity"],
                    value: this.state.patientCity,
                  },
                  {
                    name: ["AadharNo"],
                    value: this.state.aadhar,
                  },
                  {
                    name: ["Health"],
                    value: this.state.health,
                  },
                  {
                    name: ["AyushmanBharat"],
                    value: this.state.ayushman,
                  },
                  {
                    name: ["disease"],
                    value: this.state.getDisease.join(", "),
                  },
                  {
                    name: ["DOB"],
                    value: moment(this.state.dateOfBirth ?? new Date()),
                  },
                  {
                    name: ["imageupload"],
                    value: moment(this.state.patientImage),
                  },
                ]}
              >
                <Row gutter={[16, 8]}>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Full Name"
                      name="patientName"
                      rules={[
                        {
                          pattern: /^[a-zA-Z. ]{3,30}$/,
                          message: "It should be valid Name.",
                        },
                      ]}
                    >
                      <Input data-test-id="patientNameID" name="patientName" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Email Id"
                      name="patientEmail"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your email id.",
                        },
                        {
                          pattern: /^\s*\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,16}\s*$/,
                          message: "It should be valid email Id",
                        },
                      ]}
                    >
                      <Input
                        data-test-id="patientEmailId"
                        name="patientEmail"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Phone No."
                      name="PhoneNumber"
                      rules={[
                        {
                          required: true,
                          pattern:  /^[6-9]\d{9}$/,
                          message: "It should be valid Phone No",
                        },
                      ]}
                    >
                      <Input
                        data-test-id="PhoneNumberId"
                        name="PhoneNumber"
                        disabled={this.handleDateDisable()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Date of Birth(YYYY-MM-DD)"
                      name="DOB"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your DOB",
                        },
                      ]}
                    >
                      <DatePicker
                        className="createDob"
                        data-test-id="DOBId"
                        name="DOB"
                        disabledDate={this.handleDateDisablee}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Gender"
                      name="PatientGender"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Gender.",
                        },
                      ]}
                    >
                      <Select data-test-id="PatientGenderId">
                        <Select.Option value="Male">Male</Select.Option>
                        <Select.Option value="Female">Female</Select.Option>
                        <Select.Option value="Others">Others</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Weight(KG)"
                      name="Weights"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your weight",
                        },
                        {
                          pattern: new RegExp(/^(?:[1-9]\d{0,2}(\.\d+)?|0\.\d+)(kg|lbs)?$/),
                          message: "Please enter valid weight",
                        },
                      ]}
                    >
                      <Input data-test-id="WeightsId" name="Weights" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Blood Group"
                      name="BloodGroups"
                      rules={[
                        {
                          required: true,
                          message: "Please select your blood group.",
                        },
                      ]}
                    >
                      <Select data-test-id="BloodGroupsId">
                        <Select.Option value="A+">A+</Select.Option>
                        <Select.Option value="A-">A-</Select.Option>
                        <Select.Option value="B+">B+</Select.Option>
                        <Select.Option value="B-">B-</Select.Option>
                        <Select.Option value="O+">O+</Select.Option>
                        <Select.Option value="O-">O-</Select.Option>
                        <Select.Option value="AB+">AB+</Select.Option>
                        <Select.Option value="AB-">AB-</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="City"
                      name="patientCity"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your city",
                        },
                        {
                          pattern: /^[A-Z ]+$/i,
                          message: "Please enter valid city name.",
                        },
                      ]}
                    >
                      <Input data-test-id="patientCityId" name="patientCity" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Aadhar No."
                      name="AadharNo"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Aadhar Card No",
                        },
                        {
                          pattern:/^[2-9]{1}\d{3}\d{4}\d{4}$/,
                          message: "Please enter valid Aadhar card no.",
                        },
                      ]}
                    >
                      <Input data-test-id="AadharNoId" name="AadharNo" />
                    </Form.Item>
                    {this.state.aadharTaken ? (
                      <p className="error-password">
                        Aadhar number has already been taken
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Health ID"
                      name="Health"
                      rules={[
                        {
                          pattern: /^[A-Za-z0-9]*$/,
                          message: "Please enter valid Health Id.",
                        },
                      ]}
                    >
                      <Input data-test-id="HealthsID" name="Health" disabled />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Ayushman Bharat ID"
                      name="AyushmanBharat"
                      rules={[
                        {
                          pattern: /^[A-Za-z0-9]*$/,
                          message: "Please enter valid Ayushman bharat Id.",
                        },
                      ]}
                    >
                      <Input
                        data-test-id="AyushmanBharatsID"
                        name="AyushmanBharat"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Disease(History or Current)"
                      name="disease"
                      rules={[
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          message: "Please enter valid disease name",
                        },
                      ]}
                    >
                      <Input data-test-id="diseasesId" name="disease" />
                    </Form.Item>
                  </Col>
                  <Col xl={24} lg={24} sm={24} md={24} xs={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submitButton"
                        data-test-id="UpdateID"
                      >
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return <div>{this.renderPatientUpdateModal()}</div>;
  }
}
// Customizable Area End

// Customizable Area Start
export default PatientUpdate;
// Customizable Area End

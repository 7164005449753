import moment from "moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: any;
  start_time: any;
  end_time: any;
  id: any;
  token: string;
  appointmentsList: any;
  isRefreshing: boolean;
  mobileOpen: boolean,
  selectedIndex: number,
  selectedFile: any,
  UploadCertificate: any,
  selectAdminRole:
  {
    id: number,
    name: string
  }[];
  value: string,
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addAppointmentApiCallId: any;
  getAppointmentsListApiCallId: any;
  deleteAllAppointmentsApiCallId: any;
  newUserCreateCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: new Date(),
      end_time: endTime,
      available_date: moment(new Date()).format("DD/MM/YY"),
      appointmentsList: [],
      token: "",
      isRefreshing: false,
      mobileOpen: false,
      selectedIndex: 0,
      selectedFile: "",
      UploadCertificate: [],
      selectAdminRole: [
        {
          id: 1,
          name: 'admin'
        },
        {
          id: 2,
          name: 'Doctor'
        },
        {
          id: 3,
          name: 'Receptionist'
        },
        {
          id: 4,
          name: 'Leboratory'
        },
        {
          id: 5,
          name: 'Pharmacy'
        }
      ],
      value: ''
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addAppointmentApiCallId != null &&
      this.addAppointmentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.getAppointmentList(this.state.token);
        this.props.navigation.navigate("Appointments");
        // this.setState({ isRefreshing: false });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getAppointmentList(token);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId != null &&
      this.getAppointmentsListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          appointmentsList: responseJson?.data?.attributes?.time_slots,
        });
      } else {
        this.showAlert("Alert", "No Slots", "");
        this.setState({ appointmentsList: [] });

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.newUserCreateCallId:
            console.log(responseJson.errors, 'profileData');
            if ((responseJson.errors)) {
              responseJson.errors.forEach((errorObj: { [x: string]: any; }) => {
                Object.keys(errorObj).forEach(key => {
                  toast.error(`${key}: ${errorObj[key]}`);
                });
              });
            } else {
              toast.success("User create success");
              this.props.history.push(`/AdminDashboard/${"Dashboard"}`, { userData: responseJson.data });
            }
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addAppointment(): boolean {
    if (
      this.isStringNullOrBlank(this.state.available_date) ||
      this.isStringNullOrBlank(this.state.start_time) ||
      this.isStringNullOrBlank(this.state.end_time)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const attrs = {
      start_time: moment(this.state.start_time).format("HH:mm A"),
      end_time: moment(this.state.end_time).format("HH:mm A"),
      availability_date: moment(this.state.available_date, "DD/MM/YY").format(
        "YY/MM/DD"
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAppointmentApiCallId = requestMessage.messageId;

    const httpBody = {
      ...attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appointmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAppointmentList = (token: any) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      service_provider_id: "1",
      availability_date: this.state.available_date,
    };

    this.getAppointmentsListApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToAppointments = () => {
    this.props.navigation.navigate("Appointments");
  };
  // Customizable Area Start
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  };

  // handleListItemClick = (index: number, text: string) => {
  //   this.setState({ selectedIndex: index });
  //   if (text === "logout") {
  //     this.props.history.push('/AdminLogin')
  //   } else if (text === 'Create a new user') {
  //     this.props.history.push('/CreateNewUser')
  //   }
  // };

  handleFileChange = (event: any) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  handleFileChange1 = (event: any) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    this.setState((prevState) => ({
      UploadCertificate: [...prevState.UploadCertificate, file],
    }));
  };
  removeImage = (indexToRemove: number) => {
    this.setState((prevState) => ({
      UploadCertificate: prevState.UploadCertificate.filter((_: any, index: number) => index !== indexToRemove),
    }));
  };
  handleChange = (event: any) => {
    let adminRole = event.target.value
    this.setState({ value: adminRole }, () => {
      console.log(this.state.value, 'CreateNewUser');
    });
  };
  CreateNewUser = async (data: any) => {
    let formdata = new FormData();
    formdata.append("data[first_name]", data.firstname);
    formdata.append("data[last_name]", data.lastname);
    formdata.append("data[email]", data.emailid);
    formdata.append("data[phone_number]", data.phoneno);
    formdata.append("data[hospital_name]", data.hospitalname);
    formdata.append("data[address]", data.address);
    formdata.append("data[specialty]", data.speciality);
    formdata.append("data[role]", this.state.value);
    formdata.append("data[hospital_id]", "7");
    this.state.UploadCertificate.forEach((certificate: File, index: number) => {
      formdata.append(`data[certificates][${index}]`, certificate);
    });

    const header = {
      ContentType: "multipart/form-data",
      token: await getStorageData('AdminToken')
    };
    const httpBody = formdata;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.newUserCreateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/create_hospital_user`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { Col, Row,Card,Typography,Button } from "antd";
import { Rate } from 'antd';
import { rupay } from "../../blocks/splashscreen/src/assets";
// @ts-ignore
import femaleDoctoe from "./female-doctor.png"
// @ts-ignore
import verifiedDoc from "./verified_doc.png"
let config = require('../../framework/src/config.js').baseURL;
// Customizable Area End

type DoctorsCommonComponentProps = {
    // Customizable Area Start
    label1: any,
    label2: any,
    img: any,
    // Customizable Area End
};

const DoctorsCommonComponent = ({
    // Customizable Area Start
    label1,
    label2,
    img,
    // Customizable Area End
}: DoctorsCommonComponentProps) => {
    // Customizable Area Start

    return (
        <>
            <div className='ourServicesContainer marginCarousel'>
                <Row gutter={[20, 20]}>
                        
                            <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                              <Card className="offers_card" >
                                <div className="contentwrapper" >
                                    <div className="textWrapper" >
                                        <Typography className="promo_title" >
                                        30% off <span className="promo_title_span" >
                                        on your first 
                                        Consultation  
                                            </span> 
                                        </Typography>
                                        <Button  className="offerlabel_button" >
                                        Code: Health 30
                                        </Button>                           
                                    </div>
                                    <div className="icon_rapper" >
                                        <img className="doctor_img" src={femaleDoctoe} alt="alt" />
                                    </div> 
                                </div>

                              </Card>
                            </Col>

                            <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                              <Card className="offers_card">
                                <div className="contentwrapper" >
                                    <div className="textWrapper" >
                                        <Typography className="promo_title">
                                         Verified <span className="promo_title_span_doctors" >
                                                  Doctor
                                                </span> 
                                        </Typography>
                                        <br/>
                                        <img src={verifiedDoc} alt="" className="verifiedDoc_img" />
                                    </div>
                                    <div className="icon_rapper" >
                                        <img className="doctor_img" src={femaleDoctoe} alt="alt" />
                                    </div>  
                                </div>

                              </Card>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                              <Card className="offers_card">
                                <div className="contentwrapper" >
                                <div className="textWrapper" >
                                        <Typography className="promo_title" >
                                        30% off <span className="promo_title_span" >
                                        on your first 
                                        Consultation  
                                            </span> 
                                        </Typography>
                                        <Button  className="offerlabel_button" >
                                        Code: Health 30
                                        </Button>                           
                                    </div>
                                    <div className="icon_rapper" >
                                        <img className="doctor_img" src={femaleDoctoe} alt="alt" />
                                    </div>  
                                </div>

                              </Card>
                            </Col>
                </Row>
            </div>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default DoctorsCommonComponent
// Customizable Area End

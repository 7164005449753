import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import { Doctor, receptionist, LabAssistans, Pharmacy } from "./assets";
// Customizable Area End

import HospitalAdminController, {
    Props,
} from "./HospitalAdminController.web";



// Customizable Area Start
const madication = [
    {
        id: 1,
        name: 'Doctor',
        image: Doctor,
    },
    {
        id: 2,
        name: 'Receptionist',
        image: receptionist,
    },
    {
        id: 3,
        name: 'Lab Assistant',
        image: LabAssistans,
    },
    {
        id: 4,
        name: 'Pharmacy',
        image: Pharmacy
    },
]

// Customizable Area End

export default class HospitalAdmin extends HospitalAdminController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <Box>
                <SideBar UserDetailList={this.UserDetailList} data-test-id="sideBardata" childProps={this.props.history} madication={madication} handleDrawerToggle={this.handleDrawerToggle} selectedIndex={this.state.selectedIndex} mobileOpen={this.state.mobileOpen} handleListItemClick={this.handleListItemClick} />
            </Box>
        );
        // Customizable Area Start
    }
}

// Customizable Area Start
const Styles = {

}
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
  message: object;
  data: object;
}

export interface TransactionType {
  from: object
}

export interface InvalidResponseType {
  errors: "";
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string
}

export interface DoctorDetailsType {
  data: DoctorDetailsData;
}
export interface DoctorDetailsData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    medical_representative_name: string;
    representative_contact_no: string;
    profile_image: string;
  }
}

export interface ScheduleType {
  data: {
      id: string;
      type:string;
      attributes: {
          doctor_id: number;
          patient_id: number;
          health_id: string;
          patient_phone_number: string;
          symtoms: Array<string>;
          time_slot: string;
          end_time: string;
          pick_time: string;
          mode_type: string;
          status: number;
          last_visit: string;
          razorpay_order_id: string;
          payment_status: string;
          patient_full_name: string;
          patient_gender: string;
          patient_year: string;
          patient_profile_image: string;
          doctor_gender: string;
          doctor_age: string;
          appointment_date: string;
          appointment_type: string;
          payment: string;
      }
  }
}

export interface TransactionDetailsType {
	from: {
		data: Array<TransactionFrom>;
	},
	amount: number;
	payment_completed_on: string;
	to: {
		data: Array<TransactionTo>;
	}
}

export interface TransactionFrom {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    full_phone_number: string;
    profile_photo: string;
  }
}

export interface TransactionTo {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    email: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPopup: boolean;
  doctorProfileData: {};
  transactionModal: boolean;
  scheduledHelthId: string;
  scheduledDetails: ScheduleType;
  transactionDetails: TransactionDetailsType;
  contentHeight: number;
  isLoader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ScheduledDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDetailsCallId: string = "";
  getScheduledDetailsCallId: string = "";
  getTransactionDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showPopup: false,
      doctorProfileData: {},
      transactionModal: false,
      scheduledHelthId: this.props.location?.state.scheduledHelthId,
      scheduledDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            doctor_id: 0,
            patient_id: 0,
            health_id: "",
            patient_phone_number: "",
            symtoms: [],
            time_slot: "",
            end_time: "",
            pick_time: "",
            mode_type: "",
            status: 0,
            last_visit: "",
            razorpay_order_id: "",
            payment_status: "",
            patient_full_name: "",
            patient_gender: "",
            patient_year: "",
            patient_profile_image: "",
            doctor_gender: "",
            doctor_age: "",
            appointment_date: "",
            appointment_type: "",
            payment: ""
          }
        }
      },
      transactionDetails: {
        from: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                full_name: "",
                full_phone_number: "",
                profile_photo: ""
              }
            }
          ]
        },
        amount: 0,
        payment_completed_on: "",
        to: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                full_name: "",
                email: ""
              }
            }
          ]
        }
      },
      contentHeight: 0,
      isLoader: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleDocDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handlePopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  handleEditDocProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };
  
  handelShowDoctoPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  async componentDidMount() {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
    this.getProfilefunction();
    this.getScheduledDetailsfunction();
  }

  apiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint, body } = data;
    let token = localStorage.getItem("token");
    let header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.validResponse(responseJson)) {
        this.apiInvoiceSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
  }

  validResponse = (responseJson: ValidResponseType & TransactionDetailsType) => {
    return (responseJson && responseJson.data) || (responseJson);
  };

  apiInvoiceSuccessCallBacks = (apiRequestCallId: string, responseJson: DoctorDetailsType & ScheduleType & TransactionDetailsType) => {
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      this.getProfileSuccessCallBack(responseJson);
    }
    if (apiRequestCallId == this.getScheduledDetailsCallId) {
      this.getScheduledDetailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getTransactionDetailsCallId) {
      this.getTransactionDetailsSuccessCallBack(responseJson);
    }
  };

  getProfilefunction = async () => {
    this.getProfileDetailsCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint
    });
  };

  getProfileSuccessCallBack = (response: object) => {
    this.setState({
      doctorProfileData: response
    });
  };

  getScheduledDetailsfunction = async () => {
    this.getScheduledDetailsCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getScheduledDetailsApiEndPoint}?health_id=${this.state.scheduledHelthId}`
    });
  };

  getScheduledDetailsSuccessCallBack = (response: ScheduleType) => {
    this.setState({ scheduledDetails: response});
  };

  getTransactionDetailsfunction = async () => {
    this.getTransactionDetailsCallId = this.apiCall({
      contentType: configJSON.transactionApiContentType,
      method: configJSON.transactionApiMethodType,
      endPoint: `${configJSON.getTransactionDetailsApiEndPoint}?health_id=${this.state.scheduledHelthId}`
    });
    this.setState({
      isLoader: true
    });
  };

  getTransactionDetailsSuccessCallBack = (response: TransactionDetailsType) => {
    this.setState({
      transactionDetails: response,
      isLoader: false
    });
  };

  onClickModalOpen = () => {
    this.setState({ 
      transactionModal: true } , () => 
    {
      this.getTransactionDetailsfunction();
    });
  };

  onCloseModal = () => {
    this.setState({ transactionModal: false });
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleReschedule = () => {
    this.props.history.push("/doctorschedule")
  };
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Modal
} from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import { MdArrowBack } from "react-icons/md";
import { BsQrCodeScan } from "react-icons/bs";
import { PiNotepadLight } from "react-icons/pi";
import { FaRegCalendarAlt } from "react-icons/fa";
import MypatientController, { AllPatient, Props } from "./MypatientController.web";
let config = require("../../../framework/src/config.js").baseURL;
import { Default } from "../../../blocks/splashscreen/src/assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class Mypatient extends MypatientController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderNavigatePage = () => {
        return (
            <EditPageHeader>
                <Link to={"/doctorhome"}><PageHeader>Home</PageHeader></Link>
                <Link to={"/doctorschedule"}><PageHeader>Schedule</PageHeader></Link>
                <Link to={"/addpatient"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderAllPatients = () => {
        return (
            <>
                {
                    (this.state.activePatientTab === "All" && this.state.allPatientLists.data[0].attributes.gender !== "") ?
                        this.state.allPatientLists.data.map((item: AllPatient) => {
                            return (
                                <>
                                    <AllPatientBox>
                                        <PatientInfoBox>
                                            <PatientProfileBox>
                                                <PatientProfile>
                                                    {item.attributes.profile_photo ? <ProfileImage src={config + item.attributes.profile_photo} /> : <ProfileImage src={Default} />}
                                                </PatientProfile>
                                                <PatientNameBox>
                                                    <PatientNameText>{item.attributes.full_name} | {item.attributes.age}&nbsp;{item.attributes.gender}</PatientNameText>
                                                    <PatientBluredText data-test-id="activeTabButtonTestId">Last Visit : <LastVisitText component={"span"}>{item.attributes.last_visit}</LastVisitText></PatientBluredText>
                                                </PatientNameBox>
                                            </PatientProfileBox>
                                            <Box>
                                                <PatientNumberText>{item.attributes.full_phone_number}</PatientNumberText>
                                                <PatientBluredText>#{item.attributes.health_id}</PatientBluredText>
                                            </Box>
                                        </PatientInfoBox>
                                        <TagsBox>
                                            <PatientBluredText component={"span"}>TAGS:</PatientBluredText>
                                            {
                                                item.attributes.disease.map((diseaseItem: string) => {
                                                    return (
                                                        <Tags>{diseaseItem}&nbsp;</Tags>
                                                    )
                                                })
                                            }
                                        </TagsBox>
                                        <ActionButtonBox>
                                            {item.attributes.appointment_id > 0 ?
                                                <FilledButton onClick={() => this.handleAppointmentsDetails(item.attributes.appointment_id)}
                                                ><FaRegCalendarAlt />Scheduled</FilledButton>
                                                :
                                                <FilledButtonDisable disabled><FaRegCalendarAlt />Scheduled</FilledButtonDisable>
                                            }
                                            <OutlineButton data-test-id="recordsbuttonid" onClick={() => this.handleMedicalHistory(item.attributes.patient_id)}><PiNotepadLight />Records</OutlineButton>
                                        </ActionButtonBox>
                                    </AllPatientBox>
                                </>
                            )
                        })
                        :
                        this.renderClinicNoData()
                }
            </>
        )
    };

    renderScheduleds = () => {
        return (
            <>
                {
                    (this.state.activePatientTab === "Scheduled" && this.state.allScheduleList.data[0].attributes.gender !== "") ?
                        this.state.allScheduleList.data.map((scheduleItem: AllPatient) => {
                            return (
                                <>
                                    <ScheduleBox onClick={() => this.navigateToTransactionScreen(scheduleItem.attributes.health_id)} data-test-id="scheduleId">
                                        <PatientInfoBox>
                                            <PatientProfileBox>
                                                <PatientProfile>
                                                    {scheduleItem.attributes.profile_photo ? <ProfileImage src={config + scheduleItem.attributes.profile_photo} /> : <ProfileImage src={Default} />}
                                                </PatientProfile>
                                                <PatientNameBox>
                                                    <PatientNameText>{scheduleItem.attributes.full_name} | {scheduleItem.attributes.age}&nbsp;{scheduleItem.attributes.gender}</PatientNameText>
                                                    <PatientBluredText>Last Visit : <LastVisitText component={"span"}>{scheduleItem.attributes.last_visit}</LastVisitText></PatientBluredText>
                                                </PatientNameBox>
                                            </PatientProfileBox>
                                            <Box>
                                                <PatientNumberText>{scheduleItem.attributes.full_phone_number}</PatientNumberText>
                                                <PatientBluredText>#{scheduleItem.attributes.health_id}</PatientBluredText>
                                            </Box>
                                        </PatientInfoBox>
                                        <TagsBox>
                                            <PatientBluredText component={"span"}>TAGS:</PatientBluredText>
                                            {
                                                scheduleItem.attributes.disease.map((diseaseItem: string) => {
                                                    return (
                                                        <Tags>{diseaseItem}&nbsp;</Tags>
                                                    )
                                                })
                                            }
                                        </TagsBox>
                                        <ActionButtonBox>
                                            <OutlineButton><PiNotepadLight style={{ fontSize: "16px" }} /> Records</OutlineButton>
                                            <ScanButton><BsQrCodeScan /> Scan</ScanButton>
                                        </ActionButtonBox>
                                    </ScheduleBox>
                                </>
                            )
                        })
                        :
                        this.renderClinicNoData()
                }
            </>
        )
    };

    renderContainer = () => {
        return (
            <>
                <MyPatientsHeader>
                    <BackArrow onClick={this.handleBack} data-test-id="previousButton" />
                    <MyPatientsHeadingText data-test-id="myPatientId">My Patients</MyPatientsHeadingText>
                </MyPatientsHeader>
                <MyPatientsMenuTab>
                    {
                        this.state.myPatientsTab.map((tabName, index: number) => {
                            return (
                                <>
                                    {
                                        this.state.activePatientTab === tabName ?
                                            <ActiveTabButton key={index} onClick={() => this.changeTab(tabName)} data-test-id={`activeTabButtonTestId-${index}`}>{tabName} </ActiveTabButton>
                                            :
                                            <UnActivateTabButton key={index} onClick={() => this.changeTab(tabName)}>{tabName}</UnActivateTabButton>
                                    }
                                </>
                            )
                        })
                    }
                </MyPatientsMenuTab>
                {
                    this.state.activePatientTab === "All" && this.renderAllPatients()
                }
                {
                    this.state.activePatientTab === "Scheduled" && this.renderScheduleds()
                }
            </>
        )
    };

    renderRecordsModal = () => {
        return (
            <CustomModal
                open={this.state.medicalHistory}
                onClose={this.onHistoryCloseModal}
            >
                <>
                    {this.state.medicalHistoryData.data.length > 0 ?
                        <ModalContainer>
                            <p style={{ fontSize: "18px", fontWeight: 600, padding: "10px 0px", margin: "0 auto", width: "90%" }}>Records</p>
                            {this.state.medicalHistoryData.data.map((item) => {
                                return (
                                    <CurrentCaseBox>
                                        <CurrentCaseBoldHeading>Medical History - 25/12/2021</CurrentCaseBoldHeading>
                                        <CurrentCaseInnerBox>
                                            <CurrentCaseText data-test-id="diagnosisId">DIAGNOSIS:</CurrentCaseText>
                                            <CurrentCaseBoldText>Weakness from last 15 days.</CurrentCaseBoldText>
                                        </CurrentCaseInnerBox>
                                        <CurrentCaseInnerBox>
                                            <CurrentCaseText>PRESCRIPTION</CurrentCaseText>
                                            <div style={{ display: "flex" }}>
                                                {item.attributes.prescription_medicines.data.map((sub_item, index: number) => {
                                                    return (
                                                        <CurrentCaseBoldTextMed>{sub_item.attributes.medicine_name}{(item.attributes.prescription_medicines.data.length - 1 == index) ? "" : ","}&nbsp;</CurrentCaseBoldTextMed>
                                                    )
                                                })}
                                            </div>
                                        </CurrentCaseInnerBox>
                                        <CurrentCaseInnerBox>
                                            <CurrentCaseText>BY DOCTOR:</CurrentCaseText>
                                            <CurrentCaseBoldText>{item.attributes.doctor_full_name}</CurrentCaseBoldText>
                                        </CurrentCaseInnerBox>
                                    </CurrentCaseBox>
                                )
                            })}
                            <ButtonBox>
                                <CloseButton onClick={this.onHistoryCloseModal} data-test-id="historymodalId">Close</CloseButton>
                            </ButtonBox>
                        </ModalContainer>
                        :
                        <RecordsDiv>
                            <p style={{ fontSize: "18px" }}>Records not found</p>
                        </RecordsDiv>
                    }
                </>
            </CustomModal>
        )
    };

    renderClinicNoData = () => {
        return (
            <NoDataBox>
                <p>No data found</p>
            </NoDataBox>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handlePopupClose} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderNavigatePage()}
                    {this.renderContainer()}
                </ContainerBox>
                {this.renderRecordsModal()}
                <FooterDoctor />
                <Loader loading={this.state.isLoader} height={0} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const MyPatientsHeader = styled((Box))({
    margin: "10px 0",
    display: "flex",
    columnGap: "5px",
    alignItems: "center"
});

const BackArrow = styled(MdArrowBack)({
    fontSize: "30px"
});

const MyPatientsHeadingText = styled(Typography)({
    fontWeight: 600,
    fontSize: "16px"
});

const MyPatientsMenuTab = styled((Box))({
    display: "inline-flex",
    borderRadius: "30px",
    backgroundColor: "#e3ecf7",
    margin: "10px 0"
});

const ActiveTabButton = styled(("button"))({
    width: "120px",
    padding: "10px",
    borderRadius: "30px",
    backgroundColor: "#3a79c0",
    color: "#ffffff",
    border: "none",
    cursor: "pointer"
});

const UnActivateTabButton = styled(("button"))({
    width: "120px",
    padding: "10px",
    borderRadius: "30px",
    color: "#3a79c0",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer"
});

const AllPatientBox = styled(Box)({
    height: "auto",
    margin: "10px 0",
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0px 0px 5px 0px rgba(173, 216, 230, 0.75)",
    "-webkit - box - shadow": "0px 0px 5px 0px rgba(173, 216, 230, 0.75)",
    "-moz - box - shadow": "0px 0px 5px 0px rgba(173, 216, 230, 0.75)"
});

const ScheduleBox = styled(Box)({
    height: "auto",
    margin: "10px 0",
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0px 0px 5px 0px rgba(173, 216, 230, 0.75)",
    "-webkit - box - shadow": "0px 0px 5px 0px rgba(173, 216, 230, 0.75)",
    "-moz - box - shadow": "0px 0px 5px 0px rgba(173, 216, 230, 0.75)"
});

const PatientInfoBox = styled(Box)({
    display: "flex",
    width: "100%",
    height: "50px",
    padding: "10px 0",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center"
});

const PatientProfile = styled(Box)({
    height: "40px",
    width: "40px",
    borderRadius: "5px",
    overflow: "hidden"
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const ProfileImage = styled("img")({
    height: "100%",
    width: "100%"
});

const PatientNameText = styled(Box)({
    fontWeight: 500,
    color: "#000",
    fontSize: "15px",
    "@media (max-width:500px)": {
        fontSize: "12px"
    }
});

const PatientNumberText = styled(Typography)({
    color: "#3a79c0",
    fontWeight: 500,
    fontSize: "12px",
    "@media (max-width:500px)": {
        fontSize: "10px"
    }
});

const PatientBluredText = styled(Box)({
    color: "#808080",
    fontWeight: 500,
    fontSize: "12px",
    "@media (max-width:500px)": {
        fontSize: "10px"
    }
});

const Tags = styled(Box)({
    padding: "5px 10px",
    borderRadius: "25px",
    backgroundColor: "#fef4ca",
    fontSize: "12px",
    fontWeight: 500,
    "@media (max-width:500px)": {
        fontSize: "10px"
    }
});

const PatientProfileBox = styled(Box)({
    display: "flex",
    columnGap: "10px"
});

const CurrentCaseBoldText = styled(Box)({
    fontSize: "13px",
    fontWeight: 600
});

const LastVisitText = styled(Box)({
    fontSize: "12px",
    color: "#000",
    "@media (max-width:500px)": {
        fontSize: "10px"
    }
});

const PatientNameBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    rowGap: "5px"
});

const TagsBox = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "5px",
    padding: "10px 0"
});

const CurrentCaseText = styled(Box)({
    fontSize: "13px",
    fontWeight: 600,
    color: "#808080"
});

const ActionButtonBox = styled(Box)({
    display: "flex",
    padding: "10px",
    borderTop: "1px solid #D3D3D3",
    columnGap: "10px",
    "@media (max-width:500px)": {
        justifyContent: "center"
    }
});

const CurrentCaseInnerBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    margin: "10px 0px",
    rowGap: "5px"
});

const FilledButton = styled("button")({
    display: "inline-flex",
    columnGap: "5px",
    alignItems: "center",
    width: "150px",
    padding: "10px",
    borderRadius: "30px",
    backgroundColor: "#3a79c0",
    color: "#ffffff",
    border: "none",
    fontWeight: 500,
    boxSizing: "border-box",
    cursor: "pointer",
    alignItem: "center",
    justifyContent: "center",
    fontSize: "16px"
});

const FilledButtonDisable = styled("button")({
    display: "inline-flex",
    columnGap: "5px",
    alignItems: "center",
    width: "150px",
    padding: "10px",
    borderRadius: "30px",
    backgroundColor: "grey",
    color: "#ffffff",
    border: "none",
    fontWeight: 500,
    boxSizing: "border-box",
    cursor: "not-allowed",
    alignItem: "center",
    justifyContent: "center",
    fontSize: "16px"
});

const OutlineButton = styled("button")({
    display: "inline-flex",
    columnGap: "5px",
    alignItems: "center",
    width: "150px",
    padding: "10px",
    borderRadius: "30px",
    backgroundColor: "transparent",
    color: "#808080",
    boxSizing: "border-box",
    cursor: "pointer",
    border: "1px solid #808080",
    fontWeight: 500,
    alignItem: "center",
    justifyContent: "center",
    fontSize: "16px"
});

const ScanButton = styled("button")({
    display: "none"
});

const CustomModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const CurrentCaseBox = styled(Box)({
    width: "90%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
    "-webkit-box-shadow": "0px 0px 3px 0px rgba(0,0,0,0.75)",
    "-moz-box-shadow": "0px 0px 3px 0px rgba(0,0,0,0.75)",
    backgroundColor: "white",
    margin: "0 auto",
    marginBottom: "10px"
});

const CurrentCaseBoldHeading = styled(Box)({
    fontSize: "14px",
    fontWeight: 700
});

const ButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    margin: "20px 0"
});

const CloseButton = styled("button")({
    width: "120px",
    padding: "10px",
    borderRadius: "25px",
    color: "#ffffff",
    fontWeight: 500,
    backgroundColor: "#3a79c0",
    border: "2px solid #3a79c0",
    cursor: "pointer"
});

const ModalContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "80%",
    height: "100%",
    margin: "0 auto",
    overflowX: "scroll",
    backgroundColor: "white"
});

const CurrentCaseBoldTextMed = styled(Box)({
    fontSize: "13px",
    fontWeight: 600
});

const RecordsDiv = styled("div")({
    display: "flex",
    justifyContent: "center",
    width: "80%",
    height: "300px",
    margin: "0 auto",
    backgroundColor: "white",
    alignItems: "center"
});

const NoDataBox = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    boxShadow: "1px 1px 5px #aaaaaa",
    marginBottom: "20px"
});
// Customizable Area End
import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import {
  landingpageBanner,
  banner7,banner9,banner10
} from "./assets";
import { Carousel } from "react-responsive-carousel";
import PatientAppointmentLandingpageController from "../src/PatientAppointmentLandingpageController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web"
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import FooterDocotr from "../../../components/src/FooterDoctor.web";
import PatientCommanOffersComponent from "../../../components/src/PatientCommanOffersComponent.web";
import MerilLoader from "../../../components/src/MerilLoader.web";
import { searchIcon } from "../../appointmentmanagement/src/assets";
let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class PatientAppointmentLandingpage extends PatientAppointmentLandingpageController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/">Doctor Appointment</Link>
          <Link to="/">Surgical Procedures</Link>
          <Link to="/">Medicines</Link>
          <Link to="/">Lab Tests</Link>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderNavigationHomeTitle = () => {
    let isAppointmentActive =
      window.location.pathname === "/patient-appointment-landingpage";
    let appointmentClassName = isAppointmentActive ? "active" : "";

    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link
            className={appointmentClassName}
            to="/patient-appointment-landingpage"
          >
            Appointments
          </Link>
          <Link to="/services">Services</Link>
          <Link to="#">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderCarousel = () => {
    return (
      <>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={false}
          className="renderMainCarousel"
        >
          <div>
            <img className="carouselImage" src={banner7} />
          </div>
          <div>
            <img className="carouselImage" src={banner9} />
          </div>
          <div>
            <img className="carouselImage" src={banner10} />
          </div>
        </Carousel>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderGeneralHealth = () => {
    let generalHealthDataShow = this.state.generalHealthDataSliced
      ? this.state.generalHealthDocctors
      : this.state.generalHealthDocctors.slice(0, 6);
    return (
        <div>
          <div className="ourServicesHeaderPart">
            <h2 className="ourServicesTitle">General Health</h2>
            <p
              data-test-id="ourServicesId"
              className="ourServicesSubTitle"
              onClick={this.handleGeneralHealthData}
            >
              {!this.state.generalHealthDataSliced ? "See All" : "View less"}
            </p>
          </div>
          <Row className="cardRow" gutter={[20, 20]}>
            {generalHealthDataShow.map((item: any) => {
              return (
                <Col xl={4} lg={4} md={8} sm={12} xs={24}>
                  <div
                    className="generalHealthCard"
                    data-test-id="generalHealthCardID"
                    onClick={() => this.handleGeneralHealthonClickCard(item.attributes.id, item.attributes.name, item)}
                  >
                    <div className="generalHealthCardImgBox">
                      <img
                        src={config + item.attributes.image}
                        className="generalHealthCarddImg"
                      />
                    </div>
                    <p className="servicesCardText">{item.attributes.name}</p>
                    <p
                      className="servicesSubText"
                      dangerouslySetInnerHTML={{
                        __html: item.attributes.description
                      }}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderSearchSection = () => {
    return (
    
        <div className="ourServicesContainer">
          <div className="ourServicesHeaderPart topmargin">
            <Typography className="appintlandingpageSearchTitle">
              Enter the symptoms you are experiencing to find the best doctor
              for you.
            </Typography>
          </div>
          <Row className="cardRow">
            <Col xl={24} lg={24} md={8} sm={24} xs={24}>
              <div className="searchBoxDoc_doctor">
              <span>
                <img className="searchIcon" src={searchIcon} alt="" />
             </span>
                <input
                  className="searchInput_doctor"
                  placeholder="Search Doctors / Disease / Treatments"
                />
              </div>
            </Col>
          </Row>
        </div>
    );
  };
  // Customizable Area End
  renderAdvanceHealth = () => {
    let generalHealthDataShow = this.state.advancedHealthDataSliced
      ? this.state.advancedHealthDocctors
      : this.state.advancedHealthDocctors.slice(0, 6);
    return (
        <div className="ourServicesContainer">
          <div className="ourServicesHeaderPart">
            <h2 className="ourServicesTitle">Advanced Health</h2>
            <p
              data-test-id="ourServicesId"
              className="ourServicesSubTitle"
              onClick={this.handleAdvancedHealthData}
            >
              {!this.state.advancedHealthDataSliced ? "See All" : "View less"}
            </p>
          </div>
          <Row className="cardRow" gutter={[20, 20]}>
            {generalHealthDataShow.map((item: any) => {
              return (
                <Col xl={4} lg={4} md={8} sm={12} xs={24}>
                  <div className="servicesCard">
                    <div className="servicesCardImgBox">
                      <img
                        src={config + item.attributes.image}
                        className="servicesCarddImg"
                      />
                    </div>
                    <p className="servicesCardText">{item.attributes.name}</p>
                    <p
                      className="servicesSubText"
                      dangerouslySetInnerHTML={{
                        __html: item.attributes.description
                      }}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
    );
  };

  // Customizable Area Start
  renderCarouselSecondPart = () => {
    return (
      <>
        <Carousel
          interval={3000}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={false}
          className="secondCarousel"
        >
          <div>
            <img
              className="carouselImage secondCarouselImg"
              src={landingpageBanner}
            />
          </div>
          <div>
            <img
              className="carouselImage secondCarouselImg"
              src={landingpageBanner}
            />
          </div>
          <div>
            <img
              className="carouselImage secondCarouselImg"
              src={landingpageBanner}
            />
          </div>
          <div>
            <img
              className="carouselImage secondCarouselImg"
              src={landingpageBanner}
            />
          </div>
          <div>
            <img
              className="carouselImage secondCarouselImg"
              src={landingpageBanner}
            />
          </div>
        </Carousel>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    let location = window.location.pathname;

    return (
      <div>
     
        <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
        {location === "/"
          ? this.renderNavigationTitle()
          : this.renderNavigationHomeTitle()}
        <div className="itemContainerWiidth">
          <PatientCommanOffersComponent
            data-test-id="topDoctorsId"
            label1="30% off on your first Consultation "
            label2=""
            img=""
          />
          {this.renderSearchSection()}
          {this.renderGeneralHealth()}
          {this.renderAdvanceHealth()}
          {this.renderCarouselSecondPart()}
          <br />
          <br />
        </div>
        <FooterDocotr />
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default MerilLoader(PatientAppointmentLandingpage);
export { PatientAppointmentLandingpage };
// Customizable Area End

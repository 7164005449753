import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import { createRef } from "react";
// Customizable Area End
export const configJSON = require("./config.js");
// Customizable Area Start
export interface OurServicesType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    service_image: string;
  };
}
// Customizable Area End

// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start
export interface SymptomNameType {
  id: number;
  name: string;
}
// Customizable Area End
// Customizable Area Start
export interface TrendingType {
  id: string;
  type: string;
  attributes: {
    id: number;
    featured_therapy_id: number;
    therapy_name: string;
    therapy_images: Array<HospitalsImagesType>;
  };
}
// Customizable Area End
// Customizable Area Start
export interface MinimalType {
  id: string;
  therapy_name: string;
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  topTherapiesData: Array<OurServicesType>;
  trendingTherapiesData: Array<TrendingType>;
  minimalTherapieData: Array<MinimalType>;
  minimalnonTherapieData: Array<MinimalType>;
  topTherapiesDataSliced: boolean;
  trendingTherapiesDataSliced: boolean;
  minimalDataSliced: boolean;
  nonMinimalDataSliced: boolean;
  navigationName: any;
  ids: any;
  showPopup:boolean;
  isLoginUser: boolean

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class LandingpageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTopTherapiesApiCallId: string | null = null;
  getTrendingTherapiesApiCallId: string | null = null;
  getMinimallyListApiCallId: string | null = null;
  getNonMinimallyListApiCallId: string | null = null;
  myRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.myRef = createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      topTherapiesData: [],
      trendingTherapiesData: [],
      minimalTherapieData: [],
      minimalnonTherapieData: [],
      topTherapiesDataSliced: true,
      trendingTherapiesDataSliced: true,
      minimalDataSliced: true,
      nonMinimalDataSliced: true,
      navigationName: "",
      ids: "",
      showPopup: false,
      isLoginUser: true

      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.scrollToTop();
    this.getNavigationCallBack(
      this.props.location.state.navigateList,
      this.props.location.state.ids.therapyListID
    );
    this.getTopTherapies(this.props.location.state.ids.therapyListID);
    this.getTrendingTherapiesShow();
    this.getMinimallyTherapies();
    this.getNonMinimallyTherapies();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: any) => {
    return (
      (responseJson && responseJson.data) || responseJson.invasive_therapies
    );
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getTopTherapiesApiCallId) {
      this.getOurServicesSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getMinimallyListApiCallId) {
      this.getMinimallyTherapiesSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getTrendingTherapiesApiCallId) {
      this.getTrendingTherapiesSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getNonMinimallyListApiCallId) {
      this.getNonMinimallyTherapiesSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getTrendingTherapiesApiCallId) {
      this.getTrendingTherapiesFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  handleServicesData = () => {
    this.setState({
      topTherapiesDataSliced: !this.state.topTherapiesDataSliced,
    });
  };
  handleTherapiesData = () => {
    this.setState({
      trendingTherapiesDataSliced: !this.state.trendingTherapiesDataSliced,
    });
  };
  getTopTherapies = async (id: string) => {
    this.getTopTherapiesApiCallId = ApiCall({
      contentType: configJSON.getTrendingTherapiesApiContentType,
      method: configJSON.getTrendingTherapiesAPiMethod,
      endPoint: `${configJSON.getTrendingTherapiesAPiEndPoint}?featured_therapy_id=${id}`,
    });
  };

  getOurServicesSuccessCallBack = (res: any) => {
    this.setState({
      topTherapiesData: res.data,
    });
  };
  getTrendingTherapiesShow = async () => {
    this.getTrendingTherapiesApiCallId = ApiCall({
      contentType: configJSON.getTrendingTherapiesApiContentType,
      method: configJSON.getTrendingTherapiesAPiMethod,
      endPoint: `${configJSON.getTrendingTherapiesAPiEndPoint}?featured_therapy_id=2`,
    });
  };
  getTrendingTherapiesSuccessCallBack = (res: any) => {
    this.setState({
      trendingTherapiesData: res.data,
    });
  };
  getTrendingTherapiesFailureCallBack = (err: any) => {};
  getNavigationCallBack = (data: any, ids: any) => {
    this.setState({ navigationName: data, ids: ids });
  };
  getMinimallyTherapies = async () => {
    this.getMinimallyListApiCallId = ApiCall({
      contentType: configJSON.getMinimallyListApiContentType,
      method: configJSON.getMinimallyListAPiMethod,
      endPoint: `${configJSON.getMinimallyListAPiEndPoint}?featured_therapy_id=2`,
    });
  };
  getMinimallyTherapiesSuccessCallBack = (res: any) => {
    this.setState({
      minimalTherapieData: res?.invasive_therapies,
    });
  };
  handleMinimalData = () => {
    this.setState({
      minimalDataSliced: !this.state.minimalDataSliced,
    });
  };

  getNonMinimallyTherapies = async () => {
    this.getNonMinimallyListApiCallId = ApiCall({
      contentType: configJSON.getNonMinimallyListApiContentType,
      method: configJSON.getNonMinimallyListAPiMethod,
      endPoint: `${configJSON.getNonMinimallyListAPiEndPoint}?featured_therapy_id=2`,
    });
  };
  getNonMinimallyTherapiesSuccessCallBack = (res: any) => {
    this.setState({
      minimalnonTherapieData: res?.invasive_therapies,
    });
  };
  handleNonMinimalData = () => {
    this.setState({
      nonMinimalDataSliced: !this.state.nonMinimalDataSliced,
    });
  };
  // this.props.location.state.therapyID
  navigateToDetailPage = (id: any, name: string) => {
    const { navigationName, ids } = this.state;

    this.props.history.push({
      pathname: "/TherapiesDetailspage",
      state: {
        ids: { Id: id, therapyListID: ids },
        navigateList: [
          { name: navigationName, route: "/Therapies" },
          { name: name, route: "/TherapiesDetailspage" },
        ],
      },
    });
  };
  scrollToTop = () => {
    this.myRef.current.scrollTo(0, 0);
    window.scrollTo(0, 0);
  };
  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
} 

handleLogout = () => {
    localStorage.clear();
    notification["success"]({
        message: "Logout successfully",
      });
}
}
// Customizable Area End

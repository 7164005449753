import React from 'react'
// Customizable Area Start
import { Col, Row, } from "antd";
import CarouselCommonComponet from '../../../components/src/CarouselCommonComponet.web';
import { apple, google } from "../src/assets";
import OtpInput from "react-otp-input";
import SignUpOtpController from "./SignUpOtpController.web";
import { Link } from 'react-router-dom';
import OTPCounter from '../../../components/src/OtpCounter.web';
import {
    Box,
    Modal,
    styled
} from '@material-ui/core';
import { MdArrowBack } from 'react-icons/md';
import Loader from "../../../components/src/Loader.web";
const ContainerBox = styled(Box)({
    width: "89%",
    margin: "135px auto",
    backgroundColor: "white",
    padding: "20px",
    borderradius: "10px",
    "& .submitBotton":{
        color: "#fff",
        padding: "15px",
        width: "250px",
        borderRadius: "50px",
        backgroundColor: "#3a79c1",
        fontSize: "15px",
        fontWeight: 500,
        border: "none",
        display: "block",
        margin: "20px auto"
        },
        "& .disableButton":{
            color: "white",
            padding: "15px",
            width: "250px",
            borderRadius: "50px",
            backgroundColor: "grey",
            fontSize: "15px",
            fontWeight: 500,
            border: "none",
            display: "block",
            margin: "20px auto"
        }
});

const BackArrow = styled(MdArrowBack)({
    fontSize: "24px"
});

const TermsTextBox = styled(Box)({
    paddingTop: "1px",
});

const TermsText = styled("span")({
  marginLeft: "10px",
  marginBottom: "10px",
  fontWeight: 600
});

const CheckboxContainer = styled("div")({
    display : "flex",
    alignItems: "center"
});

const TextContent = styled("p")({
  "& span":{
  backgroundColor: "transparent !important"
  }
});

const CustomModal = styled(Modal)({
    border:"1px solid red"
});

const Privacy = styled("span")({
  marginLeft: "10px",
  marginBottom: "10px",
  fontWeight: 600
});
// Customizable Area End
export default class SignUpOtp extends SignUpOtpController {
    // Customizable Area Start
    renderOtpFormInput = () => {
        return (
            <>
                <h1 className="signup-otp-heading">Sign Up</h1>
                <p className="verify-para">
                    We've sent you the verification code on your {this.state.doctorName ? "Email Id or Mobile No." : " Mobile No."}
                </p>
                <OtpInput
                    data-test-id="otpInputId"
                    onChange={this.state.doctorPin ? this.handleDoctorOTPChange : this.handleOTPChange}
                    value={this.state.doctorPin ? this.state.doctorPin : this.state.otpInput}
                    numInputs={6}
                    containerStyle={"otp-style"}
                    inputStyle={"input-style"}
                    renderInput={(props) => <input {...props} />}
                />
                {this.state.invalidOtp ? (
                    <p className="error-password">Please enter a valid OTP</p>
                ) : (
                    ""
                )}
                {this.state.sessionOtp ? (
                    <p className="error-password">Otp has been expired</p>
                ) : (
                    ""
                )}
                <p className="otp-notify-para">
                    Didn't Receive the Code? &nbsp;
                    {this.state.IsotpExpired ? (
                        <label
                           data-test-id="callresendOtpId"
                            className="otp-again" onClick={this.state.doctorPin ? this.signUpDoctorResendFunction : this.signUpResendFunction}>
                            Resend OTP
                        </label>
                    ) : (
                        <OTPCounter
                           data-test-id={"resendOtpId"}
                            OtpFinishTime={this.OtpFinishTime}
                            Otptime={this.state.Otptime}
                            className="otp-timer"
                        />
                    )}
                </p>
                <div></div>
                <form className="check-style">
                    <input data-test-id="checkboxId" type="checkbox" id="checkbox" onChange={this.handleCheckbox} className="checkboxClass" checked={this.state.checkboxCheck && (this.state.privacyCheckox && this.state.termsCheckbox)}/>
                    <label className="checkbox-text">
                        By signing up you are Agreeing Our <span onClick={this.handleTerms} data-test-id="termsModalId">Terms and Conditions</span> & <span onClick={this.handlePrivacy} data-test-id="privacyId">Privacy Policy</span>
                    </label>
                </form>
                <button data-test-id="verifyOTPId" className={`${!this.state.checkboxCheck && "sign-otp-up-button-disbale-btn "} sign-otp-up-button`}
                    onClick={() => this.state.doctorPin ? this.handleDoctorVerifyOtp() : this.handleVerifyOtp()} disabled={!this.state.checkboxCheck} >Sign Up</button>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start 
    renderOtpFormText = () => {
        return (
            <>
                <div className="signup-footer-container">
                    <div className="horizontal-ruler-container">
                        <div className="horizonatal-ruler signHide"></div>
                        <p className='signInWith signHide'>Or Sign Up with</p>
                        <div className="horizonatal-ruler signHide"></div>
                    </div>
                    {this.renderSocial()}
                </div>
                <div className="social-login-text">
                    <p className='already_accoiunt'>Already have an account? </p>
                    <Link to="/login" className="login-link">
                        Login
                    </Link>
                </div>
            </>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderSocial = () => {
        return (
            <div className="social-login-container">
                <div className="social-login-item">
                    <img src={google} className='socail_img' />
                </div>
                <div className="social-login-item socail_img">
                    <img src={apple} className='socail_img' />
                </div>
            </div>
        )
    }

    renderPrivacyHeader = () => {
        return (
            <>
                <Box>
                    <BackArrow onClick={this.handleBack} style={{cursor: "pointer"}}/>
                    <Privacy>
                        Privacy and Policy
                    </Privacy>
                </Box>
            </>
        )
    }

    renderPrivacyTerms = () => {
        return (
            <Box style={{maxHeight: "350px",overflowX: "scroll"}}>
                {
                    this.state.privacyPolicyData.settings.map((item) => {
                        return (
                            <>
                                <p style={{fontWeight: 600 , paddingTop: "10px"}}>
                                    {item.name}
                                </p>
                                <p dangerouslySetInnerHTML={{
                                    __html: item.description
                                }}></p>
                            </>
                        ) 
                    })
                   }
                   <CheckboxContainer>
                   <input data-test-id="privacyCheckbox" type="checkbox" id="box" className="checkboxClass" onChange={this.handlePrivacyCheckbox} checked={this.state.privacyCheckox}/>&nbsp;
                   <span>I have read & agree to all the Privacy Policy.</span>
                   </CheckboxContainer>
                   <button data-test-id="continuepolicyID" className={this.state.privacyCheckox?"submitBotton":"disableButton"} disabled={!this.state.privacyCheckox} onClick={this.handlePrivacySubmit}>Confirm & Continue</button>
            </Box>
        )
    }
    // Customizable Area End

    // Customizable Area Start 
        renderModal = () => {
            return (
                <CustomModal
                data-test-id="modalId"
                open={this.state.isTermModal}
                onClose={this.closeModal}
                >
                <ContainerBox>
                    <div>
                   {this.renderHeader()}
                   {this.renderTextTerms()}
                   </div>
                </ContainerBox>
                </CustomModal>
            )
        }

        renderPrivacyModal = () => {
            return (
                <CustomModal
                open={this.state.isPrivacyModal}
                onClose={this.closeModal}
                >
                <ContainerBox>
                    <div>
                   {this.renderPrivacyHeader()}
                   {this.renderPrivacyTerms()}
                   </div>
                </ContainerBox>
                </CustomModal>
            )
        }
        // Customizable Area End

    // Customizable Area Start
    renderOtpForm = () => {
        return (
            <div className="otp-form-contianer">
                {this.renderOtpFormInput()}
                {this.renderOtpFormText()}
            </div>
        );
    };

    renderHeader = () => {
        return (
            <>
                <TermsTextBox>
                    <BackArrow data-test-id="previousViewButton" style={{cursor: "pointer"}} onClick={this.handleBack}/>
                    <TermsText>
                        Terms & Conditions
                    </TermsText>
                </TermsTextBox>
            </>
        )
    };

    renderTextTerms = () => {
        return (
            <Box style={{maxHeight: "350px",overflowX: "scroll"}}>
                {
                    this.state.termsConditionData.settings.map((item) => {
                        return (
                            <>
                                <p style={{fontWeight: 600 , paddingTop: "10px"}}>
                                    {item.name}
                                </p>
                                <TextContent dangerouslySetInnerHTML={{
                                    __html: item.description
                                }} style={{backgroundColor: "transparent !important"}}></TextContent>
                            </>
                        ) 
                    })
                   }
                   <CheckboxContainer>
                   <input data-test-id="termsCheckboxId" type="checkbox" id="checkboxterms" className="checkboxClass" onChange={this.handleTermsCheckbox} checked={this.state.termsCheckbox}/>&nbsp;
                   <span>I have read & agree to all the Terms & Conditions.</span>
                   </CheckboxContainer>
                   <button data-test-id="continueID" className={this.state.termsCheckbox? "submitBotton" : "disableButton"} disabled={!this.state.termsCheckbox} onClick={this.handleConfirm}>Confirm & Continue</button>
            </Box>
        )
    };
    // Customizable Area End
    // Customizable Area Start
    render() {
        return (
            <div className='mainContainerheight'>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <CarouselCommonComponet />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        {this.renderOtpForm()}
                        {this.renderOtpFormText}
                    </Col>
                </Row>
                {this.renderModal()}
                {this.renderPrivacyModal()}
                <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
            </div>
        );
    }
    // Customizable Area End
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
import { notification } from "antd";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
    withLoaderProps & {
        // Customizable Area Start
        id: string;
        history: any;
        location: any;
        window: any
        // Customizable Area End
    }


// Customizable Area Start

export interface CheckoutData {
    id: string,
    type: string,
    attributes: {
        name: string
        identifier: string,
        description: string,
        category_type: string
        image: string
    }
}

// Customizable Area End





interface S {
    // Customizable Area Start
    PatientCheckoutData: Array<CheckoutData>;
    doctor_full_name: string;
    doctor_specialisation: string;
    doctor_experience: string;
    doctor_rating: string;
    patient_full_name: string;
    consultation_type: string;
    consultation_fees: string;
    consultation_date: string;
    time_slot: string;

    image: string;
    bookingAppointmentDetails: any,
    razorpay_payment_id: any,
    showPopup:boolean,
    isLogin:boolean,
    rating_average:number,
    reviews_count:number
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class PatientAppoitnmentCheckoutController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getappointmentChekoutApiCallId: string | null = null;
    GetRazorpayIdApiCallId:string | null =null;
    myRef: any
    // Customizable Area Enddata:

    constructor(props: Props) {
        super(props);
        this.myRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            // Customizable Area Start
            doctor_full_name: "",
            doctor_specialisation: "",
            doctor_experience: "",
            doctor_rating: "",
            patient_full_name: "",
            consultation_type: "",
            consultation_date: "",
            time_slot: "",
            consultation_fees: "",
            PatientCheckoutData: [],
            image: '',
            bookingAppointmentDetails: null,
            razorpay_payment_id: "",
            showPopup:false,
            isLogin:true,
            rating_average:0,
            reviews_count:0
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        this.setState({ doctor_full_name: this.props.location.state?.doctorData?.full_name });
        this.setState({ consultation_type: this.props.location.state?.doctorData?.consultation_type })
        this.setState({ consultation_date: this.props.location.state?.doctorData?.selected_date })
        this.setState({ consultation_fees: this.props.location.state?.doctorData?.consultation_fees })
        this.setState({ image: this.props.location.state?.doctorData?.image })
        this.setState({ time_slot: this.props.location.state?.doctorData?.time_slot })
        this.setState({ doctor_experience: this.props.location.state?.doctorData?.doctorExp })
        this.setState({ doctor_rating: this.props.location.state?.doctorData?.doctorRatingList });
        this.setState({ doctor_specialisation: this.props.location.state?.doctorData?.specialization })
        this.setState({ bookingAppointmentDetails: this.props.location.state?.doctorData?.bookingAppointmentDetails })
        this.setState({ rating_average: this.props.location.state?.doctorData?.rating_average })
        this.setState({ reviews_count: this.props.location.state?.doctorData?.reviews_count })
        this.scrollToTop();
        this.setState({ patient_full_name: JSON.parse(localStorage.getItem("patientFullName") || "") })


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // this.props.hideLoader();
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
           if(responseJson){
            if(apiRequestCallId === this.GetRazorpayIdApiCallId){
                this.props.history.push({pathname:"/patient-appointment-dashboard"})
            }
           }


        }
        // Customizable Area End
    }



    // Customizable Area Start

    // Customizable Area End

    // Customizable Area Start

    openRazorpay = async () => {

        const result = await this.displayRazorpay();
        if (!result) {
            alert("Something went wrong");
            return;
        }

        const { fees, razorpay_order_id ,patient_full_name,patient_phone_number,} = this.state.bookingAppointmentDetails.attributes;
        
        const options = {
            key: "rzp_test_cXwB7RdmcjJWlH",
            amount: fees.toString(),
            currency: 'INR',
            name: "Meril health",
            description: "Test Trans",
            order_id: razorpay_order_id,
            handler: (response: any) => {
                // this.setState({razorpay_payment_id:response.razorpay_payment_id})
                this.GetRazorpayId(response.razorpay_payment_id)
            },
            prefill: {
                name: patient_full_name,
                contact: patient_phone_number,
            },
        };
        
        const _window = window as any
        const rzp1 = new _window.Razorpay(options)

        rzp1.open();
        rzp1.on(("payment.failed"), (response: any) => {
            notification["error"]({
                message:response.error.description})
        });

    }


    displayRazorpay = async () => {

        return new Promise((resolve: any) => {
            const script = document.createElement("script");
            script.src = configJSON.razorpayCheckoutURL;
            document.body.appendChild(script);
            resolve(true)
        });

    }

    async GetRazorpayId(RazorpaymentId:any) {
        let userToken = localStorage.getItem("token")
        const header = {
           "Content-Type": "application/json",
           token: userToken,
        };
        const requestMessageDoc = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.GetRazorpayIdApiCallId = requestMessageDoc.messageId;
        let paymentStatusAPI = `bx_block_appointment_transaction/payment?paymentId=${RazorpaymentId}`;
     
        requestMessageDoc.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          paymentStatusAPI
        );
    
        requestMessageDoc.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessageDoc.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        runEngine.sendMessage(requestMessageDoc.id, requestMessageDoc);
    
        return true;
      }


    scrollToTop = () => {
        this.myRef.current?.scroll(0, 0);
        window.scroll(0, 0);

    }



    showPatientDetailHandler = () => {
        this.setState({ showPopup: !this.state.showPopup });
      } 
      
      
      patientLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout succesfully",
          });
      }

    // Customizable Area End
}
// Customizable Area End

import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Modal
} from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import ScheduledDetailsController, { Props } from "./ScheduledDetailsController.web";
let config = require("../../../framework/src/config.js").baseURL;
import { Default } from "../../../blocks/splashscreen/src/assets";
import { IoMdArrowBack, IoIosStopwatch } from "react-icons/io";
import { MdOutlineCalendarToday } from "react-icons/md";
import { VscClose } from "react-icons/vsc";
import { IoCallOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class ScheduleDetailsScreen extends ScheduledDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderNavigation = () => {
        return (
            <EditPageHeader>
                <Link to={"/doctorhome"}><PageHeader>Home</PageHeader></Link>
                <Link to={"/doctorschedule"}><PageHeader>Schedule</PageHeader></Link>
                <Link to={"/addpatient"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderHeader = () => {
        return (
            <>
                <ScheduleHeader>
                    <BackIcon onClick={this.handleGoBack} data-test-id="backButtonId"/>
                    <HeaderTitle data-test-id="scheduleId">Schedule Details</HeaderTitle>
                </ScheduleHeader>
            </>
        );
    };

    renderScheduleDetails = () => {
        const { last_visit, appointment_date,time_slot} = this.state.scheduledDetails?.data?.attributes;
        let date = appointment_date;
        let index = date.indexOf(",");
        let day = date.slice(0, index).trim();
        let dateAppoint = date.slice(index + 1);
        return (
            <>
                <ScheduleHeaderBox>
                    <SubTitle>Time Schedule</SubTitle>
                    <VerifiedTag onClick={this.onClickModalOpen} data-test-id="transactionId"><FaCheckCircle />&nbsp;PAYMENT VERIFIED</VerifiedTag>
                </ScheduleHeaderBox>
                <ScheduleInformation>
                    <DetailsBox>
                        <MdOutlineCalendarToday />
                        <BlackText>{dateAppoint} | {day}</BlackText>
                    </DetailsBox>
                    <DetailsBox>
                        <IoIosStopwatch />
                        <BlackText>{time_slot} </BlackText>
                    </DetailsBox>
                </ScheduleInformation>
                <LastVisitText>Last Visit</LastVisitText>
                <BlackText>{last_visit}</BlackText>
                <ButtonBox>
                    <CancelButton>
                        <VscClose />
                        Cancel
                    </CancelButton>
                    <RescheduleButton onClick={this.handleReschedule}>
                        <MdOutlineCalendarToday />
                        Reschedule
                    </RescheduleButton>
                </ButtonBox>
            </>
        );
    };

    renderProfileSection = () => {
        const {
            patient_full_name,
            patient_gender,
            patient_year,
            patient_profile_image,
            health_id,
            patient_phone_number,
            mode_type
        } = this.state.scheduledDetails?.data?.attributes;
        return (
            <>
                <ProfileBox>
                    {patient_profile_image ? 
                    <PaitentProfileImg src={config + patient_profile_image}/> 
                    :
                    <PaitentProfileImg src={Default}/>
                    }
                    <PaitentNameText>{patient_full_name}</PaitentNameText>
                    <GraySubHeadingText>{patient_year} {patient_gender} | ID {health_id}</GraySubHeadingText>
                    <BlackText>{mode_type} Appt. | Follow Up </BlackText>
                    <BlackText><IoCallOutline style={{ color: "rgb(33, 121, 200)", fontSize: "18px" }} />&nbsp;{patient_phone_number}</BlackText>
                </ProfileBox>
            </>
        );
    };

    renderModal = () => {
        return (
            <>
                <CustomModal
                    open={this.state.transactionModal}
                    onClose={this.onCloseModal}
                >
                    <ModalContent>
                        <ModalDetailsBox>
                            <ModalHeading data-test-id="transactionTextId">Transaction Details</ModalHeading>
                            <ModalProfileBox>
                                {this.renderFromDetails()}
                                <AmountText>₹{this.state.transactionDetails.amount}</AmountText>
                                <GrayColorTaxt>Payment completed on</GrayColorTaxt>
                                <DateOfPaymentText>{this.state.transactionDetails.payment_completed_on}</DateOfPaymentText>
                            </ModalProfileBox>
                            <OuterInformationBox>
                                <SubHeading>UPI Transaction ID</SubHeading>
                                <InfoBox>
                                    <HeadingText>00000000000</HeadingText>
                                </InfoBox>
                            </OuterInformationBox>
                            <OuterInformationBox>
                                <SubHeading>To</SubHeading>
                                <InfoBox>
                                    {this.renderTransactionTo()}
                                </InfoBox>
                            </OuterInformationBox>
                            <OuterInformationBox>
                                <SubHeading>From</SubHeading>
                                <InfoBox>
                                    <HeadingText>{this.state.transactionDetails.from.data[0]?.attributes?.full_name}</HeadingText>
                                    <HeadingText>{this.state.transactionDetails.from.data[0]?.attributes?.full_phone_number}</HeadingText>
                                </InfoBox>
                            </OuterInformationBox>
                            <ButtonBox>
                                <ShareButton>Share</ShareButton>
                                <CloseButton onClick={this.onCloseModal} data-test-id="closemodalId">Close</CloseButton>
                            </ButtonBox>
                        </ModalDetailsBox>
                    </ModalContent>
                </CustomModal>
            </>
        )
    }

    renderFromDetails = () => {
        return (
            <>
                {
                    this.state.transactionDetails.from.data.map((item) => {
                        return (
                            <>
                                <ProfileImage src={config + item.attributes.profile_photo} />
                                <GrayColorTaxt>From {item.attributes.full_name}</GrayColorTaxt>
                                <GrayColorTaxt>+{item.attributes.full_phone_number}</GrayColorTaxt>
                            </>
                        )
                    })
                }
            </>
        )
    }

    renderTransactionTo = () => {
        return (
            <>
                {
                    this.state.transactionDetails.to.data.map((item) => {
                        return (
                            <>
                                <HeadingText>Dr. {item.attributes.full_name}</HeadingText>
                                <HeadingText>{item.attributes.email}</HeadingText>
                            </>
                        )
                    })
                }
            </>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
                <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handlePopupClose} data-test-id="doctorProfileId" />
                <ContainerBox>
                    {this.renderNavigation()}
                    {this.renderHeader()}
                    {this.renderProfileSection()}
                    {this.renderScheduleDetails()}
                    {this.renderModal()}
                </ContainerBox>
                <FooterDoctor />
                <Loader height={this.state.contentHeight} loading={this.state.isLoader} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const ScheduleHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    columnGap: "5px",
    margin: "10px 0"
});

const BackIcon = styled(IoMdArrowBack)({
    fontSize: "20px",
    color: "#000000"
});

const HeaderTitle = styled(Typography)({
    fontSize: "20px",
    color: "#000000",
    fontWeight: 500
});

const ProfileBox = styled(Box)({
    padding: "30px",
    boxSizing: "border-box",
    backgroundColor: "#f1f7ff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "5px"
});

const PaitentNameText = styled(Box)({
    color: "#498ECC",
    fontSize: "16px",
    fontWeight: 100,
    lineHeight: "normal"
});

const PaitentProfileImg = styled("img")({
    height: "60px",
    width: "60px",
    borderRadius: "100%"
});

const ScheduleHeaderBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0"
});

const GraySubHeadingText = styled(Box)({
    fontSize: "12px",
    color: "gray"
});

const BlackText = styled(Box)({
    fontSize: "12px",
    color: "#000000",
    fontWeight: 500
});

const SubTitle = styled(Box)({
    fontSize: "16px",
    color: "#000000",
    fontWeight: 500
});

const VerifiedTag = styled(Box)({
    padding: "5px 10px",
    backgroundColor: "#E7FFF4",
    color: "green",
    fontWeight: 500,
    fontSize: "12px",
    borderRadius: "2px",
    cursor: "popinter"
});

const ScheduleInformation = styled(Box)({
    borderBottom: "1px solid gray",
    padding: "10px 0"
});

const DetailsBox = styled(Box)({
    padding: "5px 0",
    columnGap: "5px",
    display: "flex",
    alignItems: "center",
    color: "#3a79c0"
});

const LastVisitText = styled(Box)({
    margin: "10px 0",
    fontWeight: 400,
    color: "gray",
    fontSize: "12px"
});

const ButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    margin: "20px 0"
});

const CancelButton = styled("button")({
    width: "120px",
    padding: "10px",
    borderRadius: "25px",
    color: "gray",
    fontWeight: 500,
    backgroundColor: "transparent",
    border: "1px solid gray",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "5px",
    cursor: "pointer"
});

const RescheduleButton = styled("button")({
    width: "120px",
    padding: "10px",
    borderRadius: "25px",
    color: "#ffffff",
    fontWeight: 500,
    backgroundColor: "#3a79c0",
    border: "1px solid #3a79c0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "5px",
    cursor: "pointer",
});

const CustomModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})

const ModalContent = styled(Box)({
    height: "auto",
    width: "600px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    "@media (max-width:650px)": {
        width: "90%"
    }
})

const ModalDetailsBox = styled(Box)({
    width: "300px",
    backgroundColor: "#ffffff",
    padding: "20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",

})

const ModalHeading = styled(Box)({
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    textAlign: "center"
})

const ModalProfileBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    rowGap: "5px",
    flexDirection: "column"
})

const ProfileImage = styled("img")({
    height: "40px",
    width: "40px",
    borderRadius: "100%"
});

const GrayColorTaxt = styled(Box)({
    fontSize: "12px",
    fontWeight: 500,
    color: "#353c47"
});

const AmountText = styled(Box)({
    fontSize: "15px",
    fontWeight: 500,
    color: "#000000",
    margin: "10px 0"
});

const InfoBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    width: "330px"
});

const SubHeading = styled(Typography)({
    color: "#353c47",
    fontSize: "14px"
});

const HeadingText = styled(Typography)({
    color: "#000000",
    fontSize: "14px",
    fontWeight: 500
});

const ShareButton = styled("button")({
    width: "120px",
    padding: "10px",
    borderRadius: "25px",
    color: "gray",
    fontWeight: 500,
    backgroundColor: "transparent",
    border: "2px solid gray",
    cursor: "pointer"
});

const CloseButton = styled("button")({
    width: "120px",
    padding: "10px",
    borderRadius: "25px",
    color: "#ffffff",
    fontWeight: 500,
    backgroundColor: "#3a79c0",
    border: "2px solid #3a79c0",
    cursor: "pointer"
});

const OuterInformationBox = styled(Box)({
    margin: "10px"
});

const DateOfPaymentText = styled(Box)({
    color: "#000",
    fontSize: "14px",
    fontWeight: 400
});
// Customizable Area End

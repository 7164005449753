import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {ZegoUIKitPrebuilt} from "@zegocloud/zego-uikit-prebuilt"
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appID :number;
  serverSecret:string;
  roomID :string;
  isModalOpen:boolean,
  isViewPrescriptionMOdalOpen:boolean,
  name:string,
  consultation_mode:string,
  address:string,
  date:string,
  time:string,
  doc_name:string,
  appointments_id:number,
  patient_Id:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideosController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  myMeetings:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      appID : 513544998,
      serverSecret : "de3a31edf586060163e44091f9374696",
      roomID :"ROOM_12",
      isModalOpen:false,
      isViewPrescriptionMOdalOpen:false,
      name:"",
      consultation_mode:"",
      address:"",
      date:"",
      time:"",
      doc_name:"",
      appointments_id:0,
      patient_Id:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.myMeetings = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleVideoCallData();
  }

  navigateToViewPrescription=()=>{
    this.setState({isViewPrescriptionMOdalOpen:true,isModalOpen:false})
  }
  handleNavigation=()=>{
    this.props.history.push({pathname:"/PatientAppointmentsPage"});
    this.setState({isModalOpen:false});
  }

  handleViewPrescription=()=>{
    this.props.history.push({pathname:"/PatientAppointmentsPage"});
    this.setState({isViewPrescriptionMOdalOpen:false});
  }

  onLeaveRoom=()=>{
    const doctorName = localStorage.getItem("doctorName");
    if(doctorName){
      this.props.history.push({pathname:"/viewprescription",state:{
        appointments_id:this.state.appointments_id,
        patient_Id:this.state.patient_Id
        }});
    }else{
      this.setState({isModalOpen:true})
    }
  }

  onUserLeave=()=>{
    this.setState({isModalOpen:true});
  }

  navigateToPatientHome=()=>{
    this.props.history.push({pathname:"/patienthome"});
    this.setState({isViewPrescriptionMOdalOpen:false});
    this.setState({isModalOpen:false});
  }
  navigatePrescriptionScreen=()=>{
    this.props.history.push({pathname:"/prescription"});
    this.setState({isViewPrescriptionMOdalOpen:false});
    this.setState({isModalOpen:false});
  }

  navigateViewPrescriptionScreen=()=>{
    this.props.history.push({pathname:"/viewprescription"});
    this.setState({isViewPrescriptionMOdalOpen:false});
    this.setState({isModalOpen:false});
  }

  handleVideoCallData = async () => {
    const doctorname = localStorage.getItem("doctorName");
    if (doctorname) {
      this.setState({
        name: this.props.location.state.item.data.attributes.doctor_full_name,
        appointments_id: parseInt( this.props.location.state.item.data.id),
        patient_Id: parseInt(this.props.location.state.item.data.attributes.patient_id),
        roomID: this.props.location.state.item.data.id,
      })
    } else {
      this.setState({
        name: this.props.location.state.item.attributes.patient_full_name,
        consultation_mode: this.props.location.state.item.attributes.consultation_type,
        date: this.props.location.state.item.attributes.appointment_date,
        doc_name: this.props.location.state.item.attributes.doctor_full_name,
        roomID: this.props.location.state.item.data?.id,
      })

    }
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(this.state.appID, this.state.serverSecret, this.state.roomID, Date.now().toString(), this.props.location.state.item.data?.attributes?.doctor_full_name || this.props.location.state.item.attributes?.patient_full_name);
      const zc = ZegoUIKitPrebuilt.create(kitToken);
      zc.joinRoom({
        container: this.myMeetings,
        scenario: {
          mode: ZegoUIKitPrebuilt.OneONoneCall
        },
        showPreJoinView: true,
        showScreenSharingButton: true,
        turnOnCameraWhenJoining: true,
        showTextChat: true,
        onLeaveRoom: () => {
          this.onLeaveRoom()
        },
        onUserLeave: () => {
        }
      })
  }
  // Customizable Area End
}

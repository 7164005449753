import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { notification } from "antd";
import {TherapDetailsType} from "../../shoppingcart/src/TherapiesDetailsController.web"
import React from "react";
// Customizable Area End
export const configJSON = require("./config.js");
// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start
export interface SymptomNameType {
  id: number;
  name: string;
}
// Customizable Area End

// Customizable Area Start
export interface MinimalType {
  id: string;
  therapy_name: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  therapiesDetails: TherapDetailsType;
  navigationList: any;
  showPopup: boolean;
  isLoginUser:boolean;
  trendingTherapiesDetail:any
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class TherapiesDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  myRef:any
  getTherapyDetailApiCallId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    this.subScribedMessages = [
        getName(MessageEnum.ReciveUserCredentials),
        getName(MessageEnum.CountryCodeMessage),
       getName(MessageEnum.RestAPIResponceMessage)
     
    ];

    this.state = {
      // Customizable Area Start
      showPopup: false,
      isLoginUser: true,
      therapiesDetails: {
        id: "",
        type: "",
        attributes: {
          featured_therapy_id: 0,
          description: "",
          is_trading: false,
          id: 0,
          therapy_type: "",
          therapy_name: "",
          is_top_cardiovascular_procedures: false,
          is_top_orthopaedics_procedures: false,
          symptoms_name: [],
          diagnosis_name: [],
          treatment_id: "",
          therapy_images: [],
        },
      },
      navigationList: [],
      trendingTherapiesDetail:""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.scrollToTop()
    this.setState({therapiesDetails: this.props.location.state.therapiesDetails})
    this.getTherapiesDetails(this.props.location.state.therapiesDetails.id);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId === this.getTherapyDetailApiCallId){
        this.setState({trendingTherapiesDetail:responseJson.data});
      }
    }
    // Customizable Area End
  }

  scrollToTop=()=>{
    this.myRef.current.scroll(0,0)
    window.scroll(0,0);
  }
  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
  };

  getTherapiesDetails=(id:any)=>{

    const header = {
      "Content-Type": "application/json",
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getTherapyDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_categories/show_trending_therapies?id=${id}&is_request=web`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
        );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
    }
}
// Customizable Area End

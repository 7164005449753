import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End
export const configJSON = require("./config.js");
// Customizable Area Start
export interface HospitalsImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start
export interface SymptomNameType {
  id: number;
  name: string;
}
// Customizable Area End

// Customizable Area Start
export interface MinimalType {
  id: string;
  therapy_name: string;
}
// Customizable Area End

// Customizable Area Start
export interface HospitalsListType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    distance: number;
    address: string;
    hospital_image: Array<HospitalsImagesType>;
  };
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  HospitalsDataList: Array<HospitalsListType>;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class HospitalListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getHospitalsApiCallId: string | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      HospitalsDataList: [],
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getTopHospitalsShow();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isHospitalDetailsResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInHospitalDetailaResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isHospitalDetailsResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInHospitalDetailaResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getHospitalsApiCallId) {
      this.getTopHospitalsSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {};
  // Customizable Area End

  getTopHospitalsShow = async () => {
    this.getHospitalsApiCallId = ApiCall({
      contentType: configJSON.getTherapyDetailsApiContentType,
      method: configJSON.getTherapyDetailsAPiMethod,
      endPoint: `${configJSON.getHospitalsAPiEndPoint}?is_request=web`,
    });
  };

  getTopHospitalsSuccessCallBack = (res: any) => {
    this.setState({
      HospitalsDataList: res.data,
    });
  };
}
// Customizable Area End

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web"
// Customizable Area End

export const configJSON = require("./config.js");


// Customizable Area Start
export interface OurBrandType {
    id: string,

    type: string,
    attributes: {
        name: string,
        description: string,
        brand_image: string
    }
}
// Customizable Area End

// Customizable Area Start
export interface HospitalsImagesType {
    id: number,
    url: string
}
// Customizable Area End
// Customizable Area Start
export interface SymptomNameType {
    id: number,
    name: string
}
// Customizable Area End

// Customizable Area Start
export interface MedicineDetailsType {
    id: string,
    type: string,
    attributes: {
        id: number,
        medicine_name: string,
        medicine_quantity:number;
        total_amount: number,
        discount: number,
        manufacturer_name:string,
        brand_id:number,
        featured_therapy_id: number,
        symptom_id:number,
        add_offers:string,
        stock_qty:number,
        product_details:string,
        medicine_images:Array<HospitalsImagesType>,
        discounted_price:number,

    }
}
// Customizable Area End 


export interface Props {
    // Customizable Area Start
    id: string;
    history: any;
    location: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    medicineDetails: MedicineDetailsType;
    offerSlice:boolean,
    addionalSlice:boolean,
    showPopup:boolean,
    isLoginUser:boolean;
    loading:boolean;
 
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class MedicinepageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getMedicineDetailsApiCallId: string | null = null;
    

    // Customizable Area Enddata:

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            showPopup:false,
            isLoginUser:true,
            // Customizable Area Start
            medicineDetails: {
                id: '' ,
                type:'',
                attributes:{
                    id: 0,
                    medicine_name:"",
                    medicine_quantity:0,
                    total_amount: 0,
                    discount:0,
                    manufacturer_name:"", 
                    brand_id:0,
                    featured_therapy_id: 0,
                    symptom_id:0,
                    add_offers: "",
                    stock_qty:0,
                    product_details: "",
                    medicine_images:[],
                    discounted_price:0
                },
            },
            offerSlice:true,
            addionalSlice:true,
            loading:false
          
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getMedicineDetailsShow();
        
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (this.validOKResponse(responseJson)) {
                this.apiSuccessOKCallBacks(apiRequestCallId, responseJson);
            } else if (this.inValidOkResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
validOKResponse = (responseJson: any) => {
        return responseJson && responseJson.data;
    };
    // Customizable Area End

    // Customizable Area Start
    inValidOkResponse = (responseJson: any) => {
        return responseJson && responseJson.errors;
    };
    // Customizable Area End

    // Customizable Area Start
    apiSuccessOKCallBacks = (apiRequestCallId: any, responseJson: any) => {
        console.log("@@@ iresponseJson", responseJson)
        if (apiRequestCallId === this.getMedicineDetailsApiCallId) {
            this.getMedicineDetailsDataSuccessCallBack(responseJson);
        }

    };
    // Customizable Area End

    // Customizable Area Start
    apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getMedicineDetailsApiCallId) {
            this.getMedicineDetailsFailureCallBack(responseJson);
        }


    };
    // Customizable Area End

    handleMedicineDetailsData = (res:any) => {
        this.setState({
            medicineDetails:res.data
        })
    }


    getMedicineDetailsShow = async () => {
        this.getMedicineDetailsApiCallId = ApiCall({
            contentType: configJSON.getOurservicesApiContentType,
            method: configJSON.getOurservicesAPiMethod,
            endPoint: `${configJSON.getMedicineDetailsAPiEndPoint}?id=3`,
        });
    };

    getMedicineDetailsDataSuccessCallBack = (res: any) => {
        console.log(res ,'resData');
        
        this.setState({
            medicineDetails: res.data
        })
    }
    getMedicineDetailsFailureCallBack = (err: any) => {
        console.log("@@@----error service", err)
    }
    showDetailsHandler = () => {
        this.setState({ showPopup: !this.state.showPopup });
    } 
    
    handleLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout succesfully",
          });
   
}
// Customizable Area End
}